import styled, { css } from 'styled-components';

import { Icon, Link } from '@powdr/components';
import {
  ThemeProps as Prop, Breakpoints, FontSize, FontFamily,
  Components,
} from '@powdr/constants';
import { componentColor, getFontStyles } from '@powdr/utils';

const CPNT = Components.MAIN_NAV;

export const StyledMainNavItem = styled.li`
  display: block;
  width: 100%;

  ${Breakpoints.mobile(css`
    height: inherit;
    overflow: hidden;
  `)}
`;

const sharedItemStyles = css`
  color: ${() => componentColor(Prop.CONTENT, CPNT, Prop.CONTENT)};
  ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.MED30, undefined, undefined)};
  text-align: left;
  line-height: 1.1;
`;

export const Level1WithChildren = styled.div`
  display: flex;
  justify-content: ${({ $isTopRow }) => (($isTopRow) ? 'flex-end' : 'flex-start')};
  flex-direction: column;
  height: 100%;

  .content-height {
    ${Breakpoints.desktopOnly(css`
      height: 100%;
    `)}
  }
`;

export const Level1OnlyLink = styled(Link)`
  ${sharedItemStyles}
`;

export const Level1ToggleWrapper = styled.div`
  background-color: ${() => componentColor(Prop.BACKGROUND, CPNT)};
  border-top: 5px solid ${() => componentColor(Prop.BORDER, CPNT, Prop.BORDER)};
  padding: 15px 0;
`;

export const Level1Toggle = styled.div`
  ${sharedItemStyles}
  display: flex;
  width: 100%;
  transition: transform 0.35s ease-in-out 0s;
  transform: translate3d(0px, 0px, 0px);
  text-align: left;

  ${Breakpoints.mobile(css`
    flex-direction: row-reverse;
    justify-content: space-between;
  `)}
`;

export const ToggleIcon = styled(Icon)`
  width: ${({ $showIcon }) => (($showIcon) ? '25px' : 0)};
  margin-right: ${({ $showIcon }) => (($showIcon) ? '10px' : 0)};
  transition:
    width ${({ $animDuration }) => $animDuration}ms ease-in-out,
    margin-right ${({ $animDuration }) => $animDuration}ms ease-in-out;

  ${Breakpoints.mobile(css`
    transform: ${({ $isOpen }) => (($isOpen) ? 'rotate(180deg)' : 'rotate(0deg)')};
    transition: transform ${({ $animDuration }) => $animDuration}ms ease-in-out;

  `)}

  svg {
    fill: ${() => componentColor(Prop.CONTENT, CPNT, Prop.CONTENT)};
    color: ${() => componentColor(Prop.CONTENT, CPNT, Prop.CONTENT)};
  }
`;
