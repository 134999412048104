import PropTypes from 'prop-types';
import React from 'react';

import { HtmlContentLoader, WysiwygStyleWrapper } from '@powdr/components';
import { useSeason } from '@powdr/hooks';
import { colorProfileByIndex } from '@powdr/utils';

import {
  StyledHeaderContent,
  StyledIcon,
  StyledUiHeader,
} from './styles';

export const UiHeader = ({
  id,
  headerAlignment,
  html,
  iconName,
  iconColor,
  paddingTop,
  paddingBottom,
  colorProfile,
  parentColorProfile,
  componentSeason,
}) => {
  const { checkSeasonality } = useSeason();
  const trueColorProfile = (colorProfile) ? colorProfileByIndex(colorProfile) : parentColorProfile;
  const iconColorProfile = (iconColor) ? colorProfileByIndex(iconColor) : trueColorProfile;

  return (
    checkSeasonality(componentSeason) && (
      <StyledUiHeader
        key={id}
        alignment={headerAlignment}
        $paddingTop={(paddingTop === null) ? 50 : paddingTop}
        $paddingBottom={(paddingBottom === null) ? 50 : paddingBottom}
      >
        {iconName && (
          <StyledIcon
            className="header-icon"
            height="50"
            name={iconName}
            alignment={headerAlignment}
            colorProfile={iconColorProfile}
          />
        )}
        <StyledHeaderContent
          $alignment={headerAlignment}
          colorProfile={trueColorProfile}
        >
          <WysiwygStyleWrapper colorProfile={parentColorProfile}>
            <HtmlContentLoader html={html?.value} allowRerender />
          </WysiwygStyleWrapper>
        </StyledHeaderContent>
      </StyledUiHeader>
    )
  );
};

UiHeader.propTypes = {
  id: PropTypes.string.isRequired,
  html: PropTypes.shape({
    value: PropTypes.string,
  }),
  headerAlignment: PropTypes.string,
  iconName: PropTypes.string,
  iconColor: PropTypes.number,
  paddingBottom: PropTypes.number,
  paddingTop: PropTypes.number,
  colorProfile: PropTypes.string,
  parentColorProfile: PropTypes.string,
  componentSeason: PropTypes.string,
};

UiHeader.defaultProps = {
  html: { value: '' },
  headerAlignment: 'center',
  iconName: '',
  iconColor: '',
  paddingTop: 50,
  paddingBottom: 50,
  colorProfile: '',
  parentColorProfile: null,
  componentSeason: null,
};
