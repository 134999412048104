import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DorPercentWheel } from '@powdr/components';
import { StatusProps } from '@powdr/constants';
import { getStatus } from '@powdr/stores/actions/status-action';
import { capitalizeAllWords } from '@powdr/utils';

import { StyledDorSectorReport, SectorGraph } from './styles';

export const DorSectorReport = ({
  relationships,
  isParentNavFeed,
}) => {
  const { sectorGraphs } = relationships;
  const dispatch = useDispatch();
  const useStatusData = useSelector((state) => state.status);
  const {
    error,
    data,
  } = useStatusData;
  const [reports, setReports] = useState([]);
  const season = StatusProps.SEASON.ALL_SEASONS;

  useEffect(() => {
    dispatch(getStatus());
  }, [dispatch]);

  useEffect(() => {
    if (data) setReports(data?.dorLiftsTrails.reports[season]);
  }, [data, season]);

  // ERRORS START
  if (error) {
    console.error('DOR Status API Failed:', error.message);
    return null;
  }
  // ERRORS END

  if (sectorGraphs?.length === 0 || reports?.length === 0) return null;

  return (
    <StyledDorSectorReport isParentNavFeed={isParentNavFeed}>
      {sectorGraphs.map((graph) => {
        const { sector, type, labelOverride } = graph;
        const sectorReport = {
          ...reports.filter((report) => report?.label === sector)
            .filter((x) => x)[0],
        };

        return (
          <SectorGraph isParentNavFeed={isParentNavFeed}>
            <DorPercentWheel
              openVal={sectorReport?.report?.[type]?.open}
              totalVal={sectorReport?.report?.[type]?.total}
            />
            <hr />
            <h3>{`${(labelOverride) || `${sector} ${capitalizeAllWords(type)}`}`}</h3>
          </SectorGraph>
        );
      })}
    </StyledDorSectorReport>
  );
};

DorSectorReport.propTypes = {
  relationships: PropTypes.shape().isRequired,
  isParentNavFeed: PropTypes.bool,
};

DorSectorReport.defaultProps = {
  isParentNavFeed: false,
};
