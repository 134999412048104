/* eslint-disable react/jsx-props-no-spreading */
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import styled, { css } from 'styled-components';

import {
  ReversibleTable, Link, Icon, Slider,
  HtmlContentLoader,
} from '@powdr/components';
import {
  BlockTypes, ThemeProps as Prop, Breakpoints, FontFamily, FontSize,
} from '@powdr/constants';
import { ContentBlockType } from '@powdr/model';
import {
  themeUtil, fixCmsLinks, getFontStyles,
} from '@powdr/utils';

const CPNT = BlockTypes.DETAIL_BLOCK;

// styled components
const StyledDetailBlock = styled.div`
  display: flex;

  ${Breakpoints.mobile(css`
    flex-direction: column;
  `)}
`;

const GalleryContainer = styled.div`
  padding: 0 30px;
  width: ${({ isSplit }) => ((isSplit) ? '50%' : '100%')};

  ${Breakpoints.mobile(css`
    width: 100% !important;
    padding: 0 30px 30px 30px;
  `)}
`;

const DataContainer = styled.div`
  padding: 0 30px;
  width: ${({ isSplit }) => ((isSplit) ? '50%' : '100%')};
  display: flex;
  flex-direction: column;

  ${Breakpoints.mobile(css`
    width: 100% !important;
  `)}
`;

const Title = styled.h2`
  color: ${({ colorProfile }) => themeUtil(Prop.HEADER, CPNT, colorProfile)};
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.MED20, undefined, undefined)};
  margin-bottom: 10px;
`;

const ShortDesc = styled.p`
  color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
  margin-bottom: 30px;
  line-height: 1.2;
`;

const Body = styled.div`
  color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
  line-height: 1.2;
  margin-bottom: 20px;
  width: 100%;

  p {
    ${() => getFontStyles(FontFamily.BASE_FONT, undefined, undefined, undefined)};
    color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
    margin-bottom: 10px;

    ${Breakpoints.large(css`
      ${() => getFontStyles(FontFamily.BASE_FONT, undefined, 1.25, undefined)};
    `)}

    ${Breakpoints.larger(css`
      ${() => getFontStyles(FontFamily.BASE_FONT, undefined, 1.5, undefined)};
    `)}
  }

  li {
    ${() => getFontStyles(FontFamily.BASE_FONT, undefined, undefined, undefined)};
    margin-bottom: 5px;

    ${Breakpoints.large(css`
      ${() => getFontStyles(FontFamily.BASE_FONT, undefined, 1.1, undefined)};
    `)}

    ${Breakpoints.larger(css`
      ${() => getFontStyles(FontFamily.BASE_FONT, undefined, 1.2, undefined)};
    `)}
  }

  ul {
    margin-left: 15px;
    list-style: disc;
  }

  b {
    font-weight: bold;
  }

  a:not(.btn) {
    transition: color .25s ease-out, background-color .25s ease-out;
    color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};

    &:hover {
      color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT_HOVER, CPNT, colorProfile)};
    }
  }

  .btn {
    ${() => getFontStyles(FontFamily.SECONDARY_FONT, undefined, undefined, undefined)};
    line-height: 1;
    border: none;
    cursor: pointer;
    display: inline-block;
    position: relative;
    text-align: center;
    width: auto;
    height: auto;
    padding: 16px 20px;
    text-transform: none;
    margin: 0;
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};

    &:hover {
      color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
      background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
    }
  }

  /* ${Breakpoints.mobile(css`
    overflow: scroll;
  `)} */
`;

const StatContainer = styled.div`
  margin-bottom: 20px;
`;

const MainLink = styled(Link)`
  display: flex;
  align-items: center;
  color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};
  text-transform: ;
  ${() => getFontStyles(FontFamily.SECONDARY_FONT, undefined, undefined, undefined)};

  .link-icon {
    margin-left: 10px;

    svg {
      fill: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};
      color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};
    }
  }

  &:hover, &:focus {
    color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT_HOVER, CPNT, colorProfile)};

    .link-icon {
      svg {
        fill: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT_HOVER, CPNT, colorProfile)};
        color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT_HOVER, CPNT, colorProfile)};
      }
    }
  }

  &:focus {
    outline: 2px solid ${({ colorProfile }) => themeUtil(Prop.LINK_TXT_HOVER, CPNT, colorProfile)};
    outline-offset: 2px;
  }
`;

const StyledImage = styled(GatsbyImage)``;

const Image = styled.img``;

export const DetailBlock = ({
  title, copy, body, reversibleTable, slider, image, isStaticImageLink, colorProfile,
  buttonEnabled, buttonText, buttonLink,
}) => (
  <StyledDetailBlock>
    {(image || slider) && (
      <GalleryContainer isSplit={(image || slider)}>
        {(!isStaticImageLink && image && !slider)
          && <StyledImage image={image.src} alt={image?.altText} />}
        {(isStaticImageLink && image && !slider)
          && <Image src={image.src} alt={image?.altText} />}
        {(slider) && <Slider {...slider} />}
      </GalleryContainer>
    )}
    <DataContainer isSplit={(image || slider)}>
      {(title) && <Title colorProfile={colorProfile}>{title}</Title>}
      {(copy) && <ShortDesc colorProfile={colorProfile}>{copy}</ShortDesc>}
      {(body) && (
        <Body colorProfile={colorProfile}>
          <HtmlContentLoader html={body} allowRerender />
        </Body>
      )}
      {(reversibleTable && reversibleTable?.tables) && (
        <StatContainer>
          <ReversibleTable colorProfile={colorProfile} data={reversibleTable} />
        </StatContainer>
      )}
      {(buttonEnabled) && (
        <MainLink colorProfile={colorProfile} href={buttonLink}>
          {buttonText}
          <Icon
            className="link-icon"
            name="ui-arrow-thin-right"
            height="14"
          />
        </MainLink>
      )}
    </DataContainer>
  </StyledDetailBlock>
);

DetailBlock.propTypes = PropTypes.objectOf(ContentBlockType).isRequired;
