import PropTypes from 'prop-types';
import React, {
  useContext, useCallback, useState, useRef,
} from 'react';

import { Icon } from '@powdr/components';
import {
  CarouselControlPlacement, CarouselPaginationType,
  Components,
  KeydownEventCodes, NavigationDirection, ScreenType,
} from '@powdr/constants';
import { AppContext } from '@powdr/context';
import { useSeason, useWindowResize } from '@powdr/hooks';

import { HeroSlide } from './components';
import { StyledContentBlockHero, ScrollButton, HeroCarousel } from './styles';

export const ContentBlockHero = ({
  isAutoRotate,
  isEnableMobileVideoAutoplay,
  isImageDictatesHeight,
  isL1HeroOverride,
  slideOffsetDirection,
  slideOffsetAdjustment,
  isAltCopyDisplay,
  screenHeightOverride,
  scrollToName,
  hideOnPlatform,
  componentSeason,
  relationships,
}) => {
  const { isMobile, fullHeaderHeight } = useContext(AppContext);
  const [keyboardControlEnabled, setKeyboardControlEnabled] = useState(false);
  const heroCarouselRef = useRef();
  const { checkSeasonality } = useSeason();
  const resize = useWindowResize();
  const { heroSlides } = relationships || [];
  const showScrollButton = (screenHeightOverride >= 100 && !isImageDictatesHeight);

  const navigate = useCallback(
    (direction) => (
      (direction === NavigationDirection.PREVIOUS)
        ? heroCarouselRef.current?.slidePrev()
        : heroCarouselRef.current?.slideNext()),
    [heroCarouselRef],
  );

  const keyboardControl = useCallback((e) => {
    if (!keyboardControlEnabled || !heroCarouselRef?.current) return;

    if (e.keyCode === KeydownEventCodes.LEFT) {
      navigate(NavigationDirection.PREVIOUS);
    }

    if (e.keyCode === KeydownEventCodes.RIGHT) {
      navigate(NavigationDirection.NEXT);
    }
  }, [keyboardControlEnabled, navigate]);

  const heroScroll = () => {
    window.scrollTo({
      behavior: 'smooth',
      top: resize.windowHeight - fullHeaderHeight,
    });
  };

  const handleHideHero = () => {
    if (hideOnPlatform) {
      if ((isMobile && hideOnPlatform === ScreenType.MOBILE)
        || (!isMobile && hideOnPlatform === ScreenType.DESKTOP)
      ) return true;
    }

    if (!checkSeasonality(componentSeason)) return true;

    if (isL1HeroOverride) return true;

    return false;
  };

  return (
    (!handleHideHero()) && (
      <StyledContentBlockHero
        id={scrollToName}
        heroHeight={screenHeightOverride}
        isImageDictatesHeight={isImageDictatesHeight}
        tabIndex={0}
        onFocus={() => setKeyboardControlEnabled(true)}
        onBlur={() => setKeyboardControlEnabled(false)}
        onKeyDown={(e) => keyboardControl(e)}
        aria-label="Use left and right arrow keys to navigate the carousel"
      >
        <HeroCarousel
          $moveControlsUp={showScrollButton}
          controlSettings={{
            /* navigation: {
              placement: CarouselControlPlacement.ON_SLIDE,
              iconName: 'ui-arrow-left',
              iconSize: 40,
              classes: {
                [NavigationDirection.PREVIOUS]: 'hero-control-previous',
                [NavigationDirection.NEXT]: 'hero-control-next',
              },
            }, */
            pagination: {
              placement: CarouselControlPlacement.ON_SLIDE,
              type: CarouselPaginationType.BULLETS,
              class: 'hero-pagination',
            },
          }}
          componentTheme={Components.CONTENT_BLOCK_HERO}
          isAutoRotate={isAutoRotate}
        >
          {heroSlides.filter((s) => !s.isHideSlide).map((slide) => (
            <HeroSlide
              key={`${slide.id}`}
              slide={slide}
              slideOffsetDirection={slideOffsetDirection}
              slideOffsetAdjustment={slideOffsetAdjustment}
              screenHeightOverride={screenHeightOverride}
              isEnableMobileVideoAutoplay={isEnableMobileVideoAutoplay}
              isAltCopyDisplay={isAltCopyDisplay}
            />
          ))}
        </HeroCarousel>
        {(showScrollButton) && (
          <ScrollButton className="bounce" onClick={() => heroScroll()} aria-label="Scroll to Content">
            <Icon name="ui-arrow-scroll" width="50" height="50" />
          </ScrollButton>
        )}
      </StyledContentBlockHero>
    )
  );
};

ContentBlockHero.propTypes = {
  isAutoRotate: PropTypes.bool,
  componentSeason: PropTypes.string,
  slideOffsetDirection: PropTypes.string,
  slideOffsetAdjustment: PropTypes.number,
  isEnableMobileVideoAutoplay: PropTypes.bool,
  isImageDictatesHeight: PropTypes.bool,
  isL1HeroOverride: PropTypes.bool,
  screenHeightOverride: PropTypes.number,
  scrollToName: PropTypes.string,
  isAltCopyDisplay: PropTypes.bool,
  hideOnPlatform: PropTypes.string,
  relationships: PropTypes.shape().isRequired,
};

ContentBlockHero.defaultProps = {
  isAutoRotate: true,
  componentSeason: null,
  slideOffsetDirection: null,
  slideOffsetAdjustment: null,
  isEnableMobileVideoAutoplay: false,
  isImageDictatesHeight: false,
  isL1HeroOverride: false,
  isAltCopyDisplay: false,
  screenHeightOverride: null,
  hideOnPlatform: null,
  scrollToName: null,
};
