import PropTypes from 'prop-types';
import React from 'react';

import { UiTable, UiHtml } from '@powdr/components';

import { StyledGlobalTable } from './styles';

export const GlobalTable = ({
  className,
  parentColorProfile,
  tables,
  html,
}) => (
  <StyledGlobalTable className={className}>
    <>
      {(tables) && (
        <UiTable
          tables={[tables]}
          parentColorProfile={parentColorProfile}
        />
      )}
      {(html) && (
        <UiHtml
          html={html}
          parentColorProfile={parentColorProfile}
        />
      )}
    </>
  </StyledGlobalTable>
);

GlobalTable.propTypes = {
  className: PropTypes.string,
  parentColorProfile: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  tables: PropTypes.shape(),
  html: PropTypes.shape(),
};

GlobalTable.defaultProps = {
  className: '',
  parentColorProfile: null,
  tables: null,
  html: null,
};
