import PropTypes from 'prop-types';
import React, {
  useState, useEffect, useCallback, useContext, useRef,
} from 'react';

import { ColorProfiles } from '@powdr/constants';
import { AppContext } from '@powdr/context/app-context';
import { getImageAltText, setChildFocus } from '@powdr/utils';

import { HotspotModal } from './components';
import {
  StyledHotspotImage, Image, HotspotsContainer,
  Hotspot, HotspotIcon,
  HotspotList, HotspotListItem, ItemTitle, ItemIcon, HotspotListContainer,
} from './styles';

export const HotspotImage = ({
  colorProfile,
  isActive,
  modalStateChange,
  isShowIconTitles,
  relationships,
}) => {
  const { isMobile } = useContext(AppContext);
  const { gatsbyImage: mainImage } = relationships?.mainImage?.relationships?.image || {};
  const { hotspotArray } = relationships?.children?.relationships || [];
  const [activeItemIndex, setActiveItemIndex] = useState(null);
  const hotspotsParentRef = useRef(null);

  const handleClose = useCallback((isViaKeypress) => {
    setActiveItemIndex(null);
    modalStateChange(false);

    if (isViaKeypress) {
      setChildFocus(hotspotsParentRef, activeItemIndex);
    }
  }, [modalStateChange, hotspotsParentRef, activeItemIndex]);

  const handleOpen = (idx) => {
    setActiveItemIndex(idx);
    modalStateChange(true);
  };

  return (
    <StyledHotspotImage>
      {(!isMobile) && (
        <>
          {(mainImage) && (
            <Image
              image={mainImage}
              alt={getImageAltText(relationships?.mainImage?.relationships?.image)}
            />
          )}
          <HotspotsContainer ref={hotspotsParentRef}>
            {hotspotArray?.map((hotspot, idx) => (
              <Hotspot
                key={hotspot.id}
                tabIndex={(isActive) ? '-1' : '0'}
                colorProfile={colorProfile || ColorProfiles.BASE}
                height={hotspot.iconHeight}
                iconVerticalCoordinate={hotspot.iconVerticalCoordinate}
                iconHorizontalCoordinate={hotspot.iconHorizontalCoordinate}
                onMouseDown={(e) => e.preventDefault()}
                onClick={() => handleOpen(idx, 'via hotspot')}
              >
                <HotspotIcon
                  name={hotspot.iconName || 'content-pin'}
                  height={hotspot.iconHeight}
                  colorProfile={colorProfile || ColorProfiles.BASE}
                />
              </Hotspot>
            ))}
          </HotspotsContainer>
        </>
      )}
      {(isMobile) && (
        <HotspotListContainer>
          <HotspotList>
            {hotspotArray?.map((hotspot, idx) => (
              <HotspotListItem
                key={hotspot.id}
                onClick={() => handleOpen(idx)}
              >
                {(isShowIconTitles) && <ItemTitle>{hotspot.title}</ItemTitle>}
                <ItemIcon
                  name={hotspot.iconName || 'content-pin'}
                  height="45"
                  colorProfile={colorProfile || ColorProfiles.BASE}
                />
              </HotspotListItem>
            ))}
          </HotspotList>
        </HotspotListContainer>
      )}
      {(activeItemIndex !== null) && (
        <HotspotModal
          data={hotspotArray[activeItemIndex]}
          handleClose={handleClose}
          colorProfile={colorProfile || ColorProfiles.BASE}
        />
      )}
    </StyledHotspotImage>
  );
};

HotspotImage.propTypes = {
  colorProfile: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
  modalStateChange: PropTypes.func.isRequired,
  relationships: PropTypes.shape().isRequired,
  isShowIconTitles: PropTypes.bool,
};

HotspotImage.defaultProps = {
  colorProfile: ColorProfiles.BASE,
  isShowIconTitles: true,
};
