import PropTypes from 'prop-types';
import React from 'react';
import ReactSelect, { components } from 'react-select';
import styled, { css } from 'styled-components';

import { Icon } from '@powdr/components/icon';
import {
  Breakpoints, FontFamily, FontSize, ThemeProps as Prop,
} from '@powdr/constants';
import { getFontStyles, themeUtil } from '@powdr/utils';

const SelectContainer = styled.div`
  width: 100%;
  ${() => getFontStyles(FontFamily.BASE_FONT, FontSize.REG50, undefined, undefined)};
  margin-right: 15px;

  ${Breakpoints.desktopOnly(css`
    max-width: 275px;
  `)}

  ${Breakpoints.mobile(css`
    margin-bottom: 15px;
  `)}

  .react-select__control {
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 0;
    box-shadow: none;
    width: 100%;
    margin-right: 15px;

    &--is-focused {
      border-color: #007FBB;
    }

    ${Breakpoints.mobile(css`
      width: 100%;
      max-width: none;
      margin-right: 0;
    `)}
  }

  .react-select__menu-list {
    padding: 0;
    max-height: 200px;
  }

  .react-select__value-container {
    padding: 0;
  }

  .react-select__single-value {
    white-space: normal;
  }

  .react-select__input {
    background-clip: padding-box;
    line-height: 1.25;
    height: auto;
    overflow: hidden;
    margin: 0;
  }

  .react-select__menu {
    margin: -1px 0 0 0;
    width: auto;
    min-width: 100%;
    border: 1px solid rgba(0,0,0,.15);
    border-top: 0 none;
    border-radius: 0;
  }

  .react-select__option {
    background-color: inherit;
    border-bottom: 1px solid rgba(0,0,0,.15);
    padding: 15px;
    color: inherit;

    &:hover, &--is-selected {
      background-color: #f5f5f5;
    }
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__placeholder,
  .react-select__single-value,
  .react-select__input-container {
    padding: 10px 0 10px 15px;
  }
  .react-select__dropdown-indicator {
    padding: 10px 15px 10px 0;
  }

  .react-select__placeholder {
    color: #55595c;
  }
`;

const DropdownIcon = styled(Icon)`
  svg {
    fill: ${({ colorProfile }) => themeUtil(Prop.ICON, undefined, colorProfile)};
    color: ${({ colorProfile }) => themeUtil(Prop.ICON, undefined, colorProfile)};
  }
`;

const CustomDropdownIndicator = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <components.DropdownIndicator {...props}>
    <DropdownIcon className="select-icon" width="15" height="15" name="ui-chevron-down" />
  </components.DropdownIndicator>
);

export const Select = ({
  className, component, radio, handleSelectChange,
}) => {
  const {
    placeholder,
    choices,
    name,
    defaultValue,
  } = component;
  const selectedRadioChoice = radio ? choices.find((choice) => choice.select) : null;

  return (
    <SelectContainer className={className}>
      <ReactSelect
        classNamePrefix="react-select"
        placeholder={selectedRadioChoice?.label || defaultValue?.label || placeholder || 'Choose...'}
        options={choices}
        defaultValue={selectedRadioChoice?.value || defaultValue?.value}
        onChange={(selected) => handleSelectChange(selected, name)}
        components={{
          DropdownIndicator: CustomDropdownIndicator,
        }}
      />
    </SelectContainer>
  );
};

Select.propTypes = {
  className: PropTypes.string.isRequired,
  colorProfile: PropTypes.string,
  component: PropTypes.shape({
    placeholder: PropTypes.string,
    name: PropTypes.string,
    choices: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        label: PropTypes.string,
      }),
    ).isRequired,
    defaultValue: PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  }).isRequired,
  radio: PropTypes.bool,
  handleSelectChange: PropTypes.func,
};

Select.defaultProps = {
  colorProfile: 'base',
  radio: false,
  handleSelectChange: () => {},
};
