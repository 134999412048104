import PropTypes from 'prop-types';
import React, {
  useCallback, useMemo, useState, useEffect,
} from 'react';
import { useDispatch } from 'react-redux';

import { Tab, Tabs } from '@powdr/components';
import { EcommerceTab } from '@powdr/components/ecommerce-widget';
import { QueryParamNames } from '@powdr/constants/constants';
import { resetEcommerceStore } from '@powdr/stores/actions/ecommerce-action';

import {
  StyledEcommerceWidget,
  IconWrapper,
  TabIcon,
} from './styles';

export const EcommerceWidget = ({
  cartCheckoutLink,
  cartCheckoutButtonText,
  relationships,
}) => {
  const dispatch = useDispatch();
  const { ecommTabs, additionalComponents } = relationships || [];
  const [tabKey, setTabKey] = useState();
  const [selectedDate, setSelectedDate] = useState(null);

  const onStateReset = () => {
    setSelectedDate(null);
    setTabKey(Math.random());
  };

  useEffect(() => {
    // Reset products onload in case user came from different product selector
    dispatch(resetEcommerceStore);
    onStateReset();
    // TODO: some of these variables might be something we control via CMS at some point
    window.dataLayer.push({
      event: 'view_item_list', // Fired on widget load (page view)
      ecommerce: {
        items: [
          {
            index: 0,
            item_list_id: 'category_page',
            item_list_name: 'Category Page',
            item_name: 'Killington Lift Ticket',
            affiliation: 'Killington',
            currency: 'USD',
            item_category: 'Lift Tickets',
            price: null,
          },
        ],
      },
    });
    return () => {
      // On unmount of ecommerce widget clear the product query param
      if (typeof URLSearchParams !== 'undefined') {
        // const params = new URLSearchParams(document.location.search);
        // params.delete(QueryParamNames.PRODUCT);

        // For some reason the above doesn't work, so this is our temp solution
        // Maybe an conflict with the URLSearchParams API and Gatsby/Reach Router?
        window.history.pushState({}, document.title, window.location.pathname);
      }
    };
  }, []);

  const getTabIcon = useCallback((iconName) => (
    <IconWrapper>
      <TabIcon name={iconName} width="25" />
    </IconWrapper>
  ), []);

  const tabs = useMemo(() => ecommTabs.map((panel) => (
    <Tab
      key={panel.id}
      label={panel.title}
      value={panel.productAttribute}
      icon={getTabIcon(panel.iconName)}
    >
      <EcommerceTab
        additionalComponents={additionalComponents}
        calendarDisclaimer={panel.calendarDisclaimer}
        calendarTitle={panel.calendarTitle}
        cartCheckoutLink={cartCheckoutLink}
        cartCheckoutButtonText={cartCheckoutButtonText}
        choiceChipsAttributeId={panel.choiceChipsAttributeId}
        defaultChoiceChip={panel.selectedChoiceChip}
        defaultVariantId={panel.defaultVariantId}
        endScreenAttributeId={panel.endScreenAttributeId}
        handleDateChange={setSelectedDate}
        handleStateReset={onStateReset}
        hideChoiceChips={panel.hideChoiceChips || []}
        key={`${panel.id}-${tabKey}`}
        productAttribute={panel.productAttribute}
        productSlug={panel.productSlug}
        searchButtonText={panel.searchButtonText}
        selectedChoiceChipId={panel.selectedChoiceChip}
        selectedDate={selectedDate}
        tabTitle={panel.title}
      />
    </Tab>
  )), [
    cartCheckoutLink,
    cartCheckoutButtonText,
    ecommTabs,
    additionalComponents,
    getTabIcon,
    selectedDate,
    tabKey,
  ]);

  return (
    <StyledEcommerceWidget>
      <Tabs className="product-tabs" queryParamName={QueryParamNames.PRODUCT}>
        {tabs}
      </Tabs>
    </StyledEcommerceWidget>
  );
};

EcommerceWidget.propTypes = {
  cartCheckoutLink: PropTypes.string,
  cartCheckoutButtonText: PropTypes.string,
  relationships: PropTypes.shape(),
};

EcommerceWidget.defaultProps = {
  cartCheckoutLink: '',
  cartCheckoutButtonText: '',
  relationships: {},
};
