import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Icon } from '@powdr/components';
import {
  ThemeProps as Prop, Components, NavigationDirection, handleCarouselNavigation,
} from '@powdr/constants';
import { componentColor, themeUtil } from '@powdr/utils';

const CPNT = Components.CONTENT_CAROUSEL;

export const StyledControlArrow = styled.button`
  cursor: pointer;
`;

export const ControlArrowInner = styled.div`
  ${({ $direction }) => (($direction !== NavigationDirection.PREVIOUS) && 'transform: rotate(-180deg);')};
  transition: all 0.25s ease;

  .control-arrow-icon {
    svg {
      transition: all 0.25s ease;
      fill: ${({ $colorProfile, $componentTheme }) => (($componentTheme) ? componentColor(Prop.DIRECTIONAL_NAV, $componentTheme, Prop.BTN_BG) : themeUtil(Prop.BTN_BG, CPNT, $colorProfile))};
      color: ${({ $colorProfile, $componentTheme }) => (($componentTheme) ? componentColor(Prop.DIRECTIONAL_NAV, $componentTheme, Prop.BTN_BG) : themeUtil(Prop.BTN_BG, CPNT, $colorProfile))};
    }
  }


  &:hover, &:focus {
    .control-arrow-icon {
      svg {
        transition: all 0.25s ease;
        fill: ${({ $colorProfile, $componentTheme }) => (($componentTheme) ? componentColor(Prop.DIRECTIONAL_NAV_HOVER, $componentTheme, Prop.BTN_BG_HOVER) : themeUtil(Prop.BTN_BG_HOVER, CPNT, $colorProfile))};
        color: ${({ $colorProfile, $componentTheme }) => (($componentTheme) ? componentColor(Prop.DIRECTIONAL_NAV_HOVER, $componentTheme, Prop.BTN_BG_HOVER) : themeUtil(Prop.BTN_BG_HOVER, CPNT, $colorProfile))};
      }
    }
  }
`;

export const ControlArrowIcon = styled(Icon)``;

export const ControlArrow = ({
  className,
  carouselRef,
  direction,
  iconName,
  iconSize,
  colorProfile,
  componentTheme,
}) => (
  <StyledControlArrow
    className={className}
    onClick={(e) => {
      e.stopPropagation();
      handleCarouselNavigation(carouselRef, direction);
    }}
    onMouseDown={(e) => { e.preventDefault(); }}
    $direction={direction}
    $colorProfile={colorProfile}
    $componentTheme={componentTheme}
  >
    <ControlArrowInner
      className="control-arrow-inner"
      $direction={direction}
      $colorProfile={colorProfile}
      $componentTheme={componentTheme}
    >
      <ControlArrowIcon
        className="control-arrow-icon"
        name={iconName}
        width={iconSize}
        height={iconSize}
      />
    </ControlArrowInner>
  </StyledControlArrow>
);

ControlArrow.propTypes = {
  className: PropTypes.string,
  carouselRef: PropTypes.shape().isRequired,
  direction: PropTypes.string,
  iconName: PropTypes.string.isRequired,
  iconSize: PropTypes.number,
  colorProfile: PropTypes.string,
  componentTheme: PropTypes.oneOf(Object.values(Components)),
};

ControlArrow.defaultProps = {
  className: '',
  direction: '',
  iconSize: 25,
  colorProfile: '',
  componentTheme: null,
};
