import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Icon } from '@powdr/components';
import {
  ThemeProps as Prop, Components,
} from '@powdr/constants';
import { componentColor, themeUtil } from '@powdr/utils';

const CPNT = Components.CONTENT_CAROUSEL;

export const StyledDecorativeLine = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 100%;
`;

export const DecorativeLineInner = styled.div`
  height: 2px;
  width: 100%;
  background-color: ${({ $colorProfile, $componentTheme }) => (($componentTheme) ? componentColor(Prop.PAGINATION, $componentTheme, Prop.BTN_BG) : themeUtil(Prop.BTN_BG, CPNT, $colorProfile))};
`;

export const DecorativeLine = ({
  className,
  colorProfile,
  componentTheme,
}) => (
  <StyledDecorativeLine
    className={className}
  >
    <DecorativeLineInner
      $colorProfile={colorProfile}
      $componentTheme={componentTheme}
    />
  </StyledDecorativeLine>
);

DecorativeLine.propTypes = {
  className: PropTypes.string,
  colorProfile: PropTypes.string,
  componentTheme: PropTypes.oneOf(Object.values(Components)),
};

DecorativeLine.defaultProps = {
  className: '',
  colorProfile: '',
  componentTheme: null,
};
