import { FetchStatus } from '@powdr/constants';

import { SENSORS_PENDING, SENSORS_SUCCESS, SENSORS_ERROR } from '../types';

const initialState = {
  sensors: null,
  status: FetchStatus.IDLE,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SENSORS_PENDING:
      return {
        ...state,
        status: FetchStatus.LOADING,
      };
    case SENSORS_SUCCESS:
      return {
        ...state,
        sensors: action.payload,
        status: FetchStatus.SUCCESS,
      };
    case SENSORS_ERROR:
      return {
        status: FetchStatus.ERROR,
        error: action.payload,
      };
    default: return state;
  }
};
