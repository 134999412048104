import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { HtmlContentLoader, WysiwygStyleWrapper, DorPercentWheel } from '@powdr/components';
import {
  FontFamily,
  Components,
  FontSize,
} from '@powdr/constants';
import { capitalizeAllWords, getFontStyles, getParkingAmountString } from '@powdr/utils';

const CPNT = Components.DOR_PARKING;

const WheelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
`;

const ParkingCircleText = styled.div`
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.MED20)};
`;

const ContentContainer = styled.div``;

export const Availability = ({
  content,
  data,
}) => (
  <>
    <ContentContainer>
      <WysiwygStyleWrapper>
        <HtmlContentLoader html={content} />
      </WysiwygStyleWrapper>
    </ContentContainer>
    <WheelContainer>
      <DorPercentWheel
        openVal={data.parkingValue || 0}
        totalVal={data.parkingMax || 0}
      >
        <ParkingCircleText>
          {capitalizeAllWords(getParkingAmountString(data.parkingValue || 0, data.parkingMax || 0))}
        </ParkingCircleText>
      </DorPercentWheel>
    </WheelContainer>
  </>
);

Availability.propTypes = {
  content: PropTypes.string,
  data: PropTypes.shape({
    parkingValue: PropTypes.number,
    parkingMax: PropTypes.number,
  }),
};

Availability.defaultProps = {
  content: null,
  data: {
    parkingValue: 0,
    parkingMax: 0,
  },
};
