import PropTypes from 'prop-types';
import React from 'react';

import { StyledBannerAd, AdFrame } from './styles';

export const BannerAd = ({
  marginTop,
  marginBottom,
  alignment,
  vendor,
  sizes,
}) => (
  <StyledBannerAd>
    {(vendor) && (
      <AdFrame
        className={alignment}
        src={`/static/banner-ads/${vendor}/${sizes}/index.html`}
        sizes={sizes}
        marginTop={marginTop}
        marginBottom={marginBottom}
      />
    )}
  </StyledBannerAd>
);

BannerAd.propTypes = {
  marginTop: PropTypes.string,
  marginBottom: PropTypes.string,
  alignment: PropTypes.string,
  vendor: PropTypes.string.isRequired,
  sizes: PropTypes.string,
};

BannerAd.defaultProps = {
  marginTop: '0',
  marginBottom: '0',
  alignment: 'center',
  sizes: '1000x360xresponsive',
};
