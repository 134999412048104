import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import styled, { css } from 'styled-components';

import { Icon } from '@powdr/components/icon';
import { Link } from '@powdr/components/link';
import {
  AlternatingBlockLayoutOptions, BlockTypes, Breakpoints, FontFamily, FontSize, ThemeProps as Prop,
} from '@powdr/constants';
import { ContentBlockType } from '@powdr/model';
import {
  getDateInfo, clamp, themeUtil,
  getFontStyles,
} from '@powdr/utils';

// eslint-disable-next-line no-unused-vars
const CPNT = BlockTypes.IMAGE_FLAG;

// styled components
const ContentBlockImageFlag = styled.div`
  display: flex;
  position: relative;
  flex-direction: ${({ alternateBlockLayoutState }) => (
    (alternateBlockLayoutState === AlternatingBlockLayoutOptions.DEFAULT)
      ? 'row'
      : 'row-reverse'
  )};
  gap: 20px;

  .cb-title {
    margin-bottom: 5px;

    ${Breakpoints.mobile(css`
      ${() => getFontStyles(FontFamily.PRIMARY_FONT, '18px', undefined, undefined)};
    `)}
  }

  small {
    margin-bottom: 5px;

    ${Breakpoints.mobile(css`
      ${() => getFontStyles(FontFamily.BASE_FONT, '16px', undefined, undefined)};
    `)}
  }

  .cb-copy {
    margin-bottom: 15px;
    ${() => clamp(4)}

    ${Breakpoints.mobile(css`
      margin-bottom: 7px;
    `)}
  }

  ${Breakpoints.mobile(css`
    gap: 10px;
  `)}
`;

const ImageContainer = styled.div`
  width: 33.3%;
  min-height: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 66.6%;
  min-height: 100%;
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  width: fit-content;

  .link-icon {
    margin-left: 5px;
  }

  &:focus {
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
    outline: 2px solid ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
    outline-offset: 2px;
  }
`;

const Image = styled.img`
  min-height: 100%;
  height: initial;
  min-width: initial;
  width: initial;
  object-fit: cover;
`;

const StyledGatsbyImage = styled(GatsbyImage)`
  height: 100%;
`;

export const ImageFlag = ({
  title, copy, image, colorProfile, blockType, ariaLabel,
  buttonEnabled, buttonText, buttonLink, buttonTarget, buttonIconName, buttonClick,
  date, endDate, isOverlayEnabled, isStaticImageLink, alternateBlockLayoutState,
}) => (
  <ContentBlockImageFlag
    colorProfile={colorProfile}
    className={`content-block-${blockType}`}
    alternateBlockLayoutState={alternateBlockLayoutState}
  >
    <ImageContainer>
      {(image && !isStaticImageLink) && <StyledGatsbyImage image={image.src} alt={image?.altText} />}
      {(image && isStaticImageLink) && <Image src={image.src} alt={image?.altText} />}
    </ImageContainer>
    <ContentContainer>
      <h3 className="cb-title">{title}</h3>
      {(date) && <small>{ReactHtmlParser(getDateInfo(date, endDate, blockType).full)}</small>}
      <p className="cb-copy">{copy}</p>
      {(buttonEnabled && buttonText && !isOverlayEnabled) && (
      <StyledLink
        className="button with-background "
        href={buttonLink}
        target={buttonTarget}
        onClick={() => buttonClick()}
        aria-label={ariaLabel}
        role="button"
      >
        {buttonText}
        {(buttonIconName) && <Icon className="link-icon" name={buttonIconName} width="13" height="13" />}
      </StyledLink>
      )}
    </ContentContainer>
  </ContentBlockImageFlag>
);

ImageFlag.propTypes = PropTypes.objectOf(ContentBlockType).isRequired;
