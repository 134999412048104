// eslint-disable-next-line import/no-unresolved
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { PrimaryCarousel } from '@powdr/components';

import { SlideItem } from './components';
import { StyledSlider } from './styles';

export const Slider = ({
  // isThumbnailsEnabled, // this field exists in CMS but Angular code doesn't use it :shrug:
  relationships,
  parentColorProfile,
}) => {
  const { slides } = relationships;
  const slidesAdjusted = (slides.length === 2) ? slides.concat(slides) : slides;

  const SlideMap = () => useMemo(() => slidesAdjusted.map((item) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SlideItem {...item} />
  )), []);

  return (
    <StyledSlider className="slider">
      {(slides.length > 1)
        ? (
          <PrimaryCarousel
            colorProfile={parentColorProfile}
            isShowThumbnails
            isAutoRotate={false}
          >
            {SlideMap()}
          </PrimaryCarousel>
        ) : (
          SlideMap()
        )}
    </StyledSlider>
  );
};

Slider.propTypes = {
  relationships: PropTypes.shape().isRequired,
  parentColorProfile: PropTypes.string.isRequired,
};

Slider.defaultProps = {

};
