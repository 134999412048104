import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import {
  Breakpoints, CarouselControlPlacement, CarouselPaginationType, Components, NavigationDirection,
} from '@powdr/constants';

import { BulletPagination } from './bullet-pagination';
import { ControlArrow } from './control-arrow';
import { DecorativeLine } from './decorative-line';
import { FractionPagination } from './fraction-pagination';
import { Scrollbar } from './scrollbar';

const absolutelyPositionedControls = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 30px;
  text-align: center;
  position: absolute;
  z-index: 2;
`;

const standardControls = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 30px;
  text-align: center;

  ${({ $placement }) => (($placement === CarouselControlPlacement.BOTTOM) ? 'padding-top' : 'padding-bottom')}: 50px;

  ${Breakpoints.mobile(css`
    padding-left: 30px;
    padding-right: 30px;
  `)}
`;

export const StyledControlGroup = styled.div`
  ${({ $placement }) => (
    ($placement === CarouselControlPlacement.ON_SLIDE)
      ? absolutelyPositionedControls
      : standardControls)}
`;

export const ControlGroup = ({
  className,
  controlSettings,
  showArrows,
  showPagination,
  showScrollbar,
  showDecoration,
  placement,
  colorProfile,
  componentTheme,
  prevClass,
  nextClass,
  paginationClass,
  carouselRef,
  items,
  activeIndex,
}) => (
  <StyledControlGroup
    className={`${className || ''} carousel-control-group`}
    $placement={placement}
  >
    {/* Previous Arrow */}
    {(showArrows) && (
      <ControlArrow
        className={prevClass}
        carouselRef={carouselRef}
        colorProfile={colorProfile}
        componentTheme={componentTheme}
        direction={NavigationDirection.PREVIOUS}
        iconName={controlSettings.navigation.iconName}
        iconSize={controlSettings.navigation.iconSize}
      />
    )}

    {/* Pagination */}
    {(showPagination) && (
      (controlSettings.pagination.type === CarouselPaginationType.FRACTION) ? (
        <FractionPagination
          className={paginationClass}
          numItems={items.length}
          activeIndex={activeIndex}
          colorProfile={colorProfile}
          componentTheme={componentTheme}
        />
      ) : (
        <BulletPagination
          className={paginationClass}
          bullets={items}
          colorProfile={colorProfile}
          componentTheme={componentTheme}
          activeIndex={activeIndex}
          carouselRef={carouselRef}
          type={controlSettings.pagination.type}
        />
      )
    )}

    {/* Decorative Line */}
    {(showDecoration) && (
      <DecorativeLine
        colorProfile={colorProfile}
        componentTheme={componentTheme}
      />
    )}

    {/* Next Arrow */}
    {(showArrows) && (
      <ControlArrow
        className={nextClass}
        carouselRef={carouselRef}
        colorProfile={colorProfile}
        componentTheme={componentTheme}
        direction={NavigationDirection.NEXT}
        iconName={controlSettings.navigation.iconName}
        iconSize={controlSettings.navigation.iconSize}
      />
    )}

    {/* Carousel Scrollbar */}
    {(showScrollbar) && (<Scrollbar />)}
  </StyledControlGroup>
);

ControlGroup.propTypes = {
  className: PropTypes.string,
  controlSettings: PropTypes.shape().isRequired,
  showScrollbar: PropTypes.bool.isRequired,
  showArrows: PropTypes.bool.isRequired,
  showPagination: PropTypes.bool.isRequired,
  showDecoration: PropTypes.bool.isRequired,
  placement: PropTypes.string.isRequired,
  colorProfile: PropTypes.string.isRequired,
  componentTheme: PropTypes.oneOf(Object.values(Components)),
  prevClass: PropTypes.string.isRequired,
  nextClass: PropTypes.string.isRequired,
  paginationClass: PropTypes.string.isRequired,
  carouselRef: PropTypes.shape().isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  activeIndex: PropTypes.number.isRequired,
};

ControlGroup.defaultProps = {
  className: '',
  componentTheme: null,
};
