import PropTypes from 'prop-types';
import React from 'react';

import { componentFactory } from '@powdr/utils';

import { StyledColumnGroup } from './styles';

export const ColumnGroup = ({
  className,
  parentColorProfile,
  relationships,
}) => (
  <StyledColumnGroup className={`${className} column-group`}>
    {componentFactory(relationships?.components, parentColorProfile)}
  </StyledColumnGroup>
);

ColumnGroup.propTypes = {
  className: PropTypes.string,
  parentColorProfile: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  relationships: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

ColumnGroup.defaultProps = {
  className: '',
  parentColorProfile: null,
};
