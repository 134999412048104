import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { QueryParamProvider } from 'use-query-params';

import DorStore from '@powdr/stores/store-wrapper';

function generatePath(location) {
  return location.pathname + location.search;
}

const history = {
  push: (location) => {
    navigate(generatePath(location), { replace: false, state: location.state });
  },
  replace: (location) => {
    navigate(generatePath(location), { replace: true, state: location.state });
  },
};

// This is a Gatsby browser/SSR API component so don't change the name
export const wrapPageElement = ({ element, props }) => (
  <QueryParamProvider history={history} location={props.location}>
    <DorStore>
      {element}
    </DorStore>
  </QueryParamProvider>
);

wrapPageElement.propTypes = {
  element: PropTypes.instanceOf(Object).isRequired,
  props: PropTypes.instanceOf(Object).isRequired,
};
