import styled, { css } from 'styled-components';

import {
  ThemeProps as Prop, Components, Breakpoints,
  FontFamily,
  FontSize,
  GlobalLayoutValues,
} from '@powdr/constants';
import { getFontStyles, themeUtil } from '@powdr/utils';

const CPNT = Components.PAGE_HEADER;

export const StickyNavWrapper = styled.div`
  display: inline;
`;

export const StyledPageTitle = styled.h2`
  ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.LRG12, undefined, undefined)};
  margin-bottom: 30px;

  ${Breakpoints.mobile(css`
    margin-bottom: 0;
  `)}
`;

export const StyledPageHeader = styled.div`
  display: flex;
  justify-content: center;

  ${Breakpoints.mobile(css`
    background-color: ${() => themeUtil(Prop.BACKGROUND, CPNT)};
  `)}
`;

export const InnerWrapper = styled.div`
  max-width: ${GlobalLayoutValues.DESKTOP.WRAPPER_MAX}px;
  margin: 50px 7.5% 10px;
  margin: 50px round(nearest, 7.5%, 1px) 10px;
  width: 100%;

  ${Breakpoints.mobile(css`
    margin: 30px 4.5% 10px;
    margin: 30px round(nearest, 4.5%, 1px) 10px;
  `)}
`;

export const AlignmentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ThirdLevelNav = styled.nav`
  ul {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  li {
    flex: 0;
    margin-bottom: 20px;
  }

  h2 {
    margin-bottom: 0;
  }

  a {
    border: none;
    cursor: pointer;
    display: inline-block;
    position: relative;
    text-align: center;
    width: auto;
    height: auto;
    padding: 16px 20px;
    text-transform: none;
    margin: 0;
    background-color: ${() => themeUtil(Prop.BACKGROUND, CPNT)};
    color: ${() => themeUtil(Prop.HEADER, CPNT)};
    white-space: nowrap;
    ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.REG40, undefined, undefined)};
    transition: background-color 0.25s ease-out, color 0.25s ease-out;

    &:hover, &:focus {
      transition: background-color 0.25s ease-out, color 0.25s ease-out;
      background-color: ${() => themeUtil(Prop.BACKGROUND, CPNT)};
      color: ${() => themeUtil(Prop.LINK_TXT, CPNT)};
    }

    &:focus {
      outline: 2px solid ${() => themeUtil(Prop.LINK_TXT, CPNT)};
      outline-offset: -5px;
    }

    &.m-active {
      background-color: ${() => themeUtil(Prop.BTN_BG_ACTIVE, CPNT)};
      color: ${() => themeUtil(Prop.BTN_TXT_ACTIVE, CPNT)};

      &:hover {
        color: ${() => themeUtil(Prop.BTN_TXT_ACTIVE, CPNT)};
      }
    }
  }
`;

export const PageBackButton = styled.div`
  button {
    display: flex;
    text-transform: uppercase;
    flex-direction: row-reverse;
    align-items: center;
    margin-bottom: 20px;
    ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.REG40, undefined, undefined)};
    color: ${() => themeUtil(Prop.CONTENT, CPNT)};
    transition: color 0.15s ease-in;

    .levels-back-icon {
      margin-right: 5px;

      svg {
        transition: fill 0.15s ease-in;
        fill: ${() => themeUtil(Prop.CONTENT, CPNT)};
        color: ${() => themeUtil(Prop.CONTENT, CPNT)};
      }
    }

    &:hover, &:focus {
      color: ${() => themeUtil(Prop.LINK_TXT_HOVER, CPNT)};

      svg {
        fill: ${() => themeUtil(Prop.LINK_TXT_HOVER, CPNT)};
        color: ${() => themeUtil(Prop.LINK_TXT_HOVER, CPNT)};
      }
    }

    &:focus {
      outline: 2px solid ${() => themeUtil(Prop.LINK_TXT_HOVER, CPNT)};
      outline-offset: 2px;
    }
  }

  .level-back-icon {
    display: none;
  }
`;
