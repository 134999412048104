import styled, { css } from 'styled-components';

import {
  ThemeProps as Prop, Components, Breakpoints, ColorProfiles,
} from '@powdr/constants';
import { componentColor } from '@powdr/utils';

const CPNT = Components.ECOMMERCE_BANNER;

export const StyledSeasonToggle = styled.div`

&.season-toggle {
  height: 100%;
  position: absolute;
  left: 30px;

  &.hide {
    display: none;
  }

  ${Breakpoints.mobile(css`
    position: static;
  `)}

  button {
    display: block;
    height: 100%;
    width: 100%;

    &:focus {
      outline: 1px dotted #fff;
    }
  }

  ul,
  li,
  span {
    cursor: pointer;
    margin-bottom: 0;
  }

  ul {
    position: relative;
    width: 100%;
    height: 100%;
    white-space: nowrap;
    display: flex;

    &.winter .left-toggle svg {
      fill: #009CDB;
      color: #009CDB;
    }

    &.summer .right-toggle svg {
      fill: #ffcb00;
      color: #ffcb00;
    }

    li {
      display: flex;
      align-items: center;
      transition:0.5s;
      width: auto;
      height: 100%;
      white-space: nowrap;

      &.left-toggle {
        span {
          text-align: right;
        }
      }

      &.right-toggle {
        span {
          text-align: left;
        }

        .ecommerce-banner & {
          margin-left: 15px;
        }
      }

      span {
        transition:0.5s;
        //${({ theme }) => theme.fonts.PRI_BDY_MED};
        margin-left: 5px;
        //font-size: ${({ theme }) => theme.FontSize.REG10};
        font-weight: bold;
        padding: 9px 0;
        position: relative;
        text-transform: uppercase;
        height: 100%;
        color: ${() => componentColor(Prop.TOGGLE_INACTIVE, CPNT, Prop.BTN_BG_INACTIVE)};
      }
    }
  }

  ${Breakpoints.mobile(css`
    .season-buttons {
      flex-direction: column;
      height: unset;

      li {
        padding: 4px 0;
      }

      span {
        padding: 0;
      }

      &.summer {
        .winter-toggle {
          span {
            color: ${() => componentColor(Prop.TOGGLE_INACTIVE, CPNT, Prop.BTN_BG_INACTIVE)};
          }

          svg {
            fill: ${() => componentColor(Prop.TOGGLE_INACTIVE, CPNT, Prop.BTN_BG_INACTIVE)};
            color: ${() => componentColor(Prop.TOGGLE_INACTIVE, CPNT, Prop.BTN_BG_INACTIVE)};
          }
        }

        .summer-toggle {
          span {
            color: ${() => componentColor(Prop.TOGGLE_ACTIVE, CPNT, Prop.BTN_BG)};
          }

          svg {
            fill: ${() => componentColor(Prop.TOGGLE_ACTIVE, CPNT, Prop.BTN_BG)};
            color: ${() => componentColor(Prop.TOGGLE_ACTIVE, CPNT, Prop.BTN_BG)};
          }
        }
      }

      &.winter {
        .winter-toggle {
          span {
            color: ${() => componentColor(Prop.TOGGLE_ACTIVE, CPNT, Prop.BTN_BG)};
          }

          svg {
            fill: ${() => componentColor(Prop.TOGGLE_ACTIVE, CPNT, Prop.BTN_BG)};
            color: ${() => componentColor(Prop.TOGGLE_ACTIVE, CPNT, Prop.BTN_BG)};
          }
        }

        .summer-toggle {
          span {
            color: ${() => componentColor(Prop.TOGGLE_INACTIVE, CPNT, Prop.BTN_BG_INACTIVE)};
          }

          svg {
            fill: ${() => componentColor(Prop.TOGGLE_INACTIVE, CPNT, Prop.BTN_BG_INACTIVE)};
            color: ${() => componentColor(Prop.TOGGLE_INACTIVE, CPNT, Prop.BTN_BG_INACTIVE)};
          }
        }
      }

      .summer-toggle {
        margin-left: 15px;
      }
    }
  `)}
}
`;
