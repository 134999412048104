import styled from 'styled-components';

import {
  Components, FontFamily, FontSize, ThemeProps as Prop,
} from '@powdr/constants';
import { getFontStyles, themeUtil } from '@powdr/utils';

const CPNT = Components.DOR_LIVE_24_TABLE;

export const StyledDorLive24Table = styled.div`
  h3 {
    ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.LRG12, undefined, undefined)};
    margin-bottom: 16px;
  }
  p {
    margin-bottom: 16px;
  }
  
  .mx-auto {
    margin: auto;
  }
  
  .filters {
    display: flex;
    gap: 15px;
    margin-bottom: 16px;
  }
  .ui-table {
    overflow-x: auto;
    height: 500px;
    margin-bottom: rem(25);

    table {
      position: relative;
      table-layout: unset;

      th, td {
        text-align: center;
        ${() => getFontStyles(FontFamily.BASE_FONT, FontSize.REG20, undefined, undefined)};
      }

      th {
        position: sticky;
        top: 0;
        z-index: 2;
        background-color: white;
        padding: 10px;
        text-transform: uppercase;
        white-space: normal;
        min-width: 150px
      }

      td {
        padding: 10px;
      }

      tr {
        border-bottom: 1px solid ${({ colorProfile }) => themeUtil(Prop.BORDER, CPNT, colorProfile)};
        display: table-row;
      }

      td.timestamp {
        display: flex;
        width: auto;
        position: sticky;
        top: 60px;
        left: 0;
        z-index: 1;
        background-color: white;
        white-space: nowrap;
      }
    }
  }
`;
