import {
  ColorProfiles, FormElementTypes, FormElementInputTypes, AlternatingBlockLayoutOptions,
} from '@powdr/constants';
import { capitalizeAllWords, deslugify } from '@powdr/utils';

export const demoStateIDs = {
  demoType: 'demoType',
  numColumns: 'numColumns',
  numBlocks: 'numBlocks',
  parentColorProfile: 'parentColorProfile',
  colorProfile: 'colorProfile',
  image: 'image',
  placeholderHeight: 'placeholderHeight',
  placeholderWidth: 'placeholderWidth',
  placeholderTextColor: 'placeholderTextColor',
  placeholderBGColor: 'placeholderBGColor',
  title: 'title',
  copy: 'copy',
  formattedContent: 'formattedContent',
  buttonText: 'buttonText',
  altButtonText: 'altButtonText',
  iconName: 'iconName',
  date: 'date',
  alternateBlockLayout: 'alternateBlockLayout',
  isCenterAlignUnevenRows: 'isCenterAlignUnevenRows',
  paddingOff: 'paddingOff',
  paddingAmount: 'paddingAmount',
  isShowIndexes: 'isShowIndexes',
};

export const demoGroupKeys = {
  demo: 'demo',
  jumpTo: 'jumpTo',
  grid: 'grid',
  contentBlocks: 'contentBlocks',
};

export const demoInputGroups = {
  /* [demoGroupKeys.demo]: {
    header: 'Demo Type',
    resetButtonText: '',
    inputs: [
      {
        id: demoStateIDs.demoType,
        default: 'block-list',
        input: FormElementTypes.SELECT,
        options: [
          { id: 'block-list', label: 'Block Type List' },
          { id: 'parent-builder', label: 'Parent Builder' },
        ],
        width: '100%',
      },
    ],
  }, */
  [demoGroupKeys.jumpTo]: {
    header: 'Jump To Content Block',
    subheader: 'Click below to jump to any content block grid. Click on any content block or their button to jump back here.',
  },
  [demoGroupKeys.grid]: {
    header: 'Grid & Parent Options',
    subheader: '',
    resetButtonText: 'Reset Grid/Parent Values to Default State',
    inputs: [
      {
        id: demoStateIDs.numColumns,
        label: 'Number of Columns',
        default: 2,
        input: FormElementTypes.INPUT,
        type: FormElementInputTypes.NUMBER,
        min: 1,
        max: 6,
        width: '45%',
      },
      {
        id: demoStateIDs.numBlocks,
        label: 'Number of Blocks',
        default: 2,
        input: FormElementTypes.INPUT,
        type: FormElementInputTypes.NUMBER,
        min: 1,
        max: 9,
        width: '45%',
      },
      {
        id: demoStateIDs.paddingAmount,
        label: 'Padding Amount (px)',
        default: 10,
        input: FormElementTypes.INPUT,
        type: FormElementInputTypes.NUMBER,
        min: 0,
        max: 100,
        width: '22%',
      },
      {
        id: demoStateIDs.isCenterAlignUnevenRows,
        label: 'Center Align Uneven Rows',
        default: false,
        input: FormElementTypes.SELECT,
        options: [
          { id: false, label: 'Disable' },
          { id: true, label: 'Enable' },
        ],
        width: '22%',
      },
      {
        id: demoStateIDs.alternateBlockLayout,
        label: 'Alternate Block Layout',
        default: '',
        input: FormElementTypes.SELECT,
        options: [
          {
            id: AlternatingBlockLayoutOptions.DEFAULT_ONLY,
            label: 'Default State Only',
          },
          {
            id: AlternatingBlockLayoutOptions.NON_DEFAULT_ONLY,
            label: deslugify(AlternatingBlockLayoutOptions.NON_DEFAULT_ONLY),
          },
          {
            id: AlternatingBlockLayoutOptions.DEFAULT_FIRST,
            label: deslugify(AlternatingBlockLayoutOptions.DEFAULT_FIRST),
          },
          {
            id: AlternatingBlockLayoutOptions.NON_DEFAULT_FIRST,
            label: deslugify(AlternatingBlockLayoutOptions.NON_DEFAULT_FIRST),
          },
        ],
        width: '22%',
      },
      {
        id: demoStateIDs.isShowIndexes,
        label: 'Show Indexes in Title',
        default: false,
        input: FormElementTypes.SELECT,
        options: [
          { id: false, label: 'Disable' },
          { id: true, label: 'Enable' },
        ],
        width: '22%',
      },
      {
        id: demoStateIDs.parentColorProfile,
        label: 'Parent/Container Color Profile',
        default: null,
        input: FormElementTypes.SELECT,
        options: [
          { id: null, label: 'None' },
          { id: ColorProfiles.BASE, label: capitalizeAllWords(ColorProfiles.BASE) },
          { id: ColorProfiles.PRIMARY, label: capitalizeAllWords(ColorProfiles.PRIMARY) },
          { id: ColorProfiles.SECONDARY, label: capitalizeAllWords(ColorProfiles.SECONDARY) },
          { id: ColorProfiles.TERTIARY, label: capitalizeAllWords(ColorProfiles.TERTIARY) },
          { id: ColorProfiles.QUATERNARY, label: capitalizeAllWords(ColorProfiles.QUATERNARY) },
        ],
        width: '45%',
      },
      {
        id: demoStateIDs.colorProfile,
        label: 'Grid Color Profile',
        default: null,
        input: FormElementTypes.SELECT,
        options: [
          { id: null, label: 'None' },
          { id: 0, label: capitalizeAllWords(ColorProfiles.BASE) },
          { id: 1, label: capitalizeAllWords(ColorProfiles.PRIMARY) },
          { id: 2, label: capitalizeAllWords(ColorProfiles.SECONDARY) },
          { id: 3, label: capitalizeAllWords(ColorProfiles.TERTIARY) },
          { id: 4, label: capitalizeAllWords(ColorProfiles.QUATERNARY) },
        ],
        width: '45%',
      },
    ],
  },
  [demoGroupKeys.contentBlocks]: {
    header: 'Content Block Data',
    subheader: '',
    resetButtonText: 'Reset Content Block Data Values to Default State',
    inputs: [
      {
        id: demoStateIDs.image,
        label: 'Image Source URL',
        subheader: 'This is overriden by placeholder image settings',
        default: 'https://cms.dev.killington.com/sites/killington/files/2020-10/winter-scenic_2554x1500.jpg',
        input: FormElementTypes.INPUT,
        type: FormElementInputTypes.TEXT,
        width: '100%',
      },
      {
        id: demoStateIDs.placeholderHeight,
        label: 'Placeholder Image -- Height (px)',
        subheader: '',
        default: '',
        input: FormElementTypes.INPUT,
        type: FormElementInputTypes.NUMBER,
        min: '1',
        max: '1920',
        width: '45%',
      },
      {
        id: demoStateIDs.placeholderWidth,
        label: 'Placeholder Image -- Width (px)',
        default: '',
        input: FormElementTypes.INPUT,
        type: FormElementInputTypes.NUMBER,
        min: '1',
        max: '1920',
        width: '45%',
      },
      /* {
        id: demoStateIDs.placeholderTextColor,
        label: 'Placeholder Image -- Text Color (HEX)',
        default: '#000000',
        input: FormElementTypes.INPUT,
        type: FormElementInputTypes.TEXT,
        width: '45%',
      },
      {
        id: demoStateIDs.placeholderBGColor,
        label: 'Placeholder Image -- Background Color (HEX)',
        default: '#adadad',
        input: FormElementTypes.INPUT,
        type: FormElementInputTypes.TEXT,
        width: '45%',
      }, */
      {
        id: demoStateIDs.title,
        label: 'Title Text',
        default: 'Lorem ipsum dolor sit amet',
        input: FormElementTypes.TEXTAREA,
        width: '100%',
      },
      {
        id: demoStateIDs.copy,
        label: 'Content Text',
        default: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore',
        input: FormElementTypes.TEXTAREA,
        width: '100%',
      },
      {
        id: demoStateIDs.formattedContent,
        label: 'Formatted Content (HTML)',
        default: '',
        input: FormElementTypes.TEXTAREA,
        width: '100%',
      },
      {
        id: demoStateIDs.buttonText,
        label: 'Button Text',
        default: 'Click here',
        input: FormElementTypes.INPUT,
        type: FormElementInputTypes.TEXT,
      },
      {
        id: demoStateIDs.altButtonText,
        label: 'Alt Button Text',
        default: 'Click here too',
        input: FormElementTypes.INPUT,
        type: FormElementInputTypes.TEXT,
      },
      {
        id: demoStateIDs.iconName,
        label: 'Icon Name',
        default: '',
        input: FormElementTypes.INPUT,
        type: FormElementInputTypes.TEXT,
      },
      {
        id: demoStateIDs.date,
        label: 'Date',
        default: '',
        input: FormElementTypes.INPUT,
        type: FormElementInputTypes.TEXT,
      },
    ],
  },
};
