import axios from 'axios';
import { format } from 'date-fns';

import { FetchStatus } from '@powdr/constants';
// TODO: Change all actions to handle multiple actions in one file

import {
  ECOMMERCE_RESET,
  ECOMMERCE_ERROR,

  ECOMMERCE_CALENDAR_PENDING,
  ECOMMERCE_CALENDAR,

  ECOMMERCE_PRODUCT,
  ECOMMERCE_PRODUCT_RESET,
  ECOMMERCE_PRODUCTS_BY_DATE,

  ECOMMERCE_CART_PENDING,
  ECOMMERCE_CART_SUCCESS,
  ECOMMERCE_CART_ERROR,
  ECOMMERCE_CART_RESET,
  ECOMMERCE_CART_GET_COOKIE,
  ECOMMERCE_CART_SET_COOKIE,

  ECOMMERCE_CREATE_GUEST,
  ECOMMERCE_ADD_TO_CART,
} from '../types';

const config = {
  headers: {
    'Ocp-Apim-Subscription-Key': process.env.GATSBY_AW_SUB_KEY || '',
    // Catalog in AspenWare translates to property
    'aw-catalog': process.env.GATSBY_AW_CATALOG || '',
  },
};

const aspenWareApiUrl = process.env.GATSBY_AW_API_URL || '';

const dataProductBySlug = {
  query: `query Product($slug: String) {
    product(slug: $slug) {
      id
      name
      attributes {
        id
        name
        values {
          description
          id
          name
          metadata {
            name
            value
          }
        }
      }
      variants {
        id
        attributeValues {
          id
        }
      }
    }
  }`,
};
export const getProductBySlug = (slug) => async (dispatch, getState) => {
  const { ecommerce } = getState();

  if (!config.headers['Ocp-Apim-Subscription-Key']
      || !config.headers['aw-catalog']
      || !aspenWareApiUrl) {
    console.log('Ecommerce Action Failed: Check AspenWare env vars.');
    return null;
  }

  if (!ecommerce.products?.[slug] && slug) {
    try {
      const res = await axios.post(aspenWareApiUrl, {
        ...dataProductBySlug,
        variables: {
          slug,
        },
      }, config);
      dispatch({
        type: ECOMMERCE_PRODUCT,
        payload: {
          [slug]: res.data.data.product,
        },
      });
    } catch (error) {
      dispatch({
        type: ECOMMERCE_ERROR,
        payload: error,
      });
    }
  } else {
    console.log('Ecommerce: Missing Fields for `getProductBySlug` Action');
  }
  return null;
};

const dataProductVariant = {
  query: `query ProductVariant($id: String, $startDate: Date!, $endDate: Date!){
    productVariant (id: $id) {
      id
      availability(startDate: $startDate, endDate: $endDate) {
        date
        isAvailable
        reasonCodes {
          reason
        }
      }
      pricing {
        currentPrice
        days (startDate: $startDate, endDate: $endDate) {
          date
          currentPrice
          compareToPrice
        }
      }
    }
  }`,
};
export const getProductVariant = (id, startDate, endDate) => async (dispatch, getState) => {
  const { calendarStatus, ecommerce } = getState();

  if (calendarStatus === FetchStatus.PENDING) {
    return null;
  }
  dispatch({
    type: ECOMMERCE_CALENDAR_PENDING,
    payload: [],
  });
  if (!config.headers['Ocp-Apim-Subscription-Key']
      || !config.headers['aw-catalog']
      || !aspenWareApiUrl) {
    console.log('Ecommerce Action Failed: Check AspenWare env vars.');
    return null;
  }

  if (!ecommerce.calendarVariant.length > 0 && startDate && endDate) {
    try {
      const res = await axios.post(aspenWareApiUrl, {
        ...dataProductVariant,
        variables: {
          id,
          startDate: format(startDate, 'MM/dd/yyyy'),
          endDate: format(endDate, 'MM/dd/yyyy'),
        },
      }, config);
      dispatch({
        type: ECOMMERCE_CALENDAR,
        payload: res.data.data.productVariant,
      });
    } catch (error) {
      dispatch({
        type: ECOMMERCE_ERROR,
        payload: error,
      });
    }
  } else {
    console.log('Ecommerce: Missing Fields for `getProductVariant` Action');
  }
  return null;
};

const dataAllProductsByDate = {
  query: `query ProductsByDate($slug: String, $startDate: Date!, $endDate: Date!) {
    product(slug: $slug) {
      id
      name
      variants {
        id
        attributeValues {
          id
          name
        }
        availability(startDate: $startDate, endDate: $endDate) {
          date
          isAvailable
          reasonCodes {
            reason
          }
        }
        pricing {
          days (startDate: $startDate, endDate: $endDate) {
            date
            currentPrice
            compareToPrice
          }
        }
        }

      }

    }`,
};
export const getAllProductsByDate = (slug, startDate, endDate) => async (dispatch, getState) => {
  const { ecommerce } = getState();

  if (!config.headers['Ocp-Apim-Subscription-Key']
      || !config.headers['aw-catalog']
      || !aspenWareApiUrl) {
    console.log('Ecommerce Action Failed: Check AspenWare env vars.');
    return null;
  }

  if (!ecommerce.allProductsByDate?.[slug] && startDate && endDate) {
    try {
      const res = await axios.post(aspenWareApiUrl, {
        ...dataAllProductsByDate,
        variables: {
          slug,
          startDate: format(startDate, 'MM/dd/yyyy'),
          endDate: format(endDate, 'MM/dd/yyyy'),
        },
      }, config);
      dispatch({
        type: ECOMMERCE_PRODUCTS_BY_DATE,
        payload: {
          [slug]: res.data.data.product,
        },
      });
    } catch (error) {
      dispatch({
        type: ECOMMERCE_ERROR,
        payload: error,
      });
    }
  } else {
    console.log('Ecommerce: Missing Fields for `getAllProductsByDate` Action');
  }
  return null;
};

const dataCreateGuestCustomer = {
  query: `mutation CreateGuestCustomer {
    createGuestCustomer {
      id
      guid
    }
  }`,
};
export const createGuestCustomer = () => async (dispatch, getState) => {
  const { ecommerce } = getState();

  if (!config.headers['Ocp-Apim-Subscription-Key']
      || !config.headers['aw-catalog']
      || !aspenWareApiUrl) {
    console.log('Ecommerce Action Failed: Check AspenWare env vars.');
    return null;
  }

  if (!ecommerce.customerGuid) {
    try {
      const res = await axios.post(aspenWareApiUrl, {
        ...dataCreateGuestCustomer,
      }, {
        ...config,
        withCredentials: true,
      });
      dispatch({
        type: ECOMMERCE_CREATE_GUEST,
        payload: res.data.data.createGuestCustomer.guid,
      });
    } catch (error) {
      dispatch({
        type: ECOMMERCE_ERROR,
        payload: error,
      });
    }
  } else {
    console.log('Ecommerce: Missing Fields for `createGuestCustomer` Action');
  }
  return null;
};

const dataAddToCart = {
  query: `mutation Mutation($input: AddCartItemInput!) {
    addCartItem(input: $input) {
      item {
        id
      }
      errors {
        code
        message
      }
    }
  }`,
};

export const postAddToCart = (cartItems) => async (dispatch) => {
  // TODO: figure out what cart Store should look like and check for dupes
  if (!config.headers['Ocp-Apim-Subscription-Key']
      || !config.headers['aw-catalog']
      || !aspenWareApiUrl) {
    console.log('Ecommerce Action Failed: Check AspenWare env vars.');
    return null;
  }

  if (cartItems.length > 0) {
    try {
      const res = await axios.all(cartItems.map((cartItem) => axios.post(aspenWareApiUrl, {
        ...dataAddToCart,
        variables: {
          input: {
            quantity: parseInt(cartItem.value, 10),
            productId: cartItem.productId,
            productVariantId: cartItem.key,
            dates: [cartItem.selectedDate],
          },
        },
      }, {
        ...config,
        withCredentials: true,
      })));
      dispatch({
        type: ECOMMERCE_ADD_TO_CART,
        payload: cartItems.map((cartItem, id) => ({
          [cartItem.key]: res[id]?.data?.data?.addCartItem || {},
        })),
      });
    } catch (error) {
      dispatch({
        type: ECOMMERCE_ERROR,
        payload: error,
      });
    }
  } else {
    console.log('Ecommerce: Missing Fields for `postAddToCart` Action');
  }
  return null;
};

const dataGetCart = {
  query: `query Cart {
    customer {
      cart {
        itemCount
      }
    }
  }`,
};

export const getCart = () => async (dispatch, getState) => {
  const { cartStatus } = getState().ecommerce;
  if (cartStatus === FetchStatus.PENDING) {
    return null;
  }
  dispatch({
    type: ECOMMERCE_CART_PENDING,
    payload: [],
  });
  if (!config.headers['Ocp-Apim-Subscription-Key']
      || !config.headers['aw-catalog']
      || !aspenWareApiUrl) {
    console.log('Ecommerce Action Failed: Check AspenWare env vars.');
    return null;
  }

  try {
    const res = await axios.post(aspenWareApiUrl, dataGetCart, {
      withCredentials: true,
      ...config,
    });
    if (res.data?.errors?.length > 0) {
      // const { error } = res?.errors?.[0] || '';
      dispatch({
        type: ECOMMERCE_CART_ERROR,
        payload: 0,
      });
    } else {
      dispatch({
        type: ECOMMERCE_CART_SUCCESS,
        payload: res.data.data?.customer?.cart?.itemCount || 0,
      });
    }
  } catch (error) {
    dispatch({
      type: ECOMMERCE_CART_ERROR,
      payload: error,
    });
  }

  return null;
};

export const resetEcommerceProducts = (dispatch) => {
  dispatch({
    type: ECOMMERCE_PRODUCT_RESET,
  });
};

export const resetEcommerceCart = (dispatch) => {
  dispatch({
    type: ECOMMERCE_CART_RESET,
  });
};

export const resetEcommerceStore = (dispatch) => {
  dispatch({
    type: ECOMMERCE_RESET,
  });
};

export const getMiddlewareCookie = () => async (dispatch) => {
  try {
    const res = await axios('/api/get-cookie', { withCredentials: true });
    if (res.status === 200) {
      dispatch({
        type: ECOMMERCE_CART_GET_COOKIE,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: ECOMMERCE_ERROR,
      payload: error,
    });
  }
};

export const setMiddlewareCookie = (customerGuid) => async (dispatch) => {
  try {
    const res = await axios(`/api/set-cookie?customerGuid=${customerGuid}`, { withCredentials: true });
    if (res.status === 200) {
      dispatch({
        type: ECOMMERCE_CART_SET_COOKIE,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: ECOMMERCE_ERROR,
      payload: error,
    });
  }
};
