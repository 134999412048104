/* eslint-disable no-multiple-empty-lines */
import PropTypes from 'prop-types';

export const SectorShape = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
});

export const TrailShape = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  sector: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  lift: PropTypes.string,
  status: PropTypes.string,
  season: PropTypes.string,
  type: PropTypes.string,
  groom_status: PropTypes.string,
  difficulty: PropTypes.string,
  notes: PropTypes.string,
  properties: PropTypes.shape({
    gladed_trail: PropTypes.string,
    race: PropTypes.string,
    terrain_parks: PropTypes.string,
    length: PropTypes.string,
    area: PropTypes.string,
    freeride_terrain: PropTypes.bool,
    technical_terrain: PropTypes.bool,
    cross_country_trail: PropTypes.bool,
    skill_start_park: PropTypes.bool,
    uphill_route: PropTypes.bool,
    hiking: PropTypes.bool,
    mileage: PropTypes.number,
    vertical_rise: PropTypes.number,
    snowmaking: PropTypes.string,
  }),
});

export const LiftShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  sector: SectorShape.isRequired,
  type: PropTypes.string.isRequired,
  vertical: PropTypes.number,
  notes: PropTypes.string,
  hours: PropTypes.string.isRequired,
  season: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  capacity: PropTypes.number,
  occupancy: PropTypes.number,
  wait_time: PropTypes.number,
});

export const SettingsShape = PropTypes.shape({
  drawerStickyOffset: PropTypes.string,
  isMobile: PropTypes.bool,
  isInView: PropTypes.bool,
  cmsGeneralDrawr: PropTypes.shape({
    FITLER_HEADER: PropTypes.bool,
  }),
  hideFilterDrawer: PropTypes.arrayOf(PropTypes.string),
  hideTrailStatus: PropTypes.arrayOf(PropTypes.string),
  hideTrailDifficulty: PropTypes.arrayOf(PropTypes.string),
  hideTrailFeature: PropTypes.arrayOf(PropTypes.string),
  hideTrailFeatureDiff: PropTypes.arrayOf(PropTypes.string),
  hideTrailFeatureSize: PropTypes.arrayOf(PropTypes.string),
  hideTrailGroomedStatus: PropTypes.arrayOf(PropTypes.string),
  filterContent: PropTypes.shape({
    STATUS: PropTypes.bool,
    FEATURE_DIFFICULTY: PropTypes.bool,
    DIFFICULTY: PropTypes.bool,
  }),
  panelsDefaultState: PropTypes.oneOf([
    'OPEN',
    'CLOSED',
  ]),
});

export const FiltersShape = PropTypes.shape({
  trailIds: PropTypes.arrayOf(PropTypes.string),
  liftIds: PropTypes.arrayOf(PropTypes.string),
  current: PropTypes.arrayOf(PropTypes.string),
  setCurrent: PropTypes.func,
  filterDefault: PropTypes.oneOf([
    'OPEN',
    'CLOSED',
  ]),
});

export const DataShape = PropTypes.shape({
  trails: PropTypes.arrayOf(TrailShape),
  lifts: PropTypes.arrayOf(LiftShape),
  sectors: PropTypes.arrayOf(SectorShape),
});

export const ThemeShape = PropTypes.shape({
  parentColorProfile: PropTypes.string,
}).isRequired;

const RowShape = PropTypes.shape({
  id: PropTypes.string,
});

export const ActiveRowsShape = PropTypes.arrayOf(RowShape);

export const ActiveExpandedRowsShape = PropTypes.arrayOf(RowShape);

export const HandleCheckboxClickShape = PropTypes.func.isRequired;

export const HandleOnExpandClickCloseShape = PropTypes.func.isRequired;
