import styled from 'styled-components';

import { Icon } from '@powdr/components';
import {
  ThemeProps as Prop, StaticProfiles, ColorProfiles, Components, Property,
} from '@powdr/constants';
import { themeUtil } from '@powdr/utils';

const CPNT = Components.LOADER;

export const StyledLoader = styled.div`
  width: fit-content;
  height: fit-content;

  // Loader Animations
  .loader-animated {
    animation-duration: 1.3s;
    animation-fill-mode: both;
  }

  .loader-animated.infinite {
    animation-iteration-count: infinite;
  }

  .loader-animated.flipItUp {
    animation: flip 2s infinite, fadeInUp 1s;
  }

  @keyframes flip {
    from {
      transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
      animation-timing-function: ease-out;
    }

    40% {
      transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
      animation-timing-function: ease-out;
    }

    50% {
      transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
      animation-timing-function: ease-in;
    }

    80% {
      transform: perspective(400px) scale3d(.95, .95, .95);
      animation-timing-function: ease-in;
    }

    to {
      transform: perspective(400px);
      animation-timing-function: ease-in;
    }
  }

  .loader-animated.flip {
    backface-visibility: visible;
    animation-name: flip;
  }

  @keyframes flip-opposite {
    from {
      transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
      animation-timing-function: ease-out;
    }

    40% {
      transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
      animation-timing-function: ease-out;
    }

    50% {
      transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
      animation-timing-function: ease-in;
    }

    80% {
      transform: perspective(400px) scale3d(.95, .95, .95);
      animation-timing-function: ease-in;
    }

    to {
      transform: perspective(400px);
      animation-timing-function: ease-in;
    }
  }

  .loader-animated.flip-opposite {
    backface-visibility: visible;
    animation-name: flip-opposite;
  }

  @keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
  }

  .loader-animated.spin {
    backface-visibility: visible;
    animation-name: spin;
  }

  @keyframes loader-fadeInUp {
    from {
      opacity: 0;
      transform: translate3d(0, 25%, 0);
    }

    to {
      opacity: 1;
      transform: none;
    }
  }

  .loader-fadeInUp {
    animation-name: loader-fadeInUp;
  }

  @keyframes loader-fadeInDown {
    from {
      opacity: 0;
      transform: translate3d(0, -25%, 0);
    }
    to {
      opacity: 1;
      transform: none;
    }
  }

  .loader-fadeInDown {
    animation-name: loader-fadeInDown;
  }

`;

// TODO: Replace custom colors with component color pattern
export const LoaderIcon = styled(Icon)`
  ${({ property }) => {
    switch (property) {
      case property.includes('woodward'):
        return `
          .st0 {
            fill: ${themeUtil(Prop.BACKGROUND, CPNT, ColorProfiles.PRIMARY)};
            color: ${themeUtil(Prop.BACKGROUND, CPNT, ColorProfiles.PRIMARY)};
          }
          .st1 {
            fill: ${themeUtil(Prop.HEADER, CPNT, ColorProfiles.PRIMARY)};
            color: ${themeUtil(Prop.HEADER, CPNT, ColorProfiles.PRIMARY)};
          }
        `;
      case Property.ELDORA:
        return `svg { fill: ${themeUtil(Prop.ELEMENT, CPNT)} }`;
      case Property.KILLINGTON:
        return `
          .st0 {
            fill: ${themeUtil(Prop.WHITE, CPNT, StaticProfiles.COLORS)};
            color: ${themeUtil(Prop.WHITE, CPNT, StaticProfiles.COLORS)};
          }
          .st1 {
            fill: ${themeUtil(Prop.BACKGROUND, CPNT, ColorProfiles.PRIMARY)};
            color: ${themeUtil(Prop.BACKGROUND, CPNT, ColorProfiles.PRIMARY)};
          }
          .st2 {
            fill: ${themeUtil(Prop.LINK_TXT_HOVER, CPNT)};
            color: ${themeUtil(Prop.LINK_TXT_HOVER, CPNT)};
            mix-blend-mode: multiply;
          }
          .st3 {
            fill: ${themeUtil(Prop.LINK_TXT_HOVER, CPNT)};
            color: ${themeUtil(Prop.LINK_TXT_HOVER, CPNT)};
          }
        `;
      case Property.LEECANYONLV:
        return `
          .st0 {
            fill: #FDB525;
            color: #FDB525;
          }
          .st1 {
            fill: #F36C31;
            color: #F36C31;
          }
          .st2 {
            fill: #8BD1E6;
            color: #8BD1E6;
          }
          .st3 {
            fill: ${themeUtil(Prop.WHITE, CPNT, StaticProfiles.COLORS)};
            color: ${themeUtil(Prop.WHITE, CPNT, StaticProfiles.COLORS)};
          }
          .st4 {
            fill: ${themeUtil(Prop.CONTENT, CPNT)};
            color: ${themeUtil(Prop.CONTENT, CPNT)};
          }
        `;
      case Property.SUNCOUNTRYTOURS:
        return `
          .st0 {
            fill: #231F20;
            color: #231F20;
          }
          .st1 {
            fill: ${themeUtil(Prop.ICON, CPNT, ColorProfiles.PRIMARY)};
            color: ${themeUtil(Prop.ICON, CPNT, ColorProfiles.PRIMARY)};
          }
          .st2{
            fill: none;
            color: none;
          }
          .st3 {
            font-family: 'Arial-BoldMT';
          }
          .st4 {
            font-size: 11px;
          }
          .st5 {
            letter-spacing: 8;
          }
        `;
      case Property.COPPERCOLORADO:
      case Property.MTBACHELOR:
      case Property.PICOMOUNTAIN:
      case Property.POWDR:
      case Property.POWDRPLAYITFORWARD:
      case Property.RIDEBOREAL:
      case Property.SKISODASPRINGS:
        return `svg { fill: ${themeUtil(Prop.BACKGROUND, CPNT, ColorProfiles.PRIMARY)}; color: ${themeUtil(Prop.BACKGROUND, CPNT, ColorProfiles.PRIMARY)}; }`;
      default:
        return '';
    }
  }};


`;

export const LoaderMessage = styled.div``;
