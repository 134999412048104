import {
  useEffect, useState, useContext,
} from 'react';

import { ScrollDirection } from '@powdr/constants';
import { AppContext } from '@powdr/context';

import { useIsUserScrolling } from './use-is-user-scrolling';

export const useIsWrapperMinimized = () => {
  const { isMobile } = useContext(AppContext);
  const { lastScrollDirectionY, yPosition } = useIsUserScrolling();
  const [isWrapperMinimized, setWrapperMinimized] = useState(yPosition !== 0);

  useEffect(() => {
    if (yPosition === 0) {
      setWrapperMinimized(false);
    } else if (!isMobile) {
      setWrapperMinimized(!!(yPosition));
    } else if (lastScrollDirectionY === ScrollDirection.Y.UP || !lastScrollDirectionY) {
      setWrapperMinimized(false);
    } else {
      setWrapperMinimized(true);
    }
  }, [isMobile, yPosition, lastScrollDirectionY]);

  return isWrapperMinimized;
};
