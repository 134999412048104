import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

import { CompareCardSides } from '@powdr/constants';
import { AppContext } from '@powdr/context/app-context';

import { CompareCardInner } from '../compare-card-inner';

import {
  StyledCompareCard, CompareCardOuter, CardOuterHeader, CardFlipContainer,
} from './styles';

export const CompareCard = ({
  card,
  colorProfile,
}) => {
  const [isCardFlipped, setCardFlipped] = useState(false);
  const [isCardToggled, setCardToggled] = useState(false);
  const { isMobile } = useContext(AppContext);
  const { highlightedText } = card;

  return (
    <StyledCompareCard>
      <CompareCardOuter isHighlighted={!!(highlightedText)} isMobile={isMobile}>
        {(highlightedText) && (
          <CardOuterHeader colorProfile={colorProfile} isHighlighted={!!(highlightedText)}>
            {highlightedText}
          </CardOuterHeader>
        )}
        {(isMobile) ? (
          <CardFlipContainer isCardFlipped={isCardFlipped}>
            <CompareCardInner
              card={card}
              sideOfCard={CompareCardSides.FRONT}
              colorProfile={colorProfile}
              isCardFlipped={isCardFlipped}
              setCardFlipped={setCardFlipped}
              isCardToggled={isCardToggled}
              setCardToggled={setCardToggled}
            />
            <CompareCardInner
              card={card}
              sideOfCard={CompareCardSides.BACK}
              colorProfile={colorProfile}
              isCardFlipped={isCardFlipped}
              setCardFlipped={setCardFlipped}
              isCardToggled={isCardToggled}
              setCardToggled={setCardToggled}
            />
          </CardFlipContainer>
        ) : (
          <CompareCardInner
            card={card}
            colorProfile={colorProfile}
            isCardToggled={isCardToggled}
            setCardToggled={setCardToggled}
          />
        )}
      </CompareCardOuter>
    </StyledCompareCard>
  );
};

CompareCard.propTypes = {
  card: PropTypes.shape({
    highlightedText: PropTypes.string,
  }).isRequired,
  colorProfile: PropTypes.string,
};

CompareCard.defaultProps = {
  colorProfile: null,
};
