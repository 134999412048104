import classNames from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import { Icon } from '@powdr/components/icon';
import { Link } from '@powdr/components/link';
import {
  BlockTypes, Breakpoints, FontFamily, FontSize, ThemeProps as Prop,
} from '@powdr/constants';
import { ContentBlockType } from '@powdr/model';
import { themeUtil, clamp, getFontStyles } from '@powdr/utils';

const CPNT = BlockTypes.CARD;

// styled components
const ContentBlockCard = styled(Link)`
  display: flex;
  flex-direction: column;
  position: relative;
  height: auto;
  margin: 5px;
  transition: transform .5s;
  box-shadow: 0 2px 3px rgba(41, 45, 49, 0.1), 0 0 0 1px rgba(41, 45, 49, 0.1);
  background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};

  .image {
    position: relative;

    img {
      margin-bottom: 0;
      max-width: 100%;
      width: 100%;
    }

    .video-icon {
      position: absolute;
      display: inline-block;
      top: calc(50% - 50px);
      left: calc(50% - 50px);

      svg {
        fill: ${({ colorProfile }) => themeUtil(Prop.ICON, CPNT, colorProfile)};
        color: ${({ colorProfile }) => themeUtil(Prop.ICON, CPNT, colorProfile)};
      }

      &:hover {
        fill: ${({ colorProfile }) => themeUtil(Prop.ICON_HOVER, CPNT, colorProfile)};
        color: ${({ colorProfile }) => themeUtil(Prop.ICON_HOVER, CPNT, colorProfile)};
      }
    }
  }

  &:after {
    transition: opacity 2s cubic-bezier(0.165, 0.84, 0.44, 1);
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: -1;
  }

  &:hover:not(.overlay) {
    transform: scale3d(1.006, 1.006, 1);

    &:after {
      opacity: 1;
    }
  }

  &:hover .button, &:focus .button {
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
  }

  &:focus .button {
    outline: 2px solid ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
    outline-offset: 2px;
  }
`;

const ImageWrapper = styled.div``;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  height: 325px;

  ${Breakpoints.mobile(css`
    height: auto;
  `)}

  ${Breakpoints.large(css`
    height: 375px;
  `)}

  ${Breakpoints.larger(css`
    height: 350px;
  `)}
`;

const TextContainer = styled.div`
  display: grid;
  margin-bottom: 10px;

  .cb-title {
    margin-bottom: 15px;
  }

  .cb-copy {
    ${() => clamp(6)}
  }
`;

const ButtonIconContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Image = styled.img``;

export const Card = ({
  title, copy, blockType, colorProfile, isOverlayEnabled, image, ariaLabel,
  buttonEnabled, buttonLink, buttonTarget, buttonText, buttonClick,
  videoId, iconName, isStaticImageLink,
}) => (
  <ContentBlockCard
    href={buttonLink}
    target={buttonTarget}
    onClick={() => buttonClick()}
    aria-label={ariaLabel}
    role="button"
    colorProfile={colorProfile}
    className={classNames(`content-block-${blockType}`, { overlay: isOverlayEnabled })}
  >
    <ImageWrapper>
      <figure className="image">
        {(image && !isStaticImageLink) && <GatsbyImage image={image.src} alt={image?.altText} />}
        {(image && isStaticImageLink) && <Image src={image.src} alt={image?.altText} />}
        {(videoId) && <Icon className="video-icon" name="content-play" width="100" height="100" />}
      </figure>
    </ImageWrapper>
    <ContentWrapper>
      <TextContainer>
        <h3 className="cb-title">{title}</h3>
        {(copy) && <p className="cb-copy">{copy}</p>}
      </TextContainer>
      <ButtonIconContainer>
        {(buttonEnabled && buttonText) && (
          <div type="button" className="button with-background " aria-label={ariaLabel}>
            {buttonText}
          </div>
        )}
        {(iconName) && <Icon className="cb-icon" name={iconName} width="30" height="30" />}
      </ButtonIconContainer>
    </ContentWrapper>
  </ContentBlockCard>
);

Card.propTypes = PropTypes.objectOf(ContentBlockType).isRequired;
