import React, { useEffect, useState } from 'react';

import { UiAccordionPanel } from '@powdr/components';
import { filterLiftTrailItemsBySeason } from '@powdr/utils';

import { DorLiftTableRender } from './dor-lift-table-render';
import {
  DataShape,
  FiltersShape,
  SettingsShape,
  ThemeShape,
} from './dor-prop-types';
import { DorSectorDetailsRender } from './dor-sector-details-render';
import { DorTrailTableRender } from './dor-trail-table-render';

export const DorTrailSectorRender = ({
  data, settings, theme, filters,
}) => {
  const [lifts, setLifts] = useState([]);
  const [trails, setTrails] = useState([]);

  useEffect(() => {
    if (data) {
      const filteredTrails = filterLiftTrailItemsBySeason(data?.trails, settings?.season)
        ?.filter((f) => !settings
          ?.filterOutTrailTypes
          ?.includes(f.type))
        ?.filter((f) => (filters.trailIds.length > 0
          ? filters.trailIds
            ?.includes(f.id)
          : true));

      const filteredLifts = filterLiftTrailItemsBySeason(data?.lifts, settings?.season)
        ?.filter((f) => (filters.liftIds.length > 0
          ? filters.liftIds
            ?.includes(f.id)
          : true));

      setTrails(filteredTrails);
      setLifts(filteredLifts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, filters.trailIds, filters.liftIds]);

  if (!data?.sectors) return null;

  return (
    data?.sectors
      ?.map((sector) => (
        // Hide sector if trails are empty
        trails?.some((trail) => trail.sector.id === sector.id)
        && (
          <UiAccordionPanel
            key={sector.id} //
            panelId={`${sector.name}`}
            label={sector.name}
            isOpen={data.sectors.indexOf(sector) < 4}
            style={{ zIndex: 100 - data.sectors.indexOf(sector) }}
            extraDetailsRender={
              (
                <DorSectorDetailsRender
                  data={data}
                  settings={settings}
                  sector={sector}
                  theme={theme}
                />
              )
            }
          >

            <DorLiftTableRender
              rows={lifts
                ?.filter((f) => f?.sector?.uuid === sector.id)}
              theme={theme.sectorLiftHeader.class}
              settings={settings}
            />

            <DorTrailTableRender
              rows={trails
                ?.filter((f) => f?.sector?.id === sector.id)}
              settings={settings}
            />

          </UiAccordionPanel>
        )

      ))
  );
};

DorTrailSectorRender.propTypes = {
  data: DataShape.isRequired,
  settings: SettingsShape.isRequired,
  theme: ThemeShape,
  filters: FiltersShape.isRequired,
};

export default DorTrailSectorRender;
