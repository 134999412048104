/* eslint-disable no-nested-ternary */
// eslint-disable-next-line import/no-unresolved
import * as Forms from '@forms';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useState, useContext } from 'react';

import { Icon, UiHtml } from '@powdr/components';
import { ButtonText } from '@powdr/constants';
import { AppContext } from '@powdr/context';
import { useStaticData } from '@powdr/web/src/hooks/';

import { StyledFormFormbucket, FormStatePopup } from './styles';

const PopupMessages = {
  SUCCESS: {
    HEADER: 'Thank you',
    COPY: 'Your message has been successfully sent.',
  },
  ERROR: {
    HEADER: 'Error',
    COPY: 'There was an error processing your request.',
  },
};

// TODO: convert this to use modal component
export const FormFormbucket = ({
  formName,
  formContent,
  successHeader,
  successSubheader,
  parentColorProfile,
}) => {
  const [isUserVerified, setUserVerified] = useState(false);
  const [isSendingForm, setSendingForm] = useState();
  const [isSubmitSuccess, setSubmitSuccess] = useState(null);
  const { reCaptchaPublicKey } = useStaticData();
  const { toggleBodyScroll } = useContext(AppContext);
  const FormTemplate = Forms?.[`${formName.replaceAll(' ', '')}Form`];

  const handleCaptchaExpiry = () => {
    setUserVerified(false);
    setSendingForm(false);
    setSubmitSuccess(false);
  };

  const processForm = (e, bucketId, containsBucketId = null, captchaRef) => {
    e.preventDefault();
    setSendingForm(true);
    const formData = Object.assign(
      {},
      ...Array.from(e.target)
        .map((element) => ((element.value !== ButtonText.SUBMIT)
          ? { [element.name]: element.value }
          : null))
        .filter((i) => i),
    );

    axios.post(
      `https://api.formbucket.com/f/${(containsBucketId) ? formData[containsBucketId] : bucketId}`,
      formData,
    ).then(() => {
      setSendingForm(false);
      toggleBodyScroll(true);
      setSubmitSuccess(true);
      e.target.reset(); // reset form fields on success
      captchaRef.current.reset(); // reset captcha on success
    }).catch((error) => {
      setSendingForm(false);
      toggleBodyScroll(true);
      setSubmitSuccess(false);
      console.error('Error Submitting Form', error);
    });
  };

  return (
    <StyledFormFormbucket colorProfile={parentColorProfile}>
      {(formContent?.value) && <UiHtml html={formContent} colorProfile={parentColorProfile} />}
      {(FormTemplate) && (
        <FormTemplate
          processForm={processForm}
          reCaptchaPublicKey={reCaptchaPublicKey}
          setUserVerified={setUserVerified}
          isUserVerified={isUserVerified}
          isSendingForm={isSendingForm}
          handleCaptchaExpiry={handleCaptchaExpiry}
          buttonText={
            (isSendingForm) ? ButtonText.SENDING : (isSubmitSuccess) ? ButtonText.SENT : null
          }
        />
      )}
      {(isSubmitSuccess !== null) && (
        <FormStatePopup>
          <button
            className="close-button"
            type="button"
            onClick={() => { setSubmitSuccess(null); toggleBodyScroll(false); }}
          >
            <Icon name="ui-close" width="25" height="25" />
          </button>
          <div>
            <h2>
              {(isSubmitSuccess)
                ? (successHeader || PopupMessages.SUCCESS.HEADER) : PopupMessages.ERROR.HEADER}
            </h2>
            <p>
              {(isSubmitSuccess)
                ? (successSubheader || PopupMessages.SUCCESS.COPY) : PopupMessages.ERROR.COPY}
            </p>
          </div>
        </FormStatePopup>
      )}
    </StyledFormFormbucket>
  );
};

FormFormbucket.propTypes = {
  formName: PropTypes.string.isRequired,
  formContent: PropTypes.string,
  parentColorProfile: PropTypes.string,
  successHeader: PropTypes.string,
  successSubheader: PropTypes.string,
};

FormFormbucket.defaultProps = {
  parentColorProfile: null,
  formContent: null,
  successHeader: PopupMessages.SUCCESS.HEADER,
  successSubheader: PopupMessages.SUCCESS.COPY,
};
