/* eslint-disable no-nested-ternary */
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import styled, { css } from 'styled-components';

import 'react-datepicker/dist/react-datepicker.css';

import { Icon } from '@powdr/components/icon';
import {
  Breakpoints, FontFamily, FontSize, ThemeProps as Prop,
} from '@powdr/constants';
import { getFontStyles, themeUtil } from '@powdr/utils';

const DatePickerWrapper = styled.div`
  width: 100%;
  margin-right: 15px;
  position: relative;
  ${() => getFontStyles(FontFamily.BASE_FONT, FontSize.REG50, undefined, undefined)};
  border: 1px solid rgba(0, 0, 0, .15);

  ${Breakpoints.desktopOnly(css`
    max-width: 275px;
  `)}

  ${Breakpoints.mobile(css`
    margin-bottom: 15px;
  `)}

  .react-datepicker {
    font-family: inherit;
    font-size: inherit;

    // Fix for UX issue on datepicker via https://github.com/Hacker0x01/react-datepicker/issues/2930#issuecomment-1104174745
    // TODO: can possibly remove after next package update, check periodically for package update
    &__day--keyboard-selected,
    &__month-text--keyboard-selected,
    &__quarter-text--keyboard-selected,
    &__year-text--keyboard-selected {
      border-radius: inherit;
      background-color: inherit;
      color: inherit;
    }

    &__month--selected,
    &__month--in-selecting-range,
    &__month--in-range,
    &__quarter--selected,
    &__quarter--in-selecting-range,
    &__quarter--in-range {
      border-radius: 0.3rem;
      background-color: #216ba5;
      color: #fff;
    }

    &__header {
      background-color: inherit;
      border-bottom: none;
    }

    &__day--today {
      background-color: #e4eef5;
      color: #404040;
      border-radius: 4px;
      font-weight: inherit;
    }
  }
`;

const DatePickerCustomInput = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  cursor: pointer;
  line-height: normal;

  & > *:first-child {
    flex-grow: 1;
  }
`;

const DatePickerIcon = styled(Icon)`
  margin-left: 5px;
  svg {
    fill: ${({ colorProfile }) => themeUtil(Prop.ICON, undefined, colorProfile)};
    color: ${({ colorProfile }) => themeUtil(Prop.ICON, undefined, colorProfile)};
  }
`;

const MS_IN_DAY = 24 * 60 * 60 * 1000;

export const DatePicker = ({
  className,
  colorProfile,
  isCurrentDaySelectable,
  placeholder,
  prettyDate,
  dateFormat,
  isDefaultDate,
  isAutoSelected,
  minDate,
  maxNumDays,
  handleDateChange,
}) => {
  const [date, setDate] = useState(null);
  const minSelectableDate = () => {
    if (minDate) { return minDate; }

    return isCurrentDaySelectable
      ? new Date()
      : new Date(Date.now() + 1 * MS_IN_DAY);
  };

  const maxSelectableDate = new Date(Date.now() + (maxNumDays * MS_IN_DAY));

  const onChangeDate = (d) => {
    setDate(d);
    handleDateChange(d);
  };

  return (
    <DatePickerWrapper className={className}>
      <ReactDatePicker
        minDate={minSelectableDate()}
        maxDate={maxSelectableDate}
        dateFormat={dateFormat}
        selected={date || minSelectableDate()}
        onChange={onChangeDate}
        customInput={(
          <DatePickerCustomInput colorProfile={colorProfile}>
            <span>
              {date
                ? format(date, prettyDate)
                : (minSelectableDate && (isDefaultDate || isAutoSelected))
                  ? format(minSelectableDate(), prettyDate)
                  : placeholder}
            </span>
            <DatePickerIcon
              colorProfile={colorProfile}
              name="ui-calendar-picker"
              width="20"
              height="20"
            />
          </DatePickerCustomInput>
        )}
      />
    </DatePickerWrapper>
  );
};

DatePicker.propTypes = {
  className: PropTypes.string.isRequired,
  colorProfile: PropTypes.string,
  isCurrentDaySelectable: PropTypes.bool,
  placeholder: PropTypes.string,
  prettyDate: PropTypes.string,
  dateFormat: PropTypes.string,
  isDefaultDate: PropTypes.bool,
  isAutoSelected: PropTypes.bool,
  minDate: PropTypes.instanceOf(Date),
  maxNumDays: PropTypes.number,
  handleDateChange: PropTypes.func,
};

DatePicker.defaultProps = {
  colorProfile: 'base',
  isCurrentDaySelectable: false,
  placeholder: 'Select Date...',
  prettyDate: 'MMM dd yyyy',
  dateFormat: 'MM/dd/yyyy',
  isDefaultDate: false,
  isAutoSelected: false,
  handleDateChange: () => {},
  minDate: new Date(),
  maxNumDays: 365,
};
