import PropTypes from 'prop-types';
import React, {
  useContext, useRef, useEffect, useState,
} from 'react';

import { AppContext } from '@powdr/context';
import { doubleDigitizer, formatDate } from '@powdr/utils';

import {
  Clock, ComponentWrapper, LogoWrapper, NextButton, PauseButton,
  SlideTitle, StyledTvMain, TvNav, TvNavBar,
  TvNavItem,
  TvNavItemIndex,
  TvNavItemInner,
} from './styles';

export const TvMain = ({
  className,
  title,
  lastUpdatedTime,
  navPages,
  activeItemData,
  children,
  pauseState,
  setPauseState,
  nextItem,
}) => {
  const { svgs } = useContext(AppContext);
  const [time, setTime] = useState();
  const clockIntervalRef = useRef();

  useEffect(() => {
    clockIntervalRef.current = setInterval(() => {
      const date = new Date();
      const dateInfo = formatDate(date);

      setTime(`${dateInfo.time.hours}:${dateInfo.time.minutes} ${dateInfo.time.ampm.toUpperCase()}`);
    }, 1000);
  }, []);

  return ((
    <StyledTvMain className={className}>
      {/* <PauseButton onClick={() => setPauseState(!pauseState)}>Pause Rotation</PauseButton>
      <NextButton onClick={() => nextItem()}>Next Item</NextButton> */}
      <SlideTitle>
        <h1>{title}</h1>
        {(lastUpdatedTime) && <h2>{`Last Updated at ${lastUpdatedTime}`}</h2>}
      </SlideTitle>
      <LogoWrapper>
        <svgs.SiteLogoTv />
      </LogoWrapper>
      <ComponentWrapper>
        {children}
      </ComponentWrapper>
      <TvNavBar>
        <TvNav>
          {navPages?.map((i, idx) => (
            <TvNavItem $isActive={activeItemData.link.includes(i.link)}>
              <TvNavItemInner $isActive={activeItemData.link.includes(i.link)}>
                <TvNavItemIndex>{doubleDigitizer(idx + 1)}</TvNavItemIndex>
                {` / ${i.name}`}
              </TvNavItemInner>
            </TvNavItem>
          ))}
        </TvNav>
        <Clock>{time}</Clock>
      </TvNavBar>
    </StyledTvMain>
  ));
};

TvMain.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  navPages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  activeItemData: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  lastUpdatedTime: PropTypes.string.isRequired,
};

TvMain.defaultProps = {
  className: '',
};
