import styled from 'styled-components';

export const WebcamFrame = styled.div`
  display: block;
  position: relative;
  height: 0;
  padding-bottom: 56%;

  #webcam_holder {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
