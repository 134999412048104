/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import { QueryParamNames } from '@powdr/constants';

export { wrapPageElement } from './src/components/page-wrapper';
export { wrapRootElement } from './src/components/root-wrapper';

// TODO: This is where we will manage scroll positon between routes
// Example https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#shouldUpdateScroll
// return false to stop return of scroll postion
const shouldUpdateScroll = ({
  // getSavedScrollPosition,
  prevRouterProps,
  routerProps,
}) => {
  const { location } = routerProps;
  const { pathname: path } = location || '';
  const { pathname: prevPath } = prevRouterProps?.location || '';
  // console.log('prev saved', getSavedScrollPosition(prevRouterProps?.location || {}));

  if (location?.state?.isCancelScroll) {
    const newRouteSegments = path?.split('/')?.length || 0;
    const oldRouteSegments = prevPath?.split('/')?.length || 0;
    if (prevPath !== '/') {
      if (path?.startsWith(prevPath) && (newRouteSegments - oldRouteSegments) === 1) {
        return false;
      }
    }
    // now the opposite
    // eg. new path: /foo/bar/baz old path: /foo/bar
    if (prevPath !== '/') {
      if (prevPath?.startsWith(path) && (oldRouteSegments - newRouteSegments) === 1) {
        return false;
      }
    }
  }

  // prevent scroll when closing video modals
  if (location?.search?.includes(`${QueryParamNames.VIDEO}=`)
      || prevRouterProps?.location?.search?.includes(`${QueryParamNames.VIDEO}=`)
  ) {
    return false;
  }

  if (location?.search?.includes('?') && prevPath === path) {
    return false;
  }

  return true;
};

export { shouldUpdateScroll };
