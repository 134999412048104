import styled, { css } from 'styled-components';

import {
  ThemeProps as Prop, Breakpoints, Components,
} from '@powdr/constants';
import { themeUtil } from '@powdr/utils';

const CPNT = Components.UI_EMBED;

export const StyledUiEmbed = styled.div`
  &.fit, .fit {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
`;

export const AlignmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const BlurBackground = styled.div`
  height: 100%;
  width: 100%;
  z-index: 0;
  position: relative;
  backdrop-filter: blur(6px);
`;

export const PlayButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
  height: 30%;
  max-width: 125px;
  max-height: 125px;

  svg {
    fill: ${() => themeUtil(Prop.ICON, CPNT)};
    color: ${() => themeUtil(Prop.ICON, CPNT)};
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 50%;
  padding: 15px;
  background-color: ${() => themeUtil(Prop.BTN_BG, CPNT)};
  z-index: 1;

  ${Breakpoints.mobile(css`
    padding: 10px;
  `)}

  .close-button-icon {
    margin: 0 auto;

    svg {
      fill: ${() => themeUtil(Prop.BTN_TXT, CPNT)};
      color: ${() => themeUtil(Prop.BTN_TXT, CPNT)};
    }
  }

  &:hover {
    background-color: ${() => themeUtil(Prop.BTN_BG_HOVER, CPNT)};

    svg {
      fill: ${() => themeUtil(Prop.BTN_TXT_HOVER, CPNT)};
      color: ${() => themeUtil(Prop.BTN_TXT_HOVER, CPNT)};
    }
  }
`;
