import { createStore, applyMiddleware } from 'redux';
// import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import rootReducer from './reducers';

const initalState = {};

const middleware = [thunk];

// Leaving this for now in case we need it for debugging
// const store = createStore(rootReducer, initalState, composeWithDevTools(
//   applyMiddleware(...middleware)
// ));
const store = createStore(
  rootReducer,
  initalState,
  applyMiddleware(...middleware),
);

export default store;
