import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { CalendarBadge } from '@powdr/components/calendar-badge';
import { Icon } from '@powdr/components/icon';
import { Link } from '@powdr/components/link';
import { BlockTypes, ThemeProps as Prop } from '@powdr/constants';
import { ContentBlockType } from '@powdr/model';
import { themeUtil } from '@powdr/utils';

const CPNT = BlockTypes.FLAG;

// styled components
const ContentBlockFlag = styled.div`
  display: block;
  position: relative;
  color: ${({ colorProfile }) => themeUtil(Prop.HEADER, CPNT, colorProfile)};
  border-top: 1px solid ${({ colorProfile }) => themeUtil(Prop.ELEMENT, CPNT, colorProfile)};
  border-bottom: 1px solid ${({ colorProfile }) => themeUtil(Prop.ELEMENT, CPNT, colorProfile)};
  font-family: sans-serif;
  margin-bottom: 0px;

  .flag {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .cb-title {
    width: 100%;
    margin: 15px;
    text-transform: capitalize;
  }

  .calendar-badge {
    width: 100px;
    padding: 12.5px;

    .date-day-month-wrapper {
      margin: 0;
      border: none;
    }

    .day-of-week-text {
      display: none;
    }

    .month {
      color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
    }
  }

  .flag-button {
    margin: 5px 15px;
    border-radius: 50%;
    padding: 20px;
    transition: color .25s ease-out, background-color .25s ease-out;
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};

    &:hover, &:focus {
      color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
      background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
    }

    &:focus {
      outline: 2px solid ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
      outline-offset: 2px;
    }
  }
`;

const StyledLink = styled(Link)`
  display: inline-block;
`;

export const Flag = ({
  title, colorProfile, blockType, ariaLabel,
  buttonEnabled, buttonLink, buttonTarget, buttonClick,
  date, endDate, iconName,
}) => (
  <ContentBlockFlag
    colorProfile={colorProfile}
    className={`content-block-${blockType}`}
  >
    <div className={classNames('flag', { range: date && endDate })}>
      {(date) && <CalendarBadge startDate={date} endDate={endDate} colorProfile={colorProfile} />}
      <h3 className="cb-title">{title}</h3>
      <small className="tag">
        {/** TODO: tag list here, not sure if we still use it so not adding it for now */}
      </small>
      {(buttonEnabled) && (
        <StyledLink
          className="flag-button"
          href={buttonLink}
          target={buttonTarget}
          onClick={() => buttonClick()}
          aria-label={ariaLabel}
          role="button"
        >
          <Icon className="button-icon" name={iconName || 'ui-plus-thick'} width="15" height="15" />
        </StyledLink>
      )}
    </div>
  </ContentBlockFlag>
);

Flag.propTypes = PropTypes.objectOf(ContentBlockType).isRequired;
