import styled, { css } from 'styled-components';

import {
  ThemeProps as Prop, Breakpoints, Components,
  FontSize,
  FontFamily,
  TextTransformOptions,
} from '@powdr/constants';
import { getFontStyles, themeUtil } from '@powdr/utils';

const CPNT = Components.CONTENT_CAROUSEL;

export const StyledContentCarousel = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};
`;

export const ThumbnailCarouselContainer = styled.div`
  height: 33%;
`;

export const CarouselItemsWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  min-width: 100%;
  white-space: nowrap;
  height: 100%;
  width: 100%;
  transform: translateX(${({ translateAmount }) => translateAmount});
  ${({ isAddPadding }) => ((isAddPadding) ? 'padding: 25px 0;' : '')};

  &.transition-active {
    transition: transform ${({ animationDuration }) => `${animationDuration}ms`} ease;
  }
`;

const CarouselItemStyles = css`
  display: inline-flex;
  height: 100%;
  justify-content: center;

  ${({ isThumbnailItem, slideWidth }) => (
    (!isThumbnailItem) ? `
      width: ${slideWidth}%;
      min-width: ${slideWidth}%;
    ` : 'width: 100%; pointer-events: none;')}
`;

export const ThumbnailItemWrapper = styled.div`
  ${CarouselItemStyles}
  padding: 10px;
  cursor: pointer;

  ${Breakpoints.mobile(css`
    padding: 4px;
  `)}
`;

export const CarouselItem = styled.div`
  ${CarouselItemStyles}
`;

export const CarouselControlOuterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${({ position }) => `padding-${position}: 25px;`}
`;

export const CarouselControlInnerWrapper = styled.div`
  display: flex;
  justify-content: ${({ showArrows }) => ((showArrows) ? 'space-between' : 'center')};
  min-width: ${({ showIndicators }) => ((showIndicators) ? '175px' : '125px')};
  align-items: center;
  padding: 15px;
  gap: 30px;
`;

export const CarouselIndicators = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 15px 30px;
  height: 100%;
  min-width: 50px;

  ${Breakpoints.mobile(css`
    gap: 30px;
    min-height: 50px;
  `)}
`;

export const CarouselIndicatorWrapper = styled.li`
  display: flex;
  height: 100%;

  svg {
    width: auto;
  }

  ${Breakpoints.mobile(css`
    padding: 0;

    &:last-child {
      padding: 0;
    }
  `)}
`;

export const CarouselDotIndicator = styled.button`
  background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};
  width: 15px;
  height: 15px;
  border-radius: 50%;

  &.active, &:hover {
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
  }
`;

export const CarouselCustomIndicator = styled.button``;

export const TextIndicator = styled.div`
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.MED50, undefined, TextTransformOptions.UPPERCASE)};
  font-weight: bold;
  color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};

  &.active, &:hover {
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
  }

  ${Breakpoints.mobile(css`
    ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.MED20, undefined, TextTransformOptions.UPPERCASE)};
  `)}
`;

export const IconIndicator = styled.div`
  &.icon-indicator svg {
    fill: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};
  }

  &.active, &:hover {
    svg {
      fill: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
      color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
    }
  }
`;

export const OnSlideArrows = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  pointer-events: none;
  justify-content: space-between;
  position: absolute;
`;

export const ControlArrowWrapper = styled.button`
  cursor: pointer;

  // arrows positioned on slide
  ${({ placeOnSlide, direction, colorProfile }) => ((placeOnSlide) ? `
    width: 35px;
    height: 100%;
    pointer-events: all;

    &:hover {
      .control-arrow {
        &:after {
          border: solid ${themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
          border-width: 35px;
          border-top: 35px solid transparent;
          border-bottom: 35px solid transparent;
          ${(direction === 'prev') ? 'border-right' : 'border-left'}: none;
        }

        .control-arrow-icon {
          svg {
            fill: ${themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
            color: ${themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
          }
        }
      }
    }` : ''
  )}
`;

export const ControlArrow = styled.div`
  // arrows positioned on slide
  ${({
    placeOnSlide, direction, hideBackground, colorProfile,
  }) => ((placeOnSlide) ? `
      position: absolute;
      top: 50%;
      ${(direction === 'prev') ? 'left' : 'right'}: 0;

      &:after {
        content: " ";
        position: absolute;
        top: calc(50% - 35px);
        ${(direction === 'prev') ? 'left' : 'right'}: 0;
        ${(hideBackground) ? 'border: none;' : `border: solid ${themeUtil(Prop.BTN_BG, CPNT, colorProfile)};`}
        border-width: 35px;
        border-top: 35px solid transparent;
        border-bottom: 35px solid transparent;
        ${(direction === 'prev') ? 'border-right' : 'border-left'}: none;
        width: 0;
        height: 0;
        z-index: 2;
      }

      .control-arrow-icon {
        position: relative;
        z-index: 3;
        ${(direction === 'prev') ? 'left: 5px;' : 'right: 5px; transform: rotate(180deg);'};

        svg {
          fill: ${themeUtil((hideBackground) ? Prop.CONTENT : Prop.BTN_TXT, CPNT, colorProfile)};
          color: ${themeUtil((hideBackground) ? Prop.CONTENT : Prop.BTN_TXT, CPNT, colorProfile)};
        }
      }
    ` : `
      ${(direction === 'prev') ? '' : 'transform: rotate(-180deg);'};

      .control-arrow-icon {
        svg {
          fill: ${themeUtil(Prop.BTN_BG, CPNT, colorProfile)};
          color: ${themeUtil(Prop.BTN_BG, CPNT, colorProfile)};
        }
      }


      &:hover {
        .control-arrow-icon {
          svg {
            fill: ${themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
            color: ${themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
          }
        }
      }
    `
  )}
`;
