import axios from 'axios';

import { FetchStatus } from '@powdr/constants';
import { getNodeApiDomain } from '@powdr/utils';

import { ALERTS_PENDING, ALERTS_SUCCESS, ALERTS_ERROR } from '../types';

export const getAlerts = () => async (dispatch, getState) => {
  const alertsStatus = getState().alerts.status;
  dispatch({
    type: ALERTS_PENDING,
    payload: [],
  });
  // If more than one component calls the same feed make sure we only make one request
  if (alertsStatus === FetchStatus.IDLE) {
    try {
      const res = await axios.get(`${getNodeApiDomain()}/dor/drupal/alerts`);
      dispatch({
        type: ALERTS_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: ALERTS_ERROR,
        payload: error,
      });
    }
  }
};
