import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useQueryParam, StringParam } from 'use-query-params';

import { Icon } from '@powdr/components';
import { Season, QueryParamNames } from '@powdr/constants';
import { AppContext } from '@powdr/context';
import { useSeasonChange } from '@powdr/hooks';
import { useStaticData } from '@powdr/web/src/hooks';

import { StyledSeasonToggle } from './styles';

export const SeasonToggle = ({
  className,
}) => {
  const { uiSeason } = useContext(AppContext);
  const { property } = useStaticData();
  const [seasonParam] = useQueryParam(QueryParamNames.SEASON, StringParam);
  const { handleSeasonChange } = useSeasonChange();

  return (
    <StyledSeasonToggle
  // TODO: Get from site meta
      className={classNames('season-toggle', property, className)}
      aria-label="Season Toggle"
    >
      <button
        className="season-toggles-control"
        type="button"
        aria-label="Change Season"
        onClick={
          () => (
            seasonParam === Season.SUMMER
              ? handleSeasonChange(Season.WINTER)
              : handleSeasonChange(Season.SUMMER)
          )
        }
      >
        <ul
          className={
            classNames('season-buttons', {
              [Season.WINTER]: uiSeason === Season.WINTER,
              [Season.SUMMER]: uiSeason === Season.SUMMER,
            })
          }
        >
          <li
            className="left-toggle winter-toggle"
            aria-label="Winter"
            tabIndex="-1"
          >
            <Icon name="dor-snow" width="20" height="20" />
            <span>Winter</span>
          </li>
          <li className="right-toggle summer-toggle" aria-label="Summer">
            <Icon name="dor-clear" width="20" height="20" />
            <span>Summer</span>
          </li>
        </ul>
      </button>
    </StyledSeasonToggle>
  );
};

SeasonToggle.propTypes = {
  className: PropTypes.string,
};

SeasonToggle.defaultProps = {
  className: '',
};
