import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useWindowResize } from '@powdr/hooks';
import { getVimeoVideoData } from '@powdr/stores/actions/media-action';
import { getRatio, getVideoLink } from '@powdr/utils';

import { StyledVimeoVideo, VideoFrame } from './styles';

export const VimeoVideo = ({
  parentRef,
  videoLink,
  isBackground,
}) => {
  const [vimeoBackgroundResponse, setVimeoBackgroundResponse] = useState(null);
  const dispatch = useDispatch();
  const { media } = useSelector((state) => state.media) || [];
  const videoRef = useRef(null);
  const resize = useWindowResize();

  useEffect(() => {
    dispatch(getVimeoVideoData(videoLink));
  }, [videoLink, dispatch]);

  useEffect(() => {
    if (media?.[videoLink]) setVimeoBackgroundResponse(media[videoLink]);
  }, [media, videoLink]);

  useEffect(() => {
    if (vimeoBackgroundResponse && parentRef?.current && videoRef?.current) {
      const { width: videoW, height: videoH } = vimeoBackgroundResponse;
      const { offsetWidth: slideW, offsetHeight: slideH } = parentRef.current;
      const ratio = getRatio(videoW, videoH);
      const aspectRatio = (videoW / ratio) / (videoH / ratio);
      const scale = ((slideW / slideH) > aspectRatio)
        ? (slideW / (aspectRatio * slideH))
        : (slideH * (aspectRatio / slideW));
      videoRef.current.setAttribute('style', `transform: scale(${scale + (scale * 0.02)})`);
    }
  }, [parentRef, vimeoBackgroundResponse, resize]);

  if (!vimeoBackgroundResponse) return null;

  return (
    <StyledVimeoVideo>
      <VideoFrame
        ref={videoRef}
        src={getVideoLink(videoLink, null, isBackground)}
        autoplay
      />
    </StyledVimeoVideo>
  );
};

VimeoVideo.propTypes = {
  parentRef: PropTypes.shape().isRequired,
  videoLink: PropTypes.string.isRequired,
  isBackground: PropTypes.bool.isRequired,
};

VimeoVideo.defaultProps = {};
