import styled from 'styled-components';

export const StyledUiFrame = styled.div`
  ${({ isContainer }) => ((isContainer) ? 'padding: 0 7.5%;' : '')}
`;

export const IframeWrapper = styled.div`
  display: block;
  position: relative;
  ${({ aspectRatio }) => ((aspectRatio) ? `aspect-ratio: ${aspectRatio};` : '')};
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
