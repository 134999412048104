/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
import { BGImageBreakpoints, EmbeddableVideoSites } from '@powdr/constants';

/**
 * Get background image source from gatsbyImage object based on screen width and image context
 * @param image gatsbyImage object
 * @param screenWidth current screen width
 * @param resizeFactor adjust for multi-column layouts based on number of columns
 */
export const getBackgroundImageSrc = (image, screenWidth, resizeFactor = 1) => {
  if (image) {
    const approxImageSizeNeeded = screenWidth / resizeFactor;
    const images = image.images.sources?.[0] || image.images.fallback;
    const srcSet = images?.srcSet.split(',').map((src) => src.slice(0, src.indexOf(' ')));

    for (const bp in BGImageBreakpoints) {
      if (approxImageSizeNeeded < BGImageBreakpoints[bp] && srcSet[bp]) return srcSet[bp];
    }

    // if approxImageSizeNeeded is larger than final breakpoint available, return the largest image
    return srcSet[srcSet.length - 1];
  }
  return null;
};

/**
 * Read and parse a string that contains a link for a YouTube or Vimeo video
 * to use for other components
 *
 * @param link string containing video link
 * @param videoId string containing just a YouTube video ID
 * @param isBackground boolean to determine certain parameters for returned video link
 *
 * @returns src link to be used in iframe, if no valid case is hit will return an empty string
 */
export const getVideoLink = (link, videoId, isBackground) => {
  if (videoId) {
    return `https://www.youtube.com/embed/${videoId}?autoplay=1&rel=0&wmode=transparent&modestbranding=1&autohide=1&showinfo=0`;
  }

  if (link?.includes(EmbeddableVideoSites.VIMEO)) {
    const linkSplit = link.split(EmbeddableVideoSites.VIMEO)[link.split(EmbeddableVideoSites.VIMEO).length - 1].split('/').filter((x) => x);
    return `https://player.vimeo.com/video/${linkSplit[0]}${(linkSplit.length > 1) ? `?h=${linkSplit[linkSplit.length - 1]}&autoplay=1` : '?autoplay=1'}${(isBackground) ? '&background=1' : ''}`;
  }

  if (link?.includes(EmbeddableVideoSites.YOUTUBE)) {
    const parsedVideoId = link.split('?v=')[link.split('?v=').length - 1];
    return `https://www.youtube.com/embed/${parsedVideoId}?autoplay=1&rel=0&wmode=transparent&modestbranding=1&autohide=1&showinfo=0`;
  }

  return '';
};

export const getImageAltText = (mediaEntity) => mediaEntity?.relationships?.media__image?.[0].thumbnail?.alt || '';
