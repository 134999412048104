import styled, { css } from 'styled-components';

import {
  ThemeProps as Prop, Components, Breakpoints, FontFamily, FontSize,
  TextTransformOptions,
} from '@powdr/constants';
import { getFontStyles, themeUtil } from '@powdr/utils';

const CPNT = Components.REVERSIBLE_TABLE;

export const StyledReversibleTable = styled.div`
  table {
    width: auto;
    border-collapse: separate;
    border-spacing: 50px 0;
    margin-left: -50px;

    ${Breakpoints.smallInbetween(css`
      border-spacing: 30px 0;
      margin-left: -30px;
    `)}

    ${Breakpoints.smaller(css`
      border-spacing: 15px 0;
      margin-left: -15px;
    `)}

    ${Breakpoints.mobile(css`
      border-spacing: 5px 0;
      margin-left: -5px;
    `)}
  }

  th, td {
    color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
    line-height: 1.2;
    text-transform: uppercase;
    min-width: 100px;

    ${Breakpoints.mobile(css`
      min-width: unset;
    `)}
  }

  thead th {
    ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.REG20, undefined, undefined)};
    padding-bottom: 3px;
    border-bottom: 1px solid ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
    white-space: nowrap;
  }

  tfoot th {
    ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.REG20, undefined, undefined)};
    padding-top: 3px;
    border-top: 1px solid ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
  }

  tbody td {
    ${() => getFontStyles(FontFamily.BASE_FONT, FontSize.MED40, undefined, undefined)};

    ${Breakpoints.smallInbetween(css`
      ${() => getFontStyles(FontFamily.BASE_FONT, FontSize.MED30, undefined, undefined)};
    `)}

    ${Breakpoints.smaller(css`
      ${() => getFontStyles(FontFamily.BASE_FONT, FontSize.REG50, undefined, undefined)};
    `)}

    ${Breakpoints.mobile(css`
      ${() => getFontStyles(FontFamily.BASE_FONT, FontSize.MED40, undefined, undefined)};
    `)}

    ${({ isReversed }) => ((isReversed) ? `
      padding-bottom: 3px;
      white-space: normal;
    ` : `
      text-transform: uppercase;
      padding-top: 3px;
      white-space: nowrap;
    `)}
  }
`;
