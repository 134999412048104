/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { Season } from '@powdr/constants';
import { AppContext } from '@powdr/context';
import { useStaticData } from '@powdr/web/src/hooks';

import { StyledHeaderLogo } from './styles';

export const HeaderLogo = ({
  className,
  isFromDrawer,
  isMinimizedLogo,
  isFromDemo,
}) => {
  const { defaultSeason, siteName } = useStaticData();
  const { themeConfig, isMobile, svgs } = useContext(AppContext);

  return (
    <StyledHeaderLogo
      className={`header-logo ${className}`}
      $logoPadding={themeConfig.layout?.logoPadding || 0}
      $logoPaddingMobile={themeConfig.layout?.logoPaddingMobile || 0}
    >
      <Link
        to={defaultSeason === Season.SUMMER ? `/?season=${Season.SUMMER}` : '/'}
        aria-label="Return to Homepage"
        title={siteName}
      >
        {(isFromDrawer)
          ? (isMobile) ? <svgs.SiteLogoDrawerMobile /> : <svgs.SiteLogoDrawer />
          : (isMobile)
            ? (isMinimizedLogo) ? <svgs.SiteLogoWrapperMobileScrolled /> : <svgs.SiteLogoWrapperMobile />
            : (isMinimizedLogo || isFromDemo) ? <svgs.SiteLogoWrapperScrolled /> : <svgs.SiteLogoWrapper /> }
      </Link>
    </StyledHeaderLogo>
  );
};

HeaderLogo.propTypes = {
  className: PropTypes.string,
  isFromDrawer: PropTypes.bool,
  isMinimizedLogo: PropTypes.bool,
  isFromDemo: PropTypes.bool,
};

HeaderLogo.defaultProps = {
  className: '',
  isFromDrawer: false,
  isMinimizedLogo: false,
  isFromDemo: false,
};
