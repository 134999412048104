import { FetchStatus } from '@powdr/constants';

import { LIFTS_PENDING, LIFTS_SUCCESS, LIFTS_ERROR } from '../types';

const initialState = {
  data: null,
  status: FetchStatus.IDLE,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LIFTS_PENDING:
      return {
        ...state,
        status: FetchStatus.LOADING,
      };
    case LIFTS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        status: FetchStatus.SUCCESS,
      };
    case LIFTS_ERROR:
      return {
        status: FetchStatus.ERROR,
        error: action.payload,
      };
    default: return state;
  }
};
