import styled, { css } from 'styled-components';

import { Link } from '@powdr/components';
import {
  Breakpoints, ThemeProps as Prop, Components, FontFamily, FontSize,
} from '@powdr/constants';
import { componentColor, getFontStyles } from '@powdr/utils';

const CPNT = Components.FOOTER;

export const StyledSocialIconList = styled.div`
  display: flex;
  flex-direction: column;
  vertical-align: middle;
  ${({ isEmailSignup }) => ((isEmailSignup) ? '' : 'margin-bottom: 20px;')}

  &.mobile-spacing {
    ${Breakpoints.mobile(css`
      padding-left: 10px;
    `)}

    @media only screen and (max-width: (529px - 1)) {
      padding-left: 0px;
    }
  }

  .social {
    display: inline-block;
    vertical-align: middle;
    height: auto;
    margin: 0;
    ${({ isEmailSignup }) => ((isEmailSignup) ? 'white-space: nowrap;' : '')}

    ${Breakpoints.mobile(css`
      display: block;
      width: 100%;
      text-align: center;
    `)}

    li {
      /* @include inline-block(middle); */
      margin-right: 15px;
      display: inline-block;
    }
  }
`;

const sharedTextStyles = css`
  display: block;
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.REG30, undefined, undefined)};
  color: ${() => componentColor(Prop.LINK_TXT, Components.FOOTER_NAV)};
  transition: all 0.15s ease-in-out;
  line-height: 1.5;

  &:hover, &:active {
    color: ${() => componentColor(Prop.LINK_TXT_HOVER, Components.FOOTER_NAV)};
  }

  ${Breakpoints.large(css`
    ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.REG40, undefined, undefined)};
  `)}

  ${Breakpoints.larger(css`
    ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.REG50, undefined, undefined)};
  `)}
`;

export const Address = styled(Link)`
  ${sharedTextStyles}
`;

export const Email = styled(Link)`
  ${sharedTextStyles}
`;

export const PhoneNumber = styled(Link)`
  ${sharedTextStyles}
`;

export const SocialIconLink = styled(Link)`
  display: block;
  border-radius: 40px;
  padding: 10px;
  border: none;
  position: relative;
  text-align: center;
  width: auto;
  height: auto;
  font-size: ${({ theme }) => theme.FontSize.REG30};
  text-transform: none;
  margin: 0;
  background-color: ${() => componentColor(Prop.BTN_BG, CPNT)};
  transition: background-color 0.25s ease-out, fill 0.25s ease-out;

  &:hover, &:focus {
    transition: background-color 0.25s ease-out, fill 0.25s ease-out;
    background-color: ${() => componentColor(Prop.BTN_BG_HOVER, CPNT)};

    svg {
      fill: ${() => componentColor(Prop.BTN_TXT_HOVER, CPNT)};
      color: ${() => componentColor(Prop.BTN_TXT_HOVER, CPNT)};
    }
  }

  &:focus {
    outline: 2px solid ${() => componentColor(Prop.BTN_BG_HOVER, CPNT)};
    outline-offset: 2px;
  }

  svg {
    fill: ${() => componentColor(Prop.BTN_TXT, CPNT)};
    color: ${() => componentColor(Prop.BTN_TXT, CPNT)};
  }
`;
