import PropTypes from 'prop-types';
import React from 'react';

import { DorPercentWheel } from '@powdr/components';

import {
  CircularGraphLabel, CircularGraphWrapper,
} from './styles';

export const CircularGraph = ({
  colorProfile,
  colorOverride,
  label,
  children,
  openVal,
  totalVal,
}) => (
  <CircularGraphWrapper className="circular-graph">
    <DorPercentWheel
      parentColorProfile={colorProfile}
      openVal={openVal}
      totalVal={totalVal}
      colorOverride={colorOverride}
    >
      {children}
    </DorPercentWheel>
    {(label) && (
      <CircularGraphLabel className="label" $colorProfile={colorProfile}>
        {label}
      </CircularGraphLabel>
    )}
  </CircularGraphWrapper>
);

CircularGraph.propTypes = {
  colorProfile: PropTypes.string,
  colorOverride: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.node,
  openVal: PropTypes.number,
  totalVal: PropTypes.number,
};

CircularGraph.defaultProps = {
  colorProfile: null,
  colorOverride: null,
  label: null,
  children: null,
  openVal: null,
  totalVal: null,
};
