import {
  ArticlePreview, Basic3, BasicAlt, Basic, CardBannerAlt, CardBanner,
  Card, Cover, DetailBlock, Event, FlagV2, Flag, Float, Full3, FullAlt,
  Full, ImageFlag, Plain, PriceSplit, Price, RevealHorizontal,
  RevealVerticalFull, RevealVertical, Solid, Split3, SplitAlt, Split, Stat,
} from '@powdr/components';

import { BlockTypes } from './constants';

export const ContentBlocks = {
  [BlockTypes.ARTICLE_PREVIEW]: ArticlePreview,
  [BlockTypes.BASIC_3]: Basic3,
  [BlockTypes.BASIC_ALT]: BasicAlt,
  [BlockTypes.BASIC]: Basic,
  [BlockTypes.CARD_BANNER_ALT]: CardBannerAlt,
  [BlockTypes.CARD_BANNER]: CardBanner,
  [BlockTypes.CARD]: Card,
  [BlockTypes.COVER]: Cover,
  [BlockTypes.DETAIL_BLOCK]: DetailBlock,
  [BlockTypes.EVENT]: Event,
  [BlockTypes.FLAG_V2]: FlagV2,
  [BlockTypes.FLAG]: Flag,
  [BlockTypes.FLOAT]: Float,
  [BlockTypes.FULL_3]: Full3,
  [BlockTypes.FULL_ALT]: FullAlt,
  [BlockTypes.FULL]: Full,
  [BlockTypes.IMAGE_FLAG]: ImageFlag,
  [BlockTypes.PLAIN]: Plain,
  [BlockTypes.PRICE_SPLIT]: PriceSplit,
  [BlockTypes.PRICE]: Price,
  [BlockTypes.REVEAL_HORIZONTAL]: RevealHorizontal,
  [BlockTypes.REVEAL_VERTICAL_FULL]: RevealVerticalFull,
  [BlockTypes.REVEAL_VERTICAL]: RevealVertical,
  [BlockTypes.SOLID]: Solid,
  [BlockTypes.SPLIT_3]: Split3,
  [BlockTypes.SPLIT_ALT]: SplitAlt,
  [BlockTypes.SPLIT]: Split,
  [BlockTypes.STAT]: Stat,
};
