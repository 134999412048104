import styled, { css } from 'styled-components';

import {
  Breakpoints, FontFamily, FontSize,
  TextTransformOptions,
} from '@powdr/constants';
import { getFontStyles } from '@powdr/utils';

export const StyledDorTrailReport = styled.div``;

export const StaticHeader = styled.div``;

export const PercentWheels = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 100px;

  ${Breakpoints.mobile(css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    ${({ $isParentNavFeed }) => (($isParentNavFeed) ? `
      gap: 75px;
    ` : `
      gap: 15px;
    `)}
  `)}
`;

export const PercentWheelWrapper = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  max-width: 200px;

  h3 {
    ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.MED10, undefined, TextTransformOptions.CAPITALIZE)};
    text-align: center;
  }
`;
