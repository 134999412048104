import styled from 'styled-components';

import {
  ThemeProps as Prop, Components,
} from '@powdr/constants';
import { themeUtil } from '@powdr/utils';

const CPNT = Components.DAY;

export const StyledDay = styled.div`
  display: flex;
  flex-direction: column;

  .price {
    font-size: 14px;
    font-weight: normal;
    color: ${() => themeUtil(Prop.CONTENT, CPNT)};
  }
`;
