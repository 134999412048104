import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getTrails } from '@powdr/stores';

export const useTrailStatus = (trailUUID) => {
  if (!trailUUID) return null;
  const dispatch = useDispatch();
  const useTrailsData = useSelector((state) => state.trails);
  const { data } = useTrailsData;
  const [trailStatus, setTrailStatus] = useState(null);

  useEffect(() => {
    dispatch(getTrails());
  }, [dispatch]);

  useEffect(() => {
    if (data && data?.length > 0) {
      setTrailStatus(data.find((r) => r.id === trailUUID));
    }
  }, [data, trailUUID]);

  return trailStatus;
};
