import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { StyledUiDrawer } from './styles';

export const UiDrawer = ({
  isDrawerOpen,
  isDrawerEnabled,
  className,
  closeBtnTxt,
  drawerWidth,
  drawerOffsetTop,
  children,
  handleDrawOpen,
  handleCanvasClose,
}) => (
  <StyledUiDrawer
    className={
      classNames('ui-drawer', className, {
        'ui-drawer-open': isDrawerOpen,
        'ui-drawer-enabled': isDrawerEnabled,
      })
    }
    drawerWidth={drawerWidth}
    drawerOffsetTop={drawerOffsetTop}
  >
    {isDrawerEnabled && (
      <button
        type="button"
        className={
          classNames('ui-drawer-btn-toggle', {
            'ui-drawer-btn-toggle-open': isDrawerOpen,
          })
        }
        onClick={isDrawerOpen
          ? (e) => handleCanvasClose(e, isDrawerOpen)
          : (e) => handleDrawOpen(e, isDrawerOpen)}
      >
        {closeBtnTxt}
      </button>
    )}

    {isDrawerEnabled && (
      <button
        type="button"
        aria-hidden="true"
        className="ui-drawer-canvas-close"
        onClick={isDrawerOpen
          ? (e) => handleCanvasClose(e, isDrawerOpen)
          : (e) => handleDrawOpen(e, isDrawerOpen)}
      />
    )}
    <div className="ui-drawer-content-wrap">
      <div className="ui-drawer-content">
        {children}
      </div>
    </div>

  </StyledUiDrawer>
);
UiDrawer.propTypes = {
  className: PropTypes.string,
  closeBtnTxt: PropTypes.string,
  isDrawerEnabled: PropTypes.bool,
  isDrawerOpen: PropTypes.bool,
  drawerOffsetTop: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  drawerWidth: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  handleCanvasClose: PropTypes.func,
  handleDrawOpen: PropTypes.func,
  children: PropTypes.node,
};

UiDrawer.defaultProps = {
  className: '',
  closeBtnTxt: 'Filters',
  isDrawerEnabled: true,
  isDrawerOpen: true,
  drawerOffsetTop: 180,
  drawerWidth: 250,
  handleCanvasClose: null,
  handleDrawOpen: null,
  children: null,
};
