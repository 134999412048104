import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

import { useIsInView } from '@powdr/hooks';

import { IframeWrapper, StyledUiFrame } from './styles';

export const UiIframe = ({
  id,
  aspectRatio,
  width,
  height,
  isContainer,
  src,
}) => {
  const [wasViewed, setWasViewed] = useState(false);
  const componentRef = useRef();
  const containerIsVisible = useIsInView(componentRef);

  return (
    <StyledUiFrame ref={componentRef} isContainer={isContainer}>
      {(containerIsVisible && !wasViewed) && setWasViewed(true)}
      <IframeWrapper
        aspectRatio={(!height && !width) ? (aspectRatio || '16/9') : null}
        height={height}
        width={width}
      >
        {(wasViewed) && (
          <iframe
            title={`ui-iframe-${id}`}
            src={src}
            width={width}
            height={height}
            allowFullScreen
          />
        )}
      </IframeWrapper>
    </StyledUiFrame>
  );
};

UiIframe.propTypes = {
  id: PropTypes.string.isRequired,
  aspectRatio: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  isContainer: PropTypes.bool,
  src: PropTypes.string.isRequired,
};

UiIframe.defaultProps = {
  aspectRatio: null,
  width: null,
  height: null,
  isContainer: false,
};
