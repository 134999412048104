import axios from 'axios';

import { FetchStatus } from '@powdr/constants';
import { getNodeApiDomain } from '@powdr/utils';

import { LIVE_WEATHER_PENDING, LIVE_WEATHER_SUCCESS, LIVE_WEATHER_ERROR } from '../types';

export const getLiveWeather = () => async (dispatch, getState) => {
  const sensorsStatus = getState().liveWeather.status;
  dispatch({
    type: LIVE_WEATHER_PENDING,
    payload: [],
  });
  // If more than one component calls the same feed make sure we only make one request
  if (sensorsStatus === FetchStatus.IDLE) {
    try {
      const res = await axios.get(`${getNodeApiDomain()}/dor/sensors`);
      dispatch({
        type: LIVE_WEATHER_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: LIVE_WEATHER_ERROR,
        payload: error,
      });
    }
  }
};
