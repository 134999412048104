import axios from 'axios';

import { getNodeApiDomain } from '@powdr/utils';

import { NODE_PENDING, NODE_SUCCESS, NODE_ERROR } from '../types';

export const getExternalNodeData = (endpoint) => async (dispatch, getState) => {
  const { node } = getState();

  dispatch({
    type: NODE_PENDING,
    payload: [],
  });

  if (!node.node[endpoint]) {
    try {
      const res = await axios.get(`${getNodeApiDomain()}${endpoint}`);
      dispatch({
        type: NODE_SUCCESS,
        payload: {
          [endpoint]: res.data,
        },
      });
    } catch (error) {
      dispatch({
        type: NODE_ERROR,
        payload: error,
      });
    }
  }
};
