/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';

import { Icon } from '@powdr/components';
import {
  ThemeProps as Prop, Breakpoints, Layouts, FontFamily, FontSize, TextTransformOptions,
  Components,
} from '@powdr/constants';
import { getFontStyles, themeUtil } from '@powdr/utils';

const CPNT = Components.DOR_MEASUREMENTS;

export const CircularGraphWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  max-width: 200px;
`;

export const CircularGraphLabel = styled.div`
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.MED10)};
  text-align: center;
`;

export const CircularGraphInnerTextOverride = styled.div`
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.MED40, undefined, undefined)};
`;

export const InnerTextSplitLarge = styled.div`
`;

export const InnerTextSplitSmall = styled.div`
  margin-top: 5px;
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.REG30, undefined, undefined)};
`;

export const TextMeasurementItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const TextMeasurementLabel = styled.div`
  margin-bottom: 15px;
  color: ${({ $colorProfile }) => themeUtil(Prop.CONTENT, CPNT, $colorProfile)};

  ${({ $large }) => (($large)
    ? getFontStyles(FontFamily.PRIMARY_FONT, FontSize.MED50, undefined, undefined)
    : getFontStyles(FontFamily.BASE_FONT, FontSize.REG40, undefined, undefined))};
`;

export const TextMeasurementIcon = styled(Icon)`
  width: 35px;
  height: 35px;

  ${Breakpoints.mobile(css`
    width: 28px;
    height: 28px;
  `)}

  svg {
    fill: ${({ $colorProfile }) => themeUtil(Prop.CONTENT, CPNT, $colorProfile)};
    color: ${({ $colorProfile }) => themeUtil(Prop.CONTENT, CPNT, $colorProfile)};
  }
`;

const sharedTextStyles = css`
  ${({ $small, $smaller }) => getFontStyles(FontFamily.PRIMARY_FONT, ($smaller) ? '35px' : ($small) ? '45px' : '84px', undefined, undefined)};
  line-height: 1;
  color: ${({ $colorOverride, $colorProfile }) => (($colorOverride) || themeUtil(Prop.CONTENT, CPNT, $colorProfile))};

  ${Breakpoints.mobile(css`
    ${({ $small, $smaller }) => getFontStyles(FontFamily.PRIMARY_FONT, ($smaller) ? '20px' : ($small) ? '30px' : FontSize.LRG30, undefined, undefined)};
  `)}
`;

export const TextMeasurementContent = styled.div`
  ${sharedTextStyles}
`;

export const ItemText = styled.div``;

export const ItemValue = styled.p`
  ${sharedTextStyles}
`;

export const ItemUnit = styled.span`
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.MED10, undefined, undefined)};
`;

export const TextMeasurementSplit = styled.div`
  display: flex;
  align-items: baseline;
`;

export const TextMeasurementSplitLarge = styled.div`
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, '84px', undefined, undefined)};
`;

export const TextMeasurementSplitSmall = styled.div`
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, '28px', undefined, undefined)};
`;

export const AdditionalInfo = styled.div`
  margin-top: 10px;
  color: ${({ $colorProfile }) => themeUtil(Prop.CONTENT, CPNT, $colorProfile)};
  ${() => getFontStyles(FontFamily.BASE_FONT, '28px', undefined, undefined)};
`;
