import styled from 'styled-components';

import { WysiwygStyleWrapper } from '@powdr/components';
import {
  Alignment,
} from '@powdr/constants';

export const StyledUiFooter = styled(WysiwygStyleWrapper)`
  text-align: ${({ alignment }) => Alignment[alignment?.toUpperCase()] || Alignment.CENTER};
  padding: ${({ $paddingTop, $paddingBottom }) => `${$paddingTop}px 0px ${$paddingBottom}px`};
`;
