import styled, { css } from 'styled-components';

import { Icon } from '@powdr/components';
import {
  Breakpoints, ColorProfiles, Components, ThemeProps as Prop,
} from '@powdr/constants';
import { themeUtil } from '@powdr/utils';

const CPNT = Components.DISCOUNT_BANNER;

export const StyledDiscountBanner = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  background-color: ${() => themeUtil(Prop.BACKGROUND, CPNT, ColorProfiles.PRIMARY)};
  color: ${() => themeUtil(Prop.HEADER, CPNT, ColorProfiles.PRIMARY)};
  /* margin: 0 16px 35px 16px; */
  padding: 10px;
  width: 100%;
`;

export const Discount = styled.div`
  //${({ theme }) => theme.fonts.PRI_BDY_HVY};
  //font-size: ${({ theme }) => theme.FontSize.MED10};
  line-height: 24px;
  margin: 0 12px;
  text-align: center;

  ${Breakpoints.mobile(css`
    //font-size: ${({ theme }) => theme.FontSize.REG40};
  `)}
`;

export const Icon1 = styled(Icon)``;

export const Icon2 = styled(Icon)``;
