import styled, { css } from 'styled-components';

import {
  Components, Breakpoints,
} from '@powdr/constants';

const CPNT = Components.UI_DRAWER;

export const StyledUiDrawer = styled.div`

  &.ui-drawer-enabled {
    top: ${({ drawerOffsetTop }) => drawerOffsetTop}px;
    left: -${({ drawerWidth }) => drawerWidth}px;
    isolation: isolate;
    transition: all .3s ease;
    transition: 0.5s;
    position: fixed;
    z-index: 5;

    ${Breakpoints.mobile(css`
      width: ${({ drawerWidth }) => drawerWidth}px;
    `)}
    
    &.ui-drawer {
      .ui-drawer-content {
        min-height: 300px;
      }
    }

    &.ui-drawer-open {
      width: ${({ drawerWidth }) => drawerWidth}px;
      z-index: 5;
      left: 0;
      
      .ui-drawer-content-wrap {
        height: auto;
        overflow-y: auto;
        position: relative;
        z-index: 10;
      }
      
      .ui-drawer-btn-toggle {
        z-index: 3;
      }

      .ui-drawer-canvas-close {
        background-color: #000;
        opacity: .8;
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  
`;
