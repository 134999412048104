import PropTypes from 'prop-types';
import * as React from 'react';
import { Provider } from 'react-redux';

import store from './store';

const StoreWrapper = ({ children }) => (
  <Provider store={store}>{children}</Provider>
);

export default StoreWrapper;

StoreWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
