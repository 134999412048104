import styled, { css } from 'styled-components';

import {
  ThemeProps as Prop, Components, Breakpoints,
  FontSize,
  FontFamily,
  GlobalLayoutValues,
  TextTransformOptions,
} from '@powdr/constants';
import { componentColor, getFontStyles } from '@powdr/utils';

const CPNT = Components.STICKY_NAV;

// TODO: Refactor this to be more React-y with more styled components rather than a lot of classes
export const StyledStickyNav = styled.div`
  text-align: center;
  z-index: 70;
  width: 100%;

  ${Breakpoints.desktopOnly(css`
    ${({ $headerOffset, $removeSticky }) => (!$removeSticky) && `top: ${$headerOffset - 1}px; position: sticky;`}
  `)}

  nav.second-level {
    background-color: ${() => componentColor(Prop.BACKGROUND, CPNT, Prop.BACKGROUND)};
    width: 100%;

    li {
      display: inline-block;
      min-height: 51px;
      vertical-align: top;
    }

    a.btn.m-link {
      display: block;
      background-color: transparent;
      color: ${() => componentColor(Prop.BTN_TXT, CPNT, Prop.LINK_TXT)};
      ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.REG40, undefined, TextTransformOptions.CAPITALIZE)};
      height: 100%;
      min-height: 51px;
      line-height: 1.1;
      transition: background-color .25s ease-out, color .25s ease-out;
      border: none;
      text-align: center;
      width: auto;
      padding: 16px 20px;
      margin: 0;

      &:hover, &:focus {
        transition: background-color .25s ease-out, color .25s ease-out;
        background-color: ${() => componentColor(Prop.BTN_BG_HOVER, CPNT, Prop.BACKGROUND)};
        color: ${() => componentColor(Prop.BTN_TXT_HOVER, CPNT, Prop.LINK_TXT_HOVER)};
      }

      &:focus {
        outline: 2px solid ${() => componentColor(Prop.BTN_TXT_ACTIVE, CPNT, Prop.BACKGROUND)};
        outline-offset: -10px;
      }

      &.m-active {
        background-color: ${() => componentColor(Prop.BTN_BG_ACTIVE, CPNT, Prop.BTN_BG_ACTIVE)};
        color: ${() => componentColor(Prop.BTN_TXT_ACTIVE, CPNT, Prop.BTN_TXT_ACTIVE)};

        &:hover {
          background-color: ${() => componentColor(Prop.BTN_BG_ACTIVE, CPNT, Prop.BTN_BG_ACTIVE)};
        }
      }

      ${Breakpoints.large(css`
        ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.REG50, undefined, undefined)};
      `)}

      ${Breakpoints.larger(css`
        ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.MED10, undefined, undefined)};
      `)}
    }
  }

  .sticky-nav-mobile {
    height: ${GlobalLayoutValues.MOBILE.STICKY_NAV.HEIGHT}px;

    ul {
      margin-bottom: 0;
    }

    li {
      height: 100%;
      margin-bottom: 0;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: left;
        height: 100%;
        padding: 0 4.5%;
        padding: 0 round(nearest, 4.5%, 1px);
      }

      .m-link {
        display: block;
        padding: 20px 0;
      }

      button, .m-link {
        ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.MED10, undefined, undefined)};
        width: 100%;
      }


      ${Breakpoints.portraitSmall(css`
        .icon-c {
          width: 15px !important;
          height: 15px !important;
          margin-left: 10px;
        }
      `)}
    }

    .sticky-mobile-active {
      height: 100%;

      button {
        background-color: ${() => componentColor(Prop.BACKGROUND, CPNT)};

        span {
          ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.MED10)};
          color: ${() => componentColor(Prop.BTN_TXT, CPNT, Prop.LINK_TXT)};
          font-weight: bold;
          padding-right: 12px;
        }

        svg {
          fill: ${() => componentColor(Prop.BTN_TXT, CPNT, Prop.LINK_TXT)};
          color: ${() => componentColor(Prop.BTN_TXT, CPNT, Prop.LINK_TXT)};
        }
      }
    }

    .sticky-mobile-dropdown {
      background-color: ${() => componentColor(Prop.BTN_BG_ACTIVE, CPNT, Prop.BTN_BG_ACTIVE)};
      width: 100%;
      position: absolute;
      z-index: 20;
      overflow-y: auto;

      li {
        border-bottom: 1px solid ${() => componentColor(Prop.BTN_TXT_ACTIVE, CPNT)};
        margin: 0 4.5%;
        margin: 0 round(nearest, 4.5%, 1px);

        &:first-child {
          border-top: 1px solid ${() => componentColor(Prop.BTN_TXT_ACTIVE, CPNT)};
        }
      }

      .m-link {
        color: ${() => componentColor(Prop.BTN_TXT_ACTIVE, CPNT)};
      }
    }

    &.mobile-sticky-nav-open {
      .sticky-mobile-active {
        li {
          border-bottom: 0;
        }
        button {
          background-color: ${() => componentColor(Prop.BTN_BG_ACTIVE, CPNT, Prop.BTN_BG_ACTIVE)};

          span {
            color: ${() => componentColor(Prop.BTN_TXT_ACTIVE, CPNT, Prop.BTN_TXT_ACTIVE)};
          }

          svg {
            transform: rotate(180deg);
            fill:  ${() => componentColor(Prop.BTN_TXT_ACTIVE, CPNT, Prop.BTN_TXT_ACTIVE)};
            color:  ${() => componentColor(Prop.BTN_TXT_ACTIVE, CPNT, Prop.BTN_TXT_ACTIVE)};
          }
          }
        }
      }

    }
`;
