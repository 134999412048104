import PropTypes from 'prop-types';
import React from 'react';

import {
  Components, DorMeasurementFieldNames, MeasurementDisplayTypes, StaticProfiles,
  DorRoadStatus,
} from '@powdr/constants';
import { useRoadStatus } from '@powdr/hooks';
import {
  capitalizeAllWords, deslugify, getDorStatusColor,
} from '@powdr/utils';

import { CircularGraph } from './circular-graph';
import {
  CircularGraphInnerTextOverride,
  InnerTextSplitLarge,
  InnerTextSplitSmall,
} from './styles';
import { TextMeasurement } from './text-measurement';

export const RoadStatus = ({
  measurementDisplayType,
  parentColorProfile,
  measurementLabel,
  road,
  icon,
}) => {
  const measurementData = useRoadStatus(road?.uuid || null);
  const getColorOverride = (value) => getDorStatusColor(
    DorRoadStatus,
    value,
    Components.DOR_MEASUREMENTS,
    StaticProfiles.ALERTS,
  );
  const status = measurementData?.[DorMeasurementFieldNames.STATUS] || null;
  const handleMultilineStatus = () => {
    const statusSplit = capitalizeAllWords(deslugify(status)).split(' ');
    const statusMain = statusSplit[0];
    const statusSub = (statusSplit.length > 1) ? statusSplit.slice(1).join(' ') : null;
    return (
      <>
        <InnerTextSplitLarge className="inner-text-split-large" $showMargin={statusSub}>{statusMain}</InnerTextSplitLarge>
        {(statusSub) && <InnerTextSplitSmall className="inner-text-split-small">{statusSub}</InnerTextSplitSmall>}
      </>
    );
  };

  if (!measurementData || !status) return null;

  return (
    <>
      {(measurementDisplayType === MeasurementDisplayTypes.CIRCULAR_GRAPH) && (
        <CircularGraph
          colorProfile={parentColorProfile}
          label={measurementLabel}
          colorOverride={getColorOverride(status)}
        >
          <CircularGraphInnerTextOverride className="inner-content">
            {handleMultilineStatus()}
          </CircularGraphInnerTextOverride>
        </CircularGraph>
      )}
      {(measurementDisplayType === MeasurementDisplayTypes.TEXT
        || measurementDisplayType === MeasurementDisplayTypes.TEXT_LARGE) && (
        <TextMeasurement
          measurementDisplayType={measurementDisplayType}
          label={measurementLabel}
          icon={icon}
          small
          colorProfile={parentColorProfile}
        >
          {capitalizeAllWords(deslugify(status))}
        </TextMeasurement>
      )}
    </>
  );
};

RoadStatus.propTypes = {
  parentColorProfile: PropTypes.string,
  measurementLabel: PropTypes.string,
  measurementDisplayType: PropTypes.string,
  icon: PropTypes.string,
  road: PropTypes.shape({
    value: PropTypes.string,
    uuid: PropTypes.string,
  }),
};

RoadStatus.defaultProps = {
  parentColorProfile: null,
  measurementLabel: null,
  measurementDisplayType: null,
  road: null,
  icon: null,
};
