import styled from 'styled-components';

import { Components } from '@powdr/constants';

const CPNT = Components.DOR;

export const StyledDorWebCam = styled.div`

  &.dor-web-cam {
    margin-bottom: 16px;
  }

`;
