import { FetchStatus } from '@powdr/constants';

import {
  ECOMMERCE_RESET,
  ECOMMERCE_ERROR,

  ECOMMERCE_CALENDAR,
  ECOMMERCE_CALENDAR_PENDING,

  ECOMMERCE_PRODUCT,
  ECOMMERCE_PRODUCT_RESET,
  ECOMMERCE_PRODUCTS_BY_DATE,

  ECOMMERCE_CART_PENDING,
  ECOMMERCE_CART_SUCCESS,
  ECOMMERCE_CART_ERROR,
  ECOMMERCE_CART_RESET,
  ECOMMERCE_CART_GET_COOKIE,
  ECOMMERCE_CART_SET_COOKIE,

  ECOMMERCE_CREATE_GUEST,
  ECOMMERCE_ADD_TO_CART,
} from '../types';

const initialState = {
  status: FetchStatus.IDLE,
  error: null,
  customerGuid: null,

  calendarStatus: FetchStatus.IDLE,
  calendarVariant: [],

  products: {},
  allProductsByDate: {},

  cart: {},
  cartItemCount: 0,
  cartStatus: FetchStatus.IDLE,
  cartCookieResGet: {},
  cartCookieResSet: {},
};

const mergePricingAvailability = (payload) => {
  const { availability, pricing } = payload;
  return pricing?.days.map((day) => ({
    ...availability.find((item) => (item.date === day.date) && item),
    ...day,
  }));
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ECOMMERCE_PRODUCT:
      return {
        ...state,
        products: {
          ...state.products,
          ...action.payload,
        },
        status: FetchStatus.SUCCESS,
      };
    case ECOMMERCE_PRODUCTS_BY_DATE:
      return {
        ...state,
        allProductsByDate: {
          ...action.payload,
        },
        status: FetchStatus.SUCCESS,
      };
    case ECOMMERCE_CALENDAR_PENDING:
      return {
        ...state,
        calendarStatus: FetchStatus.LOADING,
      };
    case ECOMMERCE_CALENDAR:
      return {
        ...state,
        calendarVariant: mergePricingAvailability(action.payload),
        status: FetchStatus.SUCCESS,
      };
    case ECOMMERCE_CREATE_GUEST:
      return {
        ...state,
        customerGuid: action.payload,
        status: FetchStatus.IDLE,
      };
    case ECOMMERCE_ADD_TO_CART:
      return {
        ...state,
        cart: {
          ...state.cart,
          ...Object.assign({}, ...action.payload),
        },
        status: FetchStatus.IDLE,
      };
    case ECOMMERCE_RESET:
      return {
        ...initialState,
        cartItemCount: state.cartItemCount,
      };
    case ECOMMERCE_ERROR:
      return {
        ...state,
        status: FetchStatus.ERROR,
        error: action.payload,
      };

    // Begin Cart State
    case ECOMMERCE_CART_PENDING:
      return {
        ...state,
        cartItemCount: 0,
        cartStatus: FetchStatus.PENDING,
      };
    case ECOMMERCE_CART_SUCCESS:
      return {
        ...state,
        cartItemCount: action.payload,
        cartStatus: FetchStatus.SUCCESS,
      };
    case ECOMMERCE_CART_ERROR:
      return {
        ...state,
        cartItemCount: 0,
        cartStatus: FetchStatus.ERROR,
      };
    case ECOMMERCE_PRODUCT_RESET:
      return {
        ...state,
        allProductsByDate: {},
      };
    case ECOMMERCE_CART_RESET:
      return {
        ...state,
        cart: {},
        cartStatus: FetchStatus.IDLE,
      };
    case ECOMMERCE_CART_GET_COOKIE:
      return {
        ...state,
        cartCookieResGet: action.payload,
      };
    case ECOMMERCE_CART_SET_COOKIE:
      return {
        ...state,
        cartCookieResSet: action.payload,
      };
    default: return state;
  }
};
