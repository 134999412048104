/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getWeather } from '@powdr/stores/actions/weather-action';

// eslint-disable-next-line import/no-dynamic-require
const { gatsbyConfig } = require(`@powdr/${process.env.GATSBY_PROPERTY_NAME}`);

export const useCurrentTemperature = () => {
  const { dor } = gatsbyConfig || {};
  const dispatch = useDispatch();
  const useWeatherData = useSelector((state) => state.weather);
  const { isLoading: weatherIsLoading, error: weatherError, data: weatherData } = useWeatherData;
  const [temperatureData, setTemperatureData] = useState();
  useEffect(() => {
    dispatch(getWeather());
  }, [dispatch]);

  useEffect(() => {
    if (!weatherIsLoading) {
      setTemperatureData({
        icon: weatherData?.current?.weather_icon || null,
        value: weatherData?.current?.temperature || null,
        errors: {
          weather: weatherError,
        },
      });
    }
  }, [dor, weatherData, weatherError, weatherIsLoading]);

  return temperatureData;
};
