/* eslint-disable import/no-unresolved */
import styled, { css } from 'styled-components';

import { PrimaryCarousel } from '@powdr/components';
import { ThemeProps as Prop, Components, Breakpoints } from '@powdr/constants';
import { componentColor, themeUtil } from '@powdr/utils';

const CPNT = Components.CONTENT_BLOCK_HERO;

export const StyledContentBlockHero = styled.div`
  position: relative;
  z-index: 1;
  ${({ heroHeight, isImageDictatesHeight }) => {
    if (isImageDictatesHeight) return 'height: 100%;';
    if (heroHeight) {
      return `
        height: ${heroHeight}vh;
        max-height: ${heroHeight}vh;
        min-height: ${heroHeight}vh;
      `;
    }
    return 'height: 500px';
  }};

  // offset mobile styles overrides
  ${Breakpoints.mobile(css`
    padding-left: 0 !important;
    padding-right: 0 !important;
  `)}

  .hero-video-overlay {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;

    button.btn-close {
      position: absolute;
      top: 15px;
      right: 15px;
      z-index: 15;

      .icon-c {
        margin: 0 auto;

        svg {
          fill: #fff;
          color: #fff;
        }
      }
    }

    iframe {
      margin: 0 auto;
      height: 100%;
      width: 100%;
    }
  }
`;

export const ScrollButton = styled.button`
  position: absolute;
  left: calc(50% - 25px);
  bottom: 25px;
  z-index: 101;

  svg {
    fill: ${() => themeUtil(Prop.BTN_BG, CPNT)};
    color: ${() => themeUtil(Prop.BTN_BG, CPNT)};
  }
`;

export const HeroCarousel = styled(PrimaryCarousel)`
  width: 100%;
  height: 100%;

  .carousel-control-group {
    height: auto;
    bottom: ${({ $moveControlsUp }) => (($moveControlsUp) ? '100px' : '50px')};

    .hero-pagination {
      .pagination-item {
        :not(.active):not(:focus):not(:hover){
          .standard-bullet {
            border: 3px solid ${() => componentColor(Prop.PAGINATION, CPNT, Prop.BTN_BG)};
            background-color: unset;
          }
        }
      }

      .standard-bullet {
        height: 20px;
        width: 20px;
      }
    }

    ${Breakpoints.mobile(css`
      justify-content: flex-start;
      gap: 15px;
      padding-left: 35px;

      .hero-control-previous, .hero-control-next {
        .control-arrow-icon {
          height: 25px;
          width: 25px;
          min-width: 25px ;
        }
      }

      .hero-pagination {
        gap: 12px;

        .standard-bullet {
          height: 15px;
          width: 15px;
        }
      }
    `)}
  }


`;
