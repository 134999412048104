/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import {
  StyledDorPercentWheel,
  ProgressSVG, ProgressTextContainer,
  TextPrimary, TextSecondary,
  TextAlignmentWrapper,
} from './styles';

export const DorPercentWheel = ({
  parentColorProfile,
  totalVal,
  openVal,
  strokeWidth,
  isParentNavFeed,
  isForceFullGraph,
  colorOverride,
  children,
}) => {
  const [strokeDashoffset, setStrokeDashoffset] = useState(0);
  const [strokeDasharray, setStrokeDasharray] = useState(0);
  const radiusAbsolute = 64;
  const circleRadius = radiusAbsolute - (strokeWidth / 2);

  useEffect(() => {
    // if open and total are 0, should be 0 percent
    const percent = (!(openVal === 0 && totalVal === 0))
      ? (isForceFullGraph) ? 100 : Math.round((openVal / totalVal) * 100)
      : 0;
    const progress = percent / 100;
    const circ = 2 * Math.PI * (circleRadius);
    const dashoffset = circ * (1 - progress);

    setStrokeDasharray(circ);
    setStrokeDashoffset(dashoffset);
  }, [strokeDasharray, strokeDashoffset, openVal, totalVal, circleRadius, isForceFullGraph]);

  return (
    <StyledDorPercentWheel colorProfile={parentColorProfile} isParentNavFeed={isParentNavFeed}>
      <ProgressTextContainer>
        {(!children) ? (
          <TextAlignmentWrapper>
            <TextPrimary className="percent-primary-text">{openVal}</TextPrimary>
            <TextSecondary className="percent-secondary-text">{`/${totalVal}`}</TextSecondary>
          </TextAlignmentWrapper>
        ) : children}
      </ProgressTextContainer>
      <ProgressSVG viewBox={`0 0 ${radiusAbsolute * 2 + 1} ${radiusAbsolute * 2 + 1}`} $colorOverride={colorOverride}>
        <circle
          className="meter"
          cx={radiusAbsolute}
          cy={radiusAbsolute}
          r={circleRadius}
          strokeWidth={strokeWidth}
        />
        <circle
          className="value"
          cx={radiusAbsolute}
          cy={radiusAbsolute}
          r={circleRadius}
          strokeWidth={strokeWidth}
          strokeDasharray={strokeDasharray}
          strokeDashoffset={strokeDashoffset}
        />
      </ProgressSVG>
    </StyledDorPercentWheel>
  );
};

DorPercentWheel.propTypes = {
  totalVal: PropTypes.number,
  openVal: PropTypes.number,
  strokeWidth: PropTypes.number,
  parentColorProfile: PropTypes.number,
  isParentNavFeed: PropTypes.bool,
  colorOverride: PropTypes.string,
  isForceFullGraph: PropTypes.bool,
  children: PropTypes.node,
};

DorPercentWheel.defaultProps = {
  totalVal: null,
  openVal: null,
  strokeWidth: 24,
  parentColorProfile: null,
  isParentNavFeed: false,
  colorOverride: null,
  isForceFullGraph: false,
  children: null,
};
