import React from 'react';

import { DynamicComponents } from '@powdr/constants';

// TODO: move parentColorProfile, parentComponentSeason into parentProps object
export const componentFactory = (
  components,
  parentColorProfile,
  parentComponentSeason,
  parentProps,
) => components.filter((x) => x).map((component) => {
  const { id, typeName } = component;

  if (!DynamicComponents[typeName]) return null;

  return React.createElement(
    DynamicComponents[typeName],
    {
      ...component,
      ...parentProps,
      parentColorProfile,
      parentComponentSeason,
      key: id,
    },
  );
});
