import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getSensors } from '@powdr/stores';

export const useSensor = (sensor) => {
  if (!sensor) return null;

  const dispatch = useDispatch();
  const useSensorData = useSelector((state) => state.sensors);
  const { sensors } = useSensorData;
  const [sensorData, setSensorData] = useState();

  useEffect(() => {
    dispatch(getSensors());
  }, [dispatch, sensor]);

  useEffect(() => {
    if (sensors && sensors?.[sensor]) {
      setSensorData(sensors[sensor]);
    }
  }, [sensors, sensor]);

  return sensorData;
};
