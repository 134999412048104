import React from 'react';

import { Icon } from '@powdr/components';
import {
  CondSectorLengthLabels,
} from '@powdr/constants';
import { roundIt, setTrailCondBySecLabel, filterLiftTrailItemsBySeason } from '@powdr/utils';

import {
  DataShape,
  SectorShape,
  SettingsShape,
  ThemeShape,
} from './dor-prop-types';
import {
  StyledSectorDetails,
  StyledSectorDifficulty,
} from './styles';

export const DorSectorDetailsRender = ({
  sector,
  data,
  settings,
  theme,
}) => {
  const { id } = sector || {};
  const liftStatus = filterLiftTrailItemsBySeason(data?.lifts, settings?.season)
    ?.filter((f) => f.sector.uuid === id)
    ?.reduce(
      (acc, item) => {
        if (item.status.id === 'opening') {
          acc.open += 1;
        }
        acc.total += 1;
        return acc;
      },
      { open: 0, total: 0 },
    );

  const trailReport = filterLiftTrailItemsBySeason(data?.trails, settings?.season)
    ?.filter((f) => f.sector.id === id)
    ?.filter((f) => !settings
      ?.filterOutTrailTypes
      ?.includes(f.type))
    ?.reduce((acc, trail) => {
      const isOpen = trail.status.id === 'opening';
      const area = trail?.properties?.area || 0;
      const length = trail?.properties?.length || 0;
      const total = acc.total + 1;

      return {
        open: acc.open + (isOpen ? 1 : 0),
        total,
        area: {
          label: setTrailCondBySecLabel(
            CondSectorLengthLabels.SKIABLE_ACRES,
            CondSectorLengthLabels.RIDABLE_ACRES,
            CondSectorLengthLabels.ACRES,
          ),
          open: acc.area.open + (isOpen ? area : 0),
          total: acc.area.total + area,
        },
        length: {
          label: setTrailCondBySecLabel(
            CondSectorLengthLabels.SKIABLE_MILES,
            CondSectorLengthLabels.RIDABLE_MILES,
            CondSectorLengthLabels.MILES,
          ),
          open: acc.length.open + (isOpen ? length : 0),
          total: acc.length.total + length,
        },
      };
    }, {
      open: 0,
      total: 0,
      area: { open: 0, total: 0 },
      length: { open: 0, total: 0 },
    });

  const difficulty = data.trails
    ?.filter((f) => f.sector.id === id)
    ?.map((trail) => trail.difficulty)
    ?.filter((v, i, a) => a
      .findIndex((v2) => (v2.icon === v.icon)) === i)
    ?.filter((k) => Object.keys(k).length !== 0 || false)
    ?.sort((a, b) => a.order - b.order);

  // if (obj.name === CondSectorLabels.NORDIC
  //   && settings.property === Property.MTBACHELOR) {
  //   obj.area = null;
  //   obj.length.label = setTrailCondBySecLabel(
  //     CondSectorLengthLabels.SKIABLE_KILOMETERS,
  //     CondSectorLengthLabels.RIDABLE_MILES,
  //     CondSectorLengthLabels.MILES,
  //   );

  //   obj.length.total = roundIt(milesToKilometers(obj.length.total), 2);
  //   obj.length.open = roundIt(milesToKilometers(obj.length.open), 2);
  // }

  return (
    <>
      {difficulty && (
        <StyledSectorDifficulty
          colorProfile={theme.parentColorProfile}
          className="sector-difficulty"
        >
          {difficulty.map((diff) => (diff.icon
            ? (
              <li key={diff.id} className={diff.id}>
                <Icon
                  className={`icon ${diff.icon}`}
                  name={diff.icon}
                  width={20}
                  height={20}
                />
              </li>
            ) : null))}
        </StyledSectorDifficulty>
      )}
      <StyledSectorDetails
        colorProfile={theme.parentColorProfile}
        className="sector-details"
      >
        {settings.sectorConditions.LIFT && (
          <li className="item">
            <strong>Lifts&nbsp;</strong>
            {liftStatus?.open}
            {' of '}
            {liftStatus?.total}
          </li>
        )}

        {settings.sectorConditions.TRAIL && (
          <li className="item">
            <strong>Trail&nbsp;</strong>
            {trailReport?.open}
            {' of '}
            {trailReport?.total}
          </li>
        )}

        {settings.sectorConditions.ACRES && trailReport?.area && (
          <li className="item">
            <strong>{trailReport?.area?.label}</strong>
            {roundIt(trailReport?.area?.open, 2)}
            {' of '}
            {roundIt(trailReport?.area?.total, 2)}
          </li>
        )}

        {settings.sectorConditions.MILES && trailReport?.length && (
          <li className="item">
            <strong>{trailReport.length.label}</strong>
            {roundIt(trailReport.length.open, 2)}
            {' of '}
            {roundIt(trailReport.length.total, 2)}
          </li>
        )}
      </StyledSectorDetails>
    </>
  );
};

DorSectorDetailsRender.propTypes = {
  sector: SectorShape.isRequired,
  data: DataShape.isRequired,
  settings: SettingsShape.isRequired,
  theme: ThemeShape.isRequired,
};

export default DorSectorDetailsRender;
