import React, { useContext, useState } from 'react';

import { AbsolutePositions, Components, NavigationPromoStyleOptions } from '@powdr/constants';
import { AppContext } from '@powdr/context';
import { getImageAltText } from '@powdr/utils';
import { useStaticData } from '@powdr/web/src/hooks';

import {
  StyledNavigationDrawer,
  NavDrawerBody,
  PromoListItems,
  PromoListItem,
  PromoDisc,
  DiscBackgroundImage,
  DiscContent,
  Promotions,
  DrawerBodyInner,
  Navigation,
} from './styles';

export const NavigationDrawer = () => {
  const { navigationDrawer } = useStaticData();
  const {
    setDrawerOpen,
    setNavigationDrawerOpen,
    isNavigationDrawerOpen,
    shiftPromos,
  } = useContext(AppContext);
  const [isForceHeaderShadow, setForceHeaderShadow] = useState(false);
  const promotionArr = navigationDrawer?.promotions?.items || [];
  const promoStyle = navigationDrawer?.promoStyle || NavigationPromoStyleOptions.CIRCULAR;

  return (
    <StyledNavigationDrawer
      componentTheme={Components.NAVIGATION_DRAWER}
      isOpen={isNavigationDrawerOpen}
      slideInFrom={AbsolutePositions.RIGHT}
      closeDrawerFunc={() => setDrawerOpen(false, setNavigationDrawerOpen)}
      isShowLogoHeader
      isForceShadow={isForceHeaderShadow}
    >
      {/* Main Body */}
      <NavDrawerBody>
        <DrawerBodyInner>
          <Navigation
            isOpen={isNavigationDrawerOpen}
            handleHeaderStyles={setForceHeaderShadow}
          />
          <Promotions>
            <PromoListItems $shift={shiftPromos}>
              {promotionArr.map((promo) => {
                const { gatsbyImage: image } = promo
                  ?.relationships
                  ?.promotionImage
                  ?.relationships
                  ?.image || {};

                return (
                  <PromoListItem key={`promo-block-${promo.title}`}>
                    <PromoDisc
                      className="anim-float"
                      href={promo.url}
                      target={promo.target}
                    >
                      <DiscBackgroundImage
                        image={image}
                        alt={getImageAltText(
                          promo.relationships?.promotionImage?.relationships?.image,
                        )}
                        $promoStyle={promoStyle}
                      />
                      <DiscContent>
                        {promo.title}
                      </DiscContent>
                    </PromoDisc>
                  </PromoListItem>
                );
              })}
            </PromoListItems>
          </Promotions>
        </DrawerBodyInner>
      </NavDrawerBody>

    </StyledNavigationDrawer>
  );
};

NavigationDrawer.propTypes = {};

NavigationDrawer.defaultProps = {};
