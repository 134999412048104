import PropTypes from 'prop-types';
import React from 'react';

import { ContentBlock, Icon } from '@powdr/components';
import { BlockTypes, ColorProfiles } from '@powdr/constants';

import {
  StyledHotspotModal, CloseButton, BlockContainer,
} from './styles';

export const HotspotModal = ({
  data,
  colorProfile,
  handleClose,
}) => {
  const detailBlockData = {
    ...data,
    body: data?.body.value,
    reversibleTable: (data?.relationships?.reversibleTable?.tables?.length > 0) ? {
      ...data?.relationships.reversibleTable,
      tables: {
        value: [
          data?.relationships?.reversibleTable.tables[0].value.row0,
          data?.relationships?.reversibleTable.tables[0].value.row1,
        ],
      },
    } : null,
  };

  return (
    <StyledHotspotModal>
      <CloseButton
        title="Close Item Modal"
        aria-label="Close Item Modal"
        colorProfile={colorProfile || ColorProfiles.BASE}
        onMouseDown={(e) => { e.preventDefault(); handleClose(false); }}
        onKeyDown={(e) => { e.preventDefault(); e.stopPropagation(); if (e.key === 'Enter') handleClose(true); }}
      >
        <Icon name="ui-close" width="15" />
      </CloseButton>
      <BlockContainer>
        <ContentBlock
          index={0}
          contentBlock={detailBlockData}
          blockType={BlockTypes.DETAIL_BLOCK}
          columnOption={0}
          colorProfile={colorProfile || ColorProfiles.BASE}
        />
      </BlockContainer>
    </StyledHotspotModal>
  );
};

HotspotModal.propTypes = {
  data: PropTypes.shape().isRequired,
  handleClose: PropTypes.func.isRequired,
  colorProfile: PropTypes.string,
};

HotspotModal.defaultProps = {
  colorProfile: ColorProfiles.BASE,
};
