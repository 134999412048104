import styled, { css } from 'styled-components';

import { PrimaryCarousel } from '@powdr/components';
import { Breakpoints } from '@powdr/constants';

export const StyledCompareCards = styled.div`
  //${({ theme }) => theme.fonts.PRI_BDY_MED};
  ${({ isMobile, $isDesktopCarousel }) => {
    if (isMobile || $isDesktopCarousel) {
      return 'display: block;';
    }

    return `
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    `;
  }}
`;

export const MobileCarousel = styled(PrimaryCarousel)`
  display: none;

  ${Breakpoints.mobile(css`
    display: flex;
  `)}
`;

export const DesktopWrapper = styled.div`
  display: none;
  justify-content: center;
  flex-wrap: wrap;

  ${Breakpoints.desktopOnly(css`
    display: flex;
  `)}
`;

export const DesktopCarousel = styled(PrimaryCarousel)``;
