import styled, { css } from 'styled-components';

import { Link } from '@powdr/components';
import { Breakpoints } from '@powdr/constants';

export const StyledOverlayImage = styled.div`
  width: ${({ imageWidth }) => `${imageWidth}%`};
  margin: auto;
  max-width: 100%;
  top: 50%;
  position: relative;
  transform: translateY(-50%);

  ${Breakpoints.mobile(css`
    width: ${({ imageWidthMobile }) => `${imageWidthMobile}%`};
  `)}
`;

export const OverlayLink = styled(Link)`
  height: 100%;
  width: 100%;
  position: absolute;
`;
