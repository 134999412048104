import styled, { css } from 'styled-components';

const silverwoodsPositions = css`
  .aberdeenskiway {
    top: 21px;
    left: 8px;
    position: absolute;
  }

  .comettrail {
    top: 73px;
    left: 8px;
    position: absolute;
  }

  .easystreet {
    top: 31px;
    left: 8px;
    position: absolute;
  }

  .farout {
    top: 63px;
    left: 8px;
    position: absolute;
  }

  .silverqueen {
    top: 42px;
    left: 8px;
    position: absolute;
  }

  .creekside {
    top: 52px;
    left: 8px;
    position: absolute;
  }

  .overthehill {
    top: 84px;
    left: 8px;
    position: absolute;
  }

  .fis {
    top: 95px;
    left: 8px;
    position: absolute;
  }

  .xmasbowl {
    top: 21px;
    left: 40px;
    position: absolute;
  }

  .waltsrun {
    top: 31px;
    left: 40px;
    position: absolute;
  }

  .constellation {
    top: 42px;
    left: 40px;
    position: absolute;
  }

  .exhibition {
    top: 52px;
    left: 40px;
    position: absolute;
  }

  .whiskeyjack {
    top: 73px;
    left: 40px;
    position: absolute;
  }

  .middledipper {
    top: 84px;
    left: 40px;
    position: absolute;
  }

  .milkyway {
    top: 95px;
    left: 40px;
    position: absolute;
  }

  .skunkhollow {
    top: 21px;
    left: 72px;
    position: absolute;
  }

  .chaletalley {
    top: 31px;
    left: 72px;
    position: absolute;
  }

  .sundance {
    top: 42px;
    left: 72px;
    position: absolute;
  }

  .bigdipperupper {
    top: 63px;
    left: 72px;
    position: absolute;
  }

  .littledipper {
    top: 52px;
    left: 72px;
    position: absolute;
  }

  .blastoff {
    top: 74px;
    left: 72px;
    position: absolute;
  }

  .hotdog {
    top: 84px;
    left: 72px;
    position: absolute;
  }

  .face {
    top: 95px;
    left: 72px;
    position: absolute;
  }

  .auntgladys {
    top: 31px;
    left: 105px;
    position: absolute;
  }

  .bergerstrasse {
    top: 41px;
    left: 105px;
    position: absolute;
  }

  .lastchance {
    top: 52px;
    left: 105px;
    position: absolute;
  }

  .twentyfivenorth {
    top: 63px;
    left: 105px;
    position: absolute;
  }

  .justdandy {
    top: 73px;
    left: 105px;
    position: absolute;
  }

  .sunnyridge {
    top: 84px;
    left: 105px;
    position: absolute;
  }

  .buckedoff {
    top: 94px;
    left: 105px;
    position: absolute;
  }

  .sunnyhigh {
    top: 105px;
    left: 105px;
    position: absolute;
  }

  .robsonstreet {
    top: 116px;
    left: 105px;
    position: absolute;
  }

  .fearngully {
    top: 31px;
    left: 137px;
    position: absolute;
  }

  .eldorado {
    top: 41px;
    left: 137px;
    position: absolute;
  }

  .weewillie {
    top: 52px;
    left: 137px;
    position: absolute;
  }

  .gypsyqueen {
    top: 63px;
    left: 137px;
    position: absolute;
  }

  .campbellsloop {
    top: 73px;
    left: 137px;
    position: absolute;
  }

  .caliperridge {
    top: 84px;
    left: 137px;
    position: absolute;
  }

  .larch {
    top: 94px;
    left: 137px;
    position: absolute;
  }

  .juddsrun {
    top: 105px;
    left: 137px;
    position: absolute;
  }

  .blackpine {
    top: 116px;
    left: 137px;
    position: absolute;
  }

  .holysmokes {
    top: 20px;
    left: 168px;
    position: absolute;
  }

  .minerva {
    top: 31px;
    left: 168px;
    position: absolute;
  }

  .nirvana {
    top: 41px;
    left: 168px;
    position: absolute;
  }

  .zypper {
    top: 52px;
    left: 168px;
    position: absolute;
  }

  .bondiablo {
    top: 62px;
    left: 168px;
    position: absolute;
  }

  .heresjoe {
    top: 73px;
    left: 168px;
    position: absolute;
  }

  .normania {
    top: 83px;
    left: 168px;
    position: absolute;
  }

  .blackbear {
    top: 94px;
    left: 168px;
    position: absolute;
  }

  .whiteelephant {
    top: 105px;
    left: 168px;
    position: absolute;
  }

  .robilliardsrush {
    top: 116px;
    left: 168px;
    position: absolute;
  }

  .rollercoaster {
    top: 63px;
    left: 40px;
    position: absolute;
  }

  .starstruck {
    top: 118px;
    left: 72px;
    position: absolute;
  }

  .mineshaft {
    top: 128px;
    left: 72px;
    position: absolute;
  }

  .chaos {
    top: 139px;
    left: 72px;
    position: absolute;
  }

  .silverfox {
    top: 118px;
    left: 8px;
    position: absolute;
  }

  .shootingstar {
    top: 128px;
    left: 8px;
    position: absolute;
  }

  .simplepleasure {
    top: 139px;
    left: 8px;
    position: absolute;
  }

  .cloud9 {
    top: 150px;
    left: 8px;
    position: absolute;
  }

  .runningonempty {
    top: 118px;
    left: 40px;
    position: absolute;
  }

  .bxexpress {
    top: 128px;
    left: 40px;
    position: absolute;
  }

  .northstar {
    top: 139px;
    left: 40px;
    position: absolute;
  }

  .crisscross {
    top: 139px;
    left: 105px;
    position: absolute;
  }

  .solitude {
    top: 150px;
    left: 105px;
    position: absolute;
  }

  .ridgerun {
    top: 139px;
    left: 137px;
    position: absolute;
  }

  .lonestar {
    top: 150px;
    left: 137px;
    position: absolute;
  }

  .splitdecision {
    top: 139px;
    left: 168px;
    position: absolute;
  }

  .drsexpress {
    top: 149px;
    left: 168px;
    position: absolute;
  }

  .homeruntee {
    top: 20px;
    left: 105px;
    position: absolute;
  }

  .powdergulchexpress {
    top: 20px;
    left: 137px;
    position: absolute;
  }
`;

const gondolaPositions = css`
  .aberdeenskiway {
    top:16px;
    left:104px;
    position: absolute;
  }
  .easystreet {
    top:33px;
    left:104px;
    position: absolute;
  }
  .creekside {
    top:64px;
    left:104px;
    position: absolute;
  }
  .silverqueen {
    top:49px;
    left:104px;
    position: absolute;
  }
  .farout {
    top:80px;
    left:104px;
    position: absolute;
  }
  .comettrail {
    top:96px;
    left:104px;
    position: absolute;
  }
  .overthehill {
    top:112px;
    left:104px;
    position: absolute;
  }
  .fis {
    top:128px;
    left:104px;
    position: absolute;
  }
  .xmasbowl {
    top:16px;
    left:137px;
    position: absolute;
  }
  .waltsrun {
    top:33px;
    left:137px;
    position: absolute;
  }
  .constellation {
    top:48px;
    left:137px;
    position: absolute;
  }
  .exhibition {
    top:64px;
    left:137px;
    position: absolute;
  }
  .whiskeyjack {
    top:96px;
    left:137px;
    position: absolute;
  }
  .middledipper {
    top:112px;
    left:137px;
    position: absolute;
  }
  .milkyway {
    top:128px;
    left:137px;
    position: absolute;
  }
  .skunkhollow {
    top:16px;
    left:169px;
    position: absolute;
  }
  .chaletalley {
    top:33px;
    left:169px;
    position: absolute;
  }
  .sundance {
    top:48px;
    left:169px;
    position: absolute;
  }
  .littledipper {
    top:64px;
    left:169px;
    position: absolute;
  }
  .bigdipperupper {
    top:80px;
    left:169px;
    position: absolute;
  }
  .blastoff {
    top:96px;
    left:169px;
    position: absolute;
  }
  .hotdog {
    top:112px;
    left:169px;
    position: absolute;
  }
  .face {
    top:128px;
    left:169px;
    position: absolute;
  }
  /*Powder Gulch*/
  .auntgladys {
    top:33px;
    left:8px;
    position: absolute;
  }
  .bergerstrasse {
    top:49px;
    left:8px;
    position: absolute;
  }
  .lastchance {
    top:65px;
    left:8px;
    position: absolute;
  }
  .twentyfivenorth {
    top:81px;
    left:8px;
    position: absolute;
  }
  .justdandy {
    top:96px;
    left:8px;
    position: absolute;
  }
  .sunnyridge {
    top:113px;
    left:8px;
    position: absolute;
  }
  .buckedoff {
    top:129px;
    left:8px;
    position: absolute;
  }
  .sunnyhigh {
    top:144px;
    left:8px;
    position: absolute;
  }
  .robsonstreet {
    top:160px;
    left:8px;
    position: absolute;
  }
  .fearngully {
    top:33px;
    left:41px;
    position: absolute;
  }
  .eldorado {
    top:49px;
    left:41px;
    position: absolute;
  }
  .weewillie {
    top:65px;
    left:41px;
    position: absolute;
  }
  .gypsyqueen {
    top:81px;
    left:41px;
    position: absolute;
  }
  .campbellsloop {
    top:97px;
    left:41px;
    position: absolute;
  }
  .caliperridge {
    top:113px;
    left:41px;
    position: absolute;
  }
  .larch {
    top:129px;
    left:41px;
    position: absolute;
  }
  .juddsrun {
    top:145px;
    left:41px;
    position: absolute;
  }
  .blackpine {
    top:161px;
    left:41px;
    position: absolute;
  }
  .holysmokes {
    top:18px;
    left:73px;
    position: absolute;
  }
  .minerva {
    top:34px;
    left:73px;
    position: absolute;
  }
  .nirvana {
    top:50px;
    left:73px;
    position: absolute;
  }
  .zypper {
    top:66px;
    left:73px;
    position: absolute;
  }
  .bondiablo {
    top:82px;
    left:73px;
    position: absolute;
  }
  .heresjoe {
    top:98px;
    left:73px;
    position: absolute;
  }
  .normania {
    top:114px;
    left:73px;
    position: absolute;
  }
  .blackbear {
    top:130px;
    left:73px;
    position: absolute;
  }
  .whiteelephant {
    top:145px;
    left:73px;
    position: absolute;
  }
  .robilliardsrush {
    top:161px;
    left:73px;
    position: absolute;
  }
  /*Silver Woods*/
  .rollercoaster {
    top:80px;
    left:137px;
    position: absolute;
  }
  .starstruck {
    top:163px;
    left:169px;
    position: absolute;
  }
  .mineshaft {
    top:178px;
    left:169px;
    position: absolute;
  }
  .chaos {
    top:194px;
    left:169px;
    position: absolute;
  }
  .silverfox {
    top:163px;
    left:104px;
    position: absolute;
  }
  .shootingstar {
    top:179px;
    left:104px;
    position: absolute;
  }
  .simplepleasure {
    top:195px;
    left:104px;
    position: absolute;
  }
  .cloud9 {
    top:211px;
    left:104px;
    position: absolute;
  }
  .runningonempty {
    top:163px;
    left:137px;
    position: absolute;
  }
  .bxexpress {
    top:179px;
    left:137px;
    position: absolute;
  }
  .northstar {
    top:194px;
    left:137px;
    position: absolute;
  }
  /*Alpine Meadows*/
  .crisscross {
    top:195px;
    left:8px;
    position: absolute;
  }
  .solitude {
    top:211px;
    left:8px;
    position: absolute;
  }
  .ridgerun {
    top:196px;
    left:41px;
    position: absolute;
  }
  .lonestar {
    top:212px;
    left:41px;
    position: absolute;
  }
  .splitdecision {
    top:196px;
    left:73px;
    position: absolute;
  }
  .drsexpress {
    top:212px;
    left:73px;
    position: absolute;
  }
  /*Home Run Tee*/
  .homeruntee {
    top:18px;
    left:8px;
    position: absolute;
  }
  /*Powder Gulch Express*/
  .powdergulchexpress {
    top:18px;
    left:41px;
    position: absolute;
  }
`;

const putnamPositions = css`
  .auntgladys {
    top:30px;
    left:8px;
    position: absolute;
  }
  .bergerstrasse {
    top:41px;
    left:8px;
    position: absolute;
  }
  .lastchance {
    top:51px;
    left:8px;
    position: absolute;
  }
  .twentyfivenorth {
    top:62px;
    left:8px;
    position: absolute;
  }
  .justdandy {
    top:72px;
    left:8px;
    position: absolute;
  }
  .sunnyridge {
    top:83px;
    left:8px;
    position: absolute;
  }
  .buckedoff {
    top:94px;
    left:8px;
    position: absolute;
  }
  .sunnyhigh {
    top:104px;
    left:8px;
    position: absolute;
  }
  .robsonstreet {
    top:115px;
    left:8px;
    position: absolute;
  }
  .fearngully {
    top:30px;
    left:40px;
    position: absolute;
  }
  .eldorado {
    top:41px;
    left:40px;
    position: absolute;
  }
  .weewillie {
    top:51px;
    left:40px;
    position: absolute;
  }
  .gypsyqueen {
    top:62px;
    left:40px;
    position: absolute;
  }
  .campbellsloop {
    top:72px;
    left:40px;
    position: absolute;
  }
  .caliperridge {
    top:83px;
    left:40px;
    position: absolute;
  }
  .larch {
    top:94px;
    left:40px;
    position: absolute;
  }
  .juddsrun {
    top:104px;
    left:40px;
    position: absolute;
  }
  .blackpine {
    top:115px;
    left:40px;
    position: absolute;
  }
  .holysmokes {
    top:19px;
    left:72px;
    position: absolute;
  }
  .minerva {
    top:30px;
    left:72px;
    position: absolute;
  }
  .nirvana {
    top:41px;
    left:72px;
    position: absolute;
  }
  .zypper {
    top:51px;
    left:72px;
    position: absolute;
  }
  .bondiablo {
    top:62px;
    left:72px;
    position: absolute;
  }
  .heresjoe {
    top:72px;
    left:72px;
    position: absolute;
  }
  .normania {
    top:83px;
    left:72px;
    position: absolute;
  }
  .blackbear {
    top:94px;
    left:72px;
    position: absolute;
  }
  .whiteelephant {
    top:104px;
    left:72px;
    position: absolute;
  }
  .robilliardsrush {
    top:115px;
    left:72px;
    position: absolute;
  }
  /*Home Run Tee*/
  .homeruntee {
    top:19px;
    left:8px;
    position: absolute;
  }
  /*Powder Gulch Express*/
  .powdergulchexpress {
    top:19px;
    left:40px;
    position: absolute;
  }
`;

const villagePositions = css`
  .aberdeenskiway {
    top:20px;
    left:8px;
    position: absolute;
  }
  .comettrail {
    top:73px;
    left:8px;
    position: absolute;
  }
  .easystreet {
    top:31px;
    left:8px;
    position: absolute;
  }
  .farout {
    top:62px;
    left:8px;
    position: absolute;
  }
  .silverqueen {
    top:41px;
    left:8px;
    position: absolute;
  }
  .creekside {
    top:51px;
    left:8px;
    position: absolute;
  }
  .overthehill {
    top:83px;
    left:8px;
    position: absolute;
  }
  .fis {
    top:94px;
    left:8px;
    position: absolute;
  }
  .xmasbowl {
    top:20px;
    left:40px;
    position: absolute;
  }
  .waltsrun {
    top:31px;
    left:40px;
    position: absolute;
  }
  .constellation {
    top:41px;
    left:40px;
    position: absolute;
  }
  .exhibition {
    top:51px;
    left:40px;
    position: absolute;
  }
  .whiskeyjack {
    top:73px;
    left:40px;
    position: absolute;
  }
  .middledipper {
    top:83px;
    left:40px;
    position: absolute;
  }
  .milkyway {
    top:94px;
    left:40px;
    position: absolute;
  }
  .skunkhollow {
    top:20px;
    left:73px;
    position: absolute;
  }
  .chaletalley {
    top:31px;
    left:73px;
    position: absolute;
  }
  .sundance {
    top:42px;
    left:73px;
    position: absolute;
  }
  .bigdipperupper {
    top:63px;
    left:73px;
    position: absolute;
  }
  .littledipper {
    top:51px;
    left:73px;
    position: absolute;
  }
  .blastoff {
    top:72px;
    left:73px;
    position: absolute;
  }
  .hotdog {
    top:83px;
    left:73px;
    position: absolute;
  }
  .face {
    top:93px;
    left:73px;
    position: absolute;
  }
  /*Powder Gulch*/
  .auntgladys {
    top:31px;
    left:104px;
    position: absolute;
  }
  .bergerstrasse {
    top:41px;
    left:104px;
    position: absolute;
  }
  .lastchance {
    top:51px;
    left:104px;
    position: absolute;
  }
  .twentyfivenorth {
    top:62px;
    left:104px;
    position: absolute;
  }
  .justdandy {
    top:72px;
    left:104px;
    position: absolute;
  }
  .sunnyridge {
    top:83px;
    left:104px;
    position: absolute;
  }
  .buckedoff {
    top:94px;
    left:104px;
    position: absolute;
  }
  .sunnyhigh {
    top:104px;
    left:104px;
    position: absolute;
  }
  .robsonstreet {
    top:115px;
    left:104px;
    position: absolute;
  }
  .fearngully {
    top:30px;
    left:136px;
    position: absolute;
  }
  .eldorado {
    top:41px;
    left:136px;
    position: absolute;
  }
  .weewillie {
    top:51px;
    left:136px;
    position: absolute;
  }
  .gypsyqueen {
    top:62px;
    left:136px;
    position: absolute;
  }
  .campbellsloop {
    top:72px;
    left:136px;
    position: absolute;
  }
  .caliperridge {
    top:83px;
    left:136px;
    position: absolute;
  }
  .larch {
    top:94px;
    left:136px;
    position: absolute;
  }
  .juddsrun {
    top:104px;
    left:136px;
    position: absolute;
  }
  .blackpine {
    top:115px;
    left:136px;
    position: absolute;
  }
  .holysmokes {
    top:19px;
    left:168px;
    position: absolute;
  }
  .minerva {
    top:30px;
    left:168px;
    position: absolute;
  }
  .nirvana {
    top:41px;
    left:168px;
    position: absolute;
  }
  .zypper {
    top:51px;
    left:168px;
    position: absolute;
  }
  .bondiablo {
    top:62px;
    left:168px;
    position: absolute;
  }
  .heresjoe {
    top:72px;
    left:168px;
    position: absolute;
  }
  .normania {
    top:83px;
    left:168px;
    position: absolute;
  }
  .blackbear {
    top:94px;
    left:168px;
    position: absolute;
  }
  .whiteelephant {
    top:104px;
    left:168px;
    position: absolute;
  }
  .robilliardsrush {
    top:115px;
    left:168px;
    position: absolute;
  }
  /*Silver Woods*/
  .rollercoaster {
    top:62px;
    left:40px;
    position: absolute;
  }
  .starstruck {
    top:117px;
    left:73px;
    position: absolute;
  }
  .mineshaft {
    top:127px;
    left:73px;
    position: absolute;
  }
  .chaos {
    top:138px;
    left:73px;
    position: absolute;
  }
  .silverfox {
    top:117px;
    left:8px;
    position: absolute;
  }
  .shootingstar {
    top:127px;
    left:8px;
    position: absolute;
  }
  .simplepleasure {
    top:138px;
    left:8px;
    position: absolute;
  }
  .cloud9 {
    top:148px;
    left:8px;
    position: absolute;
  }
  .runningonempty {
    top:117px;
    left:40px;
    position: absolute;
  }
  .bxexpress {
    top:127px;
    left:40px;
    position: absolute;
  }
  .northstar {
    top:138px;
    left:40px;
    position: absolute;
  }
  /*Alpine Meadows*/
  .crisscross {
    top:139px;
    left:104px;
    position: absolute;
  }
  .solitude {
    top:149px;
    left:104px;
    position: absolute;
  }
  .ridgerun {
    top:138px;
    left:136px;
    position: absolute;
  }
  .lonestar {
    top:149px;
    left:136px;
    position: absolute;
  }
  .splitdecision {
    top:138px;
    left:168px;
    position: absolute;
  }
  .drsexpress {
    top:149px;
    left:168px;
    position: absolute;
  }
  /*Home Run Tee*/
  .homeruntee {
    top:19px;
    left:104px;
    position: absolute;
  }
  /*Powder Gulch Express*/
  .powdergulchexpress {
    top:19px;
    left:136px;
    position: absolute;
  }
`;

const village2Positions = css`
  .aberdeenskiway {
    top:20px;
    left:8px;
    position: absolute;
  }
  .comettrail {
    top:73px;
    left:8px;
    position: absolute;
  }
  .easystreet {
    top:31px;
    left:8px;
    position: absolute;
  }
  .farout {
    top:62px;
    left:8px;
    position: absolute;
  }
  .silverqueen {
    top:41px;
    left:8px;
    position: absolute;
  }
  .creekside {
    top:51px;
    left:8px;
    position: absolute;
  }
  .overthehill {
    top:83px;
    left:8px;
    position: absolute;
  }
  .fis {
    top:94px;
    left:8px;
    position: absolute;
  }
  .xmasbowl {
    top:20px;
    left:40px;
    position: absolute;
  }
  .waltsrun {
    top:31px;
    left:40px;
    position: absolute;
  }
  .constellation {
    top:41px;
    left:40px;
    position: absolute;
  }
  .exhibition {
    top:51px;
    left:40px;
    position: absolute;
  }
  .whiskeyjack {
    top:73px;
    left:40px;
    position: absolute;
  }
  .middledipper {
    top:83px;
    left:40px;
    position: absolute;
  }
  .milkyway {
    top:94px;
    left:40px;
    position: absolute;
  }
  .skunkhollow {
    top:20px;
    left:73px;
    position: absolute;
  }
  .chaletalley {
    top:31px;
    left:73px;
    position: absolute;
  }
  .sundance {
    top:42px;
    left:73px;
    position: absolute;
  }
  .bigdipperupper {
    top:63px;
    left:73px;
    position: absolute;
  }
  .littledipper {
    top:51px;
    left:73px;
    position: absolute;
  }
  .blastoff {
    top:72px;
    left:73px;
    position: absolute;
  }
  .hotdog {
    top:83px;
    left:73px;
    position: absolute;
  }
  .face {
    top:93px;
    left:73px;
    position: absolute;
  }
  /*Powder Gulch*/
  .auntgladys {
    top:31px;
    left:104px;
    position: absolute;
  }
  .bergerstrasse {
    top:41px;
    left:104px;
    position: absolute;
  }
  .lastchance {
    top:51px;
    left:104px;
    position: absolute;
  }
  .twentyfivenorth {
    top:62px;
    left:104px;
    position: absolute;
  }
  .justdandy {
    top:72px;
    left:104px;
    position: absolute;
  }
  .sunnyridge {
    top:83px;
    left:104px;
    position: absolute;
  }
  .buckedoff {
    top:94px;
    left:104px;
    position: absolute;
  }
  .sunnyhigh {
    top:104px;
    left:104px;
    position: absolute;
  }
  .robsonstreet {
    top:115px;
    left:104px;
    position: absolute;
  }
  .fearngully {
    top:30px;
    left:136px;
    position: absolute;
  }
  .eldorado {
    top:41px;
    left:136px;
    position: absolute;
  }
  .weewillie {
    top:51px;
    left:136px;
    position: absolute;
  }
  .gypsyqueen {
    top:62px;
    left:136px;
    position: absolute;
  }
  .campbellsloop {
    top:72px;
    left:136px;
    position: absolute;
  }
  .caliperridge {
    top:83px;
    left:136px;
    position: absolute;
  }
  .larch {
    top:94px;
    left:136px;
    position: absolute;
  }
  .juddsrun {
    top:104px;
    left:136px;
    position: absolute;
  }
  .blackpine {
    top:115px;
    left:136px;
    position: absolute;
  }
  .holysmokes {
    top:19px;
    left:168px;
    position: absolute;
  }
  .minerva {
    top:30px;
    left:168px;
    position: absolute;
  }
  .nirvana {
    top:41px;
    left:168px;
    position: absolute;
  }
  .zypper {
    top:51px;
    left:168px;
    position: absolute;
  }
  .bondiablo {
    top:62px;
    left:168px;
    position: absolute;
  }
  .heresjoe {
    top:72px;
    left:168px;
    position: absolute;
  }
  .normania {
    top:83px;
    left:168px;
    position: absolute;
  }
  .blackbear {
    top:94px;
    left:168px;
    position: absolute;
  }
  .whiteelephant {
    top:104px;
    left:168px;
    position: absolute;
  }
  .robilliardsrush {
    top:115px;
    left:168px;
    position: absolute;
  }
  /*Silver Woods*/
  .rollercoaster {
    top:62px;
    left:40px;
    position: absolute;
  }
  .starstruck {
    top:117px;
    left:73px;
    position: absolute;
  }
  .mineshaft {
    top:127px;
    left:73px;
    position: absolute;
  }
  .chaos {
    top:138px;
    left:73px;
    position: absolute;
  }
  .silverfox {
    top:117px;
    left:8px;
    position: absolute;
  }
  .shootingstar {
    top:127px;
    left:8px;
    position: absolute;
  }
  .simplepleasure {
    top:138px;
    left:8px;
    position: absolute;
  }
  .cloud9 {
    top:148px;
    left:8px;
    position: absolute;
  }
  .runningonempty {
    top:117px;
    left:40px;
    position: absolute;
  }
  .bxexpress {
    top:127px;
    left:40px;
    position: absolute;
  }
  .northstar {
    top:138px;
    left:40px;
    position: absolute;
  }
  /*Alpine Meadows*/
  .crisscross {
    top:139px;
    left:104px;
    position: absolute;
  }
  .solitude {
    top:149px;
    left:104px;
    position: absolute;
  }
  .ridgerun {
    top:138px;
    left:136px;
    position: absolute;
  }
  .lonestar {
    top:149px;
    left:136px;
    position: absolute;
  }
  .splitdecision {
    top:138px;
    left:168px;
    position: absolute;
  }
  .drsexpress {
    top:149px;
    left:168px;
    position: absolute;
  }
  /*Home Run Tee*/
  .homeruntee {
    top:19px;
    left:104px;
    position: absolute;
  }
  /*Powder Gulch Express*/
  .powdergulchexpress {
    top:19px;
    left:136px;
    position: absolute;
  }
`;

const village3Positions = css`
  .aberdeenskiway {
    top:20px;
    left:8px;
    position: absolute;
  }
  .comettrail {
    top:73px;
    left:8px;
    position: absolute;
  }
  .easystreet {
    top:31px;
    left:8px;
    position: absolute;
  }
  .farout {
    top:62px;
    left:8px;
    position: absolute;
  }
  .silverqueen {
    top:41px;
    left:8px;
    position: absolute;
  }
  .creekside {
    top:51px;
    left:8px;
    position: absolute;
  }
  .overthehill {
    top:83px;
    left:8px;
    position: absolute;
  }
  .fis {
    top:94px;
    left:8px;
    position: absolute;
  }
  .xmasbowl {
    top:20px;
    left:40px;
    position: absolute;
  }
  .waltsrun {
    top:31px;
    left:40px;
    position: absolute;
  }
  .constellation {
    top:41px;
    left:40px;
    position: absolute;
  }
  .exhibition {
    top:51px;
    left:40px;
    position: absolute;
  }
  .whiskeyjack {
    top:73px;
    left:40px;
    position: absolute;
  }
  .middledipper {
    top:83px;
    left:40px;
    position: absolute;
  }
  .milkyway {
    top:94px;
    left:40px;
    position: absolute;
  }
  .skunkhollow {
    top:20px;
    left:73px;
    position: absolute;
  }
  .chaletalley {
    top:31px;
    left:73px;
    position: absolute;
  }
  .sundance {
    top:42px;
    left:73px;
    position: absolute;
  }
  .bigdipperupper {
    top:63px;
    left:73px;
    position: absolute;
  }
  .littledipper {
    top:51px;
    left:73px;
    position: absolute;
  }
  .blastoff {
    top:72px;
    left:73px;
    position: absolute;
  }
  .hotdog {
    top:83px;
    left:73px;
    position: absolute;
  }
  .face {
    top:93px;
    left:73px;
    position: absolute;
  }
  /*Powder Gulch*/
  .auntgladys {
    top:31px;
    left:104px;
    position: absolute;
  }
  .bergerstrasse {
    top:41px;
    left:104px;
    position: absolute;
  }
  .lastchance {
    top:51px;
    left:104px;
    position: absolute;
  }
  .twentyfivenorth {
    top:62px;
    left:104px;
    position: absolute;
  }
  .justdandy {
    top:72px;
    left:104px;
    position: absolute;
  }
  .sunnyridge {
    top:83px;
    left:104px;
    position: absolute;
  }
  .buckedoff {
    top:94px;
    left:104px;
    position: absolute;
  }
  .sunnyhigh {
    top:104px;
    left:104px;
    position: absolute;
  }
  .robsonstreet {
    top:115px;
    left:104px;
    position: absolute;
  }
  .fearngully {
    top:30px;
    left:136px;
    position: absolute;
  }
  .eldorado {
    top:41px;
    left:136px;
    position: absolute;
  }
  .weewillie {
    top:51px;
    left:136px;
    position: absolute;
  }
  .gypsyqueen {
    top:62px;
    left:136px;
    position: absolute;
  }
  .campbellsloop {
    top:72px;
    left:136px;
    position: absolute;
  }
  .caliperridge {
    top:83px;
    left:136px;
    position: absolute;
  }
  .larch {
    top:94px;
    left:136px;
    position: absolute;
  }
  .juddsrun {
    top:104px;
    left:136px;
    position: absolute;
  }
  .blackpine {
    top:115px;
    left:136px;
    position: absolute;
  }
  .holysmokes {
    top:19px;
    left:168px;
    position: absolute;
  }
  .minerva {
    top:30px;
    left:168px;
    position: absolute;
  }
  .nirvana {
    top:41px;
    left:168px;
    position: absolute;
  }
  .zypper {
    top:51px;
    left:168px;
    position: absolute;
  }
  .bondiablo {
    top:62px;
    left:168px;
    position: absolute;
  }
  .heresjoe {
    top:72px;
    left:168px;
    position: absolute;
  }
  .normania {
    top:83px;
    left:168px;
    position: absolute;
  }
  .blackbear {
    top:94px;
    left:168px;
    position: absolute;
  }
  .whiteelephant {
    top:104px;
    left:168px;
    position: absolute;
  }
  .robilliardsrush {
    top:115px;
    left:168px;
    position: absolute;
  }
  /*Silver Woods*/
  .rollercoaster {
    top:62px;
    left:40px;
    position: absolute;
  }
  .starstruck {
    top:117px;
    left:73px;
    position: absolute;
  }
  .mineshaft {
    top:127px;
    left:73px;
    position: absolute;
  }
  .chaos {
    top:138px;
    left:73px;
    position: absolute;
  }
  .silverfox {
    top:117px;
    left:8px;
    position: absolute;
  }
  .shootingstar {
    top:127px;
    left:8px;
    position: absolute;
  }
  .simplepleasure {
    top:138px;
    left:8px;
    position: absolute;
  }
  .cloud9 {
    top:148px;
    left:8px;
    position: absolute;
  }
  .runningonempty {
    top:117px;
    left:40px;
    position: absolute;
  }
  .bxexpress {
    top:127px;
    left:40px;
    position: absolute;
  }
  .northstar {
    top:138px;
    left:40px;
    position: absolute;
  }
  /*Alpine Meadows*/
  .crisscross {
    top:139px;
    left:104px;
    position: absolute;
  }
  .solitude {
    top:149px;
    left:104px;
    position: absolute;
  }
  .ridgerun {
    top:138px;
    left:136px;
    position: absolute;
  }
  .lonestar {
    top:149px;
    left:136px;
    position: absolute;
  }
  .splitdecision {
    top:138px;
    left:168px;
    position: absolute;
  }
  .drsexpress {
    top:149px;
    left:168px;
    position: absolute;
  }
  /*Home Run Tee*/
  .homeruntee {
    top:19px;
    left:104px;
    position: absolute;
  }
  /*Powder Gulch Express*/
  .powdergulchexpress {
    top:19px;
    left:136px;
    position: absolute;
  }
`;

const summitPositions = css`
  .aberdeenskiway {
    top:16px;
    left:104px;
    position: absolute;
  }
  .easystreet {
    top:33px;
    left:104px;
    position: absolute;
  }
  .creekside {
    top:64px;
    left:104px;
    position: absolute;
  }
  .silverqueen {
    top:48px;
    left:104px;
    position: absolute;
  }
  .farout {
    top:80px;
    left:104px;
    position: absolute;
  }
  .comettrail {
    top:96px;
    left:104px;
    position: absolute;
  }
  .overthehill {
    top:111px;
    left:104px;
    position: absolute;
  }
  .fis {
    top:127px;
    left:104px;
    position: absolute;
  }
  .xmasbowl {
    top:16px;
    left:136px;
    position: absolute;
  }
  .waltsrun {
    top:33px;
    left:136px;
    position: absolute;
  }
  .constellation {
    top:48px;
    left:136px;
    position: absolute;
  }
  .exhibition {
    top:64px;
    left:136px;
    position: absolute;
  }
  .whiskeyjack {
    top:96px;
    left:136px;
    position: absolute;
  }
  .middledipper {
    top:111px;
    left:136px;
    position: absolute;
  }
  .milkyway {
    top:127px;
    left:136px;
    position: absolute;
  }
  .skunkhollow {
    top:16px;
    left:169px;
    position: absolute;
  }
  .chaletalley {
    top:33px;
    left:169px;
    position: absolute;
  }
  .sundance {
    top:48px;
    left:169px;
    position: absolute;
  }
  .littledipper {
    top:64px;
    left:169px;
    position: absolute;
  }
  .bigdipperupper {
    top:80px;
    left:169px;
    position: absolute;
  }
  .blastoff {
    top:96px;
    left:169px;
    position: absolute;
  }
  .hotdog {
    top:111px;
    left:169px;
    position: absolute;
  }
  .face {
    top:127px;
    left:169px;
    position: absolute;
  }
  /*Powder Gulch*/
  .auntgladys {
    top:33px;
    left:8px;
    position: absolute;
  }
  .bergerstrasse {
    top:49px;
    left:8px;
    position: absolute;
  }
  .lastchance {
    top:65px;
    left:8px;
    position: absolute;
  }
  .twentyfivenorth {
    top:81px;
    left:8px;
    position: absolute;
  }
  .justdandy {
    top:96px;
    left:8px;
    position: absolute;
  }
  .sunnyridge {
    top:112px;
    left:8px;
    position: absolute;
  }
  .buckedoff {
    top:128px;
    left:8px;
    position: absolute;
  }
  .sunnyhigh {
    top:144px;
    left:8px;
    position: absolute;
  }
  .robsonstreet {
    top:160px;
    left:8px;
    position: absolute;
  }
  .fearngully {
    top:33px;
    left:40px;
    position: absolute;
  }
  .eldorado {
    top:49px;
    left:40px;
    position: absolute;
  }
  .weewillie {
    top:65px;
    left:40px;
    position: absolute;
  }
  .gypsyqueen {
    top:81px;
    left:40px;
    position: absolute;
  }
  .campbellsloop {
    top:96px;
    left:40px;
    position: absolute;
  }
  .caliperridge {
    top:112px;
    left:40px;
    position: absolute;
  }
  .larch {
    top:128px;
    left:40px;
    position: absolute;
  }
  .juddsrun {
    top:144px;
    left:40px;
    position: absolute;
  }
  .blackpine {
    top:160px;
    left:40px;
    position: absolute;
  }
  .holysmokes {
    top:18px;
    left:72px;
    position: absolute;
  }
  .minerva {
    top:33px;
    left:72px;
    position: absolute;
  }
  .nirvana {
    top:49px;
    left:72px;
    position: absolute;
  }
  .zypper {
    top:65px;
    left:72px;
    position: absolute;
  }
  .bondiablo {
    top:81px;
    left:72px;
    position: absolute;
  }
  .heresjoe {
    top:96px;
    left:72px;
    position: absolute;
  }
  .normania {
    top:112px;
    left:72px;
    position: absolute;
  }
  .blackbear {
    top:128px;
    left:72px;
    position: absolute;
  }
  .whiteelephant {
    top:144px;
    left:72px;
    position: absolute;
  }
  .robilliardsrush {
    top:160px;
    left:72px;
    position: absolute;
  }
  /*Silver Woods*/
  .rollercoaster {
    top:80px;
    left:136px;
    position: absolute;
  }
  .starstruck {
    top:163px;
    left:169px;
    position: absolute;
  }
  .mineshaft {
    top:178px;
    left:169px;
    position: absolute;
  }
  .chaos {
    top:194px;
    left:169px;
    position: absolute;
  }
  .silverfox {
    top:162px;
    left:104px;
    position: absolute;
  }
  .shootingstar {
    top:178px;
    left:104px;
    position: absolute;
  }
  .simplepleasure {
    top:194px;
    left:104px;
    position: absolute;
  }
  .cloud9 {
    top:209px;
    left:104px;
    position: absolute;
  }
  .runningonempty {
    top:163px;
    left:136px;
    position: absolute;
  }
  .bxexpress {
    top:178px;
    left:136px;
    position: absolute;
  }
  .northstar {
    top:194px;
    left:136px;
    position: absolute;
  }
  /*Alpine Meadows*/
  .crisscross {
    top:195px;
    left:8px;
    position: absolute;
  }
  .solitude {
    top:211px;
    left:8px;
    position: absolute;
  }
  .ridgerun {
    top:195px;
    left:40px;
    position: absolute;
  }
  .lonestar {
    top:211px;
    left:40px;
    position: absolute;
  }
  .splitdecision {
    top:195px;
    left:72px;
    position: absolute;
  }
  .drsexpress {
    top:211px;
    left:72px;
    position: absolute;
  }
  /*Home Run Tee*/
  .homeruntee {
    top:18px;
    left:8px;
    position: absolute;
  }
  /*Powder Gulch Express*/
  .powdergulchexpress {
    top:18px;
    left:40px;
    position: absolute;
  }
`;

const baseStyles = css`
  .racing, .open, .groomed, .closed {
    text-indent: -9999px;
    line-height: 0;
  }

  .closed:after {
    width: 20px;
    height: 9px;
    background: red;
    display: block;
    float:left;
    line-height: initial;
    text-indent: 0;
    content: "";
    color:red;
    font-size: 12px;
    vertical-align: middle;
    position: absolute;
    z-index: 2;
  }

  .groomed.open:after {
    width: 20px;
    height: 9px;
    background: white;
    display: block;
    float:left;
    line-height: 1.1;
    text-indent: 5px;
    content: "G";
    font-family: 'Khula', Arial, sans-serif;
    font-weight: normal;
    color:black;
    font-size: 11px;
    vertical-align: top;
    z-index: 0;
  }

  .racing:after, .racing.groomed:after {
    width: 20px;
    height: 9px;
    background: white;
    display: block;
    float:left;
    line-height: 1.1;
    text-indent: 6px;
    content: "R";
    font-family: 'Khula', Arial, sans-serif;
    font-weight: normal;
    color:red;
    font-size: 11px;
    vertical-align: middle;
    z-index: 1;
  }

  .open:after {
    width: 20px;
    height: 9px;
    background: #00B300;
    display: block;
    float:left;
    line-height: initial;
    text-indent: 0;
    content: "";
    color:#00B300;
    font-size: 12px;
    vertical-align: middle;
    z-index: -1;
  }

  .blackout {
    width: 20px;
    height: 10px;
    background: black;
    display: block;
    float:left;
    line-height: initial;
    text-indent: 0;
    content: "";
    color:black;
    font-size: 1em;
    vertical-align: middle;
  }

  .hidestatus {
    display:none;
  }

  table, td,th {
    border: none;
  }

  .canvas {
    top: -2px;
    left:-3px;
    position:absolute;
    width:208px;
  }
`;

const baseStylesSummit = css`
  .racing, .open, .groomed, .closed {
    text-indent: -9999px;
    line-height: 0;
  }

  .closed:after {
    width: 20px;
    height: 12px;
    background: red;
    display: block;
    float:left;
    line-height: initial;
    text-indent: 0;
    content: "";
    color:red;
    font-size: 12px;
    vertical-align: middle;
    position: absolute;
  }

  .groomed:after {
    width: 20px;
    height: 12px;
    background: white;
    display: block;
    float:left;
    line-height: 1.6;
    text-indent: 5px;
    content: "G";
    font-family: 'Khula', Arial, sans-serif;
    font-weight: normal;
    color:black;
    font-size: 11px;
    vertical-align: top;
  }

  .racing:after {
    width: 20px;
    height: 12px;
    background: white;
    display: block;
    float:left;
    line-height: 1.6;
    text-indent: 6px;
    content: "R";
    font-family: 'Khula', Arial, sans-serif;
    font-weight: normal;
    color:red;
    font-size: 11px;
    vertical-align: middle;
  }

  .open:after {
    width: 20px;
    height: 12px;
    background: #00B300;
    display: block;
    float:left;
    line-height: initial;
    text-indent: 0;
    content: "";
    color:#00B300;
    font-size: 12px;
    vertical-align: middle;
  }

  .blackout {
    width: 20px;
    height: 12px;
    background: black;
    display: block;
    float:left;
    line-height: initial;
    text-indent: 0;
    content: "";
    color:black;
    font-size: 1em;
    vertical-align: middle;
  }

  .hidestatus {
    display:none;
  }

  table, td,th {
    border: none;
  }

  .canvas {
    top: -2px;
    left:-3px;
    position:absolute;
    width:208px;
  }
`;

const DisplayBoardNames = {
  SILVERWOODS: 'silverwoods',
  COMETLINE: 'cometline',
  GONDOLA: 'gondola',
  PUTNAM: 'putnam',
  SUMMIT: 'summit',
  VILLAGE: 'village',
  VILLAGE2: 'village2',
  VILLAGE3: 'village3',
};

const boardsMap = {
  [DisplayBoardNames.SILVERWOODS]: silverwoodsPositions,
  [DisplayBoardNames.GONDOLA]: gondolaPositions,
  [DisplayBoardNames.PUTNAM]: putnamPositions,
  [DisplayBoardNames.SUMMIT]: summitPositions,
  [DisplayBoardNames.VILLAGE]: villagePositions,
  [DisplayBoardNames.VILLAGE2]: village2Positions,
  [DisplayBoardNames.VILLAGE3]: village3Positions,
};

export const StyledDorDisplayBoard = styled.div`
  position: absolute;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: #000000;
  ${({ $board }) => boardsMap[$board]}
  ${({ $board }) => (($board !== DisplayBoardNames.SUMMIT) ? baseStyles : baseStylesSummit)};
`;
