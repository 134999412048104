import axios from 'axios';

import { FetchStatus } from '@powdr/constants';

import {
  VIMEO_PENDING, VIMEO_SUCCESS, VIMEO_ERROR,
  SVG_PENDING, SVG_SUCCESS, SVG_ERROR,
} from '../types';

export const getVimeoVideoData = (videoLink) => async (dispatch, getState) => {
  const { media } = getState();

  dispatch({
    type: VIMEO_PENDING,
    payload: [],
  });

  if (!media.media?.[videoLink]) {
    try {
      const res = await axios.get(`https://vimeo.com/api/oembed.json?url=${videoLink}`);
      dispatch({
        type: VIMEO_SUCCESS,
        payload: {
          [videoLink]: res.data,
        },
      });
    } catch (error) {
      dispatch({
        type: VIMEO_ERROR,
        payload: error,
      });
    }
  }
};

export const getExternalSvg = (id, svgSrc) => async (dispatch, getState) => {
  const { media } = getState();

  dispatch({
    type: SVG_PENDING,
    payload: [],
  });

  if (!media?.media?.[id] && media.status !== FetchStatus.LOADING) {
    try {
      const res = await axios.get(svgSrc);
      dispatch({
        type: SVG_SUCCESS,
        payload: {
          [id]: res.data,
        },
      });
    } catch (error) {
      dispatch({
        type: SVG_ERROR,
        payload: error,
      });
    }
  }
};
