import PropTypes from 'prop-types';
import React, { useEffect, useCallback, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useDispatch, useSelector } from 'react-redux';

import { AlertType } from '@powdr/constants';
import { getAlerts } from '@powdr/stores';
import { useStaticData } from '@powdr/web/src/hooks';

import {
  StyledDorMiniAlert, Title, Message,
} from './styles';

export const DorMiniAlert = ({
  className,
  height,
  title,
  content,
  itemIndex,
  handleDrawer,
  handleTrayColorOverride,
}) => {
  const dispatch = useDispatch();
  const useAlertData = useSelector((state) => state.alerts);
  const { data: alerts } = useAlertData;
  const [alertData, setAlertData] = useState({ title, message: content?.value || '', type: AlertType.INFO });
  const { property } = useStaticData();
  const localStorageItem = `${property}-alert-state`;

  const handleAlertApiResult = useCallback((a) => {
    const userLocalAlertState = localStorage.getItem(localStorageItem);
    let alertObj = null;

    // if user has no alert state in their local storage
    if (!userLocalAlertState) {
      setAlertData({ title: a?.title || '', message: a?.message || '', type: a?.type || null });

      // if api alert is info, don't open the drawer
      if (a.type === AlertType.INFO) {
        alertObj = JSON.stringify({
          isDrawerOpen: false,
          id: a.id,
        });
      // if api alert is not info, open the drawer
      } else {
        alertObj = JSON.stringify({
          isDrawerOpen: true,
          id: a.id,
        });

        handleDrawer(itemIndex);
      }
      localStorage.setItem(localStorageItem, alertObj);

    // if user has an alert state in their local storage
    } else {
      const localData = JSON.parse(userLocalAlertState);
      setAlertData({ title: a?.title || '', message: a?.message || '', type: a?.type || null });

      // if the last alert put into local storage does not match the new one
      if (localData?.id !== a.id) {
        const isNewAlertUrgent = a.type !== AlertType.INFO;
        alertObj = JSON.stringify({
          isDrawerOpen: isNewAlertUrgent,
          id: a.id,
        });
        localStorage.setItem(localStorageItem, alertObj);

        if (isNewAlertUrgent) {
          handleDrawer(itemIndex);
        }
      }
    }

    return true;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getAlerts());
  }, [dispatch]);

  useEffect(() => {
    if (alerts && alerts.length > 0) {
      handleAlertApiResult(alerts[alerts.length - 1]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alerts]);

  useEffect(() => {
    if (alertData?.type) {
      handleTrayColorOverride(alertData.type);
    }
  }, [alertData, handleTrayColorOverride]);

  return (
    <StyledDorMiniAlert
      className={className}
      $height={height}
    >
      <Title>{alertData.title}</Title>
      <Message>{ReactHtmlParser(alertData.message)}</Message>
    </StyledDorMiniAlert>
  );
};

DorMiniAlert.propTypes = {
  className: PropTypes.string,
  height: PropTypes.number,
  title: PropTypes.string,
  content: PropTypes.shape({
    value: PropTypes.string,
  }),
  itemIndex: PropTypes.number.isRequired,
  handleDrawer: PropTypes.func.isRequired,
  handleTrayColorOverride: PropTypes.func.isRequired,
};

DorMiniAlert.defaultProps = {
  className: '',
  height: null,
  title: null,
  content: {
    value: null,
  },
};
