import styled, { css } from 'styled-components';

import { Link } from '@powdr/components';
import {
  ThemeProps as Prop, Breakpoints, Components, FontSize,
  FontFamily,
} from '@powdr/constants';
import { getFontStyles, themeUtil } from '@powdr/utils';

const CPNT = Components.DOR_FEED_DRAWER;

export const StyledDorWebcams = styled.div`
  display: grid;
  grid-template-columns: ${({ $numCams }) => `repeat(${$numCams}, 1fr)`};
  gap: 35px;

  ${Breakpoints.mobile(css`
    grid-template-columns: repeat(1, 1fr);
  `)}
`;

export const WebcamContainer = styled(Link)`
  display: block;

  img {
    aspect-ratio: 16/9;
    height: auto;
    width: 100%;
    object-fit: fill;
  }
`;

export const WebcamLabel = styled.p`
  color: ${() => themeUtil(Prop.CONTENT, CPNT)};
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.REG50, undefined, undefined)};
  margin-top: 15px;
  line-height: 1.1;
`;
