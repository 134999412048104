import styled, { css } from 'styled-components';

import { ThemeProps as Prop, Breakpoints } from '@powdr/constants';
import { themeUtil } from '@powdr/utils';

export const StyledVimeoVideo = styled.div`
  width: 100%;
  height: 100%;
`;

export const VideoFrame = styled.iframe`
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;
