import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { ButtonText } from '@powdr/constants';

export const ConferenceServicesForm = ({
  reCaptchaPublicKey,
  processForm,
  isUserVerified,
  setUserVerified,
  handleCaptchaExpiry,
  buttonText,
}) => {
  const captchaRef = useRef();

  return (
    <form id="conference-services-form" onSubmit={(e) => processForm(e, 'buk_cpt7R4F6GwnRZ5V6cn2IWTtN', null, captchaRef)}>
      <h3>Your Name *</h3>
      <input className="form-control" id="yourName" name="yourName" type="text" required="" />

      <h3>Company Name *</h3>
      <input className="form-control" id="companyName" name="companyName" type="text" required="" />

      <h3>Street Address 1</h3>
      <input className="form-control" id="streetAddress1" name="streetAddress1" type="text" />

      <h3>Street Address 2</h3>
      <input className="form-control" id="streetAddress2" name="streetAddress2" type="text" />

      <h3>City</h3>
      <input className="form-control" id="city" name="city" type="text" />

      <h3>State</h3>
      <select className="form-control" id="state" name="state">
        <option value="">- select a state -</option>
        <option value="AL">Alabama</option>
        <option value="AK">Alaska</option>
        <option value="AB">Alberta</option>
        <option value="AZ">Arizona</option>
        <option value="AR">Arkansas</option>
        <option value="BC">British Columbia</option>
        <option value="CA">California</option>
        <option value="CO">Colorado</option>
        <option value="CT">Connecticut</option>
        <option value="DE">Delaware</option>
        <option value="DC">District of Columbia</option>
        <option value="FL">Florida</option>
        <option value="GA">Georgia</option>
        <option value="HI">Hawaii</option>
        <option value="ID">Idaho</option>
        <option value="IL">Illinois</option>
        <option value="IN">Indiana</option>
        <option value="IA">Iowa</option>
        <option value="KS">Kansas</option>
        <option value="KY">Kentucky</option>
        <option value="LA">Louisiana</option>
        <option value="ME">Maine</option>
        <option value="MB">Manitoba</option>
        <option value="MD">Maryland</option>
        <option value="MA">Massachusetts</option>
        <option value="MI">Michigan</option>
        <option value="MN">Minnesota</option>
        <option value="MS">Mississippi</option>
        <option value="MO">Missouri</option>
        <option value="MT">Montana</option>
        <option value="NE">Nebraska</option>
        <option value="NV">Nevada</option>
        <option value="NB">New Brunswick</option>
        <option value="NH">New Hampshire</option>
        <option value="NJ">New Jersey</option>
        <option value="NM">New Mexico</option>
        <option value="NY">New York</option>
        <option value="NL">Newfoundland and Labrador</option>
        <option value="NC">North Carolina</option>
        <option value="ND">North Dakota</option>
        <option value="NT">Northwest Territories</option>
        <option value="NS">Nova Scotia</option>
        <option value="NU">Nunavut</option>
        <option value="OH">Ohio</option>
        <option value="OK">Oklahoma</option>
        <option value="ON">Ontario</option>
        <option value="OR">Oregon</option>
        <option value="PA">Pennsylvania</option>
        <option value="PE">Prince Edward Island</option>
        <option value="QC">Quebec</option>
        <option value="RI">Rhode Island</option>
        <option value="SK">Saskatchewan</option>
        <option value="SC">South Carolina</option>
        <option value="SD">South Dakota</option>
        <option value="TN">Tennessee</option>
        <option value="TX">Texas</option>
        <option value="UT">Utah</option>
        <option value="VT" selected="selected">Vermont</option>
        <option value="VA">Virginia</option>
        <option value="WA">Washington</option>
        <option value="WV">West Virginia</option>
        <option value="WI">Wisconsin</option>
        <option value="WY">Wyoming</option>
        <option value="YT">Yukon</option>
      </select>

      <h3>Zip / Postal Code</h3>
      <input className="form-control" id="zipcode" name="zipCode" type="text" />

      <h3>Phone *</h3>
      <input className="form-control" id="phoneNumber" name="phoneNumber" type="tel" required="" />

      <h3>Email address *</h3>
      <input className="form-control" id="email" name="email" type="email" required="" />

      <h3>Proposed Date(s) or Month</h3>
      <input className="form-control" id="proposedDates" name="proposedDates" type="text" />

      <h3>Estimated Number of People</h3>
      <input className="form-control" id="estimatedNumberPeople" name="estimatedNumberPeople" type="number" />

      <h3>Estimated Number of Overnight Rooms</h3>
      <input className="form-control" id="estimatedNumberOvernightRooms" name="estimatedNumberOvernightRooms" type="number" />

      <h3>Additional Comments</h3>
      <textarea id="additionalComments" name="additionalComments" rows="9" />

      <ReCAPTCHA
        ref={captchaRef}
        className="recaptcha"
        sitekey={reCaptchaPublicKey}
        theme="light"
        size="normal"
        onChange={() => setUserVerified(true)}
        onExpired={() => handleCaptchaExpiry()}
      />

      <button type="submit" value="Submit" disabled={!isUserVerified}>{buttonText || ButtonText.SUBMIT}</button>
    </form>
  );
};

ConferenceServicesForm.propTypes = {
  reCaptchaPublicKey: PropTypes.string.isRequired,
  processForm: PropTypes.func.isRequired,
  setUserVerified: PropTypes.func.isRequired,
  isUserVerified: PropTypes.bool.isRequired,
  handleCaptchaExpiry: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
};

ConferenceServicesForm.defaultProps = {
  buttonText: null,
};
