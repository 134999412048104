import styled from 'styled-components';

import { Icon } from '@powdr/components';

export const StyledUiHtml = styled.div`
  padding: ${({ $paddingTop, $paddingBottom }) => `${$paddingTop || 0}px 0 ${$paddingBottom || 0}px`};
`;

export const StyledIcon = styled(Icon)`
  margin-bottom: 30px;
`;
