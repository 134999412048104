import PropTypes from 'prop-types';
import React from 'react';

import { Icon } from '@powdr/components';
import { getLevelLink, getLevelName } from '@powdr/utils';

import {
  StyledChildNavDrawer,
  DrawerBody,
  ParentName,
  ChildNavList, ChildNavItem, ChildNavItemLink,
  NameText,
} from './styles';

export const ChildNavDrawer = ({
  className,
  data,
  isOpen,
  closeDrawerFunc,
}) => (
  <StyledChildNavDrawer
    className={className}
    isOpen={isOpen}
    closeDrawerFunc={() => closeDrawerFunc(false)}
  >
    <DrawerBody>
      <ParentName onClick={() => closeDrawerFunc(false)}>
        <Icon
          className="parent-name-icon"
          name="ui-arrow-left"
        />
        <NameText>
          {data?.parentName}
        </NameText>
      </ParentName>
      <ChildNavList>
        {data?.items?.map((childItem) => (
          <ChildNavItem>
            <ChildNavItemLink href={getLevelLink(childItem)}>
              {getLevelName(childItem)}
            </ChildNavItemLink>
          </ChildNavItem>
        ))}
      </ChildNavList>
    </DrawerBody>
  </StyledChildNavDrawer>
);

ChildNavDrawer.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    parentName: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  isOpen: PropTypes.bool.isRequired,
  closeDrawerFunc: PropTypes.func.isRequired,
};

ChildNavDrawer.defaultProps = {
  className: '',
  data: {
    parentName: '',
    items: [],
  },
};
