import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { ButtonText, ColorProfiles } from '@powdr/constants';
import { usePagination, DOTS } from '@powdr/hooks';

import {
  StyledPagination, PaginationItem, NavigationControl, NavButton,
} from './styles';

export const Pagination = ({
  className,
  colorProfile,
  onPageChange,
  totalCount,
  siblingCount,
  currentPage,
  pageSize,
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });
  const onNext = () => { onPageChange(currentPage + 1); };
  const onPrevious = () => { onPageChange(currentPage - 1); };
  const lastPage = paginationRange[paginationRange.length - 1];

  if (currentPage === 0 || paginationRange.length < 2) return null;

  return (
    <StyledPagination className={className}>
      {/* Previous Page Control */}
      <PaginationItem
        className={classNames('navigation', { disabled: currentPage === 1 })}
        colorProfile={colorProfile}
        onClick={onPrevious}
      >
        <NavigationControl direction="left">
          <NavButton colorProfile={colorProfile}>{ButtonText.PREVIOUS}</NavButton>
        </NavigationControl>
      </PaginationItem>

      {/* Page Indicators */}
      {paginationRange.map((pageNumber) => ((pageNumber === DOTS)
        ? <PaginationItem key={`page-indicator-dots-${Math.random()}`} className="dots" colorProfile={colorProfile}>{DOTS}</PaginationItem>
        : (
          <PaginationItem
            key={`page-indicator-${pageNumber}`}
            className={classNames('page-number', { selected: pageNumber === currentPage })}
            colorProfile={colorProfile}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </PaginationItem>
        )))}

      {/* Next Page Control */}
      <PaginationItem
        className={classNames('navigation', { disabled: currentPage === lastPage })}
        colorProfile={colorProfile}
        onClick={onNext}
      >
        <NavigationControl direction="right">
          <NavButton colorProfile={colorProfile}>{ButtonText.NEXT}</NavButton>
        </NavigationControl>
      </PaginationItem>
    </StyledPagination>
  );
};

Pagination.propTypes = {
  className: PropTypes.string,
  colorProfile: PropTypes.string,
  onPageChange: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  siblingCount: PropTypes.number,
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
};

Pagination.defaultProps = {
  className: '',
  colorProfile: ColorProfiles.BASE,
  siblingCount: 1,
};
