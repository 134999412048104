import PropTypes from 'prop-types';
import React from 'react';

import { Symbols } from '@powdr/constants';
import { getSnowfallMeasurementUnit } from '@powdr/utils';
import { useStaticData } from '@powdr/web/src/hooks';

import {
  ForecastDesc, ForecastIcon, SnowfallValues,
  StyledDorForecastItem, TemperatureValues, Date,
  Section,
} from './styles';

// eslint-disable-next-line import/no-dynamic-require
const { gatsbyConfig } = require(`@powdr/${process.env.GATSBY_PROPERTY_NAME}`);

export const DorForecastItem = ({
  className,
  temperature,
  snowfall,
  date,
  description,
  icon,
  isFitToContent,
}) => {
  const showIcons = (gatsbyConfig?.dor?.hideForecastIcons)
    ? gatsbyConfig?.dor?.hideForecastIcons
    : true;
  const measurementUnit = getSnowfallMeasurementUnit();

  return (
    <StyledDorForecastItem
      className={className}
      $isFitToContent={isFitToContent}
    >
      <Section>
        {/* {(showIcons) && <ForecastIcon name={icon} height="50" width="50" />} */}
        <ForecastIcon name={icon} height="50" width="50" />
        <TemperatureValues>
          {`${temperature.min}/${temperature.max}${Symbols.DEGREE}`}
        </TemperatureValues>
        {(Object.values(snowfall).filter((x) => x).length !== 0) && (
        <SnowfallValues>
          {`${snowfall.min}${measurementUnit} / ${snowfall.max}${measurementUnit}`}
        </SnowfallValues>
        )}
      </Section>
      <Section>
        {date && (<Date>{date}</Date>)}
        {description && (<ForecastDesc>{description}</ForecastDesc>)}
      </Section>
    </StyledDorForecastItem>
  );
};

DorForecastItem.propTypes = {
  className: PropTypes.string,
  temperature: PropTypes.shape({
    min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  snowfall: PropTypes.shape({
    min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  date: PropTypes.string.isRequired,
  description: PropTypes.string,
  icon: PropTypes.string,
  isFitToContent: PropTypes.bool,
};

DorForecastItem.defaultProps = {
  className: '',
  snowfall: {
    min: null,
    max: null,
  },
  description: null,
  icon: null,
  isFitToContent: false,
};
