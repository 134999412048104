import { AlertType } from './dor';
import { ThemeProps } from './theme';

export const AlertColorMap = {
  [AlertType.INFO]: {
    [ThemeProps.BACKGROUND]: ThemeProps.BACKGROUND,
    [ThemeProps.CONTENT]: ThemeProps.CONTENT,
  },
  [AlertType.WARNING]: {
    [ThemeProps.BACKGROUND]: ThemeProps.ALERT_WARNING_BG,
    [ThemeProps.CONTENT]: ThemeProps.ALERT_WARNING_CONTENT,
  },
  [AlertType.DANGER]: {
    [ThemeProps.BACKGROUND]: ThemeProps.ALERT_DANGER_BG,
    [ThemeProps.CONTENT]: ThemeProps.ALERT_DANGER_CONTENT,
  },
};

export const DorRoadStatus = {
  OPEN: {
    name: 'open',
    displayColor: ThemeProps.SUCCESS,
  },
  OPEN_CLEAR: {
    name: 'open_clear',
    displayColor: ThemeProps.SUCCESS,
  },
  OPEN_SNOW_COVERED: {
    name: 'open_snow_covered',
    displayColor: ThemeProps.SUCCESS,
  },
  OPEN_RESTRICTED: {
    name: 'open_restricted',
    displayColor: ThemeProps.WARNING,
  },
  OPEN_SPRING: {
    name: 'open_spring',
    displayColor: ThemeProps.WARNING,
  },
  OPEN_CONSTRUCTION: {
    name: 'open_construction',
    displayColor: ThemeProps.WARNING,
  },
  OPEN_ICY_SPOTS: {
    name: 'open_icy_spots',
    displayColor: ThemeProps.WARNING,
  },
  OPEN_SLUSHY: {
    name: 'open_slushy',
    displayColor: ThemeProps.WARNING,
  },
  OPEN_WET: {
    name: 'open_wet',
    displayColor: ThemeProps.WARNING,
  },
  CLOSED: {
    name: 'closed',
    displayColor: ThemeProps.DANGER,
  },
};

export const DorPlaceStatus = {
  OPEN: {
    name: 'open',
    displayColor: ThemeProps.SUCCESS,
  },
  CLOSED: {
    name: 'closed',
    displayColor: ThemeProps.DANGER,
  },
};

export const DorTerrainStatus = {
  OPEN: {
    name: 'open',
    displayColor: ThemeProps.SUCCESS,
  },
  CLOSED: {
    name: 'closed',
    displayColor: ThemeProps.DANGER,
  },
};

// temporary wind speed mapping until sensors are normalized across all platforms
export const WindSpeedSensorMap = {
  alta: {
    low: 'baldy_speed_avg',
    high: 'baldy_gust_max',
    direction: 'baldy_dir_avg',
  },
  altaHiddenPeak: {
    low: 'baldy_wind_spd',
    high: 'baldy_wind_gust',
    direction: 'baldy_wind_dir',
  },
  snowbirdHiddenPeak15min: {
    low: 'wind_mph',
    high: 'max_mph',
    direction: 'wind_dir',
  },
  snowbirdHiddenPeak: {
    low: 'wind_mph',
    high: 'max_mph',
    direction: 'wind_dir',
  },
};
