import {
  FontFamily, FontSize, TextTransformOptions, Components,
} from '@powdr/constants';

const fonts = {
  [FontFamily.BASE_FONT]: {
    fontFamily: '"HelveticaNeue-Roman"',
    textTransform: TextTransformOptions.NO_TRANSFORM,
    defaultSize: FontSize.REG50,
    scaling: 1,
  },
  [FontFamily.PRIMARY_FONT]: {
    fontFamily: '"HelveticaNeue-Bold"',
    textTransform: TextTransformOptions.NO_TRANSFORM,
    defaultSize: FontSize.MED40,
    scaling: 1,
  },
  [FontFamily.SECONDARY_FONT]: {
    fontFamily: '"HelveticaNeue-Bold"',
    textTransform: TextTransformOptions.CAPITALIZE,
    defaultSize: FontSize.REG40,
    scaling: 1,
  },
};

const fontOverrides = {
  [Components.CONTENT_BLOCK_HERO]: {
    header: {
      size: '50px',
    },
    headerMobile: {
      size: '50px',
    },
    copy: {
      size: FontSize.REG40,
    },
  },
  [Components.WYSIWYG]: {
    header2: {
      size: '40px',
    },
    header3: {
      size: '30px',
    },
    header4: {
      size: '24px',
    },
    header5: {
      size: '20px',
    },
    header6: {
      size: '20px',
    },
    header2Mobile: {
      size: '30px',
    },
    header3Mobile: {
      size: '24px',
    },
    header4Mobile: {
      size: '18px',
    },
    header5Mobile: {
      size: '18px',
    },
    header6Mobile: {
      size: '18px',
    },
    subheaderMobile: {
      size: '20px',
    },
  },
  [Components.CONTENT_TEMPLATE]: {
    title: {
      size: '40px',
    },
    titleMobile: {
      size: '30px',
    },
    subheaderMobile: {
      size: '20px',
    },
  },
};

export const fontTheme = {
  fonts,
  FontSize,
  fontOverrides,
};
