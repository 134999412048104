import PropTypes from 'prop-types';
import React from 'react';

import { DorMeasurementFieldNames, MeasurementDisplayTypes } from '@powdr/constants';
import { useTrailsMeasurements } from '@powdr/hooks';

import { CircularGraph } from './circular-graph';
import {
  TextMeasurementSplit,
  TextMeasurementSplitLarge,
  TextMeasurementSplitSmall,
} from './styles';
import { TextMeasurement } from './text-measurement';

export const Trails = ({
  measurementDisplayType,
  liftsTrailsMeasurement,
  ignoreTrailTypes,
  parentColorProfile,
  measurementLabel,
  icon,
}) => {
  const measurementData = useTrailsMeasurements(ignoreTrailTypes, liftsTrailsMeasurement);

  if (!measurementData) return null;

  return (
    <>
      {(measurementDisplayType === MeasurementDisplayTypes.CIRCULAR_GRAPH) && (
      <CircularGraph
        colorProfile={parentColorProfile}
        label={measurementLabel}
        openVal={measurementData[DorMeasurementFieldNames.OPEN]}
        totalVal={measurementData[DorMeasurementFieldNames.TOTAL]}
      />
      )}
      {(measurementDisplayType === MeasurementDisplayTypes.TEXT
        || measurementDisplayType === MeasurementDisplayTypes.TEXT_LARGE) && (
        <TextMeasurement
          measurementDisplayType={measurementDisplayType}
          label={measurementLabel}
          icon={icon}
          colorProfile={parentColorProfile}
        >
          <TextMeasurementSplit className="split">
            <TextMeasurementSplitLarge className="split-large">
              {measurementData[DorMeasurementFieldNames.OPEN]}
            </TextMeasurementSplitLarge>
            <TextMeasurementSplitSmall className="split-small">
              {`/${measurementData[DorMeasurementFieldNames.TOTAL]}`}
            </TextMeasurementSplitSmall>
          </TextMeasurementSplit>
        </TextMeasurement>
      )}
    </>
  );
};

Trails.propTypes = {
  parentColorProfile: PropTypes.string,
  measurementLabel: PropTypes.string,
  measurementDisplayType: PropTypes.string,
  liftsTrailsMeasurement: PropTypes.string,
  ignoreTrailTypes: PropTypes.arrayOf(PropTypes.string),
  icon: PropTypes.string,
};

Trails.defaultProps = {
  parentColorProfile: null,
  measurementLabel: null,
  measurementDisplayType: null,
  liftsTrailsMeasurement: null,
  ignoreTrailTypes: null,
  icon: null,
};
