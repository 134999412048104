import styled from 'styled-components';

import { ComponentDrawer, Link } from '@powdr/components';
import {
  ThemeProps as Prop, Components, FontSize,
  FontFamily,
} from '@powdr/constants';
import { componentColor, getFontStyles } from '@powdr/utils';

const CPNT = Components.MAIN_NAV;

export const StyledChildNavDrawer = styled(ComponentDrawer)`
  z-index: 79;
`;

export const DrawerBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 25% 15px;
  align-items: center;
`;

export const ParentName = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 15px 0;

  .parent-name-icon {
    height: ${FontSize.MED20};
    width: ${FontSize.MED20};
    margin-right: 8px;

    svg {
      fill: ${() => componentColor(Prop.CONTENT, CPNT, Prop.CONTENT)};
      color: ${() => componentColor(Prop.CONTENT, CPNT, Prop.CONTENT)};
    }
  }
`;

export const NameText = styled.div`
  ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.MED20, undefined, undefined)};
  color: ${() => componentColor(Prop.CONTENT, CPNT, Prop.CONTENT)};
  line-height: 1.1;
`;

export const ChildNavList = styled.ul`
  width: 100%;
`;

export const ChildNavItem = styled.li``;

export const ChildNavItemLink = styled(Link)`
  display: block;
  ${() => getFontStyles(FontFamily.BASE_FONT, FontSize.REG40, undefined, undefined)};
  line-height: 1.1;
  margin-bottom: 8px;
  width: 100%;
  color: ${() => componentColor(Prop.CONTENT, CPNT, Prop.CONTENT)};
`;
