import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

export const LoadScript = ({
  node,
}) => {
  const scriptContainerRef = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');
    if (scriptContainerRef?.current) {
      if (node.attribs) {
        Object.entries(node.attribs).forEach((attr) => { script.setAttribute(attr[0], attr[1]); });
      }
      if (node?.children?.[0]?.data) {
        script.innerHTML = node.children[0].data;
      }
      scriptContainerRef?.current.appendChild(script);
    }
  }, []);

  return (
    <div className="injected-script" ref={scriptContainerRef} />
  );
};

LoadScript.propTypes = {
  node: PropTypes.string,
};

LoadScript.defaultProps = {
  node: '',
};
