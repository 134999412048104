/* eslint-disable import/no-unresolved */
/* eslint-disable no-nested-ternary */
import { useLocation } from '@reach/router';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

import { Components, LinkTargets } from '@powdr/constants';
import { AppContext } from '@powdr/context';
import { useSeason } from '@powdr/hooks';
import { PageType } from '@powdr/model';
import {
  componentFactory, getLevelLink, getLevelName, isLevelLinkNormal, stripAbsolutePath,
} from '@powdr/utils';
import { useStaticData } from '@powdr/web/src/hooks';

import {
  StyledMegaNav, StyledMegaContainer, LinkContainer,
  LevelTwoLinkGroup, SingleLevelTwoItem, LevelTwoLink, NavigationWidgets,
  LevelThreeLinkGroup, SingleLevelThreeItem, LevelThreeLink,
  MobileNavToggle, MobileNavToggleIcon,
  MobileLevelThreeDrawer, LevelThreeNavigation,
  LevelThreeDrawerCloseButton, CloseButtonIcon, CloseButtonText,
} from './styles';

// TODO: Refactor Mega Nav into two different components for mobile and desktop
export const MegaNav = ({
  pages,
  isLevel2Overrides,
}) => {
  const {
    isMobile,
    setDrawerOpen,
    isMobileThirdLevelOpen,
    toggleThirdLevel,
    themeConfig,
  } = useContext(AppContext);
  const { pathname } = useLocation();
  const { checkSeasonality } = useSeason();
  const { headerConfig } = useStaticData();
  const [activeLevelTwoData, setActiveLevelTwoData] = useState(null);
  const filterLinks = (links) => ((links)
    ? links?.filter((p) => checkSeasonality(p.season) && !p.isHideFromNav)
    : []
  );
  const [numGroups] = useState((pages) ? filterLinks(pages).length : 0);

  if (!pages) return null;

  const levelThreeToggle = (levelTwoItem) => {
    if (levelTwoItem) setActiveLevelTwoData(levelTwoItem);
    toggleThirdLevel(!isMobileThirdLevelOpen);
  };

  const levelThreeLinkHandler = (levelTwoItem) => (
    filterLinks(levelTwoItem?.childPages)
      .map((levelThreeItem, idx) => ((!isLevelLinkNormal(levelTwoItem) && idx === 0) && (
        <LevelThreeLinkGroup
          className={classNames({ 'animated fadeInUp ': !isMobile }, { 'level-3': isMobile })}
          key={levelThreeItem.id}
          role="navigation"
        >
          {/* TODO: Super complicated by necessity but might be worth refactoring */}
          {/* The extra level 3 iteration has to do with the isLevelLinkNormal  */}
          {filterLinks(levelTwoItem?.childPages)?.map((levelThreeLink) => ((
            <SingleLevelThreeItem
              className={classNames(
                { active: stripAbsolutePath(getLevelLink(levelThreeLink)) === pathname },
              )}
              key={levelThreeLink.id}
            >
              <LevelThreeLink
                className={classNames(
                  { active: stripAbsolutePath(getLevelLink(levelThreeLink)) === pathname },
                )}
                href={getLevelLink(levelThreeLink)}
                onClick={() => setDrawerOpen(false)}
              >
                { getLevelName(levelThreeLink) }
                {(levelThreeLink?.navRelationships?.navigationComponents && !isMobile) && (
                  <NavigationWidgets className="animated fadeInUp ">
                    {componentFactory(
                      levelThreeLink?.navRelationships?.navigationComponents,
                      themeConfig[Components.MEGA_NAV],
                    )}
                  </NavigationWidgets>
                )}
              </LevelThreeLink>
            </SingleLevelThreeItem>
          )))}
        </LevelThreeLinkGroup>
      )))
  );

  const levelThreeOverridesHandler = (overrideLinks) => (
    <LevelThreeLinkGroup
      className={classNames({ 'animated fadeInUp ': !isMobile }, { 'level-3': isMobile })}
      role="navigation"
    >
      {overrideLinks.map((item) => (
        <SingleLevelThreeItem key={item.id}>
          <LevelThreeLink
            target={item.buttonTarget || LinkTargets.SELF}
            href={item.buttonLink}
            onClick={() => setDrawerOpen(false)}
          >
            {item.buttonText}
          </LevelThreeLink>
        </SingleLevelThreeItem>
      ))}
    </LevelThreeLinkGroup>
  );

  const levelTwoDesktopHandler = (levelTwoItem) => (
    <>
      {isLevelLinkNormal(levelTwoItem) ? (
        <SingleLevelTwoItem className="animated fadeInUp ">
          <LevelTwoLink tabIndex="0" href={getLevelLink(levelTwoItem)}>
            { getLevelName(levelTwoItem) }
          </LevelTwoLink>
        </SingleLevelTwoItem>
      ) : (
      // This is a bit confusing; look up the second level children
      // then mask the third level link with the second level name
        filterLinks(levelTwoItem?.childPages)
          .map((levelThreeItem, idx) => ((!isLevelLinkNormal(levelTwoItem) && idx === 0) && (
          <SingleLevelTwoItem
            className="animated fadeInUp "
            key={levelTwoItem.id}
          >
            <LevelTwoLink tabIndex="0" href={getLevelLink(levelThreeItem)}>
              { getLevelName(levelTwoItem) }
            </LevelTwoLink>
          </SingleLevelTwoItem>
          )))
      )}
      {/* Navigation Components */}
      {(levelTwoItem?.navRelationships?.navigationComponents) && (
      <NavigationWidgets className="animated fadeInUp ">
        {componentFactory(
          levelTwoItem?.navRelationships?.navigationComponents,
          themeConfig[Components.MEGA_NAV],
        )}
      </NavigationWidgets>
      )}
      {/* Level 3 Links */}
      {(levelTwoItem?.navRelationships?.navigationOverrides?.length > 0)
        ? levelThreeOverridesHandler(levelTwoItem?.navRelationships?.navigationOverrides)
        : levelThreeLinkHandler(levelTwoItem)}
    </>
  );

  const levelTwoMobileHandler = (levelTwoItem) => (
    (levelTwoItem?.navRelationships?.navigationOverrides?.length > 0) ? (
      <SingleLevelTwoItem key={levelTwoItem.id}>
        <MobileNavToggle onClick={() => { levelThreeToggle(levelTwoItem); }}>
          { getLevelName(levelTwoItem) }
          <MobileNavToggleIcon name="ui-chevron-thin" width="15" height="15" />
        </MobileNavToggle>
      </SingleLevelTwoItem>
    ) : (
      isLevelLinkNormal(levelTwoItem) ? (
        <SingleLevelTwoItem>
          <LevelTwoLink tabIndex="0" href={getLevelLink(levelTwoItem)} onClick={() => setDrawerOpen(false)}>
            { getLevelName(levelTwoItem) }
          </LevelTwoLink>
        </SingleLevelTwoItem>
      ) : (
        // This is a bit confusing; look up the second level children
        // then mask the third level link with the second level name
        filterLinks(levelTwoItem?.childPages)
          .map((levelThreeItem, idx) => ((!isLevelLinkNormal(levelTwoItem) && idx === 0) && (
          <SingleLevelTwoItem key={levelTwoItem.id}>
            <MobileNavToggle onClick={() => levelThreeToggle(levelTwoItem)}>
              { getLevelName(levelTwoItem) }
              <MobileNavToggleIcon name="ui-chevron-thin" width="15" height="15" />
            </MobileNavToggle>
          </SingleLevelTwoItem>
          )))
      )
    )
  );

  const levelTwoOverrideHandler = (overrideLinks) => (
    overrideLinks.map((item) => (
      <LevelTwoLinkGroup
        key={item.id}
        numGroups={overrideLinks?.length}
        navigationStyle={headerConfig.navigationDropdownStyle}
        role="navigation"
      >

        <SingleLevelTwoItem className={classNames({ 'animated fadeInUp ': !isMobile })}>
          <LevelTwoLink
            tabIndex="0"
            target={item.buttonTarget || LinkTargets.SELF}
            href={item.buttonLink}
            onClick={() => setDrawerOpen(false)}
          >
            { item.buttonText }
          </LevelTwoLink>
        </SingleLevelTwoItem>
      </LevelTwoLinkGroup>
    ))
  );

  return (
    <StyledMegaNav>
      <StyledMegaContainer
        isMegaNav
        navigationStyle={headerConfig.navigationDropdownStyle}
        isRightAlignedNav={headerConfig.isRightAlignedNav}
        numGroups={numGroups}
        splitHeight={!!(headerConfig?.navigationComponents?.length > 0)}
      >
        <LinkContainer navigationStyle={headerConfig.navigationDropdownStyle}>
          {(!isLevel2Overrides) ? (
            filterLinks(pages).map((levelTwoItem) => (
              <LevelTwoLinkGroup
                key={levelTwoItem.id}
                numGroups={filterLinks(pages)?.length}
                navigationStyle={headerConfig.navigationDropdownStyle}
                role="navigation"
              >
                {(!isMobile)
                  ? levelTwoDesktopHandler(levelTwoItem)
                  : levelTwoMobileHandler(levelTwoItem)}
              </LevelTwoLinkGroup>
            ))
          ) : levelTwoOverrideHandler(pages)}
        </LinkContainer>
      </StyledMegaContainer>
      {(isMobile) && (
        <MobileLevelThreeDrawer isMobileThirdLevelOpen={isMobileThirdLevelOpen}>
          {(activeLevelTwoData) && (
            <LevelThreeNavigation role="navigation">
              <LevelThreeDrawerCloseButton onClick={() => levelThreeToggle(null)} aria-label="Close Mobile Navigation">
                <CloseButtonIcon name="ui-chevron-thin" width="25" height="25" />
                <CloseButtonText>{ getLevelName(activeLevelTwoData) }</CloseButtonText>
              </LevelThreeDrawerCloseButton>
              {(activeLevelTwoData?.navRelationships?.navigationOverrides?.length > 0)
                ? levelThreeOverridesHandler(
                  activeLevelTwoData?.navRelationships?.navigationOverrides,
                )
                : levelThreeLinkHandler(activeLevelTwoData)}
            </LevelThreeNavigation>
          )}
        </MobileLevelThreeDrawer>
      )}
    </StyledMegaNav>
  );
};

MegaNav.propTypes = {
  isLevel2Overrides: PropTypes.bool,
  pages: PropTypes.arrayOf(PageType),
};

MegaNav.defaultProps = {
  isLevel2Overrides: false,
  pages: [],
};
