import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import { Components, ThemeProps as Prop } from '@powdr/constants';
import { componentColor, themeUtil } from '@powdr/utils';

const CPNT = Components.HEADER;

const StyledHeaderBanner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 45px;
  background-color: ${() => componentColor(Prop.NAV_BG, CPNT, Prop.CONTENT)};
  color: ${() => componentColor(Prop.NAV_ITEM_TXT_SOLID, CPNT, Prop.CONTENT)};
  cursor: pointer;
  border-top: 1px solid ${() => themeUtil(Prop.BORDER, CPNT)};
`;

export const DemoHeaderBanner = ({
  children,
}) => (
  <StyledHeaderBanner>
    {children}
  </StyledHeaderBanner>
);

DemoHeaderBanner.propTypes = {
  children: PropTypes.node.isRequired,
};

DemoHeaderBanner.defaultProps = {};
