import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getLifts } from '@powdr/stores';

export const useLiftStatus = (liftUUID) => {
  if (!liftUUID) return null;
  const dispatch = useDispatch();
  const useLiftsData = useSelector((state) => state.lifts);
  const { data } = useLiftsData;
  const [liftStatus, setLiftStatus] = useState(null);

  useEffect(() => {
    dispatch(getLifts());
  }, [dispatch]);

  useEffect(() => {
    if (data && data?.length > 0) {
      setLiftStatus(data.find((r) => r.id === liftUUID));
    }
  }, [data, liftUUID]);

  return liftStatus;
};
