import styled from 'styled-components';

import {
  ThemeProps as Prop, Components,
  FontSize,
  FontFamily,
  TextTransformOptions,
} from '@powdr/constants';
import { getFontStyles, themeUtil } from '@powdr/utils';

const CPNT = Components.DOR_LIVE_WEATHER;

export const StyledDorLiveWeather = styled.div`
  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 15px;

    .card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;;
      box-shadow: 0 0 5px -1px rgb(0 0 0 / 65%);
      position: relative;
      overflow: hidden;
      min-height: 250px;
      min-width: 250px;
      margin: 10px;
      padding: 5px;

      .grey {
        color: lightgray;
      }

      .header {

        .banner {
          background-color: black;
          color: white;
          padding: 10px;
          margin-bottom: 5px;
        }

        .location,
        .elevation,
        .date-time {
          text-align: center;
          text-transform: uppercase;
        }

        .location {
          color: white;
          margin-bottom: 5px;
          ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.MED40, undefined, undefined)};
        }

        .elevation {
          margin-bottom: 0;
          color: white;
        }

        //.date-time {}
      }

      .content {
        padding: 15px;

        .temperature {
          text-transform: uppercase;
          margin-bottom: 0;
          text-align: center;
          ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.LRG50, undefined, TextTransformOptions.UPPERCASE)};
          color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_ACTIVE, CPNT, colorProfile)};
        }
      }

      .footer {
        padding: 15px;

        .li {
          padding: 5px 0 5px;
          border-top: 1px solid lightgray;
        }

        .detail-values {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }

        .details,
        .values {
          text-transform: uppercase;
        }
      }
    }
  }
`;
