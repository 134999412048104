import React from 'react';

import { Icon, Link } from '@powdr/components';
import { useStaticData } from '@powdr/web/src/hooks';

import { StyledGlobalFooter } from './styles';

export const GlobalFooter = () => {
  const { footerPropertyList } = useStaticData();

  return (
    <StyledGlobalFooter>
      <ul className="footer" role="navigation">
        {footerPropertyList.map((p) => (
          <li className="property-logo" key={p.iconName} role="menuitem">
            <Link
              title={p.title}
              href={p.link}
              target="_blank"
              rel="noreferrer"
            >
              <Icon name={p.iconName} height={p.height} className="icon-c" />
            </Link>
          </li>
        ))}
      </ul>
    </StyledGlobalFooter>
  );
};

GlobalFooter.propTypes = {

};

GlobalFooter.defaultProps = {

};
