import PropTypes from 'prop-types';
import React from 'react';

import { Icon } from '@powdr/components';
import { ColorProfiles } from '@powdr/constants';
import { PartnerType } from '@powdr/model';

import { StyledPartners } from './styles';

export const Partners = ({
  partners, colorProfile,
}) => (
  <StyledPartners
    colorProfile={colorProfile}
    className="partners-component"
  >
    {partners.length > 0 && (
      <ul className="partners l-group u-center-block" role="navigation">
        {partners.sort((a, b) => a.weight - b.weight).map((partner) => (
          <li className="l-item" key={partner.name}>
            <a href={partner.link} target="_blank" rel="noreferrer" aria-label={`${partner.name} (opens in new tab)`}>
              <Icon
                className="u-m-b-0"
                name={partner.iconName}
                height="auto"
                width={partner.width ? partner.width : '75'}
              />
            </a>
          </li>
        ))}
      </ul>
    )}
  </StyledPartners>
);

Partners.propTypes = {
  partners: PropTypes.arrayOf(PartnerType),
  colorProfile: PropTypes.oneOf(Object.values(ColorProfiles)),
};

Partners.defaultProps = {
  partners: [],
  colorProfile: ColorProfiles.BASE,
};
