/* eslint-disable import/no-unresolved */
import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/a11y';
import 'swiper/css/parallax';
import 'swiper/css/controller';
import 'swiper/css/effect-fade';

import { Button, Icon, Link } from '@powdr/components';
import {
  ThemeProps as Prop, Breakpoints, FontSize, NavigationDirection,
  Components,
  FontFamily,
} from '@powdr/constants';
import { getFontStyles, rgbaTransparencyToHex, themeUtil } from '@powdr/utils';

const CPNT = Components.BANNER_CAROUSEL;

export const StyledBannerCarousel = styled.section`
  display: grid;
  height: ${({ $componentHeight }) => $componentHeight}px;
  width: 100%;

  ${Breakpoints.mobile(css`
    height: ${({ $componentHeight }) => $componentHeight + 50}px;
  `)};
`;

export const StyledSwiper = styled(Swiper)`
  grid-area: 1/1;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

export const StyledSwiperSlide = styled(SwiperSlide)``;

export const ButtonPlacement = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  grid-area: 1/1;
  width: 100%;
  height: 100%;
  z-index: 3;
  pointer-events: none;
  margin-top: 15px;

  ${Breakpoints.mobile(css`
    margin-top: 0;
    align-items: flex-end;
  `)}
`;

export const ButtonList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 80%;
  max-width: 400px;

  ${Breakpoints.mobile(css`
    max-width: unset;
    gap: 15px;
    padding: 25px 25px;
  `)}
`;

export const ButtonListItem = styled.li`
  width: 100%;
`;

export const CarouselButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 8px 12px;
  pointer-events: all;
  background-color: ${() => themeUtil(Prop.BTN_BG, CPNT)};
  color: ${() => themeUtil(Prop.BTN_TXT, CPNT)};
  ${() => getFontStyles(FontFamily.SECONDARY_FONT, undefined, undefined, undefined, CPNT, 'button')};
  transition: color 0.25s ease-out, background-color 0.25s ease-out;

  &:hover, &:focus {
    transition: color 0.25s ease-out, background-color 0.25s ease-out;
    background-color: ${() => themeUtil(Prop.BTN_BG_HOVER, CPNT)};
    color: ${() => themeUtil(Prop.BTN_TXT_HOVER, CPNT)};
  }
  &:focus {
    outline: 2px solid ${() => themeUtil(Prop.BTN_BG_HOVER, CPNT)};;
    outline-offset: 0.2em;
  }
`;

export const SlideWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
`;

export const OpacityLayer = styled.div`
  grid-area: 1/1;
  height: 100%;
  width: 100%;
  background-color: ${({ $color, $opacity }) => `${$color}${rgbaTransparencyToHex($opacity / 100)}`};
  z-index: 1;
`;

export const BackgroundImage = styled(GatsbyImage)`
  grid-area: 1/1;
  height: 100%;
  width: 100%;
  z-index: -1;
`;

export const ContentCarouselWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 2;
`;

export const StaticCarouselComponents = styled.div`
  position: absolute;
  width: 300px;
  z-index: 3;
  margin: 15px;
`;

export const Title = styled.h1`
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.REG30, undefined, undefined)};
  margin-bottom: 15px;
  color: ${({ $colorProfile }) => themeUtil(Prop.CONTENT, CPNT, $colorProfile)};
`;

export const SlideContent = styled.div`
  height: 100%;
  line-height: 1.2;
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.MED50, undefined, undefined)};
  padding: 0 10px;
  color: ${({ $colorProfile }) => themeUtil(Prop.CONTENT, CPNT, $colorProfile)};

  .slide-index {
    ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.REG40, undefined, undefined)};
  }
`;

export const SlideIndex = styled.span`

`;

export const ControlArrowWrapper = styled.button`
  ${({ direction }) => ((direction !== NavigationDirection.PREVIOUS) && 'transform: rotate(-180deg);')};
`;

export const ControlArrow = styled.div``;

export const ControlArrowIcon = styled(Icon)`
  width: 25px;
  height: 25px;

  svg {
    fill: ${({ $colorProfile }) => themeUtil(Prop.CONTENT, CPNT, $colorProfile)};
    color: ${({ $colorProfile }) => themeUtil(Prop.CONTENT, CPNT, $colorProfile)};
  }
`;
