/* eslint-disable no-nested-ternary */
import {
  useCallback, useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  DorMeasurementFieldNames, LiftsTrailsMeasurementTypes, LiftTrailStatus, TrailsPropertyKeys,
} from '@powdr/constants';
import { getTrails } from '@powdr/stores';

export const useTrailsMeasurements = (ignoreTrailTypes, measurementSpecification) => {
  const dispatch = useDispatch();
  const useTrailsData = useSelector((state) => state.trails);
  const { data } = useTrailsData;
  const [trailsData, setTrailsData] = useState(null);

  const filterTrails = useCallback(
    (d, checkOpen) => d
      .filter((t) => t.include === true)
      .filter((t) => !ignoreTrailTypes?.includes(t.type))
      .filter((t) => (
        (checkOpen) ? t.status === LiftTrailStatus.OPEN : true
      )),
    [ignoreTrailTypes],
  );

  const propertyReduceAdder = (d, property) => d
    .reduce((accum, current) => accum + Math.round(current.properties[property]), 0);

  const getMeasurements = useCallback(
    () => (
      (measurementSpecification === LiftsTrailsMeasurementTypes.MILES_OF_SKIING
        || measurementSpecification === LiftsTrailsMeasurementTypes.ACRES_OF_SKIING)
        ? {
          [DorMeasurementFieldNames.OPEN]: propertyReduceAdder(
            filterTrails(data, true),
            TrailsPropertyKeys[measurementSpecification],
          ),
          [DorMeasurementFieldNames.TOTAL]: propertyReduceAdder(
            filterTrails(data, false),
            TrailsPropertyKeys[measurementSpecification],
          ),
        }
        : {
          [DorMeasurementFieldNames.OPEN]: filterTrails(
            data,
            true,
          ).length,
          [DorMeasurementFieldNames.TOTAL]: filterTrails(
            data,
            false,
          ).length,
        }),
    [data, filterTrails, measurementSpecification],
  );

  useEffect(() => {
    dispatch(getTrails());
  }, [dispatch]);

  useEffect(() => {
    if (data && data?.length > 0) {
      setTrailsData(getMeasurements());
    }
  }, [data, getMeasurements]);

  return trailsData;
};
