import styled, { css } from 'styled-components';

import { Link } from '@powdr/components';
import {
  ThemeProps as Prop, Components, Breakpoints,
  FontSize,
  AbsolutePositions,
  FontFamily,
} from '@powdr/constants';
import { componentColor, getFontStyles } from '@powdr/utils';

const CPNT = Components.PROMOTIONS;

export const StyledPromotionList = styled.div`
  margin: 0 auto;
`;

export const PromoListItems = styled.ul`
  display: flex;
  position: relative;
  gap: 30px;
  align-items: center;
  justify-content: center;
  z-index: 20;

  ${Breakpoints.desktopOnly(css`
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    transform: ${({ $shift }) => (($shift === AbsolutePositions.LEFT || $shift === AbsolutePositions.RIGHT)
    ? `translateX(${($shift === AbsolutePositions.LEFT) ? '-55%' : '55%'})`
    : 'translateX(0);')};

    ${({ $shift }) => ($shift === AbsolutePositions.TOP) && `
      pointer-events: none;
      opacity: 0;
    `}
  `)}

  ${Breakpoints.mobile(css`
    flex-direction: column;
  `)}
`;

export const PromoListItem = styled.li`
`;

export const PromoDisc = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 150px;
  width: 150px;
  padding: 15px;
  border-radius: 50%;
  background: center / contain no-repeat url("https://cms.dev.killington.com/sites/default/files/2024-06/test-promo-image.png");
  color: ${() => componentColor(Prop.PROMO_ITEM_HEADER, CPNT, Prop.HEADER)};
  ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.REG40, undefined, undefined)};
  line-height: 1.1;
  font-weight: bold;
`;
