import styled from 'styled-components';

import {
  ThemeProps as Prop, Components, FontSize, FontFamily,
} from '@powdr/constants';
import { getFontStyles, themeUtil } from '@powdr/utils';

const CPNT = Components.HOTSPOT_GROUP;

export const StyledHotspotGroup = styled.div`
  scroll-margin-top: ${({ $scrollMargin }) => `${$scrollMargin}px`};
`;

export const HotspotImageSelectors = styled.ul`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const HotspotImageSelector = styled.button`
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.MED10, undefined, undefined)};
  line-height: ${FontSize.MED10};
  text-align: center;
  margin: 15px;
  padding: 20px;
  min-width: 125px;
  border-radius: 30px;
  cursor: pointer;

  &:hover, &:focus {
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_ACTIVE, CPNT, colorProfile)};
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_ACTIVE, CPNT, colorProfile)};
  }

  &:focus {
    outline: 2px solid ${({ colorProfile }) => themeUtil(Prop.BTN_BG_ACTIVE, CPNT, colorProfile)};
    outline-offset: 2px;
  }

  ${({ active, colorProfile }) => ((active) ? `
    color: ${themeUtil(Prop.BTN_TXT_ACTIVE, CPNT, colorProfile)};
    background-color: ${themeUtil(Prop.BTN_BG_ACTIVE, CPNT, colorProfile)};
  ` : `
    color: ${themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
    background-color: ${themeUtil(Prop.BTN_BG, CPNT, colorProfile)};
  `)}
`;

export const ActiveHotspotImageContainer = styled.div``;
