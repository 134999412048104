import PropTypes from 'prop-types';
import React, {
  useEffect, useMemo, useState, useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Components, ViewComponents } from '@powdr/constants';
import { getExternalNodeData } from '@powdr/stores/actions/node-action';
import { aliasContentBlockDataFromViews, componentFactory } from '@powdr/utils';

import { StyledDynamicComponent, DynamicLoader } from './styles';

export const DynamicComponent = ({
  id,
  blockType,
  columnOption,
  componentType,
  itemsPerPage,
  colorProfile,
  externalEndpoint,
}) => {
  const [isExternalDataLoader, setIsExternalDataLoader] = useState(!!(externalEndpoint));
  const dispatch = useDispatch();
  const { node } = useSelector((state) => state.node) || [];

  const getExternalData = useCallback(() => {
    dispatch(getExternalNodeData(externalEndpoint));
    setIsExternalDataLoader(true);
  }, [dispatch, externalEndpoint]);

  useEffect(() => {
    if (node[externalEndpoint]?.items) setIsExternalDataLoader(false);
  }, [node, dispatch, externalEndpoint]);

  // kickoff fetch of external data
  useEffect(() => {
    if (externalEndpoint) getExternalData();
  }, [externalEndpoint, getExternalData]);

  const componentData = useMemo(() => [{
    id,
    blockType,
    columnOption,
    typeName: ViewComponents[componentType],
    itemsPerPage,
    colorProfile,
    relationships: {
      contentBlocks: node?.[externalEndpoint]?.items?.map((cb) => ({
        ...aliasContentBlockDataFromViews(cb, true),
        typeName: Components.CONTENT_BLOCK,
      })) || [],
    },
  }], [
    node, id, blockType,
    columnOption, componentType,
    itemsPerPage, colorProfile, externalEndpoint,
  ]);

  return (
    <StyledDynamicComponent isLoading={isExternalDataLoader}>
      {(isExternalDataLoader) ? <DynamicLoader /> : componentFactory(componentData)}
    </StyledDynamicComponent>
  );
};

DynamicComponent.propTypes = {
  id: PropTypes.string.isRequired,
  blockType: PropTypes.string.isRequired,
  columnOption: PropTypes.number,
  componentType: PropTypes.string.isRequired,
  itemsPerPage: PropTypes.number,
  colorProfile: PropTypes.number,
  externalEndpoint: PropTypes.string.isRequired,
};

DynamicComponent.defaultProps = {
  columnOption: 2,
  itemsPerPage: null,
  colorProfile: null,
};
