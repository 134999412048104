import styled from 'styled-components';

export const StyledUiAccordion = styled.div``;

export const StyledUiAccordionPanel = styled.div`
  position: relative;

  &.collapsed .panel-icon {
    transition: all 0.35s ease-in-out;
    transform: rotate(-90deg);
  }

  .panel-icon {
    transition: all 0.35s ease-in-out;
    transform: rotate(0deg);
  }

  button.panel-header {
    display: flex;
    text-align: left;
    padding: 12px;
  }

  div.panel-body.collapsed  {
    display: none;
  }

  div.panel-body{
    display: block;
  }
`;
