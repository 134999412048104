import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';

import { getImageAltText } from '@powdr/utils';

import { StyledOverlayImage, OverlayLink } from './styles';

export const OverlayImage = ({
  imageData, imageWidth, imageWidthMobile, href, target,
}) => (
  <StyledOverlayImage
    imageWidth={imageWidth}
    imageWidthMobile={imageWidthMobile}
  >
    {(href) && <OverlayLink href={href} target={target} />}
    {/* Use Gatsby Image if available otherwise use a standard img tag */}
    {(imageData?.gatsbyImage)
      && <GatsbyImage alt={getImageAltText(imageData)} image={imageData.gatsbyImage} />}
    {(imageData?.imageSrc && !imageData?.gatsbyImage) && <img alt="" src={imageData.imageSrc} />}
  </StyledOverlayImage>
);

OverlayImage.propTypes = {
  imageData: PropTypes.shape({
    id: PropTypes.string,
    gatsbyImage: PropTypes.shape(),
    filemime: PropTypes.string,
    imageSrc: PropTypes.string,
  }).isRequired,
  imageWidth: PropTypes.number,
  imageWidthMobile: PropTypes.number,
  href: PropTypes.string,
  target: PropTypes.string,
};

OverlayImage.defaultProps = {
  imageWidth: 50,
  imageWidthMobile: 50,
  href: null,
  target: null,
};
