import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { AppContext } from '@powdr/context';

import {
  StyledTvPicker, TvNav, NavItem, Name, Link,
  NavHeader, LogoWrapper,
} from './styles';

export const TvPicker = ({
  tvs,
}) => {
  const {
    svgs,
  } = useContext(AppContext);

  return (
    <StyledTvPicker>
      <LogoWrapper>
        <svgs.SiteLogoTv />
      </LogoWrapper>
      <NavHeader>
        Select the desired TV page link or use the displayed URL if page interaction not available on your display system.
      </NavHeader>
      <TvNav>
        {tvs?.map((tv) => (
          <NavItem>
            <Name>{tv.name}</Name>
            <Link href={tv.link}>{tv.link}</Link>
          </NavItem>
        ))}
      </TvNav>
    </StyledTvPicker>
  );
};

TvPicker.propTypes = {
  tvs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

TvPicker.defaultProps = {};
