import PropTypes from 'prop-types';
import React, { useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  StatusTypes, DisplayBoardStatusMap, DisplayBoardStatusTypes, StatusStates,
} from '@powdr/constants';
import { AppContext } from '@powdr/context';
import { getStatus } from '@powdr/stores/actions/status-action';

import displayBoardMap from './display-board-map';
import { StyledDorDisplayBoard } from './styles';

export const DorDisplayBoard = ({
  board,
}) => {
  const { toggleBodyScroll } = useContext(AppContext);
  const dispatch = useDispatch();
  const useStatusData = useSelector((state) => state.status);
  const { isLoading, error, data } = useStatusData;

  useEffect(() => {
    dispatch(getStatus());
  }, [dispatch]);

  useEffect(() => {
    toggleBodyScroll(true);
    return () => {
      toggleBodyScroll(false);
    };
  }, [toggleBodyScroll]);

  // ERRORS START
  if (error) {
    console.error('DOR Status API Failed in DOR Display Board:', error.message);
  }

  const getItemStatus = (code, keys) => Object.keys(keys)
    .find((icon, index) => ((Object.values(keys)[index]
      .includes(code))
      ? Object.keys(keys)[index]
      : null));

  return (
    (!isLoading && data) && (
    <StyledDorDisplayBoard id="boards" $board={board}>
      <link href="https://fonts.googleapis.com/css?family=Khula:800" rel="stylesheet" />
      <table className="canvas">
        <tbody>
          {Object.values(data).map((tr) => {
            const trailData = {
              trailName: displayBoardMap.find(
                (f) => f.name === tr.properties?.name,
              )?.class,
              status: getItemStatus(tr?.properties?.global_status, DisplayBoardStatusMap),
              race: (tr?.properties?.features?.race === 'true') ? DisplayBoardStatusTypes.RACE : '',
              groomed: (StatusStates.GROOMED.includes(tr?.properties?.groom_status)) ? DisplayBoardStatusTypes.GROOMED : '',
            };

            if (trailData.trailName) console.log(trailData.trailName, tr.properties, trailData);

            return (trailData.trailName) ? (
              <tr>
                <td width="100%" className={`${Object.values(trailData).map((a) => a).join(' ')}`} />
              </tr>
            ) : console.log(tr.properties?.name);
          })}
        </tbody>
      </table>
    </StyledDorDisplayBoard>
    )
  );
};

DorDisplayBoard.propTypes = {
  board: PropTypes.string.isRequired,
};

DorDisplayBoard.defaultProps = {};
