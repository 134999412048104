/* eslint-disable react-hooks/exhaustive-deps */
import {
  useEffect, useRef, useState,
} from 'react';

import { ScrollDirection } from '@powdr/constants';
import { isBuildTime } from '@powdr/utils';

import { useThrottleValue } from './use-throttle-value';

const defaultState = {
  xPosition: 0,
  yPosition: 0,
  isScrolling: false,
  isScrollingX: false,
  isScrollingY: false,
  currentScrollDirectionX: ScrollDirection.NONE,
  currentScrollDirectionY: ScrollDirection.NONE,
  lastScrollDirectionX: ScrollDirection.NONE,
  lastScrollDirectionY: ScrollDirection.NONE,
};

export const useIsUserScrolling = () => {
  if (isBuildTime()) return defaultState;

  const [value, setValue] = useState(defaultState);
  const lastEventRef = useRef();
  const scrollTimerRef = useRef();
  const throttledScrollValue = useThrottleValue(value, 50);

  const clearTimer = () => {
    if (scrollTimerRef.current) {
      clearTimeout(scrollTimerRef.current);
      scrollTimerRef.current = undefined;
    }
  };

  const resetValues = () => {
    // console.log('values reset');
    setValue((prev) => ({
      ...defaultState,
      xPosition: window.scrollX,
      yPosition: window.scrollY,
      lastScrollDirectionX: prev.lastScrollDirectionX,
      lastScrollDirectionY: prev.lastScrollDirectionY,
    }));
  };

  const onTimerEnded = () => {
    clearTimer();
    resetValues();
  };

  const resetTimer = () => {
    clearTimer();
    scrollTimerRef.current = setTimeout(onTimerEnded, 500);
  };

  const listener = () => {
    // console.log('scroll event fired');
    const { scrollX, scrollY } = window;

    // for handling hard refresh where scroll state normally wouldn't be reported to places
    // where this hook is used
    if (!lastEventRef.current && (window.scrollX !== 0 || window.scrollY !== 0)) {
      setValue((prev) => ({
        ...prev,
        xPosition: scrollX,
        yPosition: scrollY,
      }));
    }

    if (lastEventRef.current) {
      const lastEvent = lastEventRef.current;
      const nextIsScrollingX = scrollX !== lastEvent.scrollX && lastEvent.scrollX !== 0;
      const nextIsScrollingY = scrollY !== lastEvent.scrollY && lastEvent.scrollY !== 0;
      const nextIsScrolling = nextIsScrollingX || nextIsScrollingY;

      let nextScrollDirectionX = ScrollDirection.NONE;
      if (nextIsScrollingX) {
        nextScrollDirectionX = scrollX < lastEvent.scrollX
          ? ScrollDirection.X.LEFT
          : ScrollDirection.X.RIGHT;
      }

      let nextScrollDirectionY = ScrollDirection.NONE;
      if (nextIsScrollingY) {
        nextScrollDirectionY = scrollY < lastEvent.scrollY
          ? ScrollDirection.Y.UP
          : ScrollDirection.Y.DOWN;
      }

      setValue((prev) => ({
        xPosition: scrollX,
        yPosition: scrollY,
        isScrolling: nextIsScrolling,
        isScrollingX: nextIsScrollingX,
        isScrollingY: nextIsScrollingY,
        currentScrollDirectionX: nextScrollDirectionX,
        currentScrollDirectionY: nextScrollDirectionY,
        lastScrollDirectionX: (nextScrollDirectionX === ScrollDirection.NONE)
          ? prev.lastScrollDirectionX
          : nextScrollDirectionX,
        lastScrollDirectionY: (nextScrollDirectionY === ScrollDirection.NONE)
          ? prev.lastScrollDirectionY
          : nextScrollDirectionY,
      }));

      if (nextIsScrolling) {
        resetTimer();
      }
    }

    lastEventRef.current = {
      scrollX,
      scrollY,
    };
  };

  useEffect(() => {
    window.addEventListener('scroll', listener);

    return () => {
      window.removeEventListener('scroll', listener);
      clearTimer();
    };
  }, []);

  return throttledScrollValue;
};
