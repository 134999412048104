import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';

import { DateFormats } from '@powdr/constants/constants';
import { numberToDollar } from '@powdr/utils';

import { StyledDay } from './styles';

export const Day = ({
  date, currentPrice, isAvailable, isShowPrice,
}) => (
  <StyledDay>
    <span className="number">
      {format(date, DateFormats.DAY_OF_MONTH)}
    </span>
    { (isAvailable && isShowPrice) && (
      <span className="price">
        {`$${numberToDollar(currentPrice)}`}
      </span>
    )}
  </StyledDay>
);

Day.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  isAvailable: PropTypes.bool,
  isShowPrice: PropTypes.bool,
  currentPrice: PropTypes.number,
};

Day.defaultProps = {
  isAvailable: false,
  isShowPrice: true,
  currentPrice: null,
};
