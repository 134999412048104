// eslint-disable-next-line import/no-unresolved
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useQueryParams, StringParam } from 'use-query-params';

import {
  TabsHeader, TabTitle, TabsWrapper, StyledButton,
} from './styles';

export const Tabs = ({ children, className, queryParamName }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [queryParams, setQueryParams] = useQueryParams({ [queryParamName]: StringParam });

  const handleTabChange = useCallback((index) => {
    setActiveTabIndex(index);
    if (queryParamName) {
      setQueryParams(
        (prevState) => ({ ...prevState, [queryParamName]: children[index].props.label }),
      );
    }
  }, [children, queryParamName, setQueryParams]);

  return (
    <TabsWrapper className={className}>
      <TabsHeader>
        { children.map((tab, index) => {
          const {
            icon, label, iconPosition,
          } = tab.props;
          return (
            <TabTitle
              key={tab.key}
              iconPosition={iconPosition}
              className={classNames(
                {
                  active: queryParams[queryParamName]
                    ? queryParams[queryParamName] === label
                    : activeTabIndex === index,
                },
              )}
            >
              <StyledButton type="button tab-button" onClick={() => handleTabChange(index)}>
                {icon && icon}
                {label}
              </StyledButton>
            </TabTitle>
          );
        })}
      </TabsHeader>
      { queryParams[queryParamName]
        ? [children.find((tab) => tab.props.label === queryParams[queryParamName])]
        : children[activeTabIndex]}
    </TabsWrapper>
  );
};

Tabs.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  queryParamName: PropTypes.string,
};

Tabs.defaultProps = {
  className: null,
  queryParamName: null,
};

export const Tab = styled.div.attrs({ className: 'tab' })`
  overflow: hidden;
`;

Tab.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.node,
  iconPosition: PropTypes.string,
};

Tab.defaultProps = {
  icon: undefined,
  iconPosition: 'left',
};
