/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';

import {
  Components, FontFamily, FontSize, ThemeProps as Prop,
} from '@powdr/constants';
import { getFontStyles, rgbaTransparencyToHex, themeUtil } from '@powdr/utils';

const CPNT = Components.CHECKBOX;

const chipShared = css`
  label {
    ${({ $roundedToggle }) => ($roundedToggle) && 'border-radius: 4px;'};
    padding: 10px 30px 10px 8px;
    height: 40px;
    ${() => getFontStyles(FontFamily.BASE_FONT, FontSize.REG30, undefined, undefined, CPNT, 'chip-label')};

    input[type='checkbox']:not(:checked) {
      display: none;
    }

    input[type='checkbox'],
    input[type='checkbox']:after,
    input[type='checkbox']:before {
      background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};
      border-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};
      left: auto;
      top: auto;
      position: absolute;
      width: 25px;
      height: 20px;
    }

    input[type='checkbox']:checked:after {
      border-color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
    }

    .label {
      padding-left: 25px;
    }
  }
`;

const chipUnchecked = css`
  label {
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile) + rgbaTransparencyToHex(0.25)};
    color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
  }
`;

const chipChecked = css`
  label {
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
    font-weight: bold;
    input[type='checkbox'] {
      margin: 0 2px 0 0
    }
    input[type='checkbox']:checked:after {
      border-width: 3.5px;
      width: 18px;
      height: 10px;
      left: auto;
      top: auto;
    }
  }
`;

const nonChipsShared = css`
  &:hover {
    input[type='checkbox']:before {
      background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_ACTIVE, CPNT, colorProfile)};
    }
  }

  input[type='checkbox']:checked:before {
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_ACTIVE, CPNT, colorProfile)};
  }

  .label {
    line-height: 1.1;
  }
`;

export const StyledCheckbox = styled.div`
  label {
    display: grid;
    line-height: 1.1;
    grid-template-columns: 25px auto;
    align-items: center;
    gap: 0.5em;
    cursor: pointer;
    ${() => getFontStyles(FontFamily.BASE_FONT, FontSize.REG50, undefined, undefined, CPNT, 'label')};
    color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};

    input[type='checkbox'] {
      display: grid;
      cursor: pointer;
      place-content: center;
      appearance: none;
      margin: 0;
      width: 2em;
      height: 2em;
      background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};;
      border: 2px solid ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
      ${({ $roundedToggle }) => ($roundedToggle) && 'border-radius: 4px;'};
    }

    input[type="checkbox"]::before {
      content: "";
      width: 1.25em;
      height: 1.25em;
      transform: scale(0);
      box-shadow: inset 1em 1em ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
      transform-origin: bottom left;
      clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    }

    input[type="checkbox"]:checked::before {
      transform: scale(1);
    }

    input[type="checkbox"]:focus {
      outline: max(2px, 0.15em) solid ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
      outline-offset: max(2px, 0.15em);
    }
  }

  ${({ isChips }) => (isChips ? chipShared : nonChipsShared)}
  ${({ isChips, isChecked }) => (isChips
    ? isChecked
      ? chipChecked
      : chipUnchecked
    : null)}
`;

export const CheckboxContent = styled.div`
  display: flex;
  align-items: center;
`;
