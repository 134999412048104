import PropTypes from 'prop-types';
import React from 'react';

import { Components } from '@powdr/constants';

import { StyledWysiwygStyleWrapper } from './styles';

export const WysiwygStyleWrapper = ({
  className,
  component,
  colorProfile,
  children,
}) => (
  <StyledWysiwygStyleWrapper
    className={className}
    $colorProfile={colorProfile}
    $component={component || Components.WYSIWYG}
  >
    {children}
  </StyledWysiwygStyleWrapper>
);

WysiwygStyleWrapper.propTypes = {
  className: PropTypes.string,
  colorProfile: PropTypes.string,
  component: PropTypes.string,
  children: PropTypes.node.isRequired,
};

WysiwygStyleWrapper.defaultProps = {
  className: '',
  colorProfile: null,
  component: null,
};
