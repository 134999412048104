import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getPlaces } from '@powdr/stores';

export const usePlaceStatus = (place) => {
  if (!place) return null;
  const dispatch = useDispatch();
  const usePlacesData = useSelector((state) => state.places);
  const { data } = usePlacesData;
  const [placeStatus, setPlaceStatus] = useState(null);

  useEffect(() => {
    dispatch(getPlaces());
  }, [dispatch]);

  useEffect(() => {
    if (data && data?.length > 0 && place) {
      setPlaceStatus(data.find((r) => r.id === place)?.status || null);
    }
  }, [data, place]);

  return placeStatus;
};
