import PropTypes from 'prop-types';
import React from 'react';

import { DropdownButton, Checkbox } from '@powdr/components';
import {
  ButtonText, CheckedState, ColorProfiles, FilterDefaultState, Orientations,
} from '@powdr/constants';
import { slugify, textFilter } from '@powdr/utils';

import {
  FiltersWrapper, CheckboxGroupWrapper, CheckboxGroupHeader,
  CheckboxGroup, CheckboxWrapper,
} from './styles';

export const Filters = ({
  filterToggleColorProfile,
  filterDrawerColorProfile,
  filterDefaultState,
  filters,
  handleCheckboxClick,
  urlQueryParams,
  isHorizontalFilterLayout,
  isMobile,
}) => {
  const filterMap = filters.map((grp) => (grp.inputs.length > 0 && (
    <CheckboxGroupWrapper
      key={grp.name}
      $isHorizontalFilterLayout={isHorizontalFilterLayout}
    >
      <CheckboxGroupHeader $colorProfile={filterDrawerColorProfile}>
        {grp.label}
      </CheckboxGroupHeader>
      <CheckboxGroup>
        {grp.inputs.map((field) => (
          <CheckboxWrapper key={`${field.id}`}>
            <Checkbox
              name={field.id}
              label={textFilter(field.label)}
              onChange={handleCheckboxClick}
              checked={(urlQueryParams[grp.name]?.includes(field.id))
                ? CheckedState.CHECKED
                : CheckedState.UNCHECKED}
              colorProfile={filterDrawerColorProfile}
              ariaLabel={`${grp.label}: ${field.id} (space to toggle checkbox)`}
              additionalData={{
                'data-group-id': grp.name,
              }}
            />
          </CheckboxWrapper>
        ))}
      </CheckboxGroup>
    </CheckboxGroupWrapper>
  )));

  return (isMobile) ? (
    <FiltersWrapper
      $colorProfile={filterDrawerColorProfile}
      $orientation={(isHorizontalFilterLayout)
        ? Orientations.HORIZONTAL
        : Orientations.VERTICAL}
    >
      {filterMap}
    </FiltersWrapper>
  ) : (
    <DropdownButton
      closedButtonText={ButtonText.SHOW_FILTERS}
      openButtonText={ButtonText.HIDE_FILTERS}
      openButtonAriaLabel="Hide all search filters and close dropdown menu"
      closedButtonAriaLabel="Open dropdown menu and show all search filters"
      colorProfile={filterToggleColorProfile}
      defaultState={filterDefaultState}
    >
      <FiltersWrapper
        $colorProfile={filterDrawerColorProfile}
        $orientation={(isHorizontalFilterLayout)
          ? Orientations.HORIZONTAL
          : Orientations.VERTICAL}
      >
        {filterMap}
      </FiltersWrapper>
    </DropdownButton>
  );
};

Filters.propTypes = {
  filterToggleColorProfile: PropTypes.string,
  filterDrawerColorProfile: PropTypes.string,
  filterDefaultState: PropTypes.bool,
  filters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleCheckboxClick: PropTypes.func.isRequired,
  urlQueryParams: PropTypes.shape({}).isRequired,
  isHorizontalFilterLayout: PropTypes.bool,
  isMobile: PropTypes.bool,
};

Filters.defaultProps = {
  filterToggleColorProfile: ColorProfiles.BASE,
  filterDrawerColorProfile: ColorProfiles.BASE,
  filterDefaultState: false,
  isHorizontalFilterLayout: false,
  isMobile: false,
};
