import styled from 'styled-components';

import {
  ThemeProps as Prop, Components,
} from '@powdr/constants';
import { themeUtil } from '@powdr/utils';

const CPNT = Components.PAGINATION;

export const StyledPagination = styled.ul`
  display: flex;
  justify-content: center;
  padding: 30px 0;
  list-style-type: none;
`;

export const PaginationItem = styled.li`
  display: flex;
  padding: 0 12px;
  margin: auto 4px;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  box-sizing: border-box;
  text-align: center;
  font-weight: bold;

  &.dots {
    &:hover {
      cursor: default;
      background-color: none;
    }
  }

  &.page-number {
    cursor: pointer;
    color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};

    &:hover {
      color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
      background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
    }

    &.selected {
      cursor: default;
      color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_ACTIVE, CPNT, colorProfile)};
      background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_ACTIVE, CPNT, colorProfile)};
    }
  }

  &.dots, &.page-number {
    height: 32px;
    width: 32px;
    min-width: 32px;
  }

  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const NavigationControl = styled.div`
  svg {
    ${({ direction }) => ((direction === 'left') ? 'transform: rotate(-180deg);' : '')};
  }
`;

export const NavButton = styled.button`
  border: none;
  cursor: pointer;
  display: inline-block;
  position: relative;
  text-align: center;
  width: auto;
  height: auto;
  padding: 16px 20px;
  margin: 0;
  color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};
  background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};

  &:hover {
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
  }
`;
