import styled from 'styled-components';

import { ThemeProps as Prop, Components, StaticProfiles } from '@powdr/constants';
import { componentColor, themeUtil } from '@powdr/utils';

const CPNT = Components.FOOTER_NAV;

export const StyledGlobalFooter = styled.div`
   ul.footer {
    margin-top: 10px;

    li.property-logo {
      display: inline-block;
      vertical-align: middle;
      margin: 0 20px 20px 0;
      padding: 0;

      a {
        display: block;

        &:focus {
          svg {
            outline: 2px solid ${() => componentColor(Prop.LINK_TXT_HOVER, CPNT)};
            outline-offset: 5px;
          }
        }
      }
    }

    .icon-c {
      width: auto;

      svg {
        fill: ${() => themeUtil(Prop.WHITE, CPNT, StaticProfiles.static)};
        color: ${() => themeUtil(Prop.WHITE, CPNT, StaticProfiles.static)};
        width: auto;
        height: 100%;
      }
    }
  }
`;
