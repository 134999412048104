// eslint-disable-next-line import/no-unresolved
import PropTypes from 'prop-types';
import React from 'react';

import { componentFactory } from '@powdr/utils';

import { StyledUiForm } from './styles';

export const UiForm = ({
  relationships,
  parentColorProfile,
}) => (
  <StyledUiForm>
    {(componentFactory(relationships?.formService, parentColorProfile))}
  </StyledUiForm>
);

UiForm.propTypes = {
  relationships: PropTypes.shape().isRequired,
  parentColorProfile: PropTypes.string,
};

UiForm.defaultProps = {
  parentColorProfile: null,
};
