/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Grid } from '@powdr/components';
import {
  ThemeProps as Prop, BlockTypes, AlternatingBlockLayoutOptions,
  FontFamily,
  FontSize,
} from '@powdr/constants';
import {
  themeUtil, getDateInfo, getAlternatingLayoutState, doubleDigitizer,
  getFontStyles,
} from '@powdr/utils';

const GridSection = styled.div`
  scroll-margin-top: 150px;

  hr {
    margin: 25px 0 20px 0;
  }
`;

const GridParent = styled.div`
  padding: 50px 0;
  background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, null, colorProfile)};
`;

const GridTypeHeader = styled.h1`
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.MED30, undefined, undefined)};
  margin-bottom: 15px;
`;

export const BlockListDemo = ({
  data,
}) => {
  const numColumns = (data?.numColumns === '') ? '1' : data.numColumns;
  const numBlocks = (data?.numBlocks === '') ? '2' : data.numBlocks;
  const getTextDecoration = (index) => ((data?.isShowIndexes) ? doubleDigitizer(index) : null);

  const generateContentBlockData = (blockType) => Array.from(
    Array(parseInt(numBlocks, 10)),
  ).map((item, idx) => ({
    ...data,
    id: `demo-block-${blockType}-${idx}`,
    buttonLink: '#',
    altButtonLink: '#',
    staticImageUrl: {
      name: 'demo-image',
      uri: data.image,
    },
    textDecoration: getTextDecoration(idx + 1),
    alternateBlockLayoutState: getAlternatingLayoutState(idx, data.alternateBlockLayout),
    formattedContent: {
      value: data.formattedContent,
    },
    date: ((blockType === BlockTypes.FLAG_V2
      || blockType === BlockTypes.EVENT)
      && !data?.date) ? new Date() : data.date,
  }));

  return (
    Object.values(BlockTypes).map((blockType, idx) => {
      const gridData = {
        blockType,
        columnOption: parseInt(numColumns, 10),
        alternateBlockLayout: data?.alternateBlockLayout || null,
        isCenterAlignUnevenRows: data?.isCenterAlignUnevenRows || false,
        paddingAmount: data?.paddingAmount || null,
        paddingOff: data?.paddingOff || false,
        isShowIndexes: data?.isShowIndexes || false,
        relationships: {
          contentBlocks: generateContentBlockData(blockType),
        },
      };

      return (
        <GridSection key={blockType} id={blockType}>
          <GridTypeHeader>{`${blockType}`}</GridTypeHeader>
          <GridParent colorProfile={data.parentColorProfile}>
            <Grid
              {...gridData}
              parentColorProfile={data.parentColorProfile}
              colorProfile={parseInt(data.colorProfile, 10)}
            />
          </GridParent>
          {(idx !== Object.values(blockType).length - 1) && <hr />}
        </GridSection>
      );
    })
  );
};

BlockListDemo.propTypes = {

};

BlockListDemo.defaultProps = {

};
