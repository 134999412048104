import styled, { css } from 'styled-components';

import { ThemeProps as Prop, Breakpoints } from '@powdr/constants';
import { themeUtil } from '@powdr/utils';

export const StyledPriceRange = styled.p`
  .fade {
    opacity: 0.5;
  }
`;
