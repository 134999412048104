import { FetchStatus } from '@powdr/constants';

import { DRUPAL_PENDING, DRUPAL_SUCCESS, DRUPAL_ERROR } from '../types';

const initialState = {
  drupal: {},
  status: FetchStatus.IDLE,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DRUPAL_PENDING:
      return {
        ...state,
        status: FetchStatus.LOADING,
      };
    case DRUPAL_SUCCESS:
      // TODO: modify structure of the payload below to make ideal for frontend
      return {
        ...state,
        drupal: {
          ...state.drupal,
          ...action.payload,
        },
        status: FetchStatus.SUCCESS,
      };
    case DRUPAL_ERROR:
      return {
        status: FetchStatus.ERROR,
        error: action.payload,
      };
    default: return state;
  }
};
