import PropTypes from 'prop-types';
import React from 'react';

import {
  StyledFeatureItem, ItemAlignmentWrapper, ItemIcon, ItemText,
} from './styles';

export const FeatureItem = ({
  icon,
  iconColor,
  text,
}) => (
  <StyledFeatureItem>
    <ItemAlignmentWrapper>
      <ItemIcon name={icon} iconColor={iconColor} width="20" height="20" />
      <ItemText>{text}</ItemText>
    </ItemAlignmentWrapper>
  </StyledFeatureItem>
);

FeatureItem.propTypes = {
  icon: PropTypes.string.isRequired,
  iconColor: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};
