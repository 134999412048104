import PropTypes from 'prop-types';
import React from 'react';

import { StyledDorWebcams, WebcamContainer, WebcamLabel } from './styles';

export const DorWebcams = ({
  relationships,
}) => {
  const { webcams } = relationships;

  return (
    <StyledDorWebcams $numCams={webcams?.length}>
      {webcams.map((cam) => (
        <WebcamContainer key={cam.id} href={cam.webcamLink}>
          <img src={cam.webcamImageSrc} alt={cam.webcamLabel || ''} />
          <WebcamLabel>{cam.webcamLabel}</WebcamLabel>
        </WebcamContainer>
      ))}
    </StyledDorWebcams>
  );
};

DorWebcams.propTypes = {
  relationships: PropTypes.shape({
    webcams: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
};

DorWebcams.defaultProps = {};
