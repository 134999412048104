import axios from 'axios';

import { LOGGING_PENDING, LOGGING_SUCCESS, LOGGING_ERROR } from '../types';

export const logging = (log, customerGuid) => async (dispatch) => {
  dispatch({
    type: LOGGING_PENDING,
    payload: [],
  });

  try {
    const res = await axios.get(`/api/logging?log=${log}&customerGuid=${customerGuid}`);
    if (res.status === 200) {
      dispatch({
        type: LOGGING_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: LOGGING_ERROR,
      payload: error,
    });
  }
};
