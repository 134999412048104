import styled from 'styled-components';

import { ThemeProps as Prop, Components, Orientations } from '@powdr/constants';
import { themeUtil } from '@powdr/utils';

const CPNT = Components.PRODUCT_FINDER;

export const FiltersWrapper = styled.div`
  display: flex;
  background-color: ${({ $colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, $colorProfile)};
  width: 100%;
  ${({ $orientation }) => (($orientation === Orientations.HORIZONTAL) ? `
    padding: 25px 25px 25px 90px;
    gap: 20%;
    flex-direction: row;
    justify-content: flex-start;

  ` : `
    padding: 25px;
    flex-direction: column;
    justify-content: space-between;
  `)};
`;

export const CheckboxGroupWrapper = styled.div`
  ${({ $isHorizontalFilterLayout }) => (!$isHorizontalFilterLayout) && '&:not(:last-child) { margin-bottom: 50px; }'};
`;

export const CheckboxGroupHeader = styled.h2`
  color: ${({ $colorProfile }) => themeUtil(Prop.HEADER, CPNT, $colorProfile)};
  line-height: normal;
  margin-bottom: 12px;
`;

export const CheckboxGroup = styled.ul``;

export const CheckboxWrapper = styled.li`
  margin-bottom: 12px;
`;
