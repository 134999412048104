import { FetchStatus } from '@powdr/constants';

import { LOGGING_PENDING, LOGGING_SUCCESS, LOGGING_ERROR } from '../types';

const initialState = {
  logs: {},
  status: FetchStatus.IDLE,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGGING_PENDING:
      return {
        ...state,
        status: FetchStatus.LOADING,
      };
    case LOGGING_SUCCESS:
      return {
        ...state,
        node: {
          ...state.node,
          ...action.payload,
        },
        status: FetchStatus.SUCCESS,
      };
    case LOGGING_ERROR:
      return {
        status: FetchStatus.ERROR,
        error: action.payload,
      };
    default: return state;
  }
};
