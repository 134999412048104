import styled, { css } from 'styled-components';

import { ThemeProps as Prop, Breakpoints } from '@powdr/constants';
import { themeUtil } from '@powdr/utils';

export const StyledColumnGroup = styled.div`
  ${Breakpoints.mobile(css`
    background-color: ${() => themeUtil(Prop.BACKGROUND, '')};
  `)}
`;
