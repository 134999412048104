import styled from 'styled-components';

import { Components } from '@powdr/constants';

const CPNT = Components.SOCIAL_SHARE_BUTTONS;

export const ShareButtonWrapper = styled.ul`
  .social-button {
    width: 25px;
    height: 25px;
  }
`;
