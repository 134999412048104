import styled from 'styled-components';

import {
  Components, ThemeProps as Prop, FontFamily, FontSize, TextTransformOptions,
} from '@powdr/constants';
import { getFontStyles, themeUtil } from '@powdr/utils';

const CPNT = Components.CALENDAR_BADGE;

export const StyledCalendarBadge = styled.div``;

export const DateDayMonthWrapper = styled.div`
  display: inline-block;
  text-align: center;
  margin-bottom: 10px;
  min-width: 75px;
  max-width: 100%;
  width: 100%;
  background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};
  border: 1px solid ${({ colorProfile }) => themeUtil(Prop.BORDER, CPNT, colorProfile)};
`;

export const DayOfWeekText = styled.div`
  display: block;
  padding: 5px;
  ${() => getFontStyles(FontFamily.BASE_FONT, FontSize.REG10, undefined, TextTransformOptions.UPPERCASE, CPNT, 'week-text')};
  color: ${({ colorProfile }) => themeUtil(Prop.ELEMENT_TXT, CPNT, colorProfile)};
  background-color: ${({ colorProfile }) => themeUtil(Prop.ELEMENT, CPNT, colorProfile)};
`;

export const DateMonthWrapper = styled.div`
  background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};
  padding: 5px;
  display: block;
  line-height: 1;
`;

export const DayOfMonthText = styled.div`
  color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
  ${() => getFontStyles(FontFamily.BASE_FONT, FontSize.LRG20, undefined, undefined, CPNT, 'day-text')};
  font-weight: bold;
`;

export const Month = styled.div`
  color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};
  ${() => getFontStyles(FontFamily.BASE_FONT, FontSize.REG10, undefined, TextTransformOptions.UPPERCASE, CPNT, 'month-text')};
`;
