import { FetchStatus } from '@powdr/constants';

import {
  VIMEO_PENDING, VIMEO_SUCCESS, VIMEO_ERROR,
  SVG_PENDING, SVG_SUCCESS, SVG_ERROR,
} from '../types';

const initialState = {
  media: {},
  status: FetchStatus.IDLE,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SVG_PENDING:
      return {
        ...state,
        status: FetchStatus.LOADING,
      };
    case SVG_ERROR:
      return {
        status: FetchStatus.ERROR,
        error: action.payload,
      };
    case SVG_SUCCESS:
      return {
        ...state,
        media: {
          ...state.media,
          ...action.payload,
        },
        status: FetchStatus.SUCCESS,
      };

    case VIMEO_PENDING:
      return {
        ...state,
        status: FetchStatus.LOADING,
      };
    case VIMEO_SUCCESS:
      return {
        ...state,
        media: {
          ...state.media,
          ...action.payload,
        },
        status: FetchStatus.SUCCESS,
      };

    case VIMEO_ERROR:
      return {
        status: FetchStatus.ERROR,
        error: action.payload,
      };

    default: return state;
  }
};
