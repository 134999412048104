/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import React from 'react';

import { UiHtml } from '@powdr/components';

import {
  StyledLinkList, LinkTable, TableBody,
  TableRow, TableCell, CellLinkWrapper, CellLink, CellIcon,
} from './styles';

export const LinkList = ({
  className,
  html,
  relationships,
  parentColorProfile,
}) => {
  const { links } = relationships;

  if (links?.length === 0 && html?.value === null) return null;

  return (
    <StyledLinkList className={className}>
      {(html?.value) && <UiHtml html={html} parentColorProfile={parentColorProfile} />}
      <LinkTable>
        <TableBody>
          {links?.map((link, idx) => (
            <TableRow key={`row-${idx}`} colorProfile={parentColorProfile}>
              <TableCell>
                <CellLinkWrapper colorProfile={parentColorProfile}>
                  <CellLink
                    title={link.buttonText}
                    href={link.buttonLink}
                    target={link.buttonTarget}
                    colorProfile={parentColorProfile}
                  >
                    {link.buttonText}
                  </CellLink>
                  <CellIcon name="content-expand" height="8" colorProfile={parentColorProfile} />
                </CellLinkWrapper>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </LinkTable>
    </StyledLinkList>
  );
};

LinkList.propTypes = {
  className: PropTypes.string,
  html: PropTypes.shape({
    value: PropTypes.string,
  }),
  relationships: PropTypes.shape({
    links: PropTypes.arrayOf({}),
  }).isRequired,
  parentColorProfile: PropTypes.string,
};

LinkList.defaultProps = {
  className: '',
  html: {
    value: null,
  },
  parentColorProfile: null,
};
