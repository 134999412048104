/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

import { JumpToButtons } from '@powdr/components/demos';
import {
  ThemeProps as Prop, ColorProfiles, BlockTypes, FormElementTypes,
  FontFamily,
  FontSize,
} from '@powdr/constants';
import { getFontStyles, themeUtil } from '@powdr/utils';

import { BlockListDemo } from './block-list';
import { demoInputGroups, demoStateIDs, demoGroupKeys } from './content-blocks-data';

const DemoControlInputsWrapper = styled.div`
  border: 5px solid ${() => themeUtil(Prop.BORDER, null, ColorProfiles.BASE)};
  padding: 15px;

  hr {
    margin: 35px 0 30px 0;
  }
`;

const SharedButtonStyles = css`
  ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.REG30, undefined, undefined)};
  text-transform: uppercase;
  color: ${() => themeUtil(Prop.BTN_TXT, null, ColorProfiles.BASE)};
  background-color: ${() => themeUtil(Prop.BTN_BG, null, ColorProfiles.BASE)};
  padding: 16px 20px;
  transition: all .15s ease-in-out;
  cursor: pointer;

  &:hover {
    color: ${() => themeUtil(Prop.BTN_TXT_HOVER, null, ColorProfiles.BASE)};
    background-color: ${() => themeUtil(Prop.BTN_BG_HOVER, null, ColorProfiles.BASE)};
  }
`;

const InputGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
`;

const SharedInputStyles = css`
  display: block;
  margin-bottom: 15px;
  width: 100%;
  height: auto;
  padding: 10px 15px;
  ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.REG50, undefined, undefined)};
  line-height: 1.25;
  color: ${() => themeUtil(Prop.CONTENT, null, ColorProfiles.BASE)};
  background-color: white;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.15);
`;

const InputWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  width: ${({ width }) => ((width) || 'auto')};
  min-width: 22%;

  select, input, textarea {
    ${SharedInputStyles}
  }
`;

const GroupHeader = styled.h2`
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.MED30, undefined, undefined)};
  margin-bottom: 15px;
  text-align: center;
`;

const GroupSubheader = styled.p`
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.REG50, undefined, undefined)};
  margin-bottom: 15px;
  text-align: center;
`;

const SelectorLabel = styled.h3`
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.REG50, undefined, undefined)};
  margin-bottom: 10px;
`;

const InputDesc = styled.p`
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.REG20, undefined, undefined)};
  margin-bottom: 10px;
`;

const ResetButton = styled.button`
  ${SharedButtonStyles}
  width: 100%;
`;

const ActiveDemoTypeWrapper = styled.div`
  margin: 25px;
`;

export const ContentBlocksDemo = () => {
  const getDefaults = (arr) => arr.reduce((obj, item) => (obj[item.id] = item.default, obj), {});

  const [inputStates, setInputStates] = useState(
    {
      ...Object.values(demoInputGroups)
        .map((group) => ((group?.inputs) ? getDefaults(group.inputs) : null))
        .filter((x) => x)
        .reduce(((r, c) => Object.assign(r, c)), {}),
    },
  );

  const updateInputStates = (event, inputName) => {
    setInputStates({ ...inputStates, [inputName]: event.target.value });
  };

  const resetState = (type) => {
    setInputStates({ ...inputStates, ...getDefaults(type) });
  };

  const createFormInputs = (inputs) => inputs.map((input) => (
    <InputWrapper key={input.id} width={input?.width}>
      {(input?.label) && <SelectorLabel>{input.label}</SelectorLabel>}
      {(input?.subheader) && <InputDesc>{input.subheader}</InputDesc>}
      <input.input
        type={input?.type || undefined}
        min={input?.min || undefined}
        max={input?.max || undefined}
        row={(input.input === FormElementTypes.TEXTAREA) ? (input?.row || 3) : undefined}
        value={inputStates[input.id]}
        onChange={(event) => updateInputStates(event, input.id)}
      >
        {(input?.options)
          && input.options.map((option) => (
            <option key={option.id} value={option.id}>{option.label}</option>
          ))}
      </input.input>
    </InputWrapper>
  ));

  // handle placeholder image generation
  useEffect(() => {
    if (inputStates[demoStateIDs.placeholderHeight]) {
      setInputStates({
        ...inputStates,
        image: `https://placeholder.co/${inputStates[demoStateIDs.placeholderHeight]}x${
          (inputStates[demoStateIDs.placeholderWidth])
            ? inputStates[demoStateIDs.placeholderWidth]
            : inputStates[demoStateIDs.placeholderHeight]}/E8E8E8/000000`,
      });
    }
  }, [inputStates[demoStateIDs.placeholderHeight], inputStates[demoStateIDs.placeholderWidth]]);

  return (
    <>
      <DemoControlInputsWrapper>
        {Object.entries(demoInputGroups).map(([inputGroupKey, inputGroupData], idx) => (
          <React.Fragment key={inputGroupKey}>
            <GroupHeader>{inputGroupData.header}</GroupHeader>
            <GroupSubheader>{inputGroupData.subheader}</GroupSubheader>
            <InputGroup>
              {(inputGroupData?.inputs) && createFormInputs(inputGroupData.inputs)}
            </InputGroup>
            {(inputGroupData.resetButtonText) && (
              <ResetButton onClick={() => resetState(inputGroupData.inputs)}>
                {inputGroupData.resetButtonText}
              </ResetButton>
            )}
            {(inputGroupKey === demoGroupKeys.jumpTo) && (
              <JumpToButtons items={Object.values(BlockTypes)} />
            )}
            {(idx !== Object.keys(demoInputGroups).length - 1) && <hr />}
          </React.Fragment>
        ))}
      </DemoControlInputsWrapper>
      <ActiveDemoTypeWrapper>
        <BlockListDemo data={inputStates} />
      </ActiveDemoTypeWrapper>
    </>
  );
};

ContentBlocksDemo.propTypes = {};

ContentBlocksDemo.defaultProps = {};
