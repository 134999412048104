import styled from 'styled-components';

export const StyledDorWeather = styled.div`
  display: grid;
`;

export const StaticHeader = styled.div``;

export const ForecastList = styled.ul`
  display: flex;
  overflow-x: auto;
  gap: 75px;
`;

export const ForecastListItem = styled.li``;

export const OpenWeatherAttribution = styled.a`
  display: flex;
  gap: 5px;
  align-items: center;

  img {
    width: 100px;
  }
`;
