import styled, { css } from 'styled-components';

import { Stat } from '@powdr/components';
import {
  ThemeProps as Prop, Components, StaticProfiles, ComponentGroups, Breakpoints,
  AlertType,
  AlertColorMap,
} from '@powdr/constants';
import {
  themeUtil, componentFontSize, getDorComponentColor,
  componentColor,
} from '@powdr/utils';

const CPNT = Components.DOR_ALERT;

export const StyledDorAlert = styled.div`
  margin-bottom: 30px;
`;

export const Title = styled.h3`
  margin-bottom: 5px;
`;

export const Timestamp = styled.p`
  margin-bottom: 15px;
`;

export const IndividualAlertWrapper = styled.div`
  padding: 15px;
  background-color: ${({ $alertType }) => componentColor(
    ($alertType)
      ? AlertColorMap[$alertType][Prop.BACKGROUND]
      : AlertColorMap[AlertType.INFO][Prop.BACKGROUND],
    CPNT,
    Prop.BACKGROUND,
    ($alertType !== AlertType.INFO && $alertType) && StaticProfiles.ALERTS,
  )};
  color: ${({ $alertType }) => componentColor(
    ($alertType)
      ? AlertColorMap[$alertType][Prop.CONTENT]
      : AlertColorMap[AlertType.INFO][Prop.CONTENT],
    CPNT,
    Prop.CONTENT,
    ($alertType !== AlertType.INFO && $alertType) && StaticProfiles.ALERTS,
  )};

  p, li , a:not(.btn) {
    color: ${({ $alertType }) => componentColor(
    ($alertType)
      ? AlertColorMap[$alertType][Prop.CONTENT]
      : AlertColorMap[AlertType.INFO][Prop.CONTENT],
    CPNT,
    Prop.CONTENT,
    ($alertType !== AlertType.INFO && $alertType) && StaticProfiles.ALERTS,
  )};
  }

  a:not(.btn) {
    text-decoration: underline;
  }
`;
