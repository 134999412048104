// eslint-disable-next-line import/no-unresolved
import PropTypes from 'prop-types';
import React from 'react';

import { useStaticData } from '@powdr/web/src/hooks';

import { StyledLoader, LoaderIcon, LoaderMessage } from './styles';

export const Loader = ({
  size,
  iconName,
  loadingMessage,
  className,
  classAnimationEffect,
}) => {
  const { property } = useStaticData();
  // TODO: refactor to where property name is not hard-coded
  const loadingIconName = iconName || (property.includes('woodward') ? 'loader-woodward' : `loader-${property}`);

  return (
    <StyledLoader className={className}>
      <LoaderIcon
        className={classAnimationEffect}
        name={loadingIconName}
        width={size}
        property={property}
      />
      {(loadingMessage) && <LoaderMessage>{loadingMessage}</LoaderMessage>}
    </StyledLoader>
  );
};

Loader.propTypes = {
  size: PropTypes.string,
  loadingMessage: PropTypes.string,
  iconName: PropTypes.string,
  className: PropTypes.string,
  // TODO: Refactor 'classAnimationEffect' to use ENUMS
  // EX: PropTypes.oneOf(['rightTop','rightBottom']),
  classAnimationEffect: PropTypes.string,
};

Loader.defaultProps = {
  size: '30',
  loadingMessage: '',
  iconName: '',
  className: '',
  classAnimationEffect: '',
};
