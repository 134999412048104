import {
  useCallback, useContext, useEffect,
} from 'react';
import { useQueryParam, StringParam } from 'use-query-params';

import { Season, QueryParamNames } from '@powdr/constants';
import { AppContext } from '@powdr/context';
import { useStaticData } from '@powdr/web/src/hooks';

export const useSeason = () => {
  const { uiSeason } = useContext(AppContext);
  const { defaultSeason } = useStaticData();

  const checkSeasonality = useCallback((season) => {
    if (!defaultSeason || !season) return true;
    if (uiSeason) return (season === uiSeason);
    return true;
  }, [defaultSeason, uiSeason]);

  return {
    checkSeasonality,
  };
};

export const useSeasonChange = () => {
  const { handleTheme, setUiSeason, isWoodwardColorTakeover } = useContext(AppContext);
  const [seasonParam, setSeasonParam] = useQueryParam(QueryParamNames.SEASON, StringParam);

  const handleSeasonChange = useCallback((season) => {
    handleTheme(season, isWoodwardColorTakeover);
    setSeasonParam(season === Season.SUMMER ? Season.SUMMER : undefined);
  }, [handleTheme, setSeasonParam, isWoodwardColorTakeover]);

  useEffect(() => {
    setUiSeason(seasonParam === Season.SUMMER ? Season.SUMMER : Season.WINTER);
  }, [seasonParam, setUiSeason]);

  return { handleSeasonChange };
};
