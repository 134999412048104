import PropTypes from 'prop-types';
import React from 'react';

import { StyledButton } from './styles';

export const Button = ({
  children,
  className,
  colorProfile,
  colors,
  disabled,
  icon,
  onClick,
  onMouseDown,
  ariaLabel,
}) => (
  <StyledButton
    className={className}
    $colors={colors}
    $colorProfile={colorProfile}
    disabled={disabled}
    onClick={!disabled && onClick}
    onMouseDown={!disabled && onMouseDown}
    aria-label={ariaLabel}
    name={ariaLabel}
    type="button"
  >
    {icon && icon}
    {children}
  </StyledButton>
);

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  colorProfile: PropTypes.string,
  colors: PropTypes.instanceOf(Object),
  ariaLabel: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  onClick: PropTypes.func,
  onMouseDown: PropTypes.func,
};

Button.defaultProps = {
  children: null,
  className: '',
  colorProfile: null,
  colors: null,
  disabled: false,
  icon: null,
  onClick: () => {},
  onMouseDown: () => {},
};
