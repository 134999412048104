import PropTypes from 'prop-types';
import React from 'react';

import { colorProfileByIndex, getImageAltText } from '@powdr/utils';

import {
  ContentWrapper,
  Description,
  ImageButtonWrapper,
  PromoButton,
  PromoImage,
  Promotion, PromotionList, PromotionListItem, StyledPromotionTray, Title, TrayHeader,
} from './styles';

export const PromotionTray = ({
  className,
  trayHeader,
  minHeight,
  relationships,
}) => {
  const { promotionList, colorProfile } = relationships;
  const promos = promotionList?.relationships?.promotion || [];

  return (
    <StyledPromotionTray
      className={className}
      $colorProfile={colorProfileByIndex(colorProfile)}
      $minHeight={minHeight}
    >
      {(trayHeader) && <TrayHeader>{trayHeader}</TrayHeader>}
      <PromotionList>
        {promos.map((p) => {
          const image = p.relationships?.promotionImage?.relationships?.image || {};

          return (
            <PromotionListItem>
              <Promotion>
                <ImageButtonWrapper>
                  <PromoImage
                    image={image.gatsbyImage}
                    alt={getImageAltText(image)}
                  />
                  <PromoButton href={p.url} target={p.target}>{p.buttonText}</PromoButton>
                </ImageButtonWrapper>
                <ContentWrapper>
                  <Title>{p.title}</Title>
                  <Description>{p.description}</Description>
                </ContentWrapper>
              </Promotion>
            </PromotionListItem>
          );
        })}
      </PromotionList>
    </StyledPromotionTray>
  );
};

PromotionTray.propTypes = {
  className: PropTypes.string,
  trayHeader: PropTypes.string,
  minHeight: PropTypes.number.isRequired,
  relationships: PropTypes.shape({
    colorProfile: PropTypes.string,
    promotionList: PropTypes.shape({
      relationships: PropTypes.shape({
        promotion: PropTypes.arrayOf(PropTypes.shape({})),
      }),
    }),
  }).isRequired,
};

PromotionTray.defaultProps = {
  className: '',
  trayHeader: null,
};
