import PropTypes from 'prop-types';
import React from 'react';

import { MeasurementDisplayTypes, Symbols } from '@powdr/constants';
import { useWindSpeedMeasurement } from '@powdr/hooks';

import { CircularGraph } from './circular-graph';
import {
} from './styles';
import { TextMeasurement } from './text-measurement';

export const WindSpeed = ({
  measurementDisplayType,
  parentColorProfile,
  measurementLabel,
  sensor,
  icon,
}) => {
  const getWindSpeedString = (d) => ((d?.direction !== undefined && d?.low !== undefined && d?.high !== undefined) ? `${d.direction} ${d.low} - ${d.high} mph` : `${Symbols.DOUBLE_DASH}mph`);
  const measurementData = useWindSpeedMeasurement(sensor);

  if (!measurementData) return null;

  return (
    <>
      {(measurementDisplayType === MeasurementDisplayTypes.CIRCULAR_GRAPH) && (
      <CircularGraph
        colorProfile={parentColorProfile}
        label={measurementLabel}
      >
        {getWindSpeedString(measurementData)}
      </CircularGraph>
      )}
      {(measurementDisplayType === MeasurementDisplayTypes.TEXT
        || measurementDisplayType === MeasurementDisplayTypes.TEXT_LARGE) && (
        <TextMeasurement
          measurementDisplayType={measurementDisplayType}
          label={measurementLabel}
          icon={icon}
          colorProfile={parentColorProfile}
          smaller
        >
          {getWindSpeedString(measurementData)}
        </TextMeasurement>
      )}
    </>
  );
};

WindSpeed.propTypes = {
  parentColorProfile: PropTypes.string,
  measurementLabel: PropTypes.string,
  measurementDisplayType: PropTypes.string,
  icon: PropTypes.string,
  sensor: PropTypes.shape({
    value: PropTypes.string,
    uuid: PropTypes.string,
  }),
};

WindSpeed.defaultProps = {
  parentColorProfile: null,
  measurementLabel: null,
  measurementDisplayType: null,
  icon: null,
  sensor: null,
};
