import PropTypes from 'prop-types';
import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';

import { Icon } from '@powdr/components';
import { ColorProfiles, Orientations } from '@powdr/constants';

import {
  StyledDropdownButton, ToggleButton, ToggleText,
  ChildComponentWrapper,
} from './styles';

export const DropdownButton = ({
  className,
  openButtonIcon,
  closedButtonIcon,
  defaultState,
  openButtonText,
  closedButtonText,
  openButtonAriaLabel,
  closedButtonAriaLabel,
  orientation,
  colorProfile,
  children,
}) => {
  const [isOpen, setOpen] = useState(defaultState);

  return (
    <StyledDropdownButton className={className}>
      <ToggleButton
        colorProfile={colorProfile}
        icon={<Icon name={(isOpen) ? openButtonIcon : closedButtonIcon} width={20} />}
        onClick={() => setOpen(!isOpen)}
        onMouseDown={(e) => { e.preventDefault(); e.stopPropagation(); }}
        ariaLabel={(isOpen) ? openButtonAriaLabel : closedButtonAriaLabel}
        $isOpen={isOpen}
      >
        <ToggleText>{(isOpen) ? openButtonText : closedButtonText}</ToggleText>
      </ToggleButton>
      <AnimateHeight duration={500} height={(isOpen) ? 'auto' : 0} style={{ 'overflow-y': 'auto' }}>
        <ChildComponentWrapper $orientation={orientation}>
          {children}
        </ChildComponentWrapper>
      </AnimateHeight>
    </StyledDropdownButton>
  );
};

DropdownButton.propTypes = {
  className: PropTypes.string,
  openButtonIcon: PropTypes.string,
  closedButtonIcon: PropTypes.string,
  defaultState: PropTypes.bool,
  colorProfile: PropTypes.string,
  openButtonText: PropTypes.string.isRequired,
  closedButtonText: PropTypes.string.isRequired,
  openButtonAriaLabel: PropTypes.string.isRequired,
  closedButtonAriaLabel: PropTypes.string.isRequired,
  orientation: PropTypes.oneOf([Orientations.HORIZONTAL, Orientations.VERTICAL]),
  children: PropTypes.node,
};

DropdownButton.defaultProps = {
  className: '',
  openButtonIcon: 'ui-arrow-up',
  closedButtonIcon: 'ui-arrow-up',
  defaultState: false,
  orientation: Orientations.HORIZONTAL,
  colorProfile: ColorProfiles.BASE,
  children: null,
};
