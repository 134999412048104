import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { AppContext } from '@powdr/context';
import { useStaticData } from '@powdr/web/src/hooks';

import {
  StyledPromotionList,
  PromoListItems,
  PromoListItem,
  PromoDisc,
} from './styles';

export const PromotionList = ({
  className,
}) => {
  const { headerConfig } = useStaticData();
  const { shiftPromos } = useContext(AppContext);
  const promotionArr = headerConfig?.promotions?.relationships?.promotion || [];

  return (
    <StyledPromotionList className={className}>
      <PromoListItems $shift={shiftPromos}>
        {promotionArr.map((promo) => (
          <PromoListItem key={`promo-block-${promo.title}`}>
            <PromoDisc
              className="anim-float"
              href={promo.url}
              target={promo.target}
            >
              {promo.title}
            </PromoDisc>
          </PromoListItem>
        ))}
      </PromoListItems>
    </StyledPromotionList>
  );
};

PromotionList.propTypes = {
  className: PropTypes.string,
};

PromotionList.defaultProps = {
  className: '',
};
