/* eslint-disable no-bitwise */
/* eslint-disable no-nested-ternary */
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import { CalendarBadge } from '@powdr/components/calendar-badge';
import { Link } from '@powdr/components/link';
import { UiEmbed } from '@powdr/components/ui-embed';
import {
  AlternatingBlockLayoutOptions, BlockTypes, Breakpoints, ThemeProps as Prop,
} from '@powdr/constants';
import { ContentBlockType } from '@powdr/model';
import { themeUtil } from '@powdr/utils';

// eslint-disable-next-line no-unused-vars
const CPNT = BlockTypes.REVEAL_HORIZONTAL;

// styled components
const ContentBlockRevealHorizontal = styled.div`
  .cb-title {
    margin-bottom: 15px;
  }

  .cb-copy {
    margin-bottom: 15px;
  }

  ${Breakpoints.smaller(css`
    img {
      margin-bottom: 25px;
    }
  `)}
`;

const StyledLink = styled(Link)`
  &:focus {
    outline: 2px solid ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};
    outline-offset: 2px;
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: ${({ alternateBlockLayoutState }) => (
    (alternateBlockLayoutState === AlternatingBlockLayoutOptions.DEFAULT)
      ? 'row-reverse'
      : 'row'
  )};

  ${Breakpoints.mobile(css`
    flex-direction: column-reverse;
  `)}
`;

const ImageColumn = styled.div`
  position: relative;
  ${({ columnSplitSize }) => {
    if (columnSplitSize === '50/50') return 'width: 50%;';
    return 'width: 67%;';
  }}

  ${Breakpoints.mobile(css`
    width: 100%;
  `)}
`;

const ContentColumn = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  ${({ columnSplitSize }) => {
    if (columnSplitSize === '50/50') return 'width: 50%;';
    return 'width: 33%;';
  }}

  ${Breakpoints.mobile(css`
    width: 100%;
  `)}
`;

const StyledContentContainer = styled.div`
  padding: 40px;

  .cta-reveal-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .button {
    display: block;
    width: fit-content;
  }
`;

const Image = styled.img`
  display: block;
  height: 100%;
`;

const StyledGatsbyImage = styled(GatsbyImage)`
  position: initial;
`;

export const RevealHorizontal = ({
  index, title, copy, image, colorProfile, blockType, videoId, ariaLabel,
  columnLayout, revealHorizontalLayout, date, endDate,
  buttonEnabled, buttonLink, buttonTarget, buttonText, buttonClick,
  isStaticImageLink, alternateBlockLayoutState,
}) => {
  const determineLayout = (revealHorizontalLayout === 'alternate') ? (index & 1) ? 'copy-right' : 'copy-left' : `copy-${revealHorizontalLayout}`;

  return (
    <ContentBlockRevealHorizontal className={`content-block-${blockType}`} colorProfile={colorProfile}>
      <ColumnContainer
        colorProfile={colorProfile}
        layout={determineLayout}
        alternateBlockLayoutState={alternateBlockLayoutState}
      >
        <ContentColumn colorProfile={colorProfile} columnSplitSize={columnLayout}>
          <StyledContentContainer>
            {(date) ? (
              <div className="cta-reveal-wrapper">
                <CalendarBadge
                  type={blockType}
                  startDate={date}
                  endDate={endDate}
                  colorProfile={colorProfile}
                />
                <div className="cta-content">
                  <h3 className="cb-title">{title}</h3>
                  {/* TODO: Add tags here if we are using them still */}
                </div>
              </div>
            ) : (
              <h3 className="cb-title">{title}</h3>
            /* TODO: Add tags here if we are using them still */
            )}
            <p className="cb-copy">{copy}</p>
            {(buttonEnabled && buttonText) && (
              <StyledLink
                className="button with-background "
                href={buttonLink}
                target={buttonTarget}
                onClick={() => buttonClick()}
                aria-label={ariaLabel}
                role="button"
              >
                {buttonText}
              </StyledLink>
            )}
          </StyledContentContainer>
        </ContentColumn>
        <ImageColumn colorProfile={colorProfile} columnSplitSize={columnLayout}>
          {(videoId) && <UiEmbed videoId={videoId} />}
          {(image && !isStaticImageLink)
            && <StyledGatsbyImage image={image.src} alt={image?.altText} />}
          {(image && isStaticImageLink)
            && <Image src={image.src} alt={image?.altText} />}
        </ImageColumn>
      </ColumnContainer>
    </ContentBlockRevealHorizontal>
  );
};

RevealHorizontal.propTypes = PropTypes.objectOf(ContentBlockType).isRequired;
