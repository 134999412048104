import classNames from 'classnames';
import {
  addMonths,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  isEqual,
  isSameDay,
  isSameMonth,
  startOfMonth,
  startOfWeek,
  subMonths,
} from 'date-fns';
import PropTypes from 'prop-types';
import React, { useState, useContext } from 'react';

import { DateFormats, DaysOfWeek } from '@powdr/constants/constants';
import { AppContext } from '@powdr/context';

import { Day } from './day';
import {
  ArrowIcon, CalendarCells, CalendarHeader, CalendarIcon, CalendarSubHeader, CalendarWrapper,
  NavButtonContainer,
} from './styles';

export const Calendar = ({
  className, defaultDate, daysContent, handleDayClick, isShowPrice,
}) => {
  const { isMobile } = useContext(AppContext);
  const today = new Date();
  const lastAvailableDate = new Date(daysContent[daysContent.length - 1].date);
  const [selectedDate, setSelectedDate] = useState(defaultDate);
  const [currentDate, setCurrentDate] = useState(defaultDate || new Date());

  const nextMonth = () => {
    setCurrentDate(addMonths(currentDate, 1));
  };
  const prevMonth = () => {
    setCurrentDate(subMonths(currentDate, 1));
  };

  const onDayClick = (day) => {
    setSelectedDate(day.date);
    handleDayClick(day);
  };

  const cells = () => {
    const monthStart = startOfMonth(currentDate);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);
    const daysArr = eachDayOfInterval({
      start: startDate,
      end: endDate,
    });

    // TODO: Optimize by creating mutated daysContent and rip out found dates so less to iterate
    const monthArr = daysArr.reduce((weeks, day, index) => {
      const dayObj = {
        date: day,
        content: daysContent.find(
          (content) => isEqual(
            new Date(content.date).setHours(24, 0, 0, 0),
            new Date(day).setHours(0, 0, 0, 0),
          ),
        ),
      };
      return (index % 7 === 0
        ? weeks.push([dayObj])
        : weeks[weeks.length - 1].push(dayObj)) && weeks;
    }, []);

    return (
      <div className="body">
        { monthArr.map((week) => (
          <div className="row" key={`${week[0].date}_${week[1].date}`}>
            {week.map((day) => (
              <button
                className={classNames(
                  'column',
                  'cell',
                  {
                    disabled: !day?.content?.isAvailable,
                    diffMonth: !isSameMonth(day.date, monthStart),
                    selected: isSameDay(day.date, selectedDate),
                  },
                )}
                key={day.date}
                onClick={() => (
                  day?.content?.isAvailable && isSameMonth(day.date, monthStart)
                    ? onDayClick(day)
                    : false
                )}
                type="button"
              >
                <Day
                  date={day.date}
                  currentPrice={day?.content?.currentPrice}
                  isAvailable={day?.content?.isAvailable}
                  isShowPrice={isShowPrice}
                />
              </button>
            ))}
          </div>
        ))}
      </div>
    );
  };

  return (
    <CalendarWrapper className={className}>
      <CalendarHeader>
        <div className="month-year">
          <CalendarIcon name="content-calendar" width="30" />
          <span>{format(currentDate, DateFormats.MONTH_PLUS_YEAR_LONG)}</span>
        </div>
        <NavButtonContainer>
          <button className={classNames('icon', { disabled: isSameMonth(currentDate, today) })} onClick={(isSameMonth(currentDate, today)) ? null : prevMonth} type="button">
            <ArrowIcon className="prev" name="ui-chevron-down" width={(isMobile) ? '20' : '23'} />
          </button>
          <button className={classNames('icon', { disabled: isSameMonth(currentDate, lastAvailableDate) })} onClick={(isSameMonth(currentDate, lastAvailableDate) ? null : nextMonth)} type="button">
            <ArrowIcon className="next" name="ui-chevron-down" width={(isMobile) ? '20' : '23'} />
          </button>
        </NavButtonContainer>
      </CalendarHeader>
      <CalendarSubHeader>
        <div className="days row">
          {Object.values(DaysOfWeek).map((day) => (
            <div className="column col-center" key={day}>{Array.from(day)[0]}</div>
          ))}
        </div>
      </CalendarSubHeader>
      <CalendarCells>{cells()}</CalendarCells>
    </CalendarWrapper>
  );
};

Calendar.propTypes = {
  className: PropTypes.string,
  defaultDate: PropTypes.instanceOf(Date),
  daysContent: PropTypes.instanceOf(Array),
  handleDayClick: PropTypes.func,
  isShowPrice: PropTypes.bool,
};

Calendar.defaultProps = {
  className: '',
  daysContent: [],
  defaultDate: new Date(),
  handleDayClick: () => {},
  isShowPrice: true,
};
