import {
  FetchStatus, StatusProps,
  StatusStates, StatusTypes,
  StatusTrailSubTypes,
} from '@powdr/constants';
import {
  groupByObjKey, camalize, getNormalizedId,
} from '@powdr/utils';
import {
  setFeature, setStatus, setDifficulty,
  setGroomed, setFeaturedDifficulty,
  setFeatureSizes, setMinRange,
} from '@powdr/utils/dor-util';

import { STATUS_PENDING, STATUS_SUCCESS, STATUS_ERROR } from '../types';

// TODO: Can we call this feed trails
const initialState = {
  data: null,
  status: FetchStatus.IDLE,
  error: null,
};

const setStatusObj = (data, type) => ({
  open: data.filter((s) => [
    ...StatusStates.OPENING,
    ...StatusStates.EXPECTED,
  ].includes(s[type].status?.id))?.length || 0,
  closed: data.filter((s) => StatusStates.CLOSED.includes(s[type].status?.id))?.length || 0,
  total: data.length || 0,
  area: {
    open: 0,
    total: 0,
  },
});

const setDifficultyArr = (data) => data
  .map((item) => item.trail.difficulty)
  .filter((v, i, a) => a
    .findIndex((v2) => (v2.icon === v.icon)) === i)
  .filter((k) => Object.keys(k).length !== 0 || false)
  .sort((a, b) => a.order - b.order);

const setReportObj = (data) => data.map((sector) => {
  const trails = sector.items.find((f) => f.id === StatusTypes.TRAIL) || [];
  const lifts = sector.items.find((f) => f.id === StatusTypes.LIFT) || [];
  const trailObj = setStatusObj(trails?.items || [], StatusTypes.TRAIL);
  const liftObj = setStatusObj(lifts?.items || [], StatusTypes.LIFT);
  const difficultyArr = setDifficultyArr(trails?.items || []);

  return {
    id: sector.id,
    label: sector.label,
    difficulty: difficultyArr,
    report: {
      trails: trailObj || null,
      lifts: liftObj || null,
    },
  };
});

const setSeasonSectorObj = (data, season) => Object
  .entries(groupByObjKey(data
    .filter((s) => s.season === season
      || s.season === StatusProps.SEASON.ALL_SEASONS), StatusProps.SECTOR))
  .map(([key, val]) => ({
    id: camalize(key),
    label: key,
    items: Object
      .entries(groupByObjKey(val, StatusProps.TYPE))
      .map(([k, v]) => ({
        id: camalize(k),
        label: k,
        items: v,
      })),
  }));

const setSeasonSectorAllObj = (data) => Object
  .entries(groupByObjKey(data, StatusProps.SECTOR))
  .map(([key, val]) => ({
    id: camalize(key),
    label: key,
    items: Object
      .entries(groupByObjKey(val, StatusProps.TYPE))
      .map(([k, v]) => ({
        id: camalize(k),
        label: k,
        items: v,
      })),
  }));

const setSeasonTypesObj = (data, season, type) => Object
  .entries(groupByObjKey(data
    .filter((s) => s.season === season
      || s.season === StatusProps.SEASON.ALL_SEASONS), type))
  .map(([key, val]) => ({
    id: key,
    label: key,
    items: val,
  }));

const setSeasonTypesAllObj = (data, type) => Object
  .entries(groupByObjKey(data, type))
  .map(([key, val]) => ({
    id: key,
    label: key,
    items: val,
  }));

// STILL MIGHT USE
const setTypeObj = (data, type) => Object
  .entries(groupByObjKey(data, type))
  .map(([key, val]) => ({
    id: key,
    label: key,
    items: val,
  }));

const getLiftTrailsData = (statusData) => {
  const data = statusData
    .filter((f) => f.type !== StatusTypes.POI)
    .map((val) => {
      const {
        id, sector, type,
        subtype, area, properties,
      } = val || {};

      const {
        name: label, global_status: status,
        vertical, season, notes, features, hours,
        subtype: propSubtype, groom_status: groomStatus,
        long_description: expandDetails,
        short_description: description,
      } = properties || {};

      // eslint-disable-next-line no-nested-ternary
      const combineUrls = expandDetails?.image_files && expandDetails?.video_url
        ? [expandDetails?.video_url, ...expandDetails.image_files]
        : (expandDetails?.image_files
          ? expandDetails?.image_files
          : []);

      const slides = combineUrls.map((m) => ({
        url: m,
        type: m.split('.').pop(),
      }));

      const details = expandDetails ? {
        what: expandDetails?.what || null,
        who: expandDetails?.who || null,
        where: expandDetails?.where || null,
        when: expandDetails?.when || null,
        slides,
        cta: {
          label: expandDetails?.cta_copy || null,
          url: expandDetails?.cta_url || null,
        },
      } : null;

      return {
        id,
        sector,
        type,
        area,
        season,
        notes,
        description,
        sectorId: camalize(sector),
        subtype: getNormalizedId(subtype, StatusTrailSubTypes),
        expandDetails: details,
        trail: {
          label: type === StatusTypes.TRAIL ? label : null,
          status: type === StatusTypes.TRAIL ? setStatus(status) : null,
          feature: setFeature(features || {}),
          featureSize: setFeatureSizes(features?.size_rating || null) || null,
          difficulty: setDifficulty(propSubtype) || null,
          featureDifficulty: setFeaturedDifficulty(features, propSubtype),
          groomed: setGroomed(groomStatus),
        },
        lift: {
          label: type === StatusTypes.LIFT ? label : null,
          status: type === StatusTypes.LIFT ? setStatus(status, 'lift') : null,
          hours: hours || null,
          type: propSubtype,
          vertical: vertical || null,
          waitTime: (val?.waitTime?.waitTimeSec) ? setMinRange(val?.waitTime?.waitTimeSec) : '-',
        },
      };
    });

  const winter = {
    sectors: setSeasonSectorObj(
      data,
      StatusProps.SEASON.WINTER,
    ),
    types: setSeasonTypesObj(
      data,
      StatusProps.SEASON.WINTER,
      StatusProps.TYPE,
    ),
    subTypes: setSeasonTypesObj(
      data,
      StatusProps.SEASON.WINTER,
      StatusProps.SUBTYPE,
    ),
  };

  const summer = {
    sectors: setSeasonSectorObj(
      data,
      StatusProps.SEASON.SUMMER,
    ),
    types: setSeasonTypesObj(
      data,
      StatusProps.SEASON.SUMMER,
      StatusProps.TYPE,
    ),
    subTypes: setSeasonTypesObj(
      data,
      StatusProps.SEASON.SUMMER,
      StatusProps.SUBTYPE,
    ),
  };

  const all = {
    sectors: setSeasonSectorAllObj(data),
    types: setSeasonTypesAllObj(
      data,
      StatusProps.TYPE,
    ),
    subTypes: setSeasonTypesAllObj(
      data,
      StatusProps.SUBTYPE,
    ),
  };

  return {
    sectors: {
      [StatusProps.SEASON.ALL_SEASONS]: all.sectors,
      [StatusProps.SEASON.WINTER]: winter.sectors,
      [StatusProps.SEASON.SUMMER]: summer.sectors,
    },
    types: {
      [StatusProps.SEASON.ALL_SEASONS]: all.types,
      [StatusProps.SEASON.WINTER]: winter.types,
      [StatusProps.SEASON.SUMMER]: summer.types,
    },
    subTypes: {
      [StatusProps.SEASON.ALL_SEASONS]: all.subTypes,
      [StatusProps.SEASON.WINTER]: winter.subTypes,
      [StatusProps.SEASON.SUMMER]: summer.subTypes,
    },
    reports: {
      [StatusProps.SEASON.ALL_SEASONS]: setReportObj(all.sectors),
      [StatusProps.SEASON.WINTER]: setReportObj(winter.sectors),
      [StatusProps.SEASON.SUMMER]: setReportObj(summer.sectors),
    },
  };
};

const reducer = (status) => {
  const dorLiftsTrails = getLiftTrailsData(status) || [];
  return {
    ...status,
    dorLiftsTrails,
    modified: true,
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case STATUS_PENDING:
      return {
        ...state,
        status: FetchStatus.LOADING,
      };
    case STATUS_SUCCESS:
      return {
        ...state,
        data: reducer(action.payload),
        status: FetchStatus.SUCCESS,
      };
    case STATUS_ERROR:
      return {
        status: FetchStatus.ERROR,
        error: action.payload,
      };
    default: return state;
  }
};
