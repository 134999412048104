import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { DorPercentWheel, HtmlContentLoader, WysiwygStyleWrapper } from '@powdr/components';
import {
  ThemeProps as Prop, FontFamily, ParkingStatus, StaticProfiles,
  Components,
  ParkingDisplayTypes,
  FontSize,
} from '@powdr/constants';
import {
  capitalizeAllWords, getFontStyles, themeUtil,
} from '@powdr/utils';

const CPNT = Components.DOR_PARKING;

const WheelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
`;

const ParkingCircleHeader = styled.h3`
  line-height: normal;
  ${() => getFontStyles(FontFamily.PRIMARY_FONT)};
`;

const ParkingCircleText = styled.div`
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.MED20)};
`;

const AdditionalParkingStatus = styled.div`
  line-height: normal;
  ${() => getFontStyles(FontFamily.PRIMARY_FONT)};
`;

const AdditionalParkingStatusIndicator = styled.span`
  color: ${({ $status }) => themeUtil(
    ($status === ParkingStatus.OPEN)
      ? Prop.SUCCESS
      : Prop.DANGER,
    CPNT,
    StaticProfiles.ALERTS,
  )};
`;

const ContentContainer = styled.div``;

export const Status = ({
  className,
  content,
  data,
  displayType,
}) => (
  <>
    <ContentContainer>
      <WysiwygStyleWrapper>
        <HtmlContentLoader html={content} />
      </WysiwygStyleWrapper>
    </ContentContainer>
    {(displayType === ParkingDisplayTypes.DATA_CIRCLE) ? (
      <WheelContainer>
        <DorPercentWheel
          openVal={100}
          totalVal={100}
          colorOverride={(data?.highwayParking === ParkingStatus.OPEN)
            ? themeUtil(Prop.SUCCESS, CPNT, StaticProfiles.ALERTS)
            : themeUtil(Prop.DANGER, CPNT, StaticProfiles.ALERTS)}
        >
          <ParkingCircleText>
            {capitalizeAllWords(data?.highwayParking)}
          </ParkingCircleText>
        </DorPercentWheel>
      </WheelContainer>
    ) : (
      <AdditionalParkingStatus className={className}>
        Highway Parking Status:
        <AdditionalParkingStatusIndicator $status={data?.highwayParking}>
          {` ${capitalizeAllWords(data?.highwayParking)}`}
        </AdditionalParkingStatusIndicator>
      </AdditionalParkingStatus>
    )}

  </>

);

Status.propTypes = {
  className: PropTypes.string,
  content: PropTypes.string,
  data: PropTypes.shape({
    highwayParking: PropTypes.string,
  }),
  displayType: PropTypes.string,
};

Status.defaultProps = {
  className: '',
  content: null,
  data: null,
  displayType: null,
};
