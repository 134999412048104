/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';

import { Breakpoints } from '@powdr/constants';

export const StyledDorMeasurements = styled.div`
  display: grid;

  ${Breakpoints.desktopOnly(css`
    ${({ $columns, $rows }) => `
      grid-template-columns: repeat(${($columns) || 2}, 1fr);
      ${($rows) && `grid-template-rows: repeat(${$rows}, auto);`};
      row-gap: 75px;
      column-gap: 20px;`}
  `)};

  ${Breakpoints.mobile(css`
    grid-template-columns: repeat(${({ $numItems }) => (($numItems < 3) ? $numItems : 3)}, 1fr);
    gap: 25px;
  `)};
`;
