import PropTypes from 'prop-types';
import React, {
  useCallback, useEffect, useContext, useState,
} from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';

import {
  Property,
  SeasonalityPlacement,
  EcommerceCartTypes,
  ButtonText,
  Alignment,
} from '@powdr/constants';
import { CookieNames } from '@powdr/constants/constants';
import { AppContext } from '@powdr/context';
import { getCart, getMiddlewareCookie } from '@powdr/stores/actions/ecommerce-action';
import { isEmpty } from '@powdr/utils';
import { useStaticData } from '@powdr/web/src/hooks';

import {
  Banner, StyledEcommerceBanner, StyledEcommerceBannerDesktop, StyledEcommerceBannerMobile,
  EcommerceCartLoginContainer, SeasonToggleContainer, StyledSeasonToggle,
  LoginContainer, RegisterLink, LoginLinkSeparator, LoginLink, LoggedInLink,
  CartLinkContainer, CartLink,
  LinkGroupsContainer, LinkGroup, LinkSeparator, BannerLink,
  MobileBorderContainer, LinkGroupSeparator,
} from './styles';

export const EcommerceBanner = ({
  className,
  isFromFeedContainer,
}) => {
  const dispatch = useDispatch();
  const { cartItemCount, cartCookieResGet } = useSelector((state) => state.ecommerce);
  const parseCookie = (cookieString) => Object.fromEntries(cookieString.split(',').map((s) => s.split(':')));
  const { property, headerConfig } = useStaticData();

  if (!headerConfig) return null;

  const {
    bannerLinkGroups,
    isUnityEnabled, unitySubdomain, unityCartLink,
    cartSource, isHideCartQuantity, registerLink,
  } = headerConfig;
  const { isMobile } = useContext(AppContext);
  // TODO: convert to enum that looks up cookie name my property name
  const unityCookieName = `${property} Cart`;
  const [cookies] = useCookies([unityCookieName]);
  const unityCookie = cookies[unityCookieName] ? parseCookie(cookies[unityCookieName]) : null;
  const [loggedIn, setLoggedIn] = useState(false);
  const shopUri = `//${unitySubdomain}.${property}.com`;
  const isSeasonToggleEnabled = (
    headerConfig.isSeasonalityEnabled
    && headerConfig.seasonalityPlacement === SeasonalityPlacement.IN_BANNER
    && !isMobile
  );

  const extendBannerHeight = (isMobile && bannerLinkGroups && isUnityEnabled);
  const showLinkGroups = (bannerLinkGroups && !isFromFeedContainer);
  const showMobileBanner = (bannerLinkGroups || isUnityEnabled);

  useEffect(() => {
    if (!isEmpty(unityCookie)) setLoggedIn((unityCookie?.loggedIn.toLowerCase() === 'true'));
  }, [unityCookie]);

  // Use Gatsby middleware to check for cookie to decrease load on AW servers
  useEffect(() => {
    if (cartSource === EcommerceCartTypes.ASPENWARE_ECOMM_CART) {
      dispatch(getMiddlewareCookie());
    }
  }, [cartSource, dispatch]);

  // Only call getCart if user has a Nop Customer cookie
  useEffect(() => {
    if (cartCookieResGet[CookieNames.NOP_COMMERCE]) {
      dispatch(getCart());
    }
  }, [cartCookieResGet, cartSource, dispatch]);

  // TODO: this should be a config variable in the site configuration
  const getLoginUrl = useCallback(() => {
    switch (property) {
      case Property.COPPERCOLORADO:
      case Property.ELDORA:
      case Property.KILLINGTON:
      case Property.WOODWARDPARKCITY:
      case Property.MTBACHELOR:
      case Property.LEECANYONLV:
        return '/login?ReturnUrl=%2Fcustomer%2Finfo#/';
      default:
        return '/login';
    }
  }, [property]);

  const getEcommerceBannerContent = useCallback(() => (
    <>
      {isSeasonToggleEnabled && (
        <SeasonToggleContainer>
          <StyledSeasonToggle />
        </SeasonToggleContainer>
      )}

      {showLinkGroups && (
        <>
          {isSeasonToggleEnabled && <LinkGroupSeparator position={Alignment.LEFT} />}
          <LinkGroupsContainer
            hasLeftSibling={isSeasonToggleEnabled}
            hasRightSibling={isUnityEnabled}
            numColumns={bannerLinkGroups?.length}
          >
            {bannerLinkGroups?.map((group) => (
              <LinkGroup position={group.horizontalPosition}>
                {group.relationships.links.map((link, idx) => (
                  <>
                    <BannerLink
                      href={link.buttonLink}
                      target={link.buttonTarget}
                      hasMultiple={(group.relationships.links.length > 1)}
                    >
                      {link.buttonText}
                    </BannerLink>
                    {(group.relationships.links.length > 1
                    && idx !== group.relationships.links.length - 1)
                    && (<LinkSeparator>|</LinkSeparator>)}
                  </>
                ))}
              </LinkGroup>
            ))}
          </LinkGroupsContainer>
          {isUnityEnabled && <LinkGroupSeparator position={Alignment.RIGHT} />}
        </>
      )}

      {extendBannerHeight && <MobileBorderContainer />}

      {isUnityEnabled && (
        <EcommerceCartLoginContainer isFromFeedContainer={isFromFeedContainer}>
          <LoginContainer>
            {!loggedIn ? (
              <>
                <RegisterLink
                  href={registerLink || `${shopUri}/register`}
                  className="register"
                  ariaLabel="Register User on Ecommerce Site"
                  isFromFeedContainer={isFromFeedContainer}
                >
                  Register
                </RegisterLink>
                <LoginLinkSeparator isFromFeedContainer={isFromFeedContainer}>
                  OR
                </LoginLinkSeparator>
                <LoginLink
                  href={`${shopUri}${getLoginUrl()}`}
                  ariaLabel="Log in to Ecommerce Site"
                  isFromFeedContainer={isFromFeedContainer}
                >
                  Log In
                </LoginLink>
              </>
            ) : (
              <LoggedInLink
                href={`${shopUri}/customer/info`}
                ariaLabel="Account info on Ecommerce Site"
                isFromFeedContainer={isFromFeedContainer}
              >
                {`Welcome, ${unityCookie?.name}`}
              </LoggedInLink>
            )}
          </LoginContainer>
          <CartLinkContainer isFromFeedContainer={isFromFeedContainer}>
            <CartLink
              href={`${shopUri}${unityCartLink}`}
              ariaLabel="Go to Shopping Cart"
              isFromFeedContainer={isFromFeedContainer}
            >
              {isHideCartQuantity && `${ButtonText.MY_CART}`}
              {(cartSource === EcommerceCartTypes.ASPENWARE_ECOMM_CART && !isHideCartQuantity) && `${ButtonText.MY_CART} (${cartItemCount || '0'})`}
              {((cartSource === EcommerceCartTypes.UNITY_COOKIE || !cartSource) && !isHideCartQuantity) && `${ButtonText.MY_CART} (${unityCookie?.count || '0'})`}
            </CartLink>
          </CartLinkContainer>
        </EcommerceCartLoginContainer>
      )}
    </>
  ), [
    cartItemCount,
    cartSource,
    extendBannerHeight,
    getLoginUrl,
    bannerLinkGroups,
    isFromFeedContainer,
    isHideCartQuantity,
    isSeasonToggleEnabled,
    isUnityEnabled,
    loggedIn,
    registerLink,
    shopUri,
    showLinkGroups,
    unityCartLink,
    unityCookie?.count,
    unityCookie?.name,
  ]);

  // TODO: CLS fix, Possibly clean up later w sub-components
  return (
    <StyledEcommerceBanner className={className}>
      <StyledEcommerceBannerDesktop>
        <Banner
          className="ecommerce-banner"
          property={property}
          extendHeight={extendBannerHeight}
          isSeasonToggleEnabled={isSeasonToggleEnabled}
          showLinkGroups={showLinkGroups}
          isUnityEnabled={isUnityEnabled}

        >
          {getEcommerceBannerContent()}

        </Banner>
      </StyledEcommerceBannerDesktop>
      <StyledEcommerceBannerMobile $showMobileBanner={showMobileBanner}>
        <Banner
          className="ecommerce-banner"
          property={property}
          extendHeight={extendBannerHeight}
          isSeasonToggleEnabled={isSeasonToggleEnabled}
          showLinkGroups={showLinkGroups}
          isUnityEnabled={isUnityEnabled}
        >
          {getEcommerceBannerContent()}
        </Banner>
      </StyledEcommerceBannerMobile>
    </StyledEcommerceBanner>
  );
};

EcommerceBanner.propTypes = {
  className: PropTypes.string.isRequired,
  isFromFeedContainer: PropTypes.bool,
};

EcommerceBanner.defaultProps = {
  isFromFeedContainer: false,
};
