import styled, { css } from 'styled-components';

import {
  Components, Breakpoints, ThemeProps as Prop, FontFamily, FontSize,
  Property,
} from '@powdr/constants';
import { componentColor, getFontStyles } from '@powdr/utils';

const CPNT = Components.FOOTER;
const CPNT_COPYRIGHT = Components.COPYRIGHT;

export const StyledFooter = styled.footer``;

export const MainFooterContainer = styled.div`
  background-color: ${() => componentColor(Prop.BACKGROUND, CPNT)};
  position: relative;
  z-index: 9;
  padding: 70px 7.5% 60px;
  width: 100%;

  h3 {
      color: ${() => componentColor(Prop.HEADER, CPNT)};
      ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.REG50, undefined, undefined)};
      margin-bottom: 10px;
    }

    .footer-row {
      margin-bottom: 50px;
      width: 100%;
      display: flex;

      ${Breakpoints.mobile(css`
        flex-direction: column;
      `)}

      .footer-column {
        vertical-align: top;

        &.left {
          min-width: 300px;
          ${Breakpoints.mobile(css`
            margin-bottom: 50px;
          `)}
        }

        &.right {
          flex: auto;
          padding-left: 50px;
          ${Breakpoints.mobile(css`
            padding-left: 0;
          `)}
        }
      }
    }

    // TODO: if we are only going to use the one footer component this could move into the footer-nav/style.js
    .footer-nav {
      margin-bottom: 60px;
      min-height: auto;
      ul.l-group {
        padding: 0 !important;
        li.l-item {
          display: block;
        }
        a.btn {
          line-height: 1.5;
          text-align: left;
          padding: 0;
        }
      }
    }

    .social {
      margin-top: 10px;
      ${Breakpoints.mobile(css`
        text-align: left;
      `)}
    }

    .partners-component {

      ul {
        text-align: left;

        li.l-item {
          padding: 0 25px 20px 0;
        }
      }
    }

    .footer-logo {
      text-align: left;

      a {
        display: block;
        padding-right: 50px;

        ${Breakpoints.mobile(css`
          padding-right: 40%;
        `)}

        &:focus {
          svg {
            outline: 2px solid ${() => componentColor(Prop.LINK_TXT_HOVER, Components.FOOTER_NAV)};
            outline-offset: 5px;
          }
        }

        svg {
          max-width: 150px;
        }
      }
    }

  ${({ $property }) => {
    switch ($property) {
      case Property.SNOWBIRD:
        return `
          background-image: linear-gradient(
            to top right,
            rgba(179,179,179,0.4),
            rgba(179,179,179,0.6),
            rgba(179,179,179,1),
            rgba(179,179,179,1)),
            url('/assets/${$property}/images/footer-background.png');
        `;
      default:
        return '';
    }
  }};

`;

export const FooterCopyright = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  background-color: ${() => componentColor(Prop.BACKGROUND, CPNT_COPYRIGHT)};

  p {
    color: ${() => componentColor(Prop.LINK_TXT, CPNT_COPYRIGHT)};
    ${() => getFontStyles(FontFamily.BASE_FONT, FontSize.REG40, undefined, undefined)};
  }
`;
