// import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';

import { DorLiftTableRender } from './dor-lift-table-render';
import { DorTrailTableRender } from './dor-trail-table-render';

export const DorSectorContentRender = ({
  sector,
  types,
  trailIds,
  expandedRows,
}) => {
  const { id } = sector;

  const trails = types?.trails?.items
    ?.filter((s) => s.sectorId === id) || [];

  const lifts = types?.lifts?.items
    ?.filter((s) => s.sectorId === id) || [];

  return (
    <>
      {lifts.length > 0
        && (
          <DorLiftTableRender
            rows={lifts}
          />
        )}
      {trails.length > 0

        && (
          <DorTrailTableRender
            rows={trails}
            trailIds={trailIds}
            expandedRows={expandedRows}
          />
        )}
    </>
  );
};

// Define prop types for the component
DorSectorContentRender.propTypes = {
  sector: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  types: PropTypes.shape({
    trails: PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          sectorId: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
    lifts: PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          sectorId: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  }).isRequired,
  trailIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  expandedRows: PropTypes.arrayOf(PropTypes.string).isRequired,
};
