import styled, { css } from 'styled-components';

import { Loader } from '@powdr/components';
import { ThemeProps as Prop, Breakpoints } from '@powdr/constants';
import { themeUtil } from '@powdr/utils';

export const StyledDynamicComponent = styled.div`
  ${({ isLoading }) => ((isLoading) ? `
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 15px;
      height: 500px;
    ` : '')}
`;

export const DynamicLoader = styled(Loader)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
