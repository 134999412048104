import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { ColorProfiles, Components, ThemeProps as Prop } from '@powdr/constants';
import { rgbaTransparencyToHex, themeUtil } from '@powdr/utils';

const CPNT = Components.CONTENT_CAROUSEL;

const StyledScrollbar = styled.div`
  width: 100%;
`;

const ScrollbarElement = styled.div`
  --swiper-scrollbar-drag-bg-color: ${({ $colorProfile }) => themeUtil(Prop.BORDER, CPNT, $colorProfile)};
  position: relative;
  height: 8px;
  border-radius: 10px;
  background-color: ${({ $colorProfile }) => themeUtil(Prop.BORDER, CPNT, $colorProfile) + rgbaTransparencyToHex(0.25)};
`;

export const Scrollbar = ({
  colorProfile,
}) => (
  <StyledScrollbar>
    <ScrollbarElement
      className="scrollbar-element"
      $colorProfile={colorProfile}
    />
  </StyledScrollbar>
);

Scrollbar.propTypes = {
  colorProfile: PropTypes.string,
};

Scrollbar.defaultProps = {
  colorProfile: ColorProfiles.BASE,
};
