// eslint-disable-next-line import/no-unresolved
import { useLocation, useMatch } from '@reach/router';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Components, ViewComponents } from '@powdr/constants';
import { AppContext } from '@powdr/context';
import { getViewAll, getViewSingle } from '@powdr/stores/actions/drupal-action';
import { aliasContentBlockDataFromViews, componentFactory } from '@powdr/utils';

import { StyledView } from './styles';

// TODO: refactor view to use dynamic component or possibly refactor
// dynamic component to contain all the functionality that this component
export const View = ({
  id,
  entityView,
  relationships,
  singleItemView,
  parentColorProfile,
}) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const match = useMatch(`${pathname.slice(0, pathname.lastIndexOf('/'))}/:item`);
  const { setSingleItemView } = useContext(AppContext);

  useEffect(() => {
    if (match) {
      setSingleItemView(singleItemView?.viewMachineName || '');
      dispatch(getViewSingle(singleItemView?.viewMachineName, match?.item));
    }
  }, [dispatch, match?.item, singleItemView?.viewMachineName]);

  useEffect(() => {
    // TODO put single item view data in page
    dispatch(getViewAll(entityView.viewMachineName));
  }, [dispatch, entityView.viewMachineName]);

  const { drupal } = useSelector((state) => state.drupal) || [];

  const componentData = useMemo(() => [{
    ...relationships?.dynamic,
    typeName: ViewComponents[relationships.dynamic.componentType],
    relationships: {
      contentBlocks: drupal?.[entityView.viewMachineName]?.map((cb) => ({
        ...aliasContentBlockDataFromViews(cb), typeName: Components.CONTENT_BLOCK, id: `view id ${Math.random()}`,
      })) || [],
    },
  }], [drupal, entityView.viewMachineName, relationships]);

  return (
    <StyledView>
      { componentFactory(componentData, parentColorProfile) }
    </StyledView>
  );
};

View.propTypes = {
  id: PropTypes.string.isRequired,
  entityView: PropTypes.shape({ viewMachineName: PropTypes.string }).isRequired,
  parentColorProfile: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  relationships: PropTypes.shape().isRequired,
  singleItemView: PropTypes.shape({ viewMachineName: PropTypes.string }),
};

View.defaultProps = {
  parentColorProfile: null,
  singleItemView: null,
};
