// eslint-disable-next-line import/no-unresolved
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { isBuildTime } from '@powdr/utils';

import { WebcamFrame } from './styles';

export const Webcam = ({
  id,
  webcamGuid,
}) => {
  if (isBuildTime()) return null;

  useEffect(() => {
    const hdrelayInterval = setInterval(() => {
      if (typeof HDRelay !== 'undefined') {
        // eslint-disable-next-line no-undef
        HDRelay.create({
          target: `webcam_holder_${id}`,
          id: webcamGuid,
        });

        clearInterval(hdrelayInterval);
      }
    }, 100);
  }, []);

  return (
    <>
      <Helmet>
        <script src="//manage.hdrelay.com/js/hdrelay.js" type="text/javascript" async />
      </Helmet>
      <WebcamFrame>
        <div id={`webcam_holder_${id}`} />
      </WebcamFrame>
    </>
  );
};

Webcam.propTypes = {
  id: PropTypes.string.isRequired,
  webcamGuid: PropTypes.string.isRequired,
};

Webcam.defaultProps = {};
