import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { ButtonText } from '@powdr/constants';

export const ShopProgramForm = ({
  reCaptchaPublicKey,
  processForm,
  isUserVerified,
  setUserVerified,
  handleCaptchaExpiry,
  buttonText,
}) => {
  const captchaRef = useRef();

  return (
    <form id="shop-program-form" onSubmit={(e) => processForm(e, 'buk_couTrZxhOMYXA6j3HWLNHKwo', null, captchaRef)}>
      <h3>Shop/Company Name *</h3>
      <input className="form-control" id="shopName" name="shopName" type="text" required="" />

      <h3>Contact Name *</h3>
      <input className="form-control" id="contactName" name="contactName" type="text" required="" />

      <h3>Street Address 1  *</h3>
      <input className="form-control" id="streetAddress1" name="streetAddress1" type="text" required="" />

      <h3>Street Address 2</h3>
      <input className="form-control" id="streetAddress2" name="streetAddress2" type="text" />

      <h3>City *</h3>
      <input className="form-control" id="city" name="city" type="text" required="" />

      <h3>State *</h3>
      <select className="form-control" id="state" name="state" required="">
        <option value="AL">Alabama</option>
        <option value="AK">Alaska</option>
        <option value="AB">Alberta</option>
        <option value="AZ">Arizona</option>
        <option value="AR">Arkansas</option>
        <option value="BC">British Columbia</option>
        <option value="CA">California</option>
        <option value="CO">Colorado</option>
        <option value="CT">Connecticut</option>
        <option value="DE">Delaware</option>
        <option value="DC">District of Columbia</option>
        <option value="FL">Florida</option>
        <option value="GA">Georgia</option>
        <option value="HI">Hawaii</option>
        <option value="ID">Idaho</option>
        <option value="IL">Illinois</option>
        <option value="IN">Indiana</option>
        <option value="IA">Iowa</option>
        <option value="KS">Kansas</option>
        <option value="KY">Kentucky</option>
        <option value="LA">Louisiana</option>
        <option value="ME">Maine</option>
        <option value="MB">Manitoba</option>
        <option value="MD">Maryland</option>
        <option value="MA">Massachusetts</option>
        <option value="MI">Michigan</option>
        <option value="MN">Minnesota</option>
        <option value="MS">Mississippi</option>
        <option value="MO">Missouri</option>
        <option value="MT">Montana</option>
        <option value="NE">Nebraska</option>
        <option value="NV">Nevada</option>
        <option value="NB">New Brunswick</option>
        <option value="NH">New Hampshire</option>
        <option value="NJ">New Jersey</option>
        <option value="NM">New Mexico</option>
        <option value="NY">New York</option>
        <option value="NL">Newfoundland and Labrador</option>
        <option value="NC">North Carolina</option>
        <option value="ND">North Dakota</option>
        <option value="NT">Northwest Territories</option>
        <option value="NS">Nova Scotia</option>
        <option value="NU">Nunavut</option>
        <option value="OH">Ohio</option>
        <option value="OK">Oklahoma</option>
        <option value="ON">Ontario</option>
        <option value="OR">Oregon</option>
        <option value="PA">Pennsylvania</option>
        <option value="PE">Prince Edward Island</option>
        <option value="QC">Quebec</option>
        <option value="RI">Rhode Island</option>
        <option value="SK">Saskatchewan</option>
        <option value="SC">South Carolina</option>
        <option value="SD">South Dakota</option>
        <option value="TN">Tennessee</option>
        <option value="TX">Texas</option>
        <option value="UT">Utah</option>
        <option selected="selected" value="VT">Vermont</option>
        <option value="VA">Virginia</option>
        <option value="WA">Washington</option>
        <option value="WV">West Virginia</option>
        <option value="WI">Wisconsin</option>
        <option value="WY">Wyoming</option>
        <option value="YT">Yukon</option>
      </select>

      <h3>Zip / Postal Code *</h3>
      <input className="form-control" id="zipcode" name="zipCode" type="text" required="" />

      <h3>Phone *</h3>
      <input className="form-control" id="phoneNumber" name="phoneNumber" type="tel" required="" />
      <input className="form-control" id="faxNumber" name="faxNumber" type="hidden" />

      <h3>Email address *</h3>
      <input className="form-control" id="email" name="email" type="email" required="" />

      <h3>Website *</h3>
      <input className="form-control" id="website" name="website" type="text" required="" />

      <h3>Shop Type: [select all that apply]</h3>
      <ul className="l-group">
        <li className="l-item">
          <input className="checkbox" id="shopTypeSki" name="ski" type="checkbox" />
          <label htmlFor="shopTypeSki">Ski</label>
        </li>
        <li className="l-item">
          <input className="checkbox" id="shopTypeSnowboard" name="snowboard" type="checkbox" />
          <label htmlFor="shopTypeSnowboard">Snowboard</label>
        </li>
        <li className="l-item">
          <input className="checkbox" id="shopTypeBike" name="bike" type="checkbox" />
          <label htmlFor="shopTypeBike">Mountain Bike</label>
        </li>
      </ul>

      <h3> Desired Season: [select all that apply]</h3>
      <ul className="l-group">
        <li className="l-item">
          <input className="checkbox" id="desiredSeason1" name="killingtonPicoWinter" type="checkbox" />
          <label htmlFor="desiredSeason1">Killington / Pico Winter</label>
        </li>
        <li className="l-item">
          <input className="checkbox" id="desiredSeason3" name="bike" type="checkbox" />
          <label htmlFor="desiredSeason3">Mountain Bike</label>
        </li>
      </ul>

      <h3>Desired Level *</h3>
      <select className="form-control" id="desiredLevel" name="desiredLevel" required="">
        <option selected="selected">- please select -</option>
        <option value="Partnership">Partnership</option>
        <option value="Mountain Bike">Mountain Bike</option>
      </select>

      <h3>Type of Membership</h3>
      <ul className="l-group">
        <li className="l-item">
          <input className="radio" id="membership1" value="renewal" type="radio" name="membership" />
          <label htmlFor="membership1">Renewal</label>
        </li>
        <li className="l-item">
          <input className="radio" id="membership2" value="new" type="radio" name="membership" />
          <label htmlFor="membership2">New</label>
        </li>
      </ul>

      <div>
        <input className="form-control" id="membershipCard1" name="card" type="hidden" />
        <input className="form-control" id="membershipCard2" name="card" type="hidden" />
        <input className="form-control" id="membershipCard3" name="card" type="hidden" />
        <input className="form-control" id="membershipCard4" name="card" type="hidden" />
      </div>

      <p>Bike Shops are eligible for (2) cards per shop location. Cards that are issued are to be kept/tracked by the shop for reactivation each year.</p>

      <h3>Additional Comments</h3>
      <textarea id="additionalComments" name="additionalComments" rows="9" />

      <p>By completing the form above and signing up for the Partner Program, you are authorizing Killington/Pico Ski Resort Partners, LLC. to send marketing collateral as well as email newsletters including information on resort events, news, daily conditions and terrain park information.</p>

      <ReCAPTCHA
        ref={captchaRef}
        className="recaptcha"
        sitekey={reCaptchaPublicKey}
        theme="light"
        size="normal"
        onChange={() => setUserVerified(true)}
        onExpired={() => handleCaptchaExpiry()}
      />

      <button type="submit" value="Submit" disabled={!isUserVerified}>{buttonText || ButtonText.SUBMIT}</button>
    </form>
  );
};

ShopProgramForm.propTypes = {
  reCaptchaPublicKey: PropTypes.string.isRequired,
  processForm: PropTypes.func.isRequired,
  setUserVerified: PropTypes.func.isRequired,
  isUserVerified: PropTypes.bool.isRequired,
  handleCaptchaExpiry: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
};

ShopProgramForm.defaultProps = {
  buttonText: null,
};
