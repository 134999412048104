// eslint-disable-next-line import/no-unresolved
import PropTypes from 'prop-types';
import React from 'react';

import { useStaticData } from '@powdr/web/src/hooks';

import {
  Discount, Icon1, StyledDiscountBanner,
} from './styles';

export const DiscountBanner = ({ className, maxPrice, minPrice }) => {
  if (maxPrice === 0 || !minPrice) return null;

  const { property } = useStaticData();
  const discount = Math.round(100 - ((minPrice / maxPrice) * 100)) || 0;

  if (discount === 0) return null;

  return (
    <StyledDiscountBanner className={className}>
      <Icon1 name={`property-${property}-small`} width="25" />
      <Discount>{`Save up to ${discount}% by buying online today!`}</Discount>
    </StyledDiscountBanner>
  );
};

DiscountBanner.propTypes = {
  className: PropTypes.string,
  maxPrice: PropTypes.number,
  minPrice: PropTypes.number,
};

DiscountBanner.defaultProps = {
  className: '',
  maxPrice: 0,
  minPrice: null,
};
