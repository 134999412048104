import {
  FetchStatus, MountainOverviewGroupsTypes,
  LiftTrailReportsStats, CondGroups,
} from '@powdr/constants';
import { slugify } from '@powdr/utils';

import { CONDITIONS_PENDING, CONDITIONS_SUCCESS, CONDITIONS_ERROR } from '../types';

const PRIMARY = 'Primary';

const initialState = {
  data: null,
  status: FetchStatus.IDLE,
  error: null,
};

const getWidgetConditionsData = (conditions) => {
  const [conditionObj] = conditions.snowReport
    .filter((condition) => condition.measurement_location_name === PRIMARY) || [];
  const [objItem] = conditionObj?.items
    .filter((item) => item.duration === '24 Hours') || [];
  return {
    amount: objItem?.amount || 0,
  };
};

const getDorSnowReportConditionsData = (conditions) => conditions.snowReport
  .filter((condition) => condition.measurement_location_name === PRIMARY)[0].items || [];

const getDorSnowTotalsConditionsData = (conditions) => {
  const s = conditions.snowReport;
  return s;
};

const getDorTrailReportConditionsData = (conditions) => {
  const updates = conditions.mountainOverview.groups
    .filter((f) => f.name === CondGroups.TRAIL_REPORT)
    .map((m) => m.updates)
    .flat();

  const trailReports = conditions.globalTrailReport;
  const { liftReport } = conditions;
  const reports = [];

  trailReports.liftReport = liftReport;

  const stats = LiftTrailReportsStats.map((s) => {
    const k = Object.keys(trailReports).find((f) => {
      const fs = Array.isArray(s.data)
        ? s.data.find((sdf) => sdf === f)
        : s.data;
      return f === fs;
    });

    const t = s?.type || null;
    const o = t ? trailReports[k][t] : trailReports[k];

    return {
      key: k || null,
      label: s?.label || null,
      alias: Array.isArray(s.alias) ? (s.alias || []) : [s.alias],
      open: Math.round(o?.open) || 0,
      total: Math.round(o?.total) || 0,
    };
  }).filter((f) => f.key != null);

  // De-dups stats + add stats together for LiftTrailReportsStats
  stats.reduce((res, o) => {
    if (!res[o.label]) {
      res[o.label] = {
        key: slugify(o.label),
        alias: o.alias,
        label: o.label,
        open: 0,
        total: 0,
      };
      reports.push(res[o.label]);
    }
    res[o.label].open += o.open;
    res[o.label].total += o.total;
    return res;
  }, {});

  return {
    reports,
    updates,
  };
};

const getDorLiftsTrailsData = (conditions) => {
  const trailReports = conditions.globalTrailReport || {};
  const { liftReport } = conditions;
  const lifts = liftReport?.sectors
    .map((s) => ({
      ...s,
      id: slugify(s.name),
      label: s.name,
      type: 'lift',
    })) || [];

  const trails = Object.entries(trailReports)
    .map(([k, v]) => v.sectors
      .map((s) => ({
        ...s,
        id: slugify(s.name),
        label: s.name,
        type: k,
      }))).flat();

  return {
    lift: lifts,
    trail: trails,
  };
};

const getDorMountainOverviewGroupsData = (conditions) => conditions.mountainOverview?.groups
  .filter((objName) => objName.name === MountainOverviewGroupsTypes.TRAIL_REPORT)
  .map((objUpdate) => objUpdate.updates) || [];

const reducer = (conditions) => {
  const property = process.env.GATSBY_PROPERTY_NAME;
  const widgetWeather = getWidgetConditionsData(conditions) || {};
  const dorSnowReport = getDorSnowReportConditionsData(conditions) || {};
  const dorSnowTotals = getDorSnowTotalsConditionsData(conditions) || {};
  const dorTrailReport = getDorTrailReportConditionsData(conditions) || {};
  const dorLiftsTrails = getDorLiftsTrailsData(conditions) || {};
  const dorMountainOverviewGroups = getDorMountainOverviewGroupsData(conditions) || {};
  const dorAlert = conditions?.mountainOverview?.alerts || [];

  return {
    ...conditions,
    widgetWeather,
    dorSnowReport,
    dorTrailReport,
    dorLiftsTrails,
    dorSnowTotals,
    dorMountainOverviewGroups,
    dorAlert,
    modified: true,
    property,
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CONDITIONS_PENDING:
      return {
        ...state,
        status: FetchStatus.LOADING,
      };
    case CONDITIONS_SUCCESS:
      return {
        ...state,
        data: reducer(action.payload),
        status: FetchStatus.SUCCESS,
      };
    case CONDITIONS_ERROR:
      return {
        status: FetchStatus.ERROR,
        error: action.payload,
      };
    default: return state;
  }
};
