import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { BlockTypes } from '@powdr/constants';
import { ContentBlockType } from '@powdr/model';

// eslint-disable-next-line no-unused-vars
const CPNT = BlockTypes.PLAIN;

// styled components
const ContentBlockPlain = styled.div`
  .cb-title {
    margin-bottom: 15px;
  }
`;

const StyledImage = styled(GatsbyImage)`
  margin-bottom: 15px;
`;

const Image = styled.img``;

export const Plain = ({
  title, copy, blockType, colorProfile, image, isStaticImageLink,
}) => (
  <ContentBlockPlain colorProfile={colorProfile} className={`content-block-${blockType}`}>
    {(image && !isStaticImageLink) && <StyledImage image={image.src} alt={image?.altText} />}
    {(image && isStaticImageLink) && <Image src={image.src} alt={image?.altText} />}
    <h3 className="cb-title">{title}</h3>
    <p className="cb-copy">{copy}</p>
  </ContentBlockPlain>
);

Plain.propTypes = PropTypes.objectOf(ContentBlockType).isRequired;
