import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

import { useIsInView } from '@powdr/hooks';

import { AlignmentContainer, StyledSnapwidget } from './styles';

export const Snapwidget = ({
  id,
  snapwidgetID,
}) => {
  const [wasViewed, setWasViewed] = useState(false);
  const componentRef = useRef();
  const containerIsVisible = useIsInView(componentRef);

  // TODO: we might be able to make this a feature of ui-iframe in general
  return (
    <AlignmentContainer ref={componentRef}>
      {(containerIsVisible && !wasViewed) && setWasViewed(true)}
      {(wasViewed) && (
        <StyledSnapwidget
          title={`snapwidget-${id}`}
          allowtransparency="true"
          className="snapwidget-widget"
          frameBorder="0"
          scrolling="no"
          src={`https://snapwidget.com/embed/${snapwidgetID}`}
        />
      )}
    </AlignmentContainer>
  );
};

Snapwidget.propTypes = {
  id: PropTypes.string.isRequired,
  snapwidgetID: PropTypes.string.isRequired,
};
