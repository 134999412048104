import styled, { css } from 'styled-components';

import {
  ThemeProps as Prop, Components,
  Breakpoints,
  GlobalLayoutValues,
} from '@powdr/constants';
import { componentColor } from '@powdr/utils';

const CPNT = Components.SIDEBAR;

export const StyledSidebar = styled.div`
  position: fixed;
  z-index: 80;
  top: 250px;
  right: 0;
  padding: ${GlobalLayoutValues.DESKTOP.SIDEBAR.PADDING}px 0;

  ${Breakpoints.desktopOnly(css`
    background-color: ${() => componentColor(Prop.BACKGROUND, CPNT, Prop.BTN_BG)};
    width: ${({ $minimize }) => (($minimize) ? 60 : 100)}px;
    transition: width
                ${GlobalLayoutValues.ALL_PLATFORM.WRAPPER.ANIMATION_DURATION}ms
                ${GlobalLayoutValues.ALL_PLATFORM.WRAPPER.ANIMATION_DURATION}ms
                ease;

    .content-wrapper {
      display: block !important;
      opacity: 1;
      transition: opacity
                  ${GlobalLayoutValues.ALL_PLATFORM.WRAPPER.ANIMATION_DURATION}ms
                  ${GlobalLayoutValues.ALL_PLATFORM.WRAPPER.ANIMATION_DURATION}ms
                  ease;

      &.animate-text {
        opacity: 0;
        transition: opacity
                    ${GlobalLayoutValues.ALL_PLATFORM.WRAPPER.ANIMATION_DURATION}ms
                    ${GlobalLayoutValues.ALL_PLATFORM.WRAPPER.ANIMATION_DURATION}ms
                    ease;
      }
    }
  `)}

  ${Breakpoints.smallVertical(css`
    top: 175px;
  `)}

  ${Breakpoints.mobile(css`
    display: flex;
    flex-direction: column;
    width: 100%;
    top: unset;
    bottom: ${({ $minimize }) => (($minimize) ? `-${GlobalLayoutValues.MOBILE.SIDEBAR.HEIGHT}` : 0)}px;
    transition: bottom 250ms ease;
    padding: 0;
  `)}


`;

export const SidebarItems = styled.ul`
  ${Breakpoints.desktopOnly(css`
    display: flex;
    position: relative;
    flex-direction: column;
    gap: ${GlobalLayoutValues.DESKTOP.SIDEBAR.PADDING}px;
  `)}

  ${Breakpoints.mobile(css`
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: ${() => componentColor(Prop.BACKGROUND, CPNT, Prop.BTN_BG)};
    padding: 12px 0;
  `)}
`;

export const SidebarItemWrapper = styled.li`
  flex: 1 1 0;
`;
