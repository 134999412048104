import styled, { css } from 'styled-components';

import { VerticalPosition, Breakpoints } from '@powdr/constants';

export const StyledUiColumn = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: ${({ $columnGap }) => $columnGap}%;
  align-items: ${({ contentPosition }) => {
    switch (contentPosition) {
      case VerticalPosition.TOP: return 'flex-start';
      case VerticalPosition.MIDDLE: return 'center';
      case VerticalPosition.BOTTOM: return 'flex-end';
      default: return 'flex-start';
    }
  }};

  ${Breakpoints.small(css`
    ${({ $isParentNavFeed }) => $isParentNavFeed && 'flex-direction: column; gap: 25px;'};
  `)}

  ${Breakpoints.mobile(css`
    flex-direction: column;
    gap: 25px;
  `)}

  > * {
    position: relative;

    ${Breakpoints.small(css`
      ${({ $isParentNavFeed }) => $isParentNavFeed && 'width: 100% !important;'};
    `)}

    ${Breakpoints.mobile(css`
      width: 100% !important;
    `)}
  }

  > *:nth-child(1) {
    ${({ $columnGap, $firstComponentPercentage }) => `width: calc(${$firstComponentPercentage}% - ${$columnGap / 2}%)`};
  }

  > *:nth-child(2) {
    ${({ $columnGap, $firstComponentPercentage }) => `width: calc(${100 - $firstComponentPercentage}% - ${$columnGap / 2}%)`};
  }

  > .ui-image {
    ${Breakpoints.desktopOnly(css`
      ${({ isAlternatePadding }) => ((isAlternatePadding) ? 'padding: 0; img { margin: 0; }' : '')};
    `)}
  }

  > .ui-html {
    ${Breakpoints.desktopOnly(css`
      ${({ isAlternatePadding }) => ((isAlternatePadding) ? 'padding: 0; padding-left: 100px; padding-right: 100px;' : '')};
    `)}
  }

  > .form-formbucket {
    padding: none;
  }
`;
