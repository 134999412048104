import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getSnowReport } from '@powdr/stores';

export const useSnowReportMeasurements = () => {
  const dispatch = useDispatch();
  const useSnowReportData = useSelector((state) => state.snowReport);
  const { data } = useSnowReportData;
  const [snowReportData, setSnowReportData] = useState(null);

  useEffect(() => {
    dispatch(getSnowReport());
  }, [dispatch]);

  useEffect(() => {
    if (data && data?.measurements) {
      setSnowReportData(data.measurements);
    }
  }, [data]);

  return snowReportData;
};
