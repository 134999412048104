import styled, { css } from 'styled-components';

import { Icon } from '@powdr/components';
import {
  ThemeProps as Prop, Breakpoints, StaticProfiles, FontFamily,
} from '@powdr/constants';
import { getFontStyles, themeUtil } from '@powdr/utils';

export const StyledTvForecast = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-template-rows: min-content;
  gap: 100px;
  width: 100%;
  height: fit-content;
`;

export const TvForecastItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

export const TopSectionWrapper = styled.div``;

export const BottomSectionWrapper = styled.div``;

export const DayTempWrapper = styled.div`
  display: flex;
`;

export const DayText = styled.div`
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, '32px')};
  color: ${() => themeUtil(Prop.CONTENT, null, StaticProfiles.TV)};
  margin-bottom: 10px;
`;

export const TempText = styled.div`
  margin-top: 20px;
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, '112px')};
  color: ${() => themeUtil(Prop.CONTENT, null, StaticProfiles.TV)};
`;

export const ForecastIcon = styled(Icon)`
  height: 275px;
  width: fit-content;

  svg {
    fill: ${() => themeUtil(Prop.CONTENT, null, StaticProfiles.TV)};
    color: ${() => themeUtil(Prop.CONTENT, null, StaticProfiles.TV)};
  }
`;

export const Description = styled.p`
  color: ${() => themeUtil(Prop.CONTENT, null, StaticProfiles.TV)};
  height: fit-content;
  ${({ $longDesc }) => getFontStyles(FontFamily.BASE_FONT, $longDesc ? '32px' : '64px')};
  line-height: 1.2;
`;
