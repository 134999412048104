import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import { Icon } from '@powdr/components';
import {
  Breakpoints, CarouselPaginationType,
  Components, FontFamily, FontSize, ThemeProps as Prop,
  TextTransformOptions,
} from '@powdr/constants';
import {
  componentColor, getFontStyles, getRandomInt, themeUtil,
} from '@powdr/utils';

const CPNT = Components.CONTENT_CAROUSEL;

const StyledBulletPagination = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: ${({ $smallGap }) => (($smallGap) ? 15 : 30)}px;
`;

const SinglePaginationBullet = styled.li`
  cursor: pointer;

  .standard-bullet {
    height: 15px;
    width: 15px;
    background-color: ${({ $colorProfile, $componentTheme }) => (($componentTheme) ? componentColor(Prop.PAGINATION, $componentTheme, Prop.BTN_BG) : themeUtil(Prop.BTN_BG, CPNT, $colorProfile))};
    border-radius: 50%;
    transition: all 0.25s ease-in;
  }

  .icon-bullet svg {
    transition: all 0.25s ease-in;
    fill: ${({ $colorProfile, $componentTheme }) => (($componentTheme) ? componentColor(Prop.PAGINATION, $componentTheme, Prop.BTN_BG) : themeUtil(Prop.BTN_BG, CPNT, $colorProfile))};
    color: ${({ $colorProfile, $componentTheme }) => (($componentTheme) ? componentColor(Prop.PAGINATION, $componentTheme, Prop.BTN_BG) : themeUtil(Prop.BTN_BG, CPNT, $colorProfile))};
  }

  .text-bullet {
    ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.MED50, undefined, TextTransformOptions.UPPERCASE)};
    font-weight: bold;
    color: ${({ $colorProfile, $componentTheme }) => (($componentTheme) ? componentColor(Prop.PAGINATION, $componentTheme, Prop.BTN_BG) : themeUtil(Prop.BTN_BG, CPNT, $colorProfile))};
    line-height: 1.1;
    transition: all 0.25s ease-in;
    white-space: nowrap;

    ${Breakpoints.mobile(css`
      ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.MED20, undefined, TextTransformOptions.UPPERCASE)};
    `)}
  }

  &:focus, &:hover {
    .text-bullet {
      transition: all 0.25s ease-in;
      color: ${({ $colorProfile, $componentTheme }) => (($componentTheme) ? componentColor(Prop.PAGINATION_HOVER, $componentTheme, Prop.BTN_BG_HOVER) : themeUtil(Prop.BTN_BG_HOVER, CPNT, $colorProfile))};
    }

    .icon-bullet svg {
      transition: all 0.25s ease-in;
      fill: ${({ $colorProfile, $componentTheme }) => (($componentTheme) ? componentColor(Prop.PAGINATION_HOVER, $componentTheme, Prop.BTN_BG_HOVER) : themeUtil(Prop.BTN_BG_HOVER, CPNT, $colorProfile))};
      color: ${({ $colorProfile, $componentTheme }) => (($componentTheme) ? componentColor(Prop.PAGINATION_HOVER, $componentTheme, Prop.BTN_BG_HOVER) : themeUtil(Prop.BTN_BG_HOVER, CPNT, $colorProfile))};
    }

    .standard-bullet {
      transition: all 0.25s ease-in;
      background-color: ${({ $colorProfile, $componentTheme }) => (($componentTheme) ? componentColor(Prop.PAGINATION_HOVER, $componentTheme, Prop.BTN_BG_HOVER) : themeUtil(Prop.BTN_BG_HOVER, CPNT, $colorProfile))};
    }
  }

  &.active {
    .text-bullet {
      transition: all 0.25s ease-in;
      ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.LRG20, undefined, TextTransformOptions.UPPERCASE)};
      color: ${({ $colorProfile, $componentTheme }) => (($componentTheme) ? componentColor(Prop.PAGINATION_ACTIVE, $componentTheme, Prop.BTN_BG_ACTIVE) : themeUtil(Prop.BTN_BG_ACTIVE, CPNT, $colorProfile))};

      ${Breakpoints.mobile(css`
        ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.LRG12, undefined, TextTransformOptions.UPPERCASE)};
      `)}
    }

    .icon-bullet svg {
      transition: all 0.25s ease-in;
      fill: ${({ $colorProfile, $componentTheme }) => (($componentTheme) ? componentColor(Prop.PAGINATION_ACTIVE, $componentTheme, Prop.BTN_BG_ACTIVE) : themeUtil(Prop.BTN_BG_ACTIVE, CPNT, $colorProfile))};
      color: ${({ $colorProfile, $componentTheme }) => (($componentTheme) ? componentColor(Prop.PAGINATION_ACTIVE, $componentTheme, Prop.BTN_BG_ACTIVE) : themeUtil(Prop.BTN_BG_ACTIVE, CPNT, $colorProfile))};
    }

    .standard-bullet {
      transition: all 0.25s ease-in;
      background-color: ${({ $colorProfile, $componentTheme }) => (($componentTheme) ? componentColor(Prop.PAGINATION_ACTIVE, $componentTheme, Prop.BTN_BG_ACTIVE) : themeUtil(Prop.BTN_BG_ACTIVE, CPNT, $colorProfile))};
    }
  }
`;
export const BulletPagination = ({
  className,
  bullets,
  colorProfile,
  componentTheme,
  activeIndex,
  carouselRef,
  type,
}) => (
  <StyledBulletPagination
    className={`${className} ${CarouselPaginationType.BULLETS}-pagination`}
    $colorProfile={colorProfile}
    $componentTheme={componentTheme}
    $smallGap={type === CarouselPaginationType.BULLETS}
  >
    {bullets?.map((bullet, idx) => (
      <SinglePaginationBullet
        key={`${bullet?.name || 'standard-bullet'}-${getRandomInt(0, 10000)}`}
        className={classNames({
          active: (idx) === activeIndex,
        }, 'pagination-item')}
        role="button"
        aria-label={`Navigate to Slide ${idx + 1}`}
        // onClick={() => carouselRef.current?.slideToLoop(idx)}
        onClick={() => carouselRef?.current?.slideToLoop(idx)}
        $colorProfile={colorProfile}
        $componentTheme={componentTheme}
      >
        {(type === CarouselPaginationType.BULLETS) && (
          <div className="standard-bullet" />
        )}
        {(type === CarouselPaginationType.ICON_BULLETS) && (
          <Icon className="icon-bullet" name={bullet.name} height={bullet?.height || 50} />
        )}
        {(type === CarouselPaginationType.TEXT_BULLETS) && (
          <div className="text-bullet">{bullet.name}</div>
        )}
      </SinglePaginationBullet>
    ))}
  </StyledBulletPagination>
);

BulletPagination.propTypes = {
  className: PropTypes.string,
  bullets: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  colorProfile: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  componentTheme: PropTypes.oneOf(Object.values(Components)),
  activeIndex: PropTypes.number.isRequired,
  carouselRef: PropTypes.shape({
    current: PropTypes.shape({
      slideToLoop: PropTypes.func,
    }),
  }),
  type: PropTypes.oneOf(Object.values(CarouselPaginationType)).isRequired,
};

BulletPagination.defaultProps = {
  className: '',
  carouselRef: null,
  colorProfile: null,
  componentTheme: null,
};
