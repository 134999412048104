import styled, { css } from 'styled-components';

import { Icon } from '@powdr/components/icon';
import {
  ThemeProps as Prop, Breakpoints, Components,
} from '@powdr/constants';
import { themeUtil } from '@powdr/utils';

const CPNT = Components.ECOMMERCE_WIDGET;

export const StyledEcommerceWidget = styled.div`
  background-color: ${() => themeUtil(Prop.BACKGROUND, CPNT)};
  width: 50vw;
  min-height: 323px;
  margin: 50px 40px;
  ${Breakpoints.mobile(css`
    width: 100%;
    margin: 0;
  `)}

  .tab-button {
    color: ${() => themeUtil(Prop.CONTENT, CPNT)};
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const TabIcon = styled(Icon)`
  margin-bottom: 8px;
  &:not(:first-child) {
    margin-left: 8px;
  }
  svg {
    fill: ${() => themeUtil(Prop.CONTENT, CPNT)};
    color: ${() => themeUtil(Prop.CONTENT, CPNT)};
  }
`;
