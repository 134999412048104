import PropTypes from 'prop-types';
import React, {
  useEffect,
} from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useDispatch, useSelector } from 'react-redux';

import { HtmlContentLoader, WysiwygStyleWrapper } from '@powdr/components';
import { AlertType } from '@powdr/constants';
import { getAlerts } from '@powdr/stores';
import { formatDate, formatDateFromEpoch } from '@powdr/utils';
import { useStaticData } from '@powdr/web/src/hooks';

import {
  IndividualAlertWrapper, StyledDorAlert, Timestamp, Title,
} from './styles';

export const DorAlert = ({
  hideTitle,
  hideDate,
  hideCopy,
  isParentNavFeed,
  parentColorProfile,
}) => {
  const { property } = useStaticData();
  const dispatch = useDispatch();
  const useAlertData = useSelector((state) => state.alerts);
  const { data: alerts } = useAlertData;
  const getDateString = (alert) => {
    const dateObj = formatDateFromEpoch(alert);
    return `Last Updated: ${dateObj.month.long} ${dateObj.day.num}, ${dateObj.year} -- ${dateObj.time.hours}:${dateObj.time.minutes} ${dateObj.time.ampm}`;
  };

  useEffect(() => {
    dispatch(getAlerts());
  }, [dispatch]);

  return (
    <StyledDorAlert
      colorProfile={parentColorProfile}
      isParentNavFeed={isParentNavFeed}
      property={property}
    >
      {(alerts?.length > 0) && alerts?.map((alert) => (
        <IndividualAlertWrapper
          key={alert.id}
          $alertType={alert.type || AlertType.INFO}
        >
          {!hideTitle && <Title>{alert.title}</Title>}
          {(!hideDate && alert?.updated) && <Timestamp>{getDateString(alert.updated)}</Timestamp>}
          {!hideCopy && (
            <WysiwygStyleWrapper colorProfile={parentColorProfile}>
              <HtmlContentLoader html={alert?.message || null} />
            </WysiwygStyleWrapper>
          )}
        </IndividualAlertWrapper>
      ))}
    </StyledDorAlert>
  );
};

DorAlert.propTypes = {
  hideTitle: PropTypes.bool,
  hideDate: PropTypes.bool,
  hideCopy: PropTypes.bool,
  parentColorProfile: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  isParentNavFeed: PropTypes.bool,
};

DorAlert.defaultProps = {
  hideTitle: false,
  hideDate: false,
  hideCopy: false,
  isParentNavFeed: false,
  parentColorProfile: null,
};
