import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {
  ThemeProps as Prop, ColorProfiles, TextTransformOptions, FontSize, FontFamily,
  Components,
} from '@powdr/constants';
import { getFontStyles, themeUtil } from '@powdr/utils';

const CPNT = Components.HEADER;

const JumpToButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const JumpToButton = styled.a`
  ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.REG30, undefined, TextTransformOptions.UPPERCASE)};
  color: ${() => themeUtil(Prop.BTN_TXT, CPNT)};
  background-color: ${() => themeUtil(Prop.BTN_BG, CPNT)};
  padding: 16px 20px;
  transition: all .15s ease-in-out;
  width: fit-content;
  margin: 5px;
  cursor: pointer;

  &:hover {
    color: ${() => themeUtil(Prop.BTN_TXT_HOVER, CPNT)};
    background-color: ${() => themeUtil(Prop.BTN_BG_HOVER, CPNT)};
  }
`;

export const JumpToButtons = ({ items, colorProfile, className }) => (
  <JumpToButtonGroup className={className}>
    {Object.values(items)
      .map((item) => (
        <JumpToButton
          key={item}
          colorProfile={colorProfile}
          href={`#${item}`}
        >
          {item}
        </JumpToButton>
      ))}
  </JumpToButtonGroup>
);

JumpToButtons.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  colorProfile: PropTypes.string,
};

JumpToButtons.defaultProps = {
  className: '',
  colorProfile: ColorProfiles.BASE,
};
