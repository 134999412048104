/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React, { useState, useRef, useContext } from 'react';

import { ColorProfiles } from '@powdr/constants';
import { AppContext } from '@powdr/context';

import { HotspotImage } from './components';
import {
  ActiveHotspotImageContainer, HotspotImageSelector, HotspotImageSelectors, StyledHotspotGroup,
} from './styles';

export const HotspotGroup = ({
  parentColorProfile,
  relationships,
}) => {
  const { hotspotImages } = relationships;
  const { fullHeaderHeight } = useContext(AppContext);
  const [activeGroupIndex, setActiveGroupIndex] = useState(0);
  const [modalActive, setModalActive] = useState(false);
  const colorProfile = parentColorProfile || ColorProfiles.BASE;
  const hotspotGroupRef = useRef(null);

  const selectionHandler = (idx) => {
    setActiveGroupIndex(idx);
    setModalActive(false);
  };

  return (
    <StyledHotspotGroup
      ref={hotspotGroupRef}
      $scrollMargin={fullHeaderHeight}
      colorProfile={colorProfile}
      onFocus={() => hotspotGroupRef.current.scrollIntoView({ behavior: 'smooth' })}
    >
      <HotspotImageSelectors>
        {hotspotImages.map((hotspotImage, idx) => (
          <li>
            <HotspotImageSelector
              key={hotspotImage.id}
              tabIndex="0"
              colorProfile={colorProfile}
              onMouseDown={(e) => e.preventDefault()}
              onClick={() => selectionHandler(idx)}
              active={idx === activeGroupIndex}
            >
              {hotspotImage.title}
            </HotspotImageSelector>
          </li>
        ))}
      </HotspotImageSelectors>
      <ActiveHotspotImageContainer>
        <HotspotImage
          {...hotspotImages[activeGroupIndex]}
          isActive={modalActive}
          modalStateChange={setModalActive}
          colorProfile={colorProfile}
        />
      </ActiveHotspotImageContainer>
    </StyledHotspotGroup>
  );
};

HotspotGroup.propTypes = {
  parentColorProfile: PropTypes.string,
  relationships: PropTypes.shape().isRequired,
};

HotspotGroup.defaultProps = {
  parentColorProfile: null,
};
