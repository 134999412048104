import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DorMeasurementFieldNames, LiftTrailStatus } from '@powdr/constants';
import { getLifts } from '@powdr/stores';

export const useLiftsMeasurements = (ignoreLiftTypes) => {
  const dispatch = useDispatch();
  const useLiftsData = useSelector((state) => state.lifts);
  const { data } = useLiftsData;
  const [liftsData, setLiftsData] = useState(null);

  useEffect(() => {
    dispatch(getLifts());
  }, [dispatch]);

  useEffect(() => {
    if (data && data?.length > 0) {
      setLiftsData({
        [DorMeasurementFieldNames.OPEN]: data.filter(
          (l) => !ignoreLiftTypes.includes(l.type) && l.status === LiftTrailStatus.OPEN,
        ).length,
        [DorMeasurementFieldNames.TOTAL]: data.filter(
          (l) => !ignoreLiftTypes.includes(l.type),
        ).length,
      });
    }
  }, [ignoreLiftTypes, data]);

  return liftsData;
};
