import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { ChatTrackhsType } from '@powdr/model';
import { isBuildTime } from '@powdr/utils';

export const ChatTrackhs = ({
  id,
  title,
  themeColor,
  welcomeMessage,
  awayMessage,
  goodbyeMessage,
  domain,
}) => {
  if (isBuildTime()) return null;

  window.$trChatSettings = {
    title,
    color: themeColor,
    welcome: welcomeMessage,
    away: awayMessage,
    goodbye: goodbyeMessage,
    domain,
  };

  return (
    <Helmet>
      <script
        id="trchat-app-script"
        type="text/javascript"
        src="//chat.trackhs.com/chat/track-webchat-boot.js"
        async=""
      />
    </Helmet>
  );
};

ChatTrackhs.propTypes = {
  title: PropTypes.string,
  themeColor: PropTypes.string,
  welcomeMessage: PropTypes.string,
  awayMessage: PropTypes.string,
  goodbyeMessage: PropTypes.string,
  domain: PropTypes.string,
};

ChatTrackhs.defaultProps = {
  title: 'Chat',
  themeColor: '#000000',
  welcomeMessage: 'Would you like to chat with us today?',
  awayMessage: 'We are sorry, there are no available agents.',
  goodbyeMessage: 'Thank you for chatting with us. We look forward to helping you again in the future.',
  domain: 'coppermtn',
};
