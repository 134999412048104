import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Loader } from '@powdr/components';
import { getLiveWeather } from '@powdr/stores';
import { useStaticData } from '@powdr/web/src/hooks';

import { StyledDorLiveWeather } from './styles';

export const DorLiveWeather = ({
  parentColorProfile,
}) => {
  const { property } = useStaticData();
  const dispatch = useDispatch();
  const useLiveWeatherData = useSelector((state) => state.liveWeather);
  const { isLoading, error, data } = useLiveWeatherData;
  const [cards, setCards] = useState([]);

  useEffect(() => {
    dispatch(getLiveWeather());
  }, [dispatch]);

  useEffect(() => {
    if (data?.liveWeatherCards) {
      setCards(data?.liveWeatherCards);
    }
  }, [data]);

  if (error) {
    console.error('DOR Sensors API Failed:', error.message);
    return null;
  }

  return (
    <StyledDorLiveWeather
      colorProfile={parentColorProfile}
      className={classNames('liveWeather')}
      property={property}
    >
      {isLoading ? (
        <Loader
          className="icon"
          classAnimationEffect="loader-animated spin infinite"
          iconName="sunny"
          size="30"
        />
      ) : (
        <div className="live-weather cards">
          <div className="grid">
            {cards.map((card) => {
              const {
                id, temperature, location, elevation, windSpeedAvg, windSpeedMax, isoParts,
              } = card || {};

              const {
                month, day, year, time,
              } = isoParts || {
                month: { short: null },
                day: { num: null },
                year: null,
                time: { hour: null, minutes: null, ampm: null },
              };

              const timeOfDay = `${month.short} ${day.num}, ${year}, ${time.hours}:${time.minutes} ${time.ampm}`;

              return (
                <div key={id} className="card">
                  <div className="header">
                    <div className="row banner">
                      <h4 className="location">{location}</h4>
                      <small className="elevation">
                        elevation
                        {' '}
                        {elevation}
                      </small>
                    </div>
                    <div className="row">
                      <p className="date-time">
                        {timeOfDay}
                      </p>
                    </div>
                  </div>
                  <div className="content">
                    <div className="row">
                      <h1 className="temperature">
                        {temperature}
                        <span className="grey">F</span>
                      </h1>
                    </div>
                  </div>
                  <div className="footer">
                    <div className="row">
                      {windSpeedAvg
                        && (
                          <div className="li">
                            <div className="detail-values">
                              <span className="details">Wind Avg:</span>
                              <span className="values">
                                {windSpeedAvg}
                                {' '}
                                mph
                              </span>
                            </div>
                          </div>
                        )}
                      {windSpeedMax
                        && (
                          <div className="li">
                            <div className="detail-values">
                              <span className="details">Wind Max:</span>
                              <span className="values">
                                {windSpeedMax}
                                {' '}
                                mph
                              </span>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </StyledDorLiveWeather>
  );
};

DorLiveWeather.propTypes = {
  parentColorProfile: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

DorLiveWeather.defaultProps = {
  parentColorProfile: null,
};
