exports.components = {
  "component---src-components-layout-layout-jsx": () => import("./../../../src/components/layout/layout.jsx" /* webpackChunkName: "component---src-components-layout-layout-jsx" */),
  "component---src-components-layout-tv-rotating-layout-jsx": () => import("./../../../src/components/layout/tv-rotating-layout.jsx" /* webpackChunkName: "component---src-components-layout-tv-rotating-layout-jsx" */),
  "component---src-components-layout-tv-static-layout-jsx": () => import("./../../../src/components/layout/tv-static-layout.jsx" /* webpackChunkName: "component---src-components-layout-tv-static-layout-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-ssr-jsx": () => import("./../../../src/pages/ssr.jsx" /* webpackChunkName: "component---src-pages-ssr-jsx" */),
  "component---src-pages-tv-jsx": () => import("./../../../src/pages/tv.jsx" /* webpackChunkName: "component---src-pages-tv-jsx" */),
  "component---src-templates-demo-jsx": () => import("./../../../src/templates/demo.jsx" /* webpackChunkName: "component---src-templates-demo-jsx" */)
}

