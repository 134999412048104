import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState, Children } from 'react';

import { Icon } from '@powdr/components/icon';

import { StyledUiAccordion, StyledUiAccordionPanel } from './styles';

export const UiAccordionPanel = ({
  panelId,
  id,
  isOpen,
  label,
  iconOpen,
  iconClose,
  moreInfoUrl,
  extraDetailsRender,
  children,
  className,
  style,
}) => {
  const [isPanelOpen, setIsPanelOpen] = useState(isOpen);

  useEffect(() => {
    setIsPanelOpen(isOpen);
  }, [isOpen]);

  const onPanelClick = () => {
    setIsPanelOpen(!isPanelOpen);
  };

  return (
    <StyledUiAccordionPanel
      id={id}
      key={panelId}
      role="menu"
      className={
        classNames(
          `${className}`,
          'ui-accordion-panel',
          {
            collapsed: !isPanelOpen,
            opened: isPanelOpen,
          },
        )
      }
      style={style}
    >
      <button
        role="menuitem"
        type="button"
        onClick={onPanelClick}
        className={
          classNames('panel-header btn')
        }
      >
        <span className="panel-details">

          {label && (
            <span className="panel-label">{label}</span>
          )}

          {extraDetailsRender && (
            <span className="panel-render">{extraDetailsRender}</span>
          )}

          {moreInfoUrl && (
            <a
              href={moreInfoUrl}
              className="panel-link"
            >
              <Icon
                name="content-info-open"
                width="16"
                height="16"
                className="icon"
              />
            </a>
          )}

        </span>

        <span className="panel-icon">
          <Icon
            className="icon"
            name={!isPanelOpen ? iconOpen : iconClose}
            width="16"
            height="16"
          />
        </span>

      </button>
      <div
        className={
          classNames('panel-body', className, {
            collapsed: !isPanelOpen,
            opened: isPanelOpen,
          })
        }
      >
        {children}
      </div>

    </StyledUiAccordionPanel>
  );
};

UiAccordionPanel.propTypes = {
  panelId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  label: PropTypes.string,
  isOpen: PropTypes.bool,
  iconOpen: PropTypes.string,
  iconClose: PropTypes.string,
  moreInfoUrl: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  extraDetailsRender: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

UiAccordionPanel.defaultProps = {
  panelId: null,
  label: '',
  isOpen: false,
  iconOpen: 'ui-arrow-down',
  iconClose: 'ui-arrow-down',
  moreInfoUrl: null,
  children: null,
  extraDetailsRender: null,
  className: null,
  style: null,
  id: null,
};

export const UiAccordion = ({
  panelDefaultState,
  iconOpen,
  iconClose,
  children,
  className,
  id,
}) => {
  const AccordionPanel = Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      const i = child.props.panelId || children.panelId;
      return React.cloneElement(child, {
        panelId: i,
        isOpen: panelDefaultState || child.props.isOpen || false,
        iconOpen: iconOpen || child.props.iconOpen,
        iconClose: iconClose || child.props.iconClose,
        id: id || child.props.id,
        className: className || child.props.className,
      });
    }
    return child;
  });

  return (
    <StyledUiAccordion
      id={id}
      className={classNames(
        `${className}`,
        'ui-accordion',
      )}
    >
      {AccordionPanel}
    </StyledUiAccordion>
  );
};

UiAccordion.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  iconOpen: PropTypes.string,
  iconClose: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  panelDefaultState: PropTypes.bool,
};

UiAccordion.defaultProps = {
  className: null,
  id: null,
  iconOpen: 'ui-arrow-down',
  iconClose: 'ui-arrow-down',
  children: null,
  panelDefaultState: false,
};
