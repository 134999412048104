/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';

import {
  CarouselControlPlacement, CarouselPaginationType, CarouselTransitionEffects,
} from '@powdr/constants';
import { AppContext } from '@powdr/context/app-context';
import { colorProfileByIndex } from '@powdr/utils';

import { CompareCard } from './components';
import {
  StyledCompareCards,
  DesktopWrapper, DesktopCarousel,
  MobileCarousel,
} from './styles';

export const CompareCards = ({
  isDesktopCarousel,
  colorProfile,
  relationships,
}) => {
  const { cardData } = relationships;
  const { isMobile } = useContext(AppContext);
  const colorProfileString = colorProfileByIndex(colorProfile);

  const cardMap = useMemo(() => cardData.filter((card) => !card.isHideCard).map((card) => (
    <CompareCard
      key={card.id}
      card={card}
      colorProfile={colorProfileString}
    />
  )), [cardData, colorProfileString]);

  return (
    <StyledCompareCards
      colorProfile={colorProfile}
      isMobile={isMobile}
      $isDesktopCarousel={isDesktopCarousel}
    >
      {(isMobile) && (
        <MobileCarousel
          transitionEffect={CarouselTransitionEffects.COVERFLOW}
          isInfiniteScroll={false}
          slidesPerView="auto"
          fitSlideWidthToItem
          controlSettings={{
            pagination: {
              type: CarouselPaginationType.BULLETS,
              placement: CarouselControlPlacement.BOTTOM,
            },
          }}
        >
          { cardMap }
        </MobileCarousel>
      )}
      <DesktopWrapper>
        {(isDesktopCarousel) ? (
          <DesktopCarousel
            isInfiniteScroll={false}
            slidesPerView="auto"
            fitSlideWidthToItem
            controlSettings={{
              scrollbar: {
                placement: CarouselControlPlacement.BOTTOM,
              },
            }}
          >
            { cardMap }
          </DesktopCarousel>
        ) : cardMap}
      </DesktopWrapper>
    </StyledCompareCards>
  );
};

CompareCards.propTypes = {
  isDesktopCarousel: PropTypes.bool,
  colorProfile: PropTypes.number,
  relationships: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        highlightedText: PropTypes.string,
        title: PropTypes.string,
        length: PropTypes.number,
      }).isRequired,
    ).isRequired,
  ).isRequired,
};

CompareCards.defaultProps = {
  isDesktopCarousel: false,
  colorProfile: '',
};
