import PropTypes from 'prop-types';

import { BlockTypes } from '@powdr/constants';

export const NavigationType = PropTypes.shape({
  isHideFromNav: PropTypes.bool,
  isOpenChildrenInModals: PropTypes.bool,
  isShowBackBtnOnChildren: PropTypes.bool,
  link: PropTypes.string,
  name: PropTypes.string,
  navigationOverrideType: PropTypes.string,
  season: PropTypes.string,
});

export const ContentBlockType = PropTypes.shape({
  ariaLabel: PropTypes.string,
  blockType: PropTypes.oneOf(Object.values(BlockTypes)),
  buttonClick: PropTypes.func,
  colorProfile: PropTypes.string,
  copy: PropTypes.string,
  isOverlayEnabled: PropTypes.bool,
  title: PropTypes.string,
  tags: PropTypes.string,
  isHideTags: PropTypes.bool,
});

export const AssetsType = PropTypes.shape({
});

export const SlideType = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  copy: PropTypes.string,
  copyBackground: PropTypes.string,
  copyBackgroundColor: PropTypes.string,
  btn: PropTypes.string,
  btnHref: PropTypes.string,
  svgOveridePadding: PropTypes.number,
  svgOverridePaddingMobile: PropTypes.number,
  screenHeightOverrideValue: PropTypes.number,
  contentAlignment: PropTypes.string,
  heroIcon: PropTypes.string,
  heroIconWidth: PropTypes.number,
  linkTarget: PropTypes.string,
  colorProfile: PropTypes.string,
  bgImageHPosition: PropTypes.string,
  bgImagePosition: PropTypes.string,
  componentSeason: PropTypes.string,
  isLightbox: PropTypes.bool,
  isHideSlide: PropTypes.bool,
  videoId: PropTypes.string,
  isWoodwardColorTakeover: PropTypes.bool,
  assets: PropTypes.objectOf(AssetsType),
  accent: PropTypes.string,
});

export const PartnerType = PropTypes.shape({
  name: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
  filePath: PropTypes.string,
  iconName: PropTypes.string,
  width: PropTypes.string,
});

export const FooterLinkType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  fieldLink: PropTypes.string,
});

export const SocialLinkType = PropTypes.shape({
  btnHref: PropTypes.string,
  socialSite: PropTypes.string,
  id: PropTypes.string,
  parentFieldName: PropTypes.string,
});

export const GlobalFooterType = PropTypes.shape({
  height: PropTypes.string,
  iconName: PropTypes.string,
  sort: PropTypes.string,
  url: PropTypes.string,
  title: PropTypes.string,
});

export const HeaderConfig = PropTypes.shape({
  alertCopy: {
    value: PropTypes.string,
  },
  isAlertEnabled: PropTypes.bool,
  isBannnerEnabled: PropTypes.bool,
  isCombineSeasonalityToggle: PropTypes.bool,
  isDorFeedEnabled: PropTypes.bool,
  headerStyle: PropTypes.string,
  headerUtilities: PropTypes.arrayOf([PropTypes.string]),
  headerPromoButtons: PropTypes.bool,
  isPromotionsEnabled: PropTypes.bool,
  isSeasonalityEnabled: PropTypes.bool,
  seasonalityPlacement: PropTypes.string,
  unityCartLink: PropTypes.string,
  isUnityEnabled: PropTypes.bool,
  unityPlacement: PropTypes.string,
  unitySubdomain: PropTypes.string,
  id: PropTypes.string,
  height: PropTypes.string,
  iconName: PropTypes.string,
  sort: PropTypes.string,
  url: PropTypes.string,
  title: PropTypes.string,
});

export const PlaceholderComponentType = PropTypes.shape({
  componentName: PropTypes.string,
});

export const ChildComponentType = PropTypes.shape({
  name: PropTypes.string,
  children: PropTypes.arrayOf({}),
});

export const PageType = PropTypes.shape({
  isHideFromNav: PropTypes.bool,
  isLandingPage: PropTypes.bool,
  layout: PropTypes.string,
  link: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  name: PropTypes.string,
  navigationOverrideType: PropTypes.string,
  isNoIndex: PropTypes.bool,
  isOpenChildrenInModals: PropTypes.bool,
  isShowBackBtnOnChildren: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
});

export const CalendarBadgeType = PropTypes.shape({
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  type: PropTypes.string,
  colorProfile: PropTypes.string,
});

export const SocialShareButtonsType = PropTypes.shape({
  className: PropTypes.string,
  title: PropTypes.string,
  copy: PropTypes.string,
  size: PropTypes.number,
  url: PropTypes.string,
});
