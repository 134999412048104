import PropTypes from 'prop-types';
import React from 'react';

import { MeasurementDisplayTypes } from '@powdr/constants';
import { slugify } from '@powdr/utils';

import {
  TextMeasurementContent, TextMeasurementIcon,
  TextMeasurementItem, TextMeasurementLabel,
} from './styles';

export const TextMeasurement = ({
  colorProfile,
  colorOverride,
  label,
  measurement,
  measurementDisplayType,
  icon,
  small,
  smaller,
  children,
}) => (
  <TextMeasurementItem className={`text-measurement ${slugify(measurement)}`}>
    <TextMeasurementLabel
      className="label"
      $large={measurementDisplayType === MeasurementDisplayTypes.TEXT_LARGE}
    >
      {label}
    </TextMeasurementLabel>
    {(icon) && (
      <TextMeasurementIcon
        className="icon"
        name={icon}
        $colorProfile={colorProfile}
      />
    )}
    <TextMeasurementContent
      className="content"
      $small={small}
      $smaller={smaller}
      $colorOverride={colorOverride}
    >
      {children}
    </TextMeasurementContent>
  </TextMeasurementItem>
);

TextMeasurement.propTypes = {
  colorProfile: PropTypes.string,
  colorOverride: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.node,
  measurementDisplayType: PropTypes.string,
  measurement: PropTypes.string,
  icon: PropTypes.string,
  small: PropTypes.bool,
  smaller: PropTypes.bool,
};

TextMeasurement.defaultProps = {
  colorProfile: null,
  colorOverride: null,
  label: null,
  children: null,
  measurementDisplayType: null,
  measurement: null,
  icon: null,
  small: false,
  smaller: false,
};
