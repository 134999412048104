import PropTypes from 'prop-types';
import React, {
  useContext, useEffect, useState, useMemo, useRef,
} from 'react';

import { AppContext } from '@powdr/context/app-context';
import { useWindowResize } from '@powdr/hooks';
import { colorProfileByIndex, getBackgroundImageSrc } from '@powdr/utils';

import {
  StyledSectionContentTabs, SectionHeader, ContentSection,
  TabsWrapper, IndividualTabWrapper, IndividualTab,
  ActiveTabTopAccent, ActiveTabConnector, TabIconTextWrapper,
  TabButtonIcon, TabTitle, AccordionIndicator,
  ActiveContentWrapper, DesktopActiveContentWrapper, ActiveContentOuterContainer,
  ContentCopyIcon, TabContent, ContentTitle,
  ContentCopy, CTAList, CTAItem, CTAButton,
  LeftConnectorRounding, RightConnectorRounding,
} from './styles';

export const SectionContentTabs = ({
  isHideHeader,
  wrapperHeaderText,
  headerAlignment,
  colorProfile,
  relationships,
}) => {
  const { isMobile, fullHeaderHeight } = useContext(AppContext);
  const [activeTab, setActiveTab] = useState(null);
  const [bgImage, setBgImage] = useState('');
  const sectionRef = useRef(null);
  const { tabData } = relationships;
  const { gatsbyImage: imageSrc } = relationships?.imageObj?.relationships?.image || {};
  const resize = useWindowResize();

  useEffect(() => {
    if (resize.windowWidth) {
      setBgImage(getBackgroundImageSrc(imageSrc, resize.windowWidth));
    }
  }, [resize.windowWidth, imageSrc]);

  useEffect(() => {
    if (!isMobile) {
      setActiveTab(0);
    } else {
      setActiveTab(null);
    }
  }, [isMobile]);

  const handleTabClick = (index) => {
    if (index === activeTab && isMobile) {
      setActiveTab(null);
    } else {
      setActiveTab(index);
    }
  };

  const ActiveContentHandler = ({
    index, tabTitle, tabCopy, tabButtonData, tabColorProfile,
  }) => useMemo(() => (
    <TabContent colorProfile={tabColorProfile} index={index}>
      <ContentTitle colorProfile={tabColorProfile}>{tabTitle}</ContentTitle>
      <ContentCopy colorProfile={tabColorProfile}>{tabCopy}</ContentCopy>
      <CTAList>
        {(tabButtonData) && tabButtonData.map((buttonData) => {
          const { buttonLink, buttonTarget, buttonText } = buttonData;
          return (
            <CTAItem key={`item-${index}-${Math.random()}`}>
              <CTAButton
                colorProfile={tabColorProfile}
                href={buttonLink}
                target={buttonTarget}
              >
                {buttonText}
              </CTAButton>
            </CTAItem>
          );
        })}
      </CTAList>
    </TabContent>
  ), [index, tabTitle, tabCopy, tabButtonData, tabColorProfile]);

  const DesktopActiveContentHandler = () => useMemo(() => {
    if (activeTab || activeTab === 0) {
      const {
        tabTitle, tabCopy, tabColorProfile,
        isWoodwardColorOverride, tabCopyIcon,
        relationships: { tabButtonData },
      } = tabData[activeTab];

      return (
        <DesktopActiveContentWrapper
          key={tabTitle}
          colorProfile={colorProfileByIndex(tabColorProfile)}
          isWoodwardColorOverride={isWoodwardColorOverride}
        >
          <ActiveContentOuterContainer colorProfile={colorProfileByIndex(tabColorProfile)}>
            <ContentCopyIcon colorProfile={colorProfileByIndex(tabColorProfile)} name={tabCopyIcon} width="100" height="fit-content" />
            <ActiveContentHandler
              tabTitle={tabTitle}
              tabCopy={tabCopy}
              tabButtonData={tabButtonData}
              tabColorProfile={colorProfileByIndex(tabColorProfile)}
            />
          </ActiveContentOuterContainer>
        </DesktopActiveContentWrapper>
      );
    }

    return null;
  }, []);

  return (
    <StyledSectionContentTabs
      ref={sectionRef}
      onFocus={() => sectionRef.current.scrollIntoView({ behavior: 'smooth' })}
      onMouseDown={(e) => e.preventDefault()}
      $scrollMargin={fullHeaderHeight}
      colorProfile={colorProfileByIndex(colorProfile)}
      bgImage={bgImage}
    >
      {(!isHideHeader) && (
      <SectionHeader
        isMobile={isMobile}
        alignment={headerAlignment}
        colorProfile={colorProfileByIndex(colorProfile)}
      >
        {wrapperHeaderText}
      </SectionHeader>
      )}
      <ContentSection>
        <TabsWrapper>
          {tabData.map((tab, index) => {
            const {
              tabTitle, tabCopy, tabTitleIcon,
              tabColorProfile, isWoodwardColorOverride,
              relationships: { tabButtonData },
            } = tab;
            const tabColorProfileString = colorProfileByIndex(tabColorProfile);

            return (
              <IndividualTabWrapper
                key={tabTitle}
                isWoodwardColorOverride={isWoodwardColorOverride}
              >
                <IndividualTab
                  index={index}
                  numTabs={tabData.length}
                  activeTab={index === activeTab}
                  tabIndex={(index === activeTab) ? -1 : 0}
                  onClick={() => handleTabClick(index)}
                  onMouseDown={(e) => e.preventDefault()}
                  colorProfile={tabColorProfileString}
                >
                  <TabIconTextWrapper>
                    <TabButtonIcon colorProfile={tabColorProfileString} name={tabTitleIcon} width="25" />
                    <TabTitle colorProfile={tabColorProfileString}>{tabTitle}</TabTitle>
                    {(isMobile) && <AccordionIndicator colorProfile={tabColorProfileString} activeTab={index === activeTab} name="ui-arrow-down" width="25" />}
                  </TabIconTextWrapper>
                  {(index === activeTab) && (
                  <>
                    <ActiveTabTopAccent colorProfile={tabColorProfileString} />
                    <ActiveTabConnector colorProfile={tabColorProfileString} />
                    {(index !== 0) && (
                      <LeftConnectorRounding colorProfile={tabColorProfileString} />
                    )}
                    {(index !== (tabData.length - 1)) && (
                      <RightConnectorRounding colorProfile={tabColorProfileString} />
                    )}
                  </>
                  )}
                  {(isMobile) && (
                    <ActiveContentWrapper
                      index={index}
                      isMobile={isMobile}
                      activeTab={index === activeTab}
                    >
                      <ActiveContentHandler
                        index={index}
                        tabTitle={tabTitle}
                        tabCopy={tabCopy}
                        tabButtonData={tabButtonData}
                        tabColorProfile={tabColorProfileString}
                      />
                    </ActiveContentWrapper>
                  )}
                </IndividualTab>
              </IndividualTabWrapper>
            );
          })}
        </TabsWrapper>
        {(!isMobile) && (
          <ActiveContentWrapper>
            <DesktopActiveContentHandler />
          </ActiveContentWrapper>
        )}
      </ContentSection>
    </StyledSectionContentTabs>
  );
};

SectionContentTabs.propTypes = {
  wrapperHeaderText: PropTypes.string,
  isHideHeader: PropTypes.bool,
  headerAlignment: PropTypes.string,
  colorProfile: PropTypes.number,
  relationships: PropTypes.shape({
    id: PropTypes.string,
    tabData: PropTypes.arrayOf(
      PropTypes.shape(),
    ),
    imageObj: PropTypes.shape(),
  }).isRequired,
};

SectionContentTabs.defaultProps = {
  wrapperHeaderText: '',
  isHideHeader: false,
  headerAlignment: 'left',
  colorProfile: null,
};
