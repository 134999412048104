import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { FeatureItem } from '@powdr/components';
import { CompareCardSides } from '@powdr/constants';
import { AppContext } from '@powdr/context/app-context';
import { isBuildTime, trackSelectItem } from '@powdr/utils';

import {
  StyledCompareCardInner, CompareCardTitle,
  CompareCardBodyContainer, HeadlineText, FeatureItems,
  PriceSection, PriceTextWrapper, PriceDescription, Price,
  ToggleSwitchWrapper, ToggleLabel, SwitchLabel, SwitchInput, SliderElement,
  ButtonSection, PurchaseButton, MoreInfoButton,
  FlipCardButton, FlipButtonText, FlipButtonIcon,
} from './styles';

export const CompareCardInner = ({
  card,
  sideOfCard,
  colorProfile,
  isCardFlipped,
  setCardFlipped,
  isCardToggled,
  setCardToggled,
}) => {
  const {
    title, cardSubtitle, cardSubtitleToggled,
    relationships: { featureItems, featureItemsToggled, trackingNode },
    priceDescription, priceDescriptionToggled, price, priceToggled,
    toggleTextLeft, toggleTextRight,
    buttonText, buttonLink, buttonTextToggled, buttonLinkToggled,
    subButtonText, subButtonLink, subButtonTextToggled, subButtonLinkToggled,
  } = card;
  const { isMobile } = useContext(AppContext);

  const checkHasReverseItems = (items) => items.map(
    (item) => !!(item.isFeatureDisplayOnBackMobile),
  ).includes(true);

  const hasReverseItems = checkHasReverseItems(
    (isCardToggled) ? featureItemsToggled : featureItems,
  );

  const FeatureItemMap = ({ items }) => items.map((item) => {
    const {
      id: itemId, featureText, featureIconName, featureIconColor,
      isFeatureDisplayOnBackMobile, isFeatureDisplayOnFrontMobile,
    } = item;
    const iconColor = featureIconColor || '#000000';

    return (
      (!isMobile || (isMobile && !hasReverseItems) || (isMobile && hasReverseItems && (
        (isCardFlipped && isFeatureDisplayOnBackMobile)
          || (!isCardFlipped && isFeatureDisplayOnFrontMobile)
      ))
      ) && (
        <FeatureItem
          key={itemId}
          icon={featureIconName}
          iconColor={iconColor}
          text={featureText}
        />
      )
    );
  });

  const trackItem = () => {
    if (!isBuildTime() && trackingNode) {
      trackSelectItem(trackingNode);
    }
  };

  return (
    <StyledCompareCardInner
      isMobile={isMobile}
      isCardFlipped={isCardFlipped}
      sideOfCard={sideOfCard}
    >
      <CompareCardTitle colorProfile={colorProfile}>{title}</CompareCardTitle>
      <CompareCardBodyContainer
        colorProfile={colorProfile}
        isCardFlipped={isCardFlipped}
        sideOfCard={sideOfCard}
      >
        {(sideOfCard !== CompareCardSides.BACK
          && ((cardSubtitle && !isCardToggled) || (cardSubtitleToggled && isCardToggled))) && (
          <HeadlineText colorProfile={colorProfile}>
            {(isCardToggled) ? cardSubtitleToggled : cardSubtitle}
          </HeadlineText>
        )}
        <FeatureItems
          colorProfile={colorProfile}
          isMobile={isMobile}
          sideOfCard={sideOfCard}
        >
          <FeatureItemMap items={(isCardToggled) ? featureItemsToggled : featureItems} />
          {(isMobile && hasReverseItems) && (
            <FlipCardButton
              onClick={() => setCardFlipped(!isCardFlipped)}
              isCardFlipped={isCardFlipped}
            >
              <FlipButtonText
                colorProfile={colorProfile}
                isCardFlipped={isCardFlipped}
              >
                {(isCardFlipped) ? 'Back' : 'View More'}
              </FlipButtonText>
              <FlipButtonIcon
                colorProfile={colorProfile}
                isCardFlipped={isCardFlipped}
                name="ui-back"
              />
            </FlipCardButton>
          )}
        </FeatureItems>
        {(sideOfCard !== CompareCardSides.BACK) && (
          <PriceSection isCardFlipped={isCardFlipped} sideOfCard={sideOfCard}>
            <PriceTextWrapper>
              <PriceDescription colorProfile={colorProfile}>
                {(isCardToggled) ? priceDescriptionToggled : priceDescription}
              </PriceDescription>
              <Price colorProfile={colorProfile}>
                {(isCardToggled) ? priceToggled : price}
              </Price>
            </PriceTextWrapper>
            {(toggleTextLeft && toggleTextRight) && (
              <ToggleSwitchWrapper>
                <ToggleLabel colorProfile={colorProfile} padSide="right">{toggleTextLeft}</ToggleLabel>
                <SwitchLabel>
                  <SwitchInput
                    type="checkbox"
                    colorProfile={colorProfile}
                    onClick={() => setCardToggled(!isCardToggled)}
                    defaultChecked={isCardToggled}
                  />
                  <SliderElement colorProfile={colorProfile} />
                </SwitchLabel>
                <ToggleLabel colorProfile={colorProfile} padSide="left">{toggleTextRight}</ToggleLabel>
              </ToggleSwitchWrapper>
            )}
            <ButtonSection isCardFlipped={isCardFlipped} sideOfCard={sideOfCard}>
              {((buttonLink && !isCardToggled) || (buttonLinkToggled && isCardFlipped)) && (
                <PurchaseButton
                  colorProfile={colorProfile}
                  href={(isCardToggled) ? buttonLinkToggled : buttonLink}
                  onClick={trackItem}
                >
                  {(isCardToggled) ? buttonTextToggled : buttonText}
                </PurchaseButton>
              )}
              {((subButtonLink && !isCardToggled) || (subButtonLinkToggled && isCardToggled)) && (
                <MoreInfoButton
                  colorProfile={colorProfile}
                  href={(isCardToggled) ? subButtonLinkToggled : subButtonLink}
                >
                  {(isCardToggled) ? subButtonTextToggled : subButtonText}
                </MoreInfoButton>
              )}
            </ButtonSection>
          </PriceSection>
        )}
      </CompareCardBodyContainer>
    </StyledCompareCardInner>
  );
};

CompareCardInner.propTypes = {
  card: PropTypes.shape().isRequired,
  sideOfCard: PropTypes.string,
  colorProfile: PropTypes.string.isRequired,
  isCardFlipped: PropTypes.bool.isRequired,
  setCardFlipped: PropTypes.func.isRequired,
  isCardToggled: PropTypes.bool.isRequired,
  setCardToggled: PropTypes.func.isRequired,
};

CompareCardInner.defaultProps = {
  sideOfCard: CompareCardSides.FRONT,
};
