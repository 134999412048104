/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';

import { WindDirections, WindSpeedSensorMap } from '@powdr/constants';
import { useSensor } from '@powdr/hooks';
import { getWindDirectionFromDegrees } from '@powdr/utils';

export const useWindSpeedMeasurement = (sensor) => {
  if (!sensor) return null;

  const sensorData = useSensor(sensor);
  const [windSpeedData, setWindSpeedData] = useState(null);

  /* Some directions are a degree value (0-360), some are already a cardinal direction */
  const handleDirectionValue = (d) => ((Object.values(WindDirections).includes(d))
    ? d
    : getWindDirectionFromDegrees(d));

  useEffect(() => {
    if (sensorData) {
      setWindSpeedData({
        low: sensorData[WindSpeedSensorMap[sensor].low],
        high: sensorData[WindSpeedSensorMap[sensor].high],
        direction: handleDirectionValue(sensorData[WindSpeedSensorMap[sensor].direction]),
      });
    }
  }, [sensor, sensorData]);

  return windSpeedData;
};
