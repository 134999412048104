import PropTypes from 'prop-types';
import React from 'react';

import { SnowReportUnits, SnowReportUnitsDisplay, Symbols } from '@powdr/constants';
import { useCurrentSnowfall, useCurrentTemperature } from '@powdr/hooks';
import { useStaticData } from '@powdr/web/src/hooks';

import {
  SnowfallIcon,
  SnowfallInfo,
  StyledDorToggle,
  TempIcon,
  TemperatureInfo,
  IndicatorIcon,
  IndicatorWrapper,
  SnowfallText,
  SnowfallUnit,
} from './styles';

export const DorToggle = ({
  onClick,
}) => {
  const { headerConfig } = useStaticData();
  const temperatureData = useCurrentTemperature();
  const snowfallData = useCurrentSnowfall();

  return (
    <StyledDorToggle
      onClick={onClick}
      $isInfoHidden={!headerConfig?.feedSnowfallHide || !headerConfig?.feedTempHide}
    >
      {(!headerConfig?.feedSnowfallHide) && (
        <SnowfallInfo>
          <SnowfallIcon name="dor-snow" />
          {(snowfallData.unit !== SnowReportUnitsDisplay[SnowReportUnits.CENTIMETERS]) ? (
            `${(!snowfallData || snowfallData?.value === null) ? Symbols.DOUBLE_DASH : snowfallData.value}${snowfallData.unit}`
          ) : (
            <SnowfallText>
              {`${(!snowfallData || snowfallData?.value === null) ? Symbols.DOUBLE_DASH : snowfallData.value}`}
              <SnowfallUnit>
                {snowfallData.unit}
              </SnowfallUnit>
            </SnowfallText>
          )}
        </SnowfallInfo>
      )}
      {(!headerConfig?.feedTempHide) && (
        <TemperatureInfo>
          <TempIcon name={temperatureData?.icon} />
          {`${(!temperatureData || temperatureData?.value === null) ? Symbols.DOUBLE_DASH : Math.round(temperatureData.value)}${Symbols.DEGREE}`}
        </TemperatureInfo>
      )}
      <IndicatorWrapper>
        <IndicatorIcon name="ui-arrow-down" />
      </IndicatorWrapper>
    </StyledDorToggle>
  );
};

DorToggle.propTypes = {
  onClick: PropTypes.func.isRequired,
};

DorToggle.defaultProps = {};
