import { useRef, useEffect } from 'react';

/**
 * A utility hook that determines if a component/consumer
 * is currently mounted through a ref
 * @returns {React.MutableRefObject} a ref that determines if a component is mounted
 */
export function useIsMountedRef() {
  const isMounted = useRef(true);

  useEffect(() => () => {
    isMounted.current = false;
  }, []);

  return isMounted;
}
