import {
  FetchStatus,
  StatusTypes,
} from '@powdr/constants';
import { deslugify } from '@powdr/utils';
import {
  setDifficulty,
  setFeature,
  setFeaturedDifficulty,
  setFeatureSizes,
  setGroomed,
  setStatus,
  setSeason,
} from '@powdr/utils/dor-util';

import {
  LIFT_TRAIL_REPORT_ERROR,
  LIFT_TRAIL_REPORT_PENDING,
  LIFT_TRAIL_REPORT_SUCCESS,
} from '../types';

// TODO: Can we call this feed trails
const initialState = {
  data: null,
  status: FetchStatus.IDLE,
  error: null,
};

const shapeTrailData = (data) => data
  .filter((f) => f.type !== StatusTypes.POI)
  .map((trail) => {
    const {
      freeride_terrain: freerideTerrain,
      technical_terrain: technicalTerrain,
      jump_trail: jumpTrail,
      skill_start_park: skillStartPark,
      uphill_route: uphillRoute,
      hiking,
      gladed_trail: gladedTrail,
      race,
      terrain_parks: terrainParks,
      snowmaking,
      when,
      what,
      where,
      who,
      images,
      size_rating: sizeRating,
    } = trail.properties || {};

    return {
      ...trail,
      id: trail.id,
      sector: trail.sector,
      name: trail.name,
      status: setStatus(trail.status),
      season: setSeason(trail.season),
      feature: setFeature({
        gladedTrail,
        race,
        terrainParks,
        freerideTerrain,
        technicalTerrain,
        snowmaking,
      } || {}),
      details: {
        when,
        what,
        where,
        who,
        images,
      },
      featureSize: setFeatureSizes(sizeRating) || null,
      difficulty: setDifficulty(trail.difficulty) || null,
      groomed: setGroomed(trail.groom_status),
      featureDifficulty: setFeaturedDifficulty({
        freerideTerrain,
        technicalTerrain,
        skillStartPark,
        jumpTrail,
        uphillRoute,
        hiking,
      }, trail.difficulty),
    };
  });

const shapeLiftData = (data) => data
  .filter((f) => f.type !== StatusTypes.POI)
  .map((lift) => ({
    ...lift,
    season: setSeason(lift.season),
    type: deslugify(lift.type),
    status: setStatus(lift.status, 'lift'),
  }));

const reducer = (data) => ({
  data: {
    sector: data.sector,
    trail: shapeTrailData(data.trail),
    lift: shapeLiftData(data.lift),
  },
  modified: true,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case LIFT_TRAIL_REPORT_PENDING:
      return {
        ...state,
        status: FetchStatus.LOADING,
      };
    case LIFT_TRAIL_REPORT_SUCCESS:
      return {
        ...state,
        data: reducer(action.payload),
        status: FetchStatus.SUCCESS,
      };
    case LIFT_TRAIL_REPORT_ERROR:
      return {
        status: FetchStatus.ERROR,
        error: action.payload,
      };
    default: return state;
  }
};
