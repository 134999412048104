/* eslint-disable no-plusplus */
import PropTypes from 'prop-types';
import React from 'react';

import { StyledPriceRange } from './styles';

export const PriceRange = ({
  className,
  max,
  value,
  character,
}) => {
  const spans = () => {
    const itemArr = [];

    for (let i = 0; i < max; i++) {
      if (i < value) itemArr.push(<span key={`price-range-${value}-${Math.random()}`} className="solid">{character}</span>);
      else itemArr.push(<span key={`price-range-${value}-${Math.random()}`} className="fade">{character}</span>);
    }

    return itemArr;
  };

  return (
    <StyledPriceRange className={className}>
      {spans()}
    </StyledPriceRange>
  );
};

PriceRange.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number,
  max: PropTypes.number,
  character: PropTypes.string,
};

PriceRange.defaultProps = {
  className: '',
  value: 1,
  max: 4,
  character: '$',
};
