import styled, { css } from 'styled-components';

import { WysiwygStyleWrapper } from '@powdr/components';
import {
  ThemeProps as Prop, Breakpoints, Components, ColorProfiles,
  FontFamily,
  FontSize,
} from '@powdr/constants';
import { getFontStyles, rgbaTransparencyToHex, themeUtil } from '@powdr/utils';

const CPNT = Components.FORM_FORMBUCKET;

export const StyledFormFormbucket = styled(WysiwygStyleWrapper)`
  margin-bottom: 15px;

  .form-control {
    display: block;
    margin-bottom: 15px;
    width: 100%;
    max-width: 50%;
    height: auto;
    padding: 10px 15px;
    ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.REG50, undefined, undefined)};
    line-height: 1.25;
    background-color: white;
    background-image: none;
    background-clip: padding-box;
    border: 2px solid ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
    color: black;
  }

  .l-group {
    display: block;
    margin-bottom: 30px;
  }

  .form-group {
    display: block;
    margin-bottom: 30px;
  }

  button[type="submit"] {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    border: none;
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG, CPNT, colorProfile)};
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT, CPNT, colorProfile)};

    .form-loader {
      margin-left: 20px;
    }

    &:hover:not(:disabled) {
      cursor: pointer;
      background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
      color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
    }

    &:disabled {
      background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_INACTIVE, CPNT, colorProfile)};
      color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_INACTIVE, CPNT, colorProfile)};
      cursor: not-allowed;
    }
  }

  .recaptcha {
    margin-bottom: 15px;
  }

  input:required:focus {
    outline: none;
  }

  form {
    margin-bottom: 30px;
  }

  textarea {
    border: 1px solid rgba(0, 0, 0, 0.15);
    padding: 15px;
    width: 100%;
    margin-bottom: 15px;
    ${() => getFontStyles(FontFamily.BASE_FONT, FontSize.REG50, undefined, undefined)};
    line-height: 20px;
    color: #55595c;
  }

  .formCheckbox {
    margin-bottom: 15px;
  }

  .flex-row {
    display: flex;
    width: 100%;
    gap: 25px;
  }

  .row-item {
    width: 100%;
  }

  .flex-checkboxes {
    display: flex;
    gap: 30%;
  }

  ${Breakpoints.smallest(css`
    max-width: 100%;
  `)}
`;

export const FormStatePopup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 25%;
  background-color: ${() => themeUtil(Prop.BACKGROUND, CPNT, ColorProfiles.PRIMARY) + rgbaTransparencyToHex(0.5)};

  .close-button {
    position: fixed;
    margin: 25px;
    top: 0;
    right: 0;
    padding: 10px;
    background-color: ${() => themeUtil(Prop.BTN_BG, CPNT, ColorProfiles.PRIMARY)};
    color: ${() => themeUtil(Prop.BTN_TXT, CPNT, ColorProfiles.PRIMARY)};

    svg {
      fill: ${() => themeUtil(Prop.BTN_TXT, CPNT, ColorProfiles.PRIMARY)};
      color: ${() => themeUtil(Prop.BTN_TXT, CPNT, ColorProfiles.PRIMARY)};
    }

    &:hover {
      background-color: ${() => themeUtil(Prop.BTN_BG_HOVER, CPNT, ColorProfiles.PRIMARY)};
      color: ${() => themeUtil(Prop.BTN_TXT_HOVER, CPNT, ColorProfiles.PRIMARY)};

      svg {
        fill: ${() => themeUtil(Prop.BTN_TXT_HOVER, CPNT, ColorProfiles.PRIMARY)};
        color: ${() => themeUtil(Prop.BTN_TXT_HOVER, CPNT, ColorProfiles.PRIMARY)};
      }
    }
  }

  div {
    h2 {
      color: ${() => themeUtil(Prop.HEADER, CPNT, ColorProfiles.PRIMARY)};
      ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.LRG50, undefined, undefined)};
      margin-bottom: 10px;
      line-height: 1;
    }

    p {
      color: ${() => themeUtil(Prop.CONTENT, CPNT, ColorProfiles.PRIMARY)};
      ${() => getFontStyles(FontFamily.BASE_FONT, FontSize.LRG10, undefined, undefined)};
      line-height: 1;
    }
  }
`;
