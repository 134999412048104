/* eslint-disable max-len */
// eslint-disable-next-line import/no-unresolved
import PropTypes from 'prop-types';
import React from 'react';

import { UiEmbed } from '@powdr/components';
import { getImageAltText } from '@powdr/utils';

import {
  StyledUiImage, ImageFrame, StyledGatsbyImage, StyledImage, StyledLink,
} from './styles';

export const UiImage = ({
  isImageShadow,
  videoId,
  externalImageUrl,
  imageLink,
  imageLinkTarget,
  relationships,
}) => {
  const { gatsbyImage: imageSrc } = relationships?.field_referenced_image?.relationships?.image || {};

  return (
    <StyledUiImage className="ui-image">
      {(imageLink && !videoId) && <StyledLink href={imageLink} target={imageLinkTarget} />}
      <ImageFrame isImageShadow={isImageShadow} $isVideo={!!videoId}>
        {(imageSrc) && <StyledGatsbyImage image={imageSrc} alt={getImageAltText(relationships?.field_referenced_image?.relationships?.image)} />}
        {(externalImageUrl && !imageSrc) && <StyledImage src={externalImageUrl} alt="" />}
        {(videoId) && <UiEmbed videoId={videoId} />}
      </ImageFrame>
    </StyledUiImage>
  );
};

UiImage.propTypes = {
  isImageShadow: PropTypes.bool,
  videoId: PropTypes.string,
  externalImageUrl: PropTypes.string,
  imageLink: PropTypes.string,
  imageLinkTarget: PropTypes.string,
  relationships: PropTypes.shape().isRequired,
};

UiImage.defaultProps = {
  isImageShadow: false,
  videoId: '',
  externalImageUrl: '',
  imageLink: '',
  imageLinkTarget: null,
};
