import {
  addMonths, format, isFuture, isToday,
} from 'date-fns';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryParams, StringParam } from 'use-query-params';

import {
  Button, Checkbox, Loader, Modal,
} from '@powdr/components';
import { QueryParamNames } from '@powdr/constants';
import {
  CheckedState, DateFormats, EcommerceMeta,
  EcommerceViews, FetchStatus, ProductAttributes,
} from '@powdr/constants/constants';
import {
  createGuestCustomer, getProductBySlug, getAllProductsByDate, getProductVariant, postAddToCart,
  resetEcommerceCart, resetEcommerceStore, getCart, setMiddlewareCookie, resetEcommerceProducts,
} from '@powdr/stores/actions/ecommerce-action';
import { logging } from '@powdr/stores/actions/logging-action';
import { isEmpty, numberToDollar } from '@powdr/utils';
import { useStaticData } from '@powdr/web/src/hooks';

import { DiscountBanner } from '../discount-banner';
import { LiftTicket } from '../lift-ticket';

import { OrderSummary } from './order-summary';
import {
  StyledEcommerceTab, ButtonIcon, CalendarButton, Disclaimer, FindProducts, ProductOptions,
  StyledCalendar, ButtonWrapper, NextButton, SelectDates, StyledLoader, ModalHeader,
  ProductRowsWrapper, ProductRowWrapper, Products, ScrollSpacer, AlertError, StyledErrorIcon,
} from './styles';

export const EcommerceTab = ({
  additionalComponents,
  calendarDisclaimer, calendarTitle, cartCheckoutLink, cartCheckoutButtonText,
  choiceChipsAttributeId, defaultChoiceChip, defaultVariantId, endScreenAttributeId,
  handleDateChange, handleStateReset, hideChoiceChips, productSlug, searchButtonText,
  selectedChoiceChipId, selectedDate, tabTitle, productAttribute,
}) => {
  const { property } = useStaticData();
  const [queryParams, setQueryParams] = useQueryParams({
    [QueryParamNames.PRODUCT]: StringParam,
    [QueryParamNames.VIEW]: StringParam,
  });
  const dispatch = useDispatch();

  const {
    allProductsByDate, calendarStatus, calendarVariant,
    cart, cartCookieResSet, cartStatus, customerGuid, products,
  } = useSelector((state) => state.ecommerce);

  const [productId, setProductId] = useState(false);
  const productEndDate = addMonths(new Date(), 12);

  const [isProductSelectOpen, setIsProductSelectOpen] = useState(false);
  const [isOrderSummaryOpen, setIsOrderSummaryOpen] = useState(false);
  const [cartErrors, setCartErrors] = useState([]);
  const [calendarProducts, setCalendarProducts] = useState([]);
  const [choiceChipsAttrs, setChoiceChipsAttrs] = useState();
  const [endScreenAttrs, setEndScreenAttrs] = useState();
  const [checkedChips, setCheckedChips] = useState(
    selectedChoiceChipId ? [selectedChoiceChipId] : [],
  );
  const [isAddToCartBtnDisabled, setIsAddToCartBtnDisabled] = useState(true);
  const [isAddToCartLoader, setIsAddToCartLoader] = useState(false);
  const [cartItems, setCartItems] = useState({});
  const [subtotal, setSubtotal] = useState(0);
  const [discountMaxPrice, setDiscountMaxPrice] = useState(0);
  const [discountMinPrice, setDiscountMinPrice] = useState(null);
  const [metaCount, setMetaCount] = useState(null);
  const [discountAttrId, setDiscountAttrId] = useState();

  useEffect(() => {
    if (productSlug) {
      // Get all products which has attributes in proper order (used later for grouping/sorting)
      dispatch(getProductBySlug(productSlug));
    }
  }, [dispatch, productSlug]);
  // These two useEffects are tied togeether via the ecommerce action
  useEffect(() => {
    // TODO: If we don't use, remove from destructrue and query in action
    const { attributes, id } = products?.[productSlug] || []; // includes variants if needed
    setProductId(id);

    // Find attributes in AspenWare Payload
    if (choiceChipsAttributeId) {
      // Multi-day in CMS
      // Hard-coded to remove single day below with enum
      setChoiceChipsAttrs(
        attributes?.find(
          (attr) => attr.id === choiceChipsAttributeId,
        )?.values.filter((value) => !hideChoiceChips.includes(value.id)) || [],
      );
    } else {
      // Single-day in CMS
      setChoiceChipsAttrs([
        attributes?.find(
          (attr) => attr.values.find((value) => value.id === defaultChoiceChip),
        )?.values.find((value) => value.id === defaultChoiceChip),
      ]);
    }
    if (endScreenAttributeId) {
      setEndScreenAttrs(
        attributes?.find(
          (attr) => attr.id === endScreenAttributeId,
        )?.values || [],
      );
    }
  }, [
    choiceChipsAttributeId,
    defaultChoiceChip,
    hideChoiceChips,
    products,
    endScreenAttributeId,
    productSlug,
  ]);

  useEffect(() => {
    // Get data for calendar
    // Used to be handled by a click event but now that you can get the calendar via query param
    // we had to add a lot of conditionals to make sure the calendar request kicks off only once
    if (
      productEndDate
      && defaultVariantId
      && queryParams[QueryParamNames.VIEW] === EcommerceViews.CALENDAR
      && calendarStatus === FetchStatus.IDLE
    ) {
      dispatch(getProductVariant(defaultVariantId, new Date(), productEndDate));
    }
  }, [calendarStatus, defaultVariantId, dispatch, productEndDate, queryParams]);
  // These two useEffects are tied togeether via the ecommerce action
  useEffect(() => {
    if (!isEmpty(calendarVariant) && calendarProducts.length === 0) {
      setCalendarProducts(calendarVariant || []);
    }
  }, [calendarVariant, calendarProducts.length]);

  const handleOpenCalendar = () => {
    setQueryParams(
      (prevState) => ({ ...prevState, [QueryParamNames.VIEW]: EcommerceViews.CALENDAR }),
    );
    window.dataLayer.push({
      event: 'select_item', // Fired when user clicks Select Start Date button
      ecommerce: {
        items: [
          {
            item_name: 'Killington Lift Ticket',
            item_list_id: 'category_page',
            item_list_name: 'Category Page',
            affiliation: 'Killington',
            currency: 'USD',
            index: 0,
            item_category: 'Lift Tickets',
            price: null,
          },
        ],
      },
    });
  };

  const handleCloseCalendar = () => {
    setQueryParams(
      (prevState) => ({ ...prevState, [QueryParamNames.VIEW]: undefined }),
    );
  };

  const handleDayClick = useCallback((data) => {
    handleDateChange(data.date);
  }, [handleDateChange]);

  const handleConfirmStartDate = useCallback((date) => {
    setQueryParams(
      (prevState) => ({ ...prevState, [QueryParamNames.VIEW]: undefined }),
    );
    handleDateChange(date);

    // Request all products by date so we can validate choice chips
    dispatch(getAllProductsByDate(productSlug, selectedDate, selectedDate));

    if (productAttribute === ProductAttributes.SINGLE_DAY) {
      setIsProductSelectOpen(true);
    }
  }, [dispatch, handleDateChange, productAttribute, productSlug, selectedDate, setQueryParams]);

  const handleMultiDayFindTickets = useCallback(() => {
    // Request all products by date so we can validate choice chips
    dispatch(getAllProductsByDate(productSlug, selectedDate, selectedDate));
    setIsProductSelectOpen(true);
  }, [dispatch, productSlug, selectedDate]);

  const handleCloseProductSelect = useCallback(() => {
    setIsProductSelectOpen(false);
    setDiscountMaxPrice(0);
    setDiscountMinPrice(null);
    setIsAddToCartLoader(false);
    dispatch(resetEcommerceProducts);
  }, [dispatch]);

  const addProductsToCart = useCallback(() => {
    // Disable add to cart button to prevent multiple submits
    setIsAddToCartBtnDisabled(true);
    setIsAddToCartLoader(true);
    dispatch(
      postAddToCart(Object.entries(cartItems).reduce((arr, [key, value]) => {
        if (value?.count > 0) {
          arr.push({
            selectedDate, productId, key, value: value.count,
          });
        }
        return arr;
      }, [])),
    );

    window.dataLayer.push({
      event: 'add_to_cart', // Fired when user clicks Add to Cart button
      ecommerce: {
        items: Object.values(cartItems).map((item) => ((item.count > 0) ? {
          item_name: 'Killington Lift Ticket',
          item_list_id: 'product_page',
          item_list_name: 'Product Page',
          affiliation: 'Killington',
          currency: 'USD',
          index: 0,
          item_category: 'Lift Tickets',
          price: numberToDollar(item.value),
          quantity: item.count,
          item_details: {
            ...item,
          },
        } : null)).filter((x) => x),
      },
    });
  }, [cartItems, dispatch, productId, selectedDate]);

  // Temp solution for closing order summary on back button
  // TODO: this would better be managed via views per modal
  useEffect(() => {
    dispatch(resetEcommerceCart);
  }, [dispatch, queryParams]);

  useEffect(() => {
    if (!isEmpty(cartItems)) {
      setSubtotal(
        Object.keys(cart || {})
          .reduce((acc, item) => {
            if (cart[item]?.item?.id && !cart[item]?.errors?.length > 0) {
              return acc + (cartItems[item].price * cartItems[item].count);
            }
            return 0;
          }, 0),
      );
    } else {
      setIsOrderSummaryOpen(false);
    }
  }, [cart, cartItems]);

  useEffect(() => {
    // Check for AW cart/customer (in turn means there's a Nop Id)
    if (isProductSelectOpen && cartStatus === FetchStatus.IDLE) {
      dispatch(getCart());
    }
  }, [cartStatus, dispatch, isProductSelectOpen]);

  const onClickAddToCart = useCallback(() => {
    // - if cart status failed create a guest customer id w AW mutation
    if (cartStatus === FetchStatus.ERROR && !customerGuid) {
      dispatch(createGuestCustomer());
    } else {
      addProductsToCart();
    }
  }, [addProductsToCart, cartStatus, customerGuid, dispatch]);
  // This useEffect is tied to the ecommerce action to create a customer
  useEffect(() => {
    // New Customer GUID created create NOP cookie then add products
    if (customerGuid) {
      dispatch(setMiddlewareCookie(customerGuid));
    }
  }, [addProductsToCart, customerGuid, dispatch, property]);
  // This useEffect is tied to the cart cookie being created in the Gatsby middleware
  useEffect(() => {
    // New Customer GUID created create NOP cookie then add products
    if (!isEmpty(cartCookieResSet)) {
      addProductsToCart();
    }
  }, [addProductsToCart, cartCookieResSet, customerGuid, dispatch, property]);
  // This last useEffect is attached to response from AW Cart API
  useEffect(() => {
    const cartRes = Object.values(cart) || [];
    // AspenWare AddToCart API error handling in UX
    if (cartRes.length > 0) {
      // Check if every add to cart response threw an error from AW
      if (cartRes.every((item) => item?.errors?.length > 0)) {
        // Get unique cart errors and set in array
        setCartErrors(
          [...new Set(
            cartRes.reduce((errors, res) => {
              if (res.errors?.[0]?.message) { errors.push(res.errors?.[0]?.message); }
              return errors;
            }, []),
          ) || []],
        );
        // Don't go to cart summary, trigger error dialog and reset button spinner
        setIsAddToCartLoader(false);
        setIsAddToCartBtnDisabled(false);
        // dispatch(resetEcommerceCart);
      } else if (cartRes.some((item) => item?.errors?.length > 0)) {
        // Get unique cart errors and set in array
        setCartErrors(
          [...new Set(
            cartRes.reduce((errors, res) => {
              if (res.errors?.[0]?.message) { errors.push(res.errors?.[0]?.message); }
              return errors;
            }, []),
          ) || [],
          ],
        );
        // Go to cart summary but trigger error dialog
        setIsProductSelectOpen(false);
        setIsOrderSummaryOpen(true);
        dispatch(getCart());
      } else {
        // All responses worked
        setIsProductSelectOpen(false);
        setIsOrderSummaryOpen(true);
        dispatch(getCart());
      }
    }
  }, [cart, dispatch]);

  useEffect(() => {
    if (cartErrors.length > 0) {
      dispatch(logging(cartErrors, customerGuid));
    }
  }, [cartErrors, customerGuid, dispatch]);

  useEffect(() => {
    if (queryParams[QueryParamNames.VIEW] === EcommerceViews.CALENDAR) {
      setIsProductSelectOpen(false);
      dispatch(resetEcommerceProducts);
      window.dataLayer.push({
        event: 'view_calendar', // Fired when the calendar pops up
        ecommerce: {
          items: [
            {
              item_name: 'Killington Lift Ticket',
              item_list_id: 'calendar_page',
              item_list_name: 'Calendar Page',
              affiliation: 'Killington',
              currency: 'USD',
              index: 0,
              item_brand: null,
              item_category: 'Lift Tickets',
              price: null,
            },
          ],
        },
      });
    }
  }, [dispatch, queryParams]);

  useEffect(() => {
    if (isProductSelectOpen) {
      window.dataLayer.push({
        event: 'view_item', // Fired when the product selection screen is opened
        ecommerce: {
          items: [
            {
              index: 0,
              item_list_id: 'product_page',
              item_list_name: 'Product Page',
              item_name: 'Killington Lift Ticket',
              affiliation: 'Killington',
              currency: 'USD',
              item_category: 'Lift Tickets',
              price: null,
            },
          ],
        },
      });
    }
  }, [isProductSelectOpen]);

  const handleCloseOrderSummary = useCallback(() => {
    // Reset to what? Initial screen?
    // Reset whatever states and redux needs to be reset
    dispatch(resetEcommerceStore);
    handleStateReset();
    setQueryParams(
      // The product tab name below will need to be dynamic when we build other products
      (prevState) => ({ ...prevState, [QueryParamNames.PRODUCT]: tabTitle }),
    );
    // setTabParam(null);
  }, [dispatch, handleStateReset, setQueryParams, tabTitle]);

  const handleTicketChange = useCallback((ticket) => {
    setCartItems((prevState) => ({
      ...prevState,
      [ticket.id]: {
        ...ticket,
      },
    }));
  }, []);

  useEffect(() => {
    const cartCount = Object.values(cartItems || {}).reduce((acc, ticket) => acc + ticket.count, 0);
    setIsAddToCartBtnDisabled(cartCount === 0);
  }, [cartItems]);

  // Look up the default variant max price, not pretty but gets there
  // Can't much control AspenWares data but maybe we can find a better way to manage
  useEffect(() => {
    setDiscountMaxPrice(
      allProductsByDate?.[productSlug]?.variants?.find(
        (variant) => variant.id === defaultVariantId,
      )?.pricing?.days[0]?.compareToPrice || 0,
    );
  }, [allProductsByDate, defaultVariantId, productSlug]);

  const renderProducts = useCallback((rowData) => endScreenAttrs?.map((product) => {
    if (!rowData || !allProductsByDate) {
      return null;
    }

    const currentProduct = allProductsByDate?.[productSlug]
      ?.variants?.find(
        // Find match in appProductsByDate where both attribute ids
        // match the attribute ids of the nested loop eg. chips then end screen
        (p) => p.attributeValues.map(
          ({ id }) => id,
        ).every((id) => [rowData.id, product.id].includes(id)),
      );
    const {
      availability,
      pricing,
      id,
    } = currentProduct || {};
    const { isAvailable } = availability?.[0] || false;
    // TODO: this seems off and might be causing the banner % to be broken
    const { currentPrice } = pricing?.days?.[0] || {};

    // TODO: when we have more products replace with factory
    if (!id) {
      return null;
    }
    return (
      <LiftTicket
        age={product.name}
        date={selectedDate}
        description={product.description}
        duration={rowData.name}
        isAvailable={isAvailable}
        key={id}
        max={99}
        min={0}
        handleTicketChange={handleTicketChange}
        price={currentPrice}
        productAttribute={productAttribute}
        variantId={id}
      />
    );
  }), [
    allProductsByDate,
    endScreenAttrs,
    handleTicketChange,
    productAttribute,
    productSlug,
    selectedDate,
  ]);

  // We were handling discount banner data from AW while rendering product rows/products but
  // it's causing trouble, so breaking out the gathering of discount data into a couple useEffects
  useEffect(() => {
    choiceChipsAttrs
      ?.filter((chip) => chip?.id === checkedChips[checkedChips.length - 1]).forEach((row) => {
        const count = parseInt(
          row?.metadata?.find((meta) => meta.name === EcommerceMeta.COUNT)?.value,
          10,
        ) || null;
        if (count) {
          setMetaCount(count);
        }
        setDiscountAttrId(row.id);
      });
  }, [checkedChips, choiceChipsAttrs, metaCount]);
  useEffect(() => {
    const discountProduct = allProductsByDate?.[productSlug]
      ?.variants?.find(
        // Find match in allProductsByDate where both attribute ids
        // match the attribute ids of the nested loop eg. chips then end screen
        (p) => p.attributeValues.map(
          ({ id }) => id,
        ).every((id) => [discountAttrId, endScreenAttrs?.[0]?.id].includes(id)),
      );
    if (discountProduct) {
      const { currentPrice } = discountProduct.pricing.days[0];
      if (metaCount) {
        setDiscountMinPrice(currentPrice / metaCount);
      }
    }
  }, [allProductsByDate, checkedChips, endScreenAttrs, metaCount, discountAttrId, productSlug]);

  const handleCheckedProduct = useCallback((id) => (checkedChips.includes(id)
    ? setCheckedChips((prevState) => prevState.filter((val) => val !== id))
    : setCheckedChips((prevState) => [...prevState, id])), [checkedChips]);

  const renderProductRows = useCallback(() => choiceChipsAttrs
    ?.filter((chip) => checkedChips.includes(chip.id))
    .map((row) => {
      const rowData = choiceChipsAttrs?.find(
        (attr) => attr?.id === row.id,
      ) || {};
      return (
        <React.Fragment key={row.id}>
          <h2>{rowData.name}</h2>
          <ProductRowWrapper className={row.id}>
            <Products>{renderProducts(rowData)}</Products>
          </ProductRowWrapper>
        </React.Fragment>
      );
    }), [checkedChips, choiceChipsAttrs, renderProducts]);

  return (
    <StyledEcommerceTab>
      <SelectDates>
        <CalendarButton
          onClick={handleOpenCalendar}
          icon={<ButtonIcon name="content-calendar" width="20" />}
        >
          { !selectedDate
            ? calendarTitle
            : `${productAttribute === ProductAttributes.MULTI_DAY ? 'Starting ' : ''}${format(selectedDate, DateFormats.FULL_DATE_LONG)}`}
        </CalendarButton>
      </SelectDates>
      <FindProducts>
        { !!(choiceChipsAttrs?.length > 1 && selectedDate) && (
        <ProductOptions>
          {
            choiceChipsAttrs.map((attr) => (
              <Checkbox
                checked={
                  checkedChips.includes(attr.id)
                    ? CheckedState.CHECKED
                    : CheckedState.UNCHECKED
                }
                isChips
                key={attr.id}
                label={attr.name}
                name={attr.id}
                onChange={handleCheckedProduct}
              />
            ))
          }
        </ProductOptions>
        )}
        <NextButton
          className="search"
          disabled={!((isFuture(selectedDate) || isToday(selectedDate)) && checkedChips.length > 0)}
          type="button"
          onClick={handleMultiDayFindTickets}
        >
          {searchButtonText}
        </NextButton>
      </FindProducts>
      { queryParams[QueryParamNames.VIEW] === EcommerceViews.CALENDAR
        && (
        <Modal handleCloseModal={handleCloseCalendar} isFullScreen>
          {/* TODO: Need a product type so tickets isn't hard-coded */}
          <iframe hidden src={cartCheckoutLink} title="Purchase Tickets" />
          <ModalHeader>{`${calendarTitle}`}</ModalHeader>
          { calendarProducts.length > 0
            ? (
              <>
                <StyledCalendar
                  daysContent={calendarProducts}
                  defaultDate={selectedDate}
                  handleDayClick={handleDayClick}
                  isShowPrice={productAttribute === ProductAttributes.SINGLE_DAY}
                />
                <NextButton
                  className="continue"
                  disabled={!selectedDate}
                  onClick={() => handleConfirmStartDate(selectedDate)}
                >
                  Continue
                </NextButton>
                <Disclaimer>{ReactHtmlParser(calendarDisclaimer.value)}</Disclaimer>
                <ScrollSpacer />
              </>
            )
            : (
              <StyledLoader
                classAnimationEffect="loader-animated infinite"
                size="60"
              />
            )}
        </Modal>
        )}
      { isProductSelectOpen
        && (
        <Modal handleCloseModal={handleCloseProductSelect} isFullScreen>
          {/* TODO: Need a product type so tickets isn't hard-coded */}
          <ModalHeader className="ticket-select-header">{`${tabTitle} Tickets: Select Your Tickets`}</ModalHeader>
          { allProductsByDate?.[productSlug]
            ? (
              <>
                <ProductRowsWrapper>
                  <DiscountBanner maxPrice={discountMaxPrice} minPrice={discountMinPrice} />
                  {renderProductRows()}
                </ProductRowsWrapper>
                <ButtonWrapper>
                  <NextButton disabled={isAddToCartBtnDisabled} className="add-to-cart" onClick={onClickAddToCart}>
                    {(isAddToCartLoader) ? <Loader className="data-await-loader" /> : 'Add to Cart'}
                  </NextButton>
                </ButtonWrapper>
                <ScrollSpacer />
              </>
            )
            : (
              <StyledLoader
                classAnimationEffect="loader-animated infinite"
                size="60"
              />

            )}
        </Modal>
        )}
      { isOrderSummaryOpen
        && (
        <Modal handleCloseModal={handleCloseOrderSummary} isFullScreen>
          <OrderSummary
            additionalComponents={additionalComponents}
            cartCheckoutLink={cartCheckoutLink}
            cartCheckoutButtonText={cartCheckoutButtonText}
            cart={cart}
            cartItems={cartItems}
            discountMaxPrice={discountMaxPrice}
            discountMinPrice={discountMinPrice}
            handleCloseOrderSummary={handleCloseOrderSummary}
            subtotal={subtotal}
          />
        </Modal>
        )}

      { cartErrors.length > 0
        && (
        <Modal isDialogAlert>
          {Object.values(cart).every((item) => item?.errors?.length > 0) ? (
            <AlertError>
              <StyledErrorIcon name="ui-error" width="50" />
              <div>
                <h2>Please Try Again</h2>
                <div className="errors">
                  We were unable to add lift tickets to your cart.
                  <ul>
                    {cartErrors.map((error) => <li key={error}>{error}</li>)}
                  </ul>
                </div>
                <Button onClick={() => setCartErrors([])}>Try Again</Button>
              </div>
            </AlertError>
          ) : (
            <AlertError>
              <StyledErrorIcon name="ui-error" width="50" />
              <div>
                <h2>Something Went Wrong</h2>
                <div className="errors">
                  We were unable to add some of your selected tickets to your cart.
                  <ul>
                    {cartErrors.map((error) => <li key={error}>{error}</li>)}
                  </ul>
                </div>
                <Button onClick={() => setCartErrors([])}>Continue</Button>
              </div>
            </AlertError>
          )}
        </Modal>
        )}
    </StyledEcommerceTab>
  );
};

EcommerceTab.propTypes = {
  additionalComponents: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  calendarDisclaimer: PropTypes.instanceOf(Object),
  calendarTitle: PropTypes.string,
  cartCheckoutButtonText: PropTypes.string,
  cartCheckoutLink: PropTypes.string,
  choiceChipsAttributeId: PropTypes.string,
  defaultChoiceChip: PropTypes.string,
  defaultVariantId: PropTypes.string,
  endScreenAttributeId: PropTypes.string,
  handleDateChange: PropTypes.func,
  handleStateReset: PropTypes.func,
  hideChoiceChips: PropTypes.arrayOf(PropTypes.string),
  productAttribute: PropTypes.string,
  productSlug: PropTypes.string,
  searchButtonText: PropTypes.string,
  selectedChoiceChipId: PropTypes.string,
  selectedDate: PropTypes.instanceOf(Date),
  tabTitle: PropTypes.string,
};

EcommerceTab.defaultProps = {
  additionalComponents: [],
  calendarDisclaimer: {},
  calendarTitle: '',
  cartCheckoutButtonText: '',
  cartCheckoutLink: '',
  choiceChipsAttributeId: '',
  defaultChoiceChip: '',
  defaultVariantId: '',
  endScreenAttributeId: '',
  handleDateChange: () => {},
  handleStateReset: () => {},
  hideChoiceChips: [],
  productAttribute: '',
  productSlug: '',
  searchButtonText: '',
  selectedChoiceChipId: '',
  selectedDate: null,
  tabTitle: '',
};
