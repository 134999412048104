import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getRoads } from '@powdr/stores';

export const useRoadStatus = (roadUUID) => {
  if (!roadUUID) return null;
  const dispatch = useDispatch();
  const useRoadsData = useSelector((state) => state.roads);
  const { data } = useRoadsData;
  const [roadStatus, setRoadStatus] = useState(null);

  useEffect(() => {
    dispatch(getRoads());
  }, [dispatch]);

  useEffect(() => {
    if (data && data?.length > 0) {
      setRoadStatus(data.find((r) => r.id === roadUUID));
    }
  }, [data, roadUUID]);

  return roadStatus;
};
