import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';

import { Breakpoints } from '@powdr/constants';
import { Link } from '@powdr/components';

export const StyledUiImage = styled.div`
  display: grid;
`;

export const ImageFrame = styled.div`
  position: relative;
  grid-area: 1/1;
  height: 100%;
  width: 100%;
  ${({ isImageShadow }) => ((isImageShadow) ? 'box-shadow: 0 11px 11px rgba(0, 0, 0, 0.2);' : '')};

  ${Breakpoints.mobile(css`
    ${({ $isVideo }) => (($isVideo) ? 'height: auto;' : '')};
  `)}

  img {
    display: block;
    max-width: 100%;
    height: auto;
    margin-bottom: 0;
  }
`;

export const StyledGatsbyImage = styled(GatsbyImage)``;

export const StyledImage = styled.img``;

export const StyledLink = styled(Link)`
  z-index: 1;
  position: relative;
  grid-area: 1/1;
  width: 100%;
  height: 100%;
`;
