import styled, { css } from 'styled-components';

import { Link } from '@powdr/components';
import {
  Components, Breakpoints, ThemeProps as Prop, FontSize, FontFamily,
} from '@powdr/constants';
import { componentColor, getFontStyles } from '@powdr/utils';

const CPNT = Components.FOOTER_NAV;

export const StyledFooterNav = styled.div`
  min-height: 110px;
  width: 100%;
`;

export const LinkList = styled.ul`
  text-align: left;
`;

export const LinkItem = styled.li`
  display: block;
  line-height: 1.5;
`;

export const FooterLink = styled(Link)`
  ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.REG30, undefined, undefined)};
  color: ${() => componentColor(Prop.LINK_TXT, CPNT)};
  transition: color 0.25s ease-out;

  &:hover, &:active, &:focus{
    transition: color 0.25s ease-out;
    color: ${() => componentColor(Prop.LINK_TXT_HOVER, CPNT)};
  }

  &:focus {
    outline: 2px solid ${() => componentColor(Prop.LINK_TXT_HOVER, CPNT)};
    outline-offset: 2px;
  }

  ${Breakpoints.large(css`
    ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.REG40, undefined, undefined)};
  `)}

  ${Breakpoints.larger(css`
    ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.REG40, undefined, undefined)};
  `)}
`;
