import styled, { css } from 'styled-components';

import { Breakpoints, ContentBlockGroupLayout } from '@powdr/constants';

export const StyledContentBlockGroup = styled.div``;

const oneOnLeftGrid = css`
  .cbgroup-block-0 { grid-area: 1 / 1 / 3 / 2; }
  .cbgroup-block-1 { grid-area: 1 / 2 / 2 / 3; }
  .cbgroup-block-2 { grid-area: 2 / 2 / 3 / 3; }
`;

const oneOnRightGrid = css`
  .cbgroup-block-0 { grid-area: 1 / 1 / 2 / 2; }
  .cbgroup-block-1 { grid-area: 2 / 1 / 3 / 2; }
  .cbgroup-block-2 { grid-area: 1 / 2 / 3 / 3; }
`;

const layouts = {
  [ContentBlockGroupLayout.NO_LAYOUT]: null,
  [ContentBlockGroupLayout.ONE_ON_LEFT]: oneOnLeftGrid,
  [ContentBlockGroupLayout.ONE_ON_RIGHT]: oneOnRightGrid,
  [ContentBlockGroupLayout.ONE_ON_BOTTOM]: oneOnLeftGrid,
};

export const LayoutWrapper = styled.div`
  display: grid;
  ${Breakpoints.mobile(css`display: flex; flex-direction: column;`)}

  ${({ $layout, $padding }) => (($layout !== ContentBlockGroupLayout.NO_LAYOUT) ? `
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    gap: ${$padding}px;
  ` : Breakpoints.desktopOnly(css`
      grid-template-columns: repeat(2, 1fr);
      grid-gap: ${() => `${$padding}px;`};
  `)
  )}

  ${({ $layout }) => layouts[$layout]};
`;
