import styled, { css } from 'styled-components';

import {
  ThemeProps as Prop, Breakpoints, FontFamily, FontSize,
  StaticProfiles,
} from '@powdr/constants';
import { getFontStyles, themeUtil } from '@powdr/utils';

export const StyledTvPicker = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 0 5%;
  gap: 50px;
`;

export const LogoWrapper = styled.div`
  svg {
    height: 150px;
    width: auto;
  }
`;

export const NavHeader = styled.h1`
  ${() => getFontStyles(FontFamily.PRIMARY_FONT)};
  text-align: center;
  font-size: 5vh;
  line-height: 1.1;
  color: ${() => themeUtil(Prop.HEADER, null, StaticProfiles.TV)};
`;

export const TvNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: ${() => themeUtil(Prop.HEADER, null, StaticProfiles.TV)};
`;

export const NavItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  ${() => getFontStyles(FontFamily.BASE_FONT, FontSize.LRG50)};
  border-right: 2px solid ${() => themeUtil(Prop.HEADER, null, StaticProfiles.TV)};
  padding: 25px 50px;

  &:last-child {
    border: none;
  }
`;

export const Name = styled.div``;

export const Link = styled.a`
  color: ${() => themeUtil(Prop.LINK, null, StaticProfiles.TV)};
`;
