import React from 'react';

import { Icon } from '@powdr/components';
import { trailLegendSummer } from '@powdr/constants';

import { StyledLayoutGrid } from './styles';

export const DorLegendTrailTypeRatingRender = () => (
  <StyledLayoutGrid>
    {trailLegendSummer.map((diff) => {
      const { icons } = diff;
      return (
        <div key={diff.label} className="col">
          <div className="header">
            <h4>{diff.label}</h4>
            <a aria-label="modal url" href={diff.modal.url}>
              <Icon
                className="icon"
                name="content-info-open"
                width="16"
                height="16"
              />
            </a>
          </div>
          <div className="content">
            {icons?.map((icon) => (
              <span key={icon.icon} className="icon-wrap">
                <Icon
                  className={`icon ${icon.icon}`}
                  name={icon.icon}
                  width={icon.width}
                  height={icon.height}
                />
                <p>{icon.label}</p>
              </span>
            )) || []}
          </div>
        </div>
      );
    })}
  </StyledLayoutGrid>
);

export default DorLegendTrailTypeRatingRender;
