/* eslint-disable max-len */
import PropTypes from 'prop-types';
import React, {
  useState, useMemo, useCallback, Component,
} from 'react';
import { useQueryParams, StringParam } from 'use-query-params';

import {
  ContentBlock, Checkbox,
} from '@powdr/components';
import {
  QueryParamNames, CheckedState, CarouselControlPlacement, CarouselPaginationType,
  CarouselTransitionEffects,
  Components,
} from '@powdr/constants';
import { useSeason } from '@powdr/hooks';
import {
  colorProfileByIndex, prettifyMachineName, isArraysHaveSharedValue, getAlternatingLayoutState, doubleDigitizer,
} from '@powdr/utils';

import {
  StyledGrid, GridPagination,
  GridFilters, FilterHeader, FilterToggles,
  MobileCarousel,
} from './styles';

const gridParams = {
  [QueryParamNames.CURRENT_PAGE]: StringParam,
  [QueryParamNames.FILTER]: StringParam,
};

// TODO: itemPaddingOff is an explicit on/off in CMS so we have it here but might consider disabling it
// across the board
export const Grid = ({
  id,
  className,
  parentColorProfile,
  colorProfile,
  columnOption,
  columnOptionMobile,
  columnLayout,
  blockType,
  columnGap,
  rowGap,
  columnGapMobile,
  rowGapMobile,
  itemPaddingOff,
  gridTopPadding,
  gridBottomPadding,
  gridTopPaddingMobile,
  gridBottomPaddingMobile,
  itemsPerPage,
  isExpandCollapseBlocks,
  isUsingBrandingColors,
  isCenterAlignUnevenRows,
  alternateBlockLayout,
  isShowIndexes,
  isHideTags,
  isMobileCarousel,
  componentSeason,
  relationships,
  filterName,
}) => {
  if (!blockType) return null;
  const { checkSeasonality } = useSeason();
  const decodeFilters = (filters) => decodeURIComponent(filters).split(',');
  const encodeFilters = (filters) => filters.map((k) => k).join(',');
  const [urlQueryParams, setUrlQueryParams] = useQueryParams(gridParams);
  const [currentPage, setCurrentPage] = useState(
    (urlQueryParams?.[QueryParamNames.CURRENT_PAGE])
      ? parseInt(urlQueryParams?.[QueryParamNames.CURRENT_PAGE], 10)
      : 1,
  );
  const [activeFilters, setActiveFilters] = useState(
    (urlQueryParams?.[QueryParamNames.FILTER])
      ? decodeFilters(urlQueryParams?.[QueryParamNames.FILTER])
      : [],
  );

  const initialContentBlocks = relationships?.contentBlocks
    .filter((block) => ((checkSeasonality(block.season) && !block.isHideBlock) ? block : null))
    .filter(((x) => !!x));
  const isPagination = (!!itemsPerPage);
  const isFilterableGrid = (!!filterName);
  const trueColorProfile = (colorProfile !== null) ? colorProfileByIndex(colorProfile) : parentColorProfile;

  // get all filters by checking all categories present in the content blocks
  const filterList = useMemo(() => {
    if (isFilterableGrid) {
      const filterItems = [];
      initialContentBlocks.forEach((block) => {
        if (block[filterName]) {
          const tags = block[filterName].split(', ');
          tags.forEach((tag) => { filterItems.push(tag); });
        }
      });

      return [...new Set(filterItems)];
    }

    return [];
  }, [filterName, initialContentBlocks, isFilterableGrid]);

  const getFilteredContentBlocks = useCallback(
    () => ((isFilterableGrid && activeFilters.length !== 0 && activeFilters.length !== filterList.length)
      ? initialContentBlocks.map((block) => {
        if (block[filterName]) {
          const tags = (!Array.isArray(block[filterName]))
            ? block[filterName].split(', ')
            : [block[filterName]];

          return (isArraysHaveSharedValue(tags, activeFilters)) ? block : null;
        }

        return null;
      }).filter((x) => x)
      : initialContentBlocks
    ),
    [isFilterableGrid, activeFilters, filterList, filterName, initialContentBlocks],
  );

  const getContentBlocksForCurrentPage = useCallback(
    () => ((isPagination) ? getFilteredContentBlocks().slice(
      (currentPage - 1) * itemsPerPage,
      ((currentPage - 1) * itemsPerPage) + itemsPerPage,
    ) : getFilteredContentBlocks()),
    [itemsPerPage, currentPage, isPagination, getFilteredContentBlocks],
  );

  // update current pagination page to passed value
  const updateCurrentPage = useCallback((page) => {
    setCurrentPage(page);
    setUrlQueryParams((prevState) => ({ ...prevState, [QueryParamNames.CURRENT_PAGE]: page }));
  }, [setCurrentPage, setUrlQueryParams]);

  const toggleFilter = (filter) => {
    // when filters are updated, reset the current page
    updateCurrentPage(1);

    if (activeFilters.includes(filter)) {
      setActiveFilters(activeFilters.filter((f) => f !== filter));
      setUrlQueryParams((prevState) => ({ ...prevState, [QueryParamNames.FILTER]: encodeFilters(activeFilters.filter((f) => f !== filter)) }));
    } else {
      setActiveFilters([...activeFilters, filter]);
      setUrlQueryParams((prevState) => ({ ...prevState, [QueryParamNames.FILTER]: encodeFilters([...activeFilters, filter]) }));
    }
  };

  const getTextDecoration = (index) => ((isShowIndexes) ? doubleDigitizer(index) : null);
  const columnPaddingCheck = (v) => v || ((itemPaddingOff || v === 0) ? 0 : 1);
  const rowPaddingCheck = (v) => v || ((itemPaddingOff || v === 0) ? 0 : 10);

  return (
    checkSeasonality(componentSeason) && (
      <>
        {(isFilterableGrid) && (
          <GridFilters>
            <FilterHeader>{`Select ${prettifyMachineName(filterName)}`}</FilterHeader>
            <FilterToggles>
              {filterList.map((filter) => (
                <li key={filter}>
                  <Checkbox
                    colorProfile={trueColorProfile}
                    onChange={() => toggleFilter(filter)}
                    name={filter}
                    label={filter}
                    checked={activeFilters.includes(filter) ? CheckedState.CHECKED : CheckedState.UNCHECKED}
                  >
                    {filter}
                  </Checkbox>
                </li>
              ))}
            </FilterToggles>
          </GridFilters>
        )}
        <StyledGrid
          key={id}
          className={className}
          disableDesktopWrap={(columnOption === getContentBlocksForCurrentPage()?.length)}
          isCenterAlignUnevenRows={isCenterAlignUnevenRows}
          $topPadding={(gridTopPadding === null) ? 10 : gridTopPadding}
          $topPaddingMobile={(gridTopPaddingMobile === null) ? 10 : gridTopPaddingMobile}
          $bottomPadding={(gridBottomPadding === null) ? 10 : gridBottomPadding}
          $bottomPaddingMobile={(gridBottomPaddingMobile === null) ? 10 : gridBottomPaddingMobile}
          $columnGap={columnPaddingCheck(columnGap)}
          $columnGapMobile={columnPaddingCheck((columnGapMobile !== null) ? columnGapMobile : columnGap)}
          $rowGap={rowPaddingCheck(rowGap)}
          $rowGapMobile={rowGapMobile}
          $isMobileCarousel={isMobileCarousel}
        >
          {getContentBlocksForCurrentPage()?.map((block, index) => {
            const { id: blockId, viewImage } = block;

            return (
              <ContentBlock
                contentBlock={block}
                key={blockId}
                index={index}
                blockType={blockType}
                columnLayout={columnLayout}
                columnOption={columnOption}
                columnOptionMobile={columnOptionMobile}
                alternateBlockLayoutState={getAlternatingLayoutState(index, alternateBlockLayout)}
                isExpandCollapseBlocks={isExpandCollapseBlocks}
                isUsingBrandingColors={isUsingBrandingColors}
                itemPaddingAmount={columnPaddingCheck(columnGap)}
                itemPaddingAmountMobile={columnPaddingCheck((columnGapMobile !== null) ? columnGapMobile : columnGap)}
                textDecoration={getTextDecoration(index + 1)}
                colorProfile={trueColorProfile}
                viewImage={viewImage}
                isHideTags={isHideTags}
              />
            );
          })}
        </StyledGrid>
        {(isMobileCarousel) && (
          <MobileCarousel
            colorProfile={trueColorProfile}
            controlSettings={{
              pagination: {
                type: CarouselPaginationType.TEXT_THUMBNAILS,
                placement: CarouselControlPlacement.BOTTOM,
                customBullets: getContentBlocksForCurrentPage().map((_item, i) => ({
                  name: (getContentBlocksForCurrentPage().length === i + 1) ? doubleDigitizer(i + 1) : `${doubleDigitizer(i + 1)} /`,
                  height: 15,
                })),
              },
              decoration: {
                placement: CarouselControlPlacement.BOTTOM,
              },
            }}
            componentTheme={Components.GRID}
            // isCenteredSlides
            // transitionEffect={CarouselTransitionEffects.COVERFLOW}
            isInfiniteScroll={false}
            slidesPerView={1.1}
            spaceBetweenSlides={15}
          >
            {getContentBlocksForCurrentPage()?.map((block, index) => {
              const { id: blockId, viewImage } = block;

              return (
                <ContentBlock
                  contentBlock={block}
                  key={blockId}
                  index={index}
                  blockType={blockType}
                  columnLayout={columnLayout}
                  columnOption={columnOption}
                  columnOptionMobile={columnOptionMobile}
                  alternateBlockLayoutState={getAlternatingLayoutState(index, alternateBlockLayout)}
                  isExpandCollapseBlocks={isExpandCollapseBlocks}
                  isUsingBrandingColors={isUsingBrandingColors}
                  itemPaddingAmount={columnPaddingCheck}
                  textDecoration={getTextDecoration(index + 1)}
                  colorProfile={trueColorProfile}
                  viewImage={viewImage}
                  isHideTags={isHideTags}
                />
              );
            })}
          </MobileCarousel>
        )}
        {(isPagination) && (
          <GridPagination
            colorProfile={trueColorProfile}
            currentPage={currentPage}
            totalCount={getFilteredContentBlocks().length}
            pageSize={itemsPerPage}
            onPageChange={(page) => updateCurrentPage(page)}
          />
        )}
      </>
    )
  );
};

Grid.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  parentColorProfile: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  colorProfile: PropTypes.number,
  columnOption: PropTypes.number,
  columnOptionMobile: PropTypes.number,
  columnLayout: PropTypes.string,
  blockType: PropTypes.string,
  gridTopPadding: PropTypes.number,
  gridBottomPadding: PropTypes.number,
  gridTopPaddingMobile: PropTypes.number,
  gridBottomPaddingMobile: PropTypes.number,
  columnGap: PropTypes.number,
  rowGap: PropTypes.number,
  columnGapMobile: PropTypes.number,
  rowGapMobile: PropTypes.number,
  itemPaddingOff: PropTypes.bool,
  isExpandCollapseBlocks: PropTypes.bool,
  isUsingBrandingColors: PropTypes.bool,
  isShowIndexes: PropTypes.bool,
  alternateBlockLayout: PropTypes.string,
  isCenterAlignUnevenRows: PropTypes.bool,
  itemsPerPage: PropTypes.number,
  filterName: PropTypes.string,
  isHideTags: PropTypes.bool,
  isMobileCarousel: PropTypes.bool,
  componentSeason: PropTypes.string,
  relationships: PropTypes.shape({
    id: PropTypes.string,
    contentBlocks: PropTypes.arrayOf(
      PropTypes.shape({}),
    ),
  }).isRequired,
};

Grid.defaultProps = {
  id: '',
  className: '',
  parentColorProfile: null,
  colorProfile: null,
  columnOption: 1,
  columnOptionMobile: 1,
  columnLayout: null,
  blockType: null,
  gridTopPadding: 10,
  gridBottomPadding: 10,
  gridTopPaddingMobile: 10,
  gridBottomPaddingMobile: 10,
  columnGap: 1,
  rowGap: null,
  columnGapMobile: 1,
  rowGapMobile: null,
  itemPaddingOff: null,
  isExpandCollapseBlocks: false,
  isUsingBrandingColors: false,
  isCenterAlignUnevenRows: null,
  alternateBlockLayout: null,
  isShowIndexes: false,
  itemsPerPage: null,
  isHideTags: false,
  isMobileCarousel: false,
  filterName: null,
  componentSeason: null,
};
