import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';

import { useMutationObserver } from '@powdr/hooks';
import { isBuildTime } from '@powdr/utils';

export const ChatSatisfi = ({ pageId }) => {
  if (isBuildTime()) return null;

  const listener = (MutationRecord) => {
    MutationRecord.forEach((records) => {
      records.addedNodes.forEach(() => {
        const ref = document.getElementById('divSatisfiChat');
        if (ref) {
          const chatButton = ref.getElementsByClassName(
            'satisfi_chat-button',
          )[0];
          chatButton.setAttribute('aria-label', 'Satisfi help chat button');
          chatButton.setAttribute('class', 'bounce satisfi_chat-button');

          // clear bounce animation class
          const interval = setInterval(() => {
            chatButton.setAttribute('class', 'satisfi_chat-button');
          }, 10000);

          return () => clearInterval(interval);
        }

        return null;
      });
    });
  };

  useMutationObserver(document?.body, listener, {
    attributes: false,
    characterData: false,
    childList: true,
    subtree: false,
  });

  return (
    pageId && (
      <Helmet>
        <script
          id="satisfiScript"
          type="text/javascript"
          src={`https://chat.satis.fi/popup/embedder?popupId=${pageId}`}
          async=""
        />
      </Helmet>
    )
  );
};

ChatSatisfi.propTypes = {
  pageId: PropTypes.string.isRequired,
};

ChatSatisfi.defaultProps = {};
