import styled from 'styled-components';

export const StyledBannerAd = styled.div``;

export const AdFrame = styled.iframe`
  border: none;
  display: inline-block;
  max-width: 100%;
  width: 100%;
  margin-top: ${({ marginTop }) => `${marginTop}px`};
  margin-bottom: ${({ marginBottom }) => `${marginBottom}px`};
  height: ${({ sizes }) => {
    const attr = sizes.split('x');
    const height = `${Math.floor(Number(attr[1]) + 20)}px`;

    return height;
  }};

  .left,
  .right,
  .center {
    margin-bottom: 15px;
  }

  .left,
  .right {
    display: inline-block;
  }

  .left {
    float: left;
  }

  .right {
    float: right;
  }

  .center {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
`;
