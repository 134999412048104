import { useState, useEffect } from 'react';

export const useIsInView = (ref, rootMargin = 0, threshold) => {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const refCopy = ref;
    const observer = new IntersectionObserver(
      ([entry]) => { setIntersecting(entry.isIntersecting); },
      { rootMargin: `${rootMargin}px`, threshold },
    );

    if (refCopy.current) observer.observe(refCopy.current);

    return () => {
      try {
        observer?.unobserve(refCopy.current);
      } catch (err) {
        console.error('Error in useIsInView unobserve: ', err, refCopy.current);
      }
    };
  }, [ref, rootMargin, threshold]);

  return isIntersecting;
};
