import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import { Button, Icon } from '@powdr/components';
import { DateFormats, ProductAttributes } from '@powdr/constants/constants';
import { numberToDollar } from '@powdr/utils';

import {
  TicketOverlay,
  Ticket,
  TicketHeader,
  Price,
  Variant,
  TicketDate,
  TicketCount,
} from './styles';

export const LiftTicket = ({
  age,
  className,
  date,
  description,
  duration,
  variantId,
  isAvailable,
  max,
  min,
  handleTicketChange,
  price,
  productAttribute,
}) => {
  const [ticketCount, setTicketCount] = useState(0);

  const onClickDecrement = () => ((ticketCount > min)
    ? setTicketCount(parseInt(ticketCount, 10) - 1)
    : min);

  const onClickIncrement = () => ((ticketCount < max)
    ? setTicketCount(parseInt(ticketCount, 10) + 1)
    : max);

  const onChangeTicketCount = useCallback((e) => {
    const { value } = e.target;
    if (value < min) {
      setTicketCount(min);
    } else if (value > max) {
      setTicketCount(max);
    } else {
      setTicketCount(parseInt(value, 10) || 0);
    }
  }, [min, max]);
  useEffect(() => {
    // Decided to send everything back for ease of add to cart/order summary
    // shouldn't be too hefty as it only update on input interaction
    handleTicketChange({
      age,
      date,
      description,
      duration,
      count: ticketCount,
      id: variantId,
      price,
    });
  }, [
    age,
    date,
    description,
    duration,
    handleTicketChange,
    price,
    ticketCount,
    variantId,
  ]);
  return (
    <TicketOverlay key={variantId} className={className}>
      <Ticket>
        <TicketHeader>
          {age}
          { description && (
            <span>
              {` (${description})`}
            </span>
          )}
        </TicketHeader>
        <Price isAvailable={isAvailable}>{isAvailable ? `$${numberToDollar(price)}` : 'Sorry this Ticket is Unavailable'}</Price>
        <Variant>
          {duration}
        </Variant>
        <TicketDate>
          {`${productAttribute === ProductAttributes.MULTI_DAY ? 'Starting ' : ''}${format(date, DateFormats.FULL_DATE_LONG)}`}
        </TicketDate>
        <TicketCount
          defaultValue={0}
          // if we use useFieldArray this needs to be an index from the append, not variant Id
          name={variantId}
        >
          <Button disabled={ticketCount === min || !isAvailable} onClick={onClickDecrement}>
            <Icon name="ui-minus" width="25" height="25" />
          </Button>
          <input
            disabled={!isAvailable}
            max={isAvailable ? 100 : 0}
            min={0}
            onChange={onChangeTicketCount}
            value={parseInt(ticketCount, 10)}
          />
          <Button disabled={ticketCount === max || !isAvailable} onClick={onClickIncrement}>
            <Icon name="ui-plus" width="25" height="25" />
          </Button>
        </TicketCount>
      </Ticket>
    </TicketOverlay>
  );
};

LiftTicket.propTypes = {
  className: PropTypes.string,
  age: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  description: PropTypes.string,
  duration: PropTypes.string.isRequired,
  isAvailable: PropTypes.bool,
  max: PropTypes.number,
  min: PropTypes.number,
  handleTicketChange: PropTypes.func,
  price: PropTypes.number,
  productAttribute: PropTypes.string.isRequired,
  variantId: PropTypes.string,
};

LiftTicket.defaultProps = {
  className: null,
  description: null,
  isAvailable: true,
  max: 100,
  min: 0,
  handleTicketChange: () => {},
  price: 0,
  variantId: null,
};
