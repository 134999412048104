export const Environments = {
  LOCAL: 'local',
  DEVELOPMENT: 'development',
  PRODUCTION: 'production',
};

export const Property = {
  COPPERCOLORADO: 'coppercolorado',
  ELDORA: 'eldora',
  KILLINGTON: 'killington',
  LEECANYONLV: 'leecanyonlv',
  MTBACHELOR: 'mtbachelor',
  PICOMOUNTAIN: 'picomountain',
  POWDR: 'powdr',
  POWDRPLAYITFORWARD: 'powdrplayitforward',
  RENDEZVAN: 'rendezvan',
  RIDEBOREAL: 'rideboreal',
  SKISILVERSTAR: 'skisilverstar',
  SKISODASPRINGS: 'skisodasprings',
  SNOWBIRD: 'snowbird',
  STOVEPIPEDEATHVALLEY: 'stovepipedeathvalley',
  SUNCOUNTRYTOURS: 'suncountrytours',
  WOODWARDBEND: 'woodwardbend',
  WOODWARDOCEANCITY: 'woodwardoceancity',
  WOODWARDPA: 'woodwardpa',
  WOODWARDPARKCITY: 'woodwardparkcity',
  WOODWARDWEST: 'woodwardwest',
  WORLDOFWOODWARD: 'worldofwoodward',
};

export const PropertyCMSPath = {
  [Property.COPPERCOLORADO]: 'copper',
  [Property.ELDORA]: Property.ELDORA,
  [Property.KILLINGTON]: Property.KILLINGTON,
  [Property.LEECANYONLV]: Property.LEECANYONLV,
  [Property.MTBACHELOR]: Property.MTBACHELOR,
  [Property.PICOMOUNTAIN]: Property.PICOMOUNTAIN,
  [Property.POWDR]: 'powdrsite2',
  [Property.POWDRPLAYITFORWARD]: Property.POWDRPLAYITFORWARD,
  [Property.RENDEZVAN]: Property.RENDEZVAN,
  [Property.RIDEBOREAL]: 'boreal',
  [Property.SKISILVERSTAR]: 'silverstar',
  [Property.SKISODASPRINGS]: Property.SKISODASPRINGS,
  [Property.SNOWBIRD]: Property.SNOWBIRD,
  [Property.STOVEPIPEDEATHVALLEY]: Property.STOVEPIPEDEATHVALLEY,
  [Property.SUNCOUNTRYTOURS]: Property.SUNCOUNTRYTOURS,
  [Property.WOODWARDBEND]: Property.WOODWARDBEND,
  [Property.WOODWARDOCEANCITY]: Property.WOODWARDOCEANCITY,
  [Property.WOODWARDPA]: Property.WOODWARDPA,
  [Property.WOODWARDPARKCITY]: Property.WOODWARDPARKCITY,
  [Property.WOODWARDWEST]: Property.WOODWARDWEST,
  [Property.WORLDOFWOODWARD]: Property.WORLDOFWOODWARD,
  DEFAULT: 'default',
};

export const DrupalComponents = {
  UI_SECTION: 'paragraph__ui_section',
  UI_COLUMN: 'paragraph__ui_column',
  UI_HTML: 'paragraph__ui_html',
  UI_HEADER: 'paragraph__ui_header',
  UI_FOOTER: 'paragraph__ui_footer',
  UI_IMAGE: 'paragraph__ui_image',
  UI_EMBED: 'paragraph__ui_embed',
  UI_TABLE: 'paragraph__ui_table',
  UI_IFRAME: 'paragraph__ui_iframe',
  UI_FORM: 'paragraph__ui_form',
  FORM_FORMBUCKET: 'paragraph__form_formbucket',
  FORM_SILVERPOP: 'paragraph__form_silverpop',
  COLLAPSE_PANEL: 'paragraph__collapse_panel',
  CONTENT_BLOCK_HERO: 'paragraph__content_block_hero',
  GLOBAL_CONTENT: 'paragraph__global_content',
  GRID: 'paragraph__grid',
  CONTENT_BLOCK: 'paragraph__content_block',
  CHAT_SATISFI: 'paragraph__chat_satisfi',
  CHAT_TRACKHS: 'paragraph__chat_trackhs',
  CONTENT_BLOCK_V2: 'paragraph__content_block_v2',
  CONTENT_BLOCK_CAROUSEL: 'paragraph__content_block_carousel',
  CONTENT_BLOCK_GROUP: 'paragraph__content_block_group',
  SECTION_CONTENT_TABS: 'paragraph__section_content_tabs',
  COMPARE_CARDS: 'paragraph__compare_cards',
  QR_QUERY_CARDS: 'paragraph__qr_query_cards',
  DOR_ALERT: 'paragraph__dor_alert',
  DOR_DISPLAY_BOARD: 'paragraph__dor_display_board',
  DOR_FEED_AREA: 'paragraph__dor_feed_area',
  DOR_SNOW_REPORT: 'paragraph__dor_snow_report',
  DOR_TRAIL_REPORT: 'paragraph__dor_trail_report',
  DOR_FEED_DETAILS: 'paragraph__dor_feed_details',
  DOR_LIFTS_TRAILS: 'paragraph__dor_lifts_trails',
  DOR_SNOW_TOTALS: 'paragraph__dor_snow_totals',
  DOR_SECTOR_REPORT: 'paragraph__dor_sector_report',
  DOR_WEATHER: 'paragraph__dor_weather',
  DOR_LIVE_WEATHER: 'paragraph__dor_live_weather',
  DOR_LIVE_24_TABLE: 'paragraph__dor_live_24_table',
  DOR_SEASON_DETAILS: 'paragraph__dor_season_details',
  DOR_WEB_CAM: 'paragraph__dor_web_cam',
  SNAPWIDGET: 'paragraph__snapwidget',
  JUICER_FEED: 'paragraph__juicer_feed',
  EMAIL_SIGNUP: 'paragraph__email_signup',
  VIEW: 'paragraph__view',
  WEBCAM: 'paragraph__webcam',
  BOOKING_WIDGET: 'paragraph__booking_widget',
  ECOMMERCE_WIDGET: 'paragraph__ecommerce_widget',
  SLIDER: 'paragraph__slider',
  PRODUCT_FINDER: 'paragraph__product_finder',
  MOBILE_DRAWER_BUTTON: 'paragraph__mobile_drawer_button',
  FOOTER_SOCIALS: 'paragraph__footer_socials',
  BANNER_AD: 'paragraph__banner_ad',
  FLOATING_IMAGE_SECTION: 'paragraph__floating_image_section',
  DYNAMIC_COMPONENT: 'paragraph__dynamic_component',
  HOTSPOT_GROUP: 'paragraph__hotspot_group',
  HOTSPOT_IMAGE: 'paragraph__hotspot_image',
  LINK_LIST: 'paragraph__link_list',
  PROMOTION_LIST: 'paragraph__promotion_list',
  NAVIGATION_CTA: 'paragraph__navigation_cta',
  WEATHER_INDICATOR: 'paragraph__weather_indicator',
  IMAGE_GRID: 'paragraph__image_grid',
  COLUMM_GROUP: 'paragraph__column_group',
  COLLAPSE_PANEL_GROUP: 'paragraph__collapse_panel_group',
  BANNER_CAROUSEL: 'paragraph__banner_carousel',
  DOR_WEBCAMS: 'paragraph__dor_webcams',
  DOR_MINI_ALERT: 'paragraph__dor_mini_alert',
  TV_FORECAST: 'paragraph__tv_forecast',
  TV_MEASUREMENTS: 'paragraph__tv_measurements',
  PROMOTION_TRAY: 'paragraph__promotion_tray',
  DOR_PARKING: 'paragraph__dor_parking',
  DOR_MEASUREMENTS: 'paragraph__dor_measurements',
};

export const DrupalNodes = {
  GLOBAL_TABLE: 'node__global_table',
  STATIC_FORECAST: 'node__static_forecast',
};

export const DrupalDynamicContentTypes = {
  ACTIVITY: 'node__activity',
  EVENT: 'node__event',
  BLOG: 'node__blog',
  NEWS: 'node__news',
  PRESS_RELEASE: 'node__press_release',
  RESTAURANT: 'node__restaurant',
  SPECIALS: 'node__specials',
  LESSON: 'node__lesson',
};

// Dynamically triggered in View Component via key below
export const ViewComponents = {
  grid: DrupalComponents.GRID,
  content_block_carousel: DrupalComponents.CONTENT_BLOCK_CAROUSEL,
};

export const Components = {
  ...DrupalComponents,
  ...DrupalNodes,
  BUTTON: 'button',
  CHECKBOX: 'checkbox',
  CALENDAR: 'calendar',
  DAY: 'day',
  DISCOUNT_BANNER: 'discountBanner',
  ECOMMERCE_TAB: 'ecommerceTab',
  LIFT_TICKET: 'liftTicket',
  FOOTER: 'footer',
  GLOBAL_FOOTER: 'globalFooter',
  HEADER: 'header',
  FOOTER_NAV: 'footerNav',
  INPUT: 'input',
  PARTNERS: 'partners',
  COPYRIGHT: 'copyright',
  MAIN_NAV: 'mainNav',
  MEGA_NAV: 'megaNav',
  MEGA_CONTAINER: 'megaContainer',
  MOBILE_NAV: 'mobileNav',
  MODAL: 'modal',
  ECOMMERCE_BANNER: 'utilBar',
  WEATHER_FEED: 'weatherFeed',
  PAGE_HEADER: 'pageHeader',
  PROMOTIONS_FEED: 'promotionsFeed',
  PROMOTIONS: 'promotions',
  STICKY_NAV: 'stickyNav',
  TERTIARY_NAV: 'tertiaryNav',
  LEVELS_MODAL: 'levelsModal',
  CONTENT_CAROUSEL: 'contentCarousel',
  DYNAMIC_CONTENT: 'dynamicContent',
  REVERSIBLE_TABLE: 'reversibleTable',
  PAGINATION: 'pagination',
  WYSIWYG: 'wysiwyg',
  TV: 'tv',
  DOR: 'dor',
  PAGE: 'page',
  FEED: 'feed',
  ALERT_BAR: 'alertBar',
  SLIDE: 'slide',
  SOCIAL_SHARE_BUTTONS: 'socialShareButtons',
  LOADER: 'loader',
  COMPONENT_DRAWER: 'componentDrawer',
  SIDEBAR: 'sidebar',
  SIDEBAR_TRAY: 'sidebarTray',
  NAVIGATION_DRAWER: 'navigationDrawer',
  NAVIGATION_TOGGLE: 'navigationToggle',
  DOR_TOGGLE: 'dorToggle',
  DOR_DRAWER: 'dorDrawer',
  CONTENT_TEMPLATE: 'contentTemplate',
};

export const BlockTypes = {
  ARTICLE_PREVIEW: 'article-preview',
  BASIC_3: 'basic-3',
  BASIC_ALT: 'basic-alt',
  BASIC: 'basic',
  CARD_BANNER_ALT: 'card-banner-alt',
  CARD_BANNER: 'card-banner',
  CARD: 'card',
  COVER: 'cover',
  DETAIL_BLOCK: 'detail-block',
  EVENT: 'event',
  FLAG_V2: 'flag-v2',
  FLAG: 'flag',
  FLOAT: 'float',
  FULL_3: 'full-3',
  FULL_ALT: 'full-alt',
  FULL: 'full',
  IMAGE_FLAG: 'image-flag',
  PLAIN: 'plain',
  PRICE_SPLIT: 'price-split',
  PRICE: 'price',
  REVEAL_HORIZONTAL: 'reveal-horizontal',
  REVEAL_VERTICAL_FULL: 'reveal-vertical-full',
  REVEAL_VERTICAL: 'reveal-vertical',
  SOLID: 'solid',
  SPLIT_3: 'split-3',
  SPLIT_ALT: 'split-alt',
  SPLIT: 'split',
  STAT: 'stat',
};

export const FontFamilies = {
  PRI_HDR_BLK: 'PRI_HDR_BLK',
  SEC_HDR_BLK: 'SEC_HDR_BLK',
  PRI_BDY_HVY: 'PRI_BDY_HVY',
  PRI_BDY_MED: 'PRI_BDY_MED',
};

export const HeaderStyles = {
  TRANSLUCENT: 'translucent',
  SOLID: 'solid',
};

export const ScreenType = {
  DESKTOP: 'desktop',
  MOBILE: 'mobile',
};

export const Season = {
  SUMMER: 'summer',
  WINTER: 'winter',
  ALL: 'all',
  ALL_SEASONS: 'all seasons',
};

export const BannerPlacement = {
  IN_BANNER: 'in-banner',
  IN_FEEDS_CONTAINER: 'in-feeds-container',
};

export const SeasonalityPlacement = {
  IN_BANNER: 'in-banner',
  IN_SIDE_TAB: 'in-side-tab',
};

export const NavigationStyles = {
  ITEM_WIDTH: 'item-width',
  FULL_WIDTH: 'full-width',
};

export const ContentBlockGroupLayout = {
  NO_LAYOUT: 'no-layout',
  ONE_ON_LEFT: '1',
  ONE_ON_RIGHT: '2',
  ONE_ON_BOTTOM: '3',
};

export const CompareCardSides = {
  FRONT: 'front',
  BACK: 'back',
};

export const RouteAction = {
  PUSH: 'PUSH',
};

export const Level = {
  LEVEL_0: 'level0',
  LEVEL_1: 'level1',
  LEVEL_2: 'level2',
  LEVEL_3: 'level3',
  LEVEL_4: 'level4',
};

export const Alignment = {
  CENTER: 'center',
  LEFT: 'left',
  RIGHT: 'right',
  JUSTIFY: 'justify',
  INHERIT: 'inherit',
};

export const FetchStatus = {
  IDLE: 'idle',
  LOADING: 'loading',
  PENDING: 'pending',
  SUCCESS: 'success',
  ERROR: 'error',
};

export const VerticalPosition = {
  TOP: 'top',
  MIDDLE: 'middle',
  BOTTOM: 'bottom',
};

export const LinkTargets = {
  SELF: '_self',
  PARENT: '_parent',
  BLANK: '_blank',
  TOP: '_top',
  FRAMENAME: 'framename',
};

// TODO: find all button instances and replace them with this
export const ButtonText = {
  SUBMIT: 'Submit',
  LEARN_MORE: 'Learn More',
  SENDING: 'Sending...',
  SENT: 'Sent!',
  BOOK_NOW: 'BOOK NOW',
  BUY_NOW: 'BUY NOW',
  SEARCH: 'SEARCH',
  PREVIOUS: 'PREVIOUS',
  NEXT: 'NEXT',
  MY_CART: 'My Cart',
  SHOW_FILTERS: 'Show Filters',
  HIDE_FILTERS: 'Hide Filters',
  CLEAR_FILTERS: 'Clear Filters',
};

export const HtmlIngestTypes = {
  INJECTOR: 'injector',
};

export const ProductAttributes = {
  SINGLE_DAY: 'single-day',
  MULTI_DAY: 'multi-day',
};

export const EcommerceMeta = {
  COUNT: 'Count',
  PERIOD: 'Period',
};

export const DateFormats = {
  DAY_OF_MONTH: 'd',
  DAY_OF_WEEK_LETTER: 'EEEEE',
  MONTH_PLUS_YEAR_LONG: 'MMMM yyyy',
  FULL_DATE_LONG: 'MMMM d, yyyy',
  PRETTY_TIMESTAMP: 'MMMM d, yyyy - hh:mm bbb',
};

export const DaysOfWeek = {
  SUNDAY: 'Sunday',
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
  SATURDAY: 'Saturday',
};

export const TimesOfDay = {
  DAY: 'day',
  MORNING: 'morning',
  DAWN: 'dawn',
  SUNRISE: 'sunrise',
  MIDDAY: 'midday ',
  NOON: 'noon',
  AFTERNOON: 'afternoon',
  SUNSET: 'sunset',
  DUSK: 'dusk',
  TWILIGHT: 'twilight',
  EVENING: 'evening',
  NIGHT: 'night',
  MIDNIGHT: 'midnight',
  DAYLIGHT: 'daylight',
};

export const CheckedState = {
  CHECKED: 'checked',
  UNCHECKED: 'unchecked',
};

export const VideoTypes = {
  MP4: 'mp4',
  MOV: 'mov',
};

export const ImageTypes = {
  PNG: 'png',
  JPG: 'jpg',
  GIF: 'gif',
};

export const MediaTypes = {
  VIDEO: [VideoTypes.MP4, VideoTypes.MOV],
  IMAGE: [ImageTypes.PNG, ImageTypes.JPG, ImageTypes.GIF],
};

export const EmbeddableVideoSites = {
  YOUTUBE: 'youtube.com',
  VIMEO: 'vimeo.com',
};

export const MimeTypes = {
  SVG: 'image/svg+xml',
  JPEG: 'image/jpeg',
};

export const DrupalDbNames = {
  RIDEBOREAL: 'boreal',
  PICOMOUNTAIN: 'pico',
  SKISILVERSTAR: 'silverstar',
  COPPERCOLORADO: 'copper',
  SKISODASPRINGS: 'sodasprings',
  RENDEZVAN: 'burningvan',
};

export const QueryParamNames = {
  PRODUCT: 'product',
  VIDEO: 'video',
  SEASON: 'season',
  PAGE: 'page',
  CURRENT_PAGE: 'current_page',
  FILTER: 'filter',
  VIEW: 'view',
};

export const EcommerceViews = {
  CALENDAR: 'calendar',
};

export const ComponentGroups = {
  WYSIWIG: 'WYSIWIG',
  HERO: 'HERO',
};

export const DefaultComponentFontAdjustments = {
  [ComponentGroups.WYSIWIG]: {
    h1: '32px',
    h2: '28px',
    h3: '18px',
    h4: '16px',
    h5: '14px',
    h6: '13px',
    'content-large-breakpoint': '18px',
    content: '16px',
    button: '16px',
  },
  [ComponentGroups.HERO]: {
    title: '45px',
    'title-large-bp': '45px',
    'title-smaller-bp': '35px',
    copy: '24px',
    'copy-larger-bp': '28px',
    'copy-large-bp': '26px',
    'copy-smaller-bp': '20px',
  },
};

export const EcommerceCartTypes = {
  UNITY_COOKIE: 'unity-cookie',
  ASPENWARE_ECOMM_CART: 'ecomm-cart',
};

export const Layouts = {
  STACKED: 'stacked',
  FIFTY_FIFTY: '50-50',
};

export const CookieNames = {
  NOP_COMMERCE: '.Nop.Customer',
};

export const FormElementTypes = {
  TEXTAREA: 'textarea',
  INPUT: 'input',
  SELECT: 'select',
  CHECKBOX: 'checkbox',
};

export const FormElementInputTypes = {
  TEXT: 'text',
  NUMBER: 'number',
};

export const AlignmentShorthand = {
  TOP_LEFT: 'tl',
  TOP_CENTER: 'tc',
  TOP_RIGHT: 'tr',
  CENTER_LEFT: 'cl',
  CENTER_CENTER: 'cc',
  CENTER_RIGHT: 'cr',
  BOTTOM_LEFT: 'bl',
  BOTTOM_CENTER: 'bc',
  BOTTOM_RIGHT: 'br',
};

export const DefaultAriaLabels = {
  CLICK_HERE: 'Click Here',
  LEARN_MORE: 'Learn More',
};

export const AlternatingBlockLayoutOptions = {
  DEFAULT: 'default',
  NON_DEFAULT: 'non-default',
  DEFAULT_ONLY: '',
  NON_DEFAULT_ONLY: 'non-default-only',
  DEFAULT_FIRST: 'default-first',
  NON_DEFAULT_FIRST: 'non-default-first',
};

export const Orientations = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
};

export const FilterType = {
  AND_OR: 'andor',
  OR: 'or',
  AND: 'and',
};

export const KeydownEventCodes = {
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
};

export const NavigationDirection = {
  PREVIOUS: 'prev',
  NEXT: 'next',
};

export const FilterDefaultState = {
  OPEN: 'open',
  CLOSED: 'closed',
};

export const ScrollDirection = {
  X: {
    LEFT: 'left',
    RIGHT: 'right',
  },
  Y: {
    UP: 'up',
    DOWN: 'down',
  },
  NONE: 'none',
};

export const AbsolutePositions = {
  TOP: 'top',
  BOTTOM: 'bottom',
  LEFT: 'left',
  RIGHT: 'right',
};

export const IconGroup = {
  CONTENT: 'content',
  DECORATION: 'decoration',
  DIFFICULTY_LEVEL: 'difficulty-level',
  DOR: 'dor',
  ECOMM: 'ecomm',
  FEATURE: 'feature',
  LIFT: 'lift',
  LIST: 'list',
  PROPERTY: 'property',
  PARTNER: 'partner',
  SOCIAL: 'social',
  STATUS: 'status',
  TIMELINE: 'timeline',
  UI: 'ui',
  WOODWARD: 'woodward',
};
