module.exports = {
  gatsbyConfig: {
    property: 'snowbird',
    siteName: 'Snowbird',
    siteUrl: 'https://www.snowbird.com/',
    reCaptchaDBPropertyName: 'Snowbird',
    reCaptchaPublicKey: '6LfiiYIUAAAAAEvSxGg7A_ZOQRemWMgGBoTr0Z5P',
    color: '#009BD1',
    facebook: 'SnowbirdUT',
    instagram: 'snowbird',
    twitter: 'Snowbird',
    robots: [],
    dynamicTypes: [
      'pressRelease',
      'event',
      'blog',
    ],
  },
};
