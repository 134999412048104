import PropTypes from 'prop-types';
import React from 'react';

import { FooterLinkType } from '@powdr/model';

import {
  StyledFooterNav, LinkList, LinkItem, FooterLink,
} from './styles';

export const FooterNav = ({ footerLinks }) => (
  <StyledFooterNav>
    <LinkList role="navigation">
      {footerLinks.sort((a, b) => a.weight - b.weight).map((link) => (
        <LinkItem key={link.name}>
          <FooterLink title={link.name} href={link.link}>{link.name}</FooterLink>
        </LinkItem>
      ))}
    </LinkList>
  </StyledFooterNav>
);

FooterNav.propTypes = {
  footerLinks: PropTypes.arrayOf(FooterLinkType),
};

FooterNav.defaultProps = {
  footerLinks: [],
};
