import styled, { css } from 'styled-components';

import { Pagination, PrimaryCarousel } from '@powdr/components';
import {
  Breakpoints, Components, FontFamily, FontSize, GlobalLayoutValues, TextTransformOptions,
} from '@powdr/constants';
import { getFontStyles } from '@powdr/utils';

const CPNT = Components.GRID;

export const StyledGrid = styled.div`
  display: flex;
  column-gap: ${({ $columnGap }) => `${$columnGap}%;`};
  row-gap: ${({ $rowGap }) => `${$rowGap}px;`};
  margin: ${({ $topPadding, $bottomPadding }) => `${$topPadding || 0}px 0px ${$bottomPadding || 0}px;`};
  ${({ disableDesktopWrap }) => ((!disableDesktopWrap) && 'flex-wrap: wrap;')}
  ${({ isCenterAlignUnevenRows }) => ((isCenterAlignUnevenRows) && 'justify-content: center;')}

  ${Breakpoints.mobile(css`
    ${({ $isMobileCarousel }) => ($isMobileCarousel) && 'display: none;'};
    flex-wrap: wrap;
    // handle mobile gap logic
    ${({ $rowGap, $columnGapMobile, $rowGapMobile }) => {
    const getRowGap = () => {
      // if $rowGapMobile has a value set (0 is valid)
      if ($rowGapMobile !== null) {
        return $rowGapMobile;
      }

      // adjust desktop row gap value if it is at/above threshold
      if ($rowGap >= GlobalLayoutValues.MOBILE.GRID.MOBILE_ROW_REDUCTION.THRESHOLD) {
        return Math.round(
          $rowGap
            - ($rowGap * GlobalLayoutValues.MOBILE.GRID.MOBILE_ROW_REDUCTION.REDUCTION_PCT),
        );
      }

      // otherwise just return desktop row gap
      return $rowGap;
    };

    return `
      column-gap: ${$columnGapMobile}%;
      row-gap: ${getRowGap()}px;
    `;
  }};
  `)}
`;

export const MobileCarousel = styled(PrimaryCarousel)`
  .carousel-control-group {
    display: grid;
    grid-template-columns: auto 1fr;
    padding-top: 15px;
    padding-left: 0;
    padding-right: 0;

    .bullets-pagination {
      gap: 10px;
      align-items: flex-end;
      flex-wrap: nowrap;
    }
  }

  ${Breakpoints.desktopOnly(css`
    display: none;
  `)}
`;

export const GridPagination = styled(Pagination)``;

export const GridFilters = styled.div``;

export const FilterHeader = styled.h2`
  text-transform: uppercase;
  ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.MED40, undefined, TextTransformOptions.UPPERCASE)};
  margin-bottom: 10px;
`;

export const FilterToggles = styled.ul`
  display: flex;
  flex-wrap: wrap;

  li {
    margin-right: 25px;
    margin-bottom: 10px;
  }
`;
