import styled, { css } from 'styled-components';

import { Components } from '@powdr/constants';

const CPNT = Components.SNAPWIDGET;

export const AlignmentContainer = styled.div`
  display: flex;
`;

export const StyledSnapwidget = styled.iframe`
  border: none;
  overflow: hidden;
  width: 900px;
  height: 300px;
  margin: 0 auto;
`;
