/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';

import {
  ThemeProps as Prop, Breakpoints, Layouts, FontFamily, FontSize, TextTransformOptions,
  Components,
} from '@powdr/constants';
import { getFontStyles, themeUtil } from '@powdr/utils';

const CPNT = Components.DOR_MEASUREMENTS;

export const StyledDorMeasurementItem = styled.div``;
