import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';

import {
  Icon,
  PrimaryCarousel,
} from '@powdr/components';
import {
  CarouselControlPlacement,
  MediaTypes,
  trailTableColumns,
} from '@powdr/constants';

import {
  SettingsShape,
  ThemeShape,
  TrailShape,
} from './dor-prop-types';
import { StyledDorTable } from './styles';

export const DorTrailTableRender = ({
  rows,
  settings,
  theme,
}) => {
  const [checked, setChecked] = useState([]);

  const handleCheckboxChange = (e) => {
    const { target } = e;
    const { value, checked: isChecked } = target;
    let updatedChecked = [];

    if (isChecked) {
      updatedChecked = [...checked, value];
    } else {
      updatedChecked = checked.filter((f) => f !== value);
    }

    setChecked(updatedChecked);
  };

  return (

    <StyledDorTable
      top={settings?.tableThStickyOffset}
      colorProfile={theme?.parentColorProfile}
      className="table table-strip"
    >
      <thead>
        <tr>
          {!!rows?.length > 0 && trailTableColumns
            ?.map((th) => !settings?.hideTrailCol?.includes(th.id) && (
              <th key={th.id} className={`${th.className} ${settings.isDesktop && 'sticky'}`}>
                {th.label}
              </th>
            ))}
        </tr>
      </thead>

      <tbody>

        {rows
          ?.map((td) => (

            <React.Fragment key={td.id}>
              <tr>
                {settings.trailColumn.NAME && (
                  <td className="name">
                    {td.name}
                  </td>
                )}

                {settings.trailColumn.STATUS && (
                  <td className="status">
                    {td.status?.icon && (
                      <Icon
                        className={`icon ${td.status.icon}`}
                        name={`status-${td.status.icon}`}
                        width="23"
                        height="23"
                      />
                    )}
                  </td>
                )}

                {settings.trailColumn.DIFFICULTY && (
                  <td className="difficulty">
                    {td.difficulty?.icon && (
                      <Icon
                        className={`icon ${td.difficulty.icon}`}
                        name={td.difficulty?.icon}
                        width="23"
                        height="23"
                      />
                    )}
                  </td>
                )}

                {settings.trailColumn.FEATURE_DIFFICULTY && (
                  <td className="difficulty">
                    {td.featureDifficulty
                      ?.filter((f) => !settings?.hideFeatureDiff
                        ?.includes(f.icon))
                      .map((f) => f.icon && (
                        <Icon
                          key={f.icon}
                          className={`icon ${f.icon}`}
                          name={f.icon}
                          width="23"
                          height="23"
                        />
                      ))}
                  </td>
                )}

                {settings.trailColumn.FEATURE_SIZE && (
                  <td className="feature-size">
                    {td.featureSize
                      ?.map((f) => f.icon && (
                        <Icon
                          key={f.icon}
                          className="icon"
                          name={f.icon}
                          width="32"
                          height="23"
                        />
                      ))}
                  </td>
                )}

                {settings.trailColumn.GROOMED && (
                  <td className="groomed">
                    {td.groomed?.icon && (
                      <Icon
                        className="icon"
                        name={td.groomed.icon}
                        height="23"
                      />
                    )}
                  </td>
                )}

                {settings.trailColumn.FEATURES && (
                  <td className="icons features">
                    {td.feature?.map((f) => f?.icon && (
                      <Icon
                        key={f.id}
                        className={`icon ${f.id}`}
                        name={f.icon}
                        width="18"
                        height="18"
                      />
                    ))}
                  </td>
                )}

                {settings.trailColumn.NOTES && (
                  <td className="notes">
                    {ReactHtmlParser(td.notes || '')}
                  </td>
                )}

                {settings.trailColumn.DESCRIPTION && (
                  <td className="description">
                    {ReactHtmlParser(td.description || '')}
                  </td>
                )}

                {settings.trailColumn.DETAILS && (
                  <td className="details">
                    <label
                      aria-label="expand button"
                      htmlFor={`expand-${td.id}`}
                      className="expand-input-checkbox"
                    >

                      <input
                        id={`expand-${td.id}`}
                        name={`expand-${td.id}`}
                        onChange={(e) => handleCheckboxChange(e, td.id)}
                        type="checkbox"
                        value={td.id}
                      />

                      <Icon
                        className="icon"
                        name={!checked?.includes(`${td.id}`)
                          ? 'ui-arrow-down'
                          : 'ui-arrow-up'}
                        width="13"
                        height="13"
                      />

                    </label>
                  </td>
                )}
              </tr>

              {settings.trailColumn.DETAILS && checked?.includes(`${td.id}`) && (
                <tr className="expanded-row">
                  <td colSpan={Object.values(settings.trailColumn)?.filter(Boolean).length}>
                    <div
                      className={classNames(
                        'expanded-td',
                        { 'col-2': td.details?.images?.length > 0 },
                      )}
                    >
                      {td.details?.images?.length > 1 ? (
                        <div className="col media">
                          <PrimaryCarousel
                            imagesPerView={1}
                            controlSettings={{
                              iconName: 'ui-arrow-left',
                              size: 15,
                              placement: CarouselControlPlacement.ON_SLIDE,
                            }}
                          >
                            {td.details.images?.map((slide) => {
                              if (slide.url) {
                                if (MediaTypes.IMAGE?.includes(slide.type)) {
                                  return <img key={slide.url} src={slide.url} alt="slide" />;
                                }
                              }
                              return null;
                            })}
                          </PrimaryCarousel>
                        </div>
                      ) : (
                        td.details?.images && td.details?.images[0] && (
                          <div className="col media">
                            <img src={td.details?.images[0]} alt="slide" />
                          </div>
                        )
                      )}

                      {td.details && (
                        <>
                          <div className="col content">
                            {td.label && <h3>{td.label}</h3>}
                            {td.description && ReactHtmlParser(td.description)}
                            {td.details.what && (
                              <p>
                                <strong>What: </strong>
                                {td.details.what}
                              </p>
                            )}

                            {td.details.who && (
                              <p>
                                <strong>Who: </strong>
                                {td.details.who}
                              </p>
                            )}

                            {td.details.where && (
                              <p>
                                <strong>Where: </strong>
                                {td.details.where}
                              </p>
                            )}

                            {td.details.when && (
                              <p>
                                <strong>When: </strong>
                                {td.details.when}
                              </p>
                            )}

                          </div>

                          <button
                            aria-label="More details"
                            className="expand-close-btn"
                            type="button"
                            onClick={() => setChecked((prevChecked) => (prevChecked.includes(td.id)
                              ? prevChecked.filter((item) => item !== td.id)
                              : [...prevChecked, td.id]))}
                          >

                            <Icon
                              className="icon content-expand-info-open"
                              name="content-info-close"
                              width="33"
                              height="33"
                            />

                          </button>
                        </>

                      )}

                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
      </tbody>
    </StyledDorTable>
  );
};

// Define prop types for the component
DorTrailTableRender.propTypes = {
  rows: PropTypes.arrayOf(TrailShape).isRequired,
  settings: SettingsShape.isRequired,
  theme: ThemeShape.isRequired,
};

DorTrailTableRender.defaultProps = {};
