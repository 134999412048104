import styled from 'styled-components';

import { ThemeProps as Prop, Components } from '@powdr/constants';
import { themeUtil, componentColor } from '@powdr/utils';

const CPNT = Components.ECOMMERCE_TAB;

export const TicketOverlay = styled.li`
  height: 275px;
  margin: 0 16px;
  overflow: hidden;
  width: 175px;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &:before, &:after {
    background-color: ${() => themeUtil(Prop.BACKGROUND, CPNT)};
    border: 5px solid ${() => componentColor(Prop.BORDER, CPNT)};
    border-radius: 54px;
    content: ' ';
    display: block;
    height: 54px;
    position: absolute;
    width: 54px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 1;
  }
  &:before {
    top: -35px;
  }
  &:after {
    bottom: -35px;
  }
`;
export const Ticket = styled.div`
  background-color: ${() => themeUtil(Prop.BACKGROUND, CPNT)};
  border: 5px solid ${() => componentColor(Prop.BORDER, CPNT)};
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  //${({ theme }) => theme.fonts.PRI_BDY_HVY};
  height: 100%;
  overflow: hidden;
  padding: 25px 0;
  position: relative;
  text-align: center;
  width: 100%;
  line-height: 1;
`;

export const TicketHeader = styled.h2`
  //font-size: ${({ theme }) => theme.FontSize.MED30};
  text-transform: capitalize;
  span {
    //font-size: ${({ theme }) => theme.FontSize.REG40};
  }
`;

export const Price = styled.div`
  font-size: ${({ isAvailable, theme }) => (isAvailable ? theme.FontSize.MED50 : theme.FontSize.MED10)};
`;
export const Variant = styled.div`
  //font-size: ${({ theme }) => theme.FontSize.REG30};
  font-weight: bold;
`;
export const TicketDate = styled.div`
  //font-size: ${({ theme }) => theme.FontSize.REG20};
`;

export const TicketCount = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  button {
    background: ${() => componentColor(Prop.BTN_BG_ALT, CPNT, Prop.BTN_BG)};
    border-radius: 40px;
    height: 40px;
    padding: 8px;
    margin: 0 5px;
    width: 40px;
  }
  input {
    border: 3px solid ${() => componentColor(Prop.BTN_BG_ALT, CPNT, Prop.BORDER)};
    text-align: center;
    //font-size: ${({ theme }) => theme.FontSize.MED40};
    //${({ theme }) => theme.fonts.PRI_BDY_HVY};
    font-weight: bold;
    height: 60px;
    padding: 0;
    width: 45px;
  }
`;
