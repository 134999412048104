import PropTypes from 'prop-types';
import React from 'react';

import {
  Components, DorMeasurementFieldNames, MeasurementDisplayTypes, StaticProfiles,
  DorRoadStatus,
} from '@powdr/constants';
import { useTrailStatus } from '@powdr/hooks';
import {
  capitalizeAllWords, deslugify, getDorStatusColor,
} from '@powdr/utils';

import { CircularGraph } from './circular-graph';
import {
  CircularGraphInnerTextOverride,
} from './styles';
import { TextMeasurement } from './text-measurement';

export const TrailStatus = ({
  measurementDisplayType,
  parentColorProfile,
  measurementLabel,
  trail,
  icon,
}) => {
  const measurementData = useTrailStatus(trail?.uuid || null);
  const getColorOverride = (value) => getDorStatusColor(
    DorRoadStatus,
    value,
    Components.DOR_MEASUREMENTS,
    StaticProfiles.ALERTS,
  );
  const status = measurementData?.[DorMeasurementFieldNames.STATUS] || null;

  if (!measurementData || !status) return null;

  return (
    <>
      {(measurementDisplayType === MeasurementDisplayTypes.CIRCULAR_GRAPH) && (
        <CircularGraph
          colorProfile={parentColorProfile}
          label={measurementLabel}
          colorOverride={getColorOverride(status)}
        >
          <CircularGraphInnerTextOverride className="inner-content">
            {capitalizeAllWords(deslugify(status))}
          </CircularGraphInnerTextOverride>
        </CircularGraph>
      )}
      {(measurementDisplayType === MeasurementDisplayTypes.TEXT
        || measurementDisplayType === MeasurementDisplayTypes.TEXT_LARGE) && (
        <TextMeasurement
          measurementDisplayType={measurementDisplayType}
          label={measurementLabel}
          icon={icon}
          colorProfile={parentColorProfile}
          colorOverride={getColorOverride(status)}
          small
        >
          {capitalizeAllWords(deslugify(status))}
        </TextMeasurement>
      )}
    </>
  );
};

TrailStatus.propTypes = {
  parentColorProfile: PropTypes.string,
  measurementLabel: PropTypes.string,
  measurementDisplayType: PropTypes.string,
  icon: PropTypes.string,
  trail: PropTypes.shape({
    value: PropTypes.string,
    uuid: PropTypes.string,
  }),
};

TrailStatus.defaultProps = {
  parentColorProfile: null,
  measurementLabel: null,
  measurementDisplayType: null,
  trail: null,
  icon: null,
};
