import axios from 'axios';

import { getDrupalViewApiDomain } from '@powdr/utils';

import { DRUPAL_PENDING, DRUPAL_SUCCESS, DRUPAL_ERROR } from '../types';

export const getViewAll = (view) => async (dispatch, getState) => {
  const { drupal } = getState();

  dispatch({
    type: DRUPAL_PENDING,
    payload: [],
  });

  if (!drupal.drupal?.[view]) {
    try {
      const res = await axios.get(`${getDrupalViewApiDomain()}&view=${view}`);
      dispatch({
        type: DRUPAL_SUCCESS,
        payload: {
          [view]: res.data,
        },
      });
    } catch (error) {
      dispatch({
        type: DRUPAL_ERROR,
        payload: error,
      });
    }
  }
};

export const getViewSingle = (view, slug) => async (dispatch, getState) => {
  const { drupal } = getState();

  dispatch({
    type: DRUPAL_PENDING,
    payload: [],
  });

  // TODO: Not digging this naming, refactor when refactoring headless ecomm
  if (!drupal.drupal[`${view}_${slug}`]) {
    try {
      const res = await axios.get(`${getDrupalViewApiDomain()}&view=${view}&field_slug=${slug}`);
      dispatch({
        type: DRUPAL_SUCCESS,
        payload: {
          [`${view}_${slug}`]: res.data?.[0] || {},
        },
      });
    } catch (error) {
      dispatch({
        type: DRUPAL_ERROR,
        payload: error,
      });
    }
  }
};
