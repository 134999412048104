import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

export const BackgroundWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  overflow: hidden;
  position: relative;
`;

export const ChildrenContent = styled.div`
  display: grid;
  grid-area: 1/1;
  position: relative;
  width: 100%;
  height: auto;
`;

export const BackgroundImage = styled(GatsbyImage)`
  display: grid;
  position: absolute;
  width: 100%;
`;

export const BackgroundVideo = styled.div`
  grid-area: 1/1;
  height: 100%;
  width: 100%;
  overflow-y: hidden;

  video {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;
