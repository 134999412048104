import styled from 'styled-components';

import { Button } from '@powdr/components';
import { ThemeProps as Prop } from '@powdr/constants';
import { themeUtil } from '@powdr/utils';

export const StyledDropdownButton = styled.div`
  display: block;
  position: relative;
  width: 100%;
  height: 100%;

  &:focus-within {
    outline: 2px solid ${({ $colorProfile }) => themeUtil(Prop.BTN_BG, null, $colorProfile)};
    outline-offset: 2px;
  }
`;

export const ToggleButton = styled(Button)`
  width: 100%;
  padding-left: 25px;
  padding-right: 25px;
  justify-content: left;
  transition: transform 0.5s ease-out;

  svg {
    transition: transform 0.5s ease-out;
    ${({ $isOpen }) => (!$isOpen) && 'transform: rotate(180deg);'};
  }
`;

export const ToggleText = styled.div``;

export const ChildComponentWrapper = styled.div`
  display: flex;
`;
