/* eslint-disable import/no-unresolved */
import { useLocation } from '@reach/router';
import PropTypes from 'prop-types';
import React, {
  useContext, useEffect, useState,
} from 'react';

import { Components, ScreenType, BlockTypes } from '@powdr/constants';
import { AppContext } from '@powdr/context/app-context';
import { useSeason, useWindowResize } from '@powdr/hooks';
import { colorProfileByIndex, componentFactory, getBackgroundImageSrc } from '@powdr/utils';

import {
  ComponentWrapper, InnerContainer, StyledUiSection, MaxWidthWrapper,
  OuterWrapper,
} from './styles';

export const UiSection = ({
  // bgImageHPosition,
  // bgImagePosition,
  colorProfile,
  isContainer,
  // gutter,
  hideOnPlatform,
  isHideSection,
  relationships,
  scrollToName,
  isTrapezoidOverlay,
  parentColorProfile,
  componentSeason,
  removePadding,
  topPadding,
  bottomPadding,
  topPaddingMobile,
  bottomPaddingMobile,
  sidePaddingPercentage,
  isIgnoreMaxWidth,
}) => {
  const { isMobile, fullHeaderHeight } = useContext(AppContext);
  const { checkSeasonality } = useSeason();
  const resize = useWindowResize();
  const childComponents = relationships?.children;
  const getScrollToName = () => ((scrollToName) ? scrollToName.replace(/[^a-zA-Z0-9 \-]/ig, '').replace(/ /g, '-').toLowerCase() : '');
  const noPaddingGridBlockTypes = [BlockTypes.COVER];
  const { gatsbyImage: backgroundImage } = relationships?.primaryImage?.relationships?.image || {};
  const [bgImage, setBgImage] = useState('');
  const scrollToHashValue = useLocation()?.hash?.replace('#', '');

  const trueColorProfile = (colorProfile !== null)
    ? colorProfileByIndex(colorProfile)
    : colorProfileByIndex(parentColorProfile);

  useEffect(() => {
    if (resize.windowWidth) {
      setBgImage(getBackgroundImageSrc(backgroundImage, resize.windowWidth));
    }
  }, [backgroundImage, resize.windowWidth]);

  // handle scroll to for section based on URL hash
  useEffect(() => {
    if (scrollToHashValue) {
      const anchorSection = document.getElementById(scrollToHashValue);
      if (anchorSection) {
        window.scrollTo({
          behavior: 'smooth',
          top: anchorSection.offsetTop - fullHeaderHeight - 50,
        });
      }
    }
  }, [scrollToHashValue, fullHeaderHeight]);

  // TODO: Should this be a useCallback so it only fires when components change?
  const hasComponentAsChild = (component) => childComponents?.map(
    (child) => (child.typeName === component),
  ).includes(true);

  // TODO: Should this be a useCallback so it only fires when components change?
  const hasNoPaddingGrid = () => childComponents?.map(
    (child) => (
      !!((child.typeName === Components.GRID
      && noPaddingGridBlockTypes.includes(child?.blockType)))
    ),
  ).includes(true);

  // TODO: Should this be a useCallback so it only fires when isMobile changes?
  // returns true if section should be hidden
  const handleHideSection = () => {
    if (hideOnPlatform) {
      if ((isMobile && hideOnPlatform === ScreenType.MOBILE)
        || (!isMobile && hideOnPlatform === ScreenType.DESKTOP)
      ) return true;
    }
    if (!checkSeasonality(componentSeason)) return true;

    return isHideSection;
  };

  // TODO: handle color profile, classes, and dynamic styles via styled component
  return (
    !handleHideSection() && (
      <StyledUiSection
        id={getScrollToName()}
        colorProfile={trueColorProfile}
        backgroundImage={bgImage}
      >
        <OuterWrapper>
          <InnerContainer
            isContainer={isContainer}
            isMobile={isMobile}
            hasUiHeaderAsChild={hasComponentAsChild(Components.UI_HEADER)}
            hasUiFooterAsChild={hasComponentAsChild(Components.UI_FOOTER)}
            hasNoPaddingGrid={hasNoPaddingGrid()}
            removePadding={removePadding}
            $topPadding={topPadding}
            $bottomPadding={bottomPadding}
            $topPaddingMobile={topPaddingMobile}
            $bottomPaddingMobile={bottomPaddingMobile}
            $sidePadding={sidePaddingPercentage}
          >
            <MaxWidthWrapper $isIgnoreMaxWidth={isIgnoreMaxWidth}>
              { isTrapezoidOverlay && <div className="trapezoid-overlay" /> }
              <ComponentWrapper>
                {(componentFactory(
                  childComponents,
                  trueColorProfile,
                  componentSeason,
                  { hasPaddableGrid: hasNoPaddingGrid() },
                ))}
              </ComponentWrapper>
            </MaxWidthWrapper>
          </InnerContainer>
        </OuterWrapper>
      </StyledUiSection>
    )
  );
};

UiSection.propTypes = {
  // bgImageHPosition: PropTypes.string,
  // bgImagePosition: PropTypes.string,
  colorProfile: PropTypes.number,
  isContainer: PropTypes.bool,
  // gutter: PropTypes.string,
  hideOnPlatform: PropTypes.string,
  isHideSection: PropTypes.bool,
  scrollToName: PropTypes.string,
  componentSeason: PropTypes.string,
  isTrapezoidOverlay: PropTypes.bool,
  removePadding: PropTypes.bool,
  topPadding: PropTypes.number,
  bottomPadding: PropTypes.number,
  topPaddingMobile: PropTypes.number,
  bottomPaddingMobile: PropTypes.number,
  sidePaddingPercentage: PropTypes.number,
  isIgnoreMaxWidth: PropTypes.bool,
  parentColorProfile: PropTypes.string,
  relationships: PropTypes.shape({
    id: PropTypes.string,
    children: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
    primaryImage: PropTypes.shape({
      relationships: PropTypes.shape({
        image: PropTypes.shape(),
      }),
    }),
  }).isRequired,
};

UiSection.defaultProps = {
  // bgImageHPosition: '',
  // bgImagePosition: '',
  colorProfile: null,
  componentSeason: null,
  isContainer: false,
  // gutter: null,
  hideOnPlatform: null,
  isHideSection: false,
  scrollToName: '',
  isTrapezoidOverlay: false,
  removePadding: false,
  topPadding: null,
  bottomPadding: null,
  topPaddingMobile: null,
  bottomPaddingMobile: null,
  sidePaddingPercentage: null,
  isIgnoreMaxWidth: false,
  parentColorProfile: null,
};
