import axios from 'axios';

import { FetchStatus } from '@powdr/constants';
import { getNodeApiDomain } from '@powdr/utils';

import { PARKING_PENDING, PARKING_SUCCESS, PARKING_ERROR } from '../types';

export const getParking = () => async (dispatch, getState) => {
  const parkingStatus = getState().parking.status;

  dispatch({
    type: PARKING_PENDING,
    payload: [],
  });

  // If more than one component calls the same feed make sure we only make one request
  if (parkingStatus === FetchStatus.IDLE) {
    try {
      const res = await axios.get(`${getNodeApiDomain()}/dor/drupal/parking`);
      dispatch({
        type: PARKING_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: PARKING_ERROR,
        payload: error,
      });
    }
  }
};
