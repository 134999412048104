import axios from 'axios';

import { FetchStatus } from '@powdr/constants';
import { getNodeApiDomain } from '@powdr/utils';

import { SNOW_REPORT_PENDING, SNOW_REPORT_SUCCESS, SNOW_REPORT_ERROR } from '../types';

export const getSnowReport = () => async (dispatch, getState) => {
  const snowReportStatus = getState().snowReport.status;

  dispatch({
    type: SNOW_REPORT_PENDING,
    payload: [],
  });

  // If more than one component calls the same feed make sure we only make one request
  if (snowReportStatus === FetchStatus.IDLE) {
    try {
      const res = await axios.get(`${getNodeApiDomain()}/dor/drupal/snow-reports?sort=date&direction=desc&limit=1`);
      dispatch({
        type: SNOW_REPORT_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: SNOW_REPORT_ERROR,
        payload: error,
      });
    }
  }
};
