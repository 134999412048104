/* eslint-disable no-nested-ternary */
import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';

import { Link } from '@powdr/components/link';
import {
  Components,
  ComponentGroups,
  Breakpoints,
  ThemeProps as Prop,
  HeaderStyles,
  AlignmentShorthand,
  Alignment,
  FontFamily,
  FontSize,
} from '@powdr/constants';
import {
  themeUtil, componentFontSize, getFontStyles, rgbaTransparencyToHex,
} from '@powdr/utils';

const CPNT = Components.CONTENT_BLOCK_HERO;

export const StyledHeroSlide = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
`;

export const BackgroundImage = styled(GatsbyImage)`
  grid-area: 1/1;
  height: 100%;
  width: 100%;
  z-index: -1;

  ${Breakpoints.desktopOnly(css`
    ${({ $slideOffsetDirection, $slideOffsetAdjustment, $isAltCopyDisplay }) => (
    ($slideOffsetAdjustment && $slideOffsetDirection && !$isAltCopyDisplay)
      ? `margin-${$slideOffsetDirection}: ${$slideOffsetAdjustment}px; width: auto;`
      : '')};
  `)}

  ${({ $isAltCopyDisplay }) => (($isAltCopyDisplay) ? `
      width: calc(100% - 600px);
      overflow: visible;
      margin-left: 600px;
    ` : '')}
`;

export const BackgroundVideo = styled.div`
  position: relative;
  grid-area: 1/1;
  height: 100%;
  width: 100%;
  z-index: 1;
  overflow: hidden;

  ${Breakpoints.desktopOnly(css`
    ${({ $slideOffsetDirection, $slideOffsetAdjustment }) => (
    ($slideOffsetAdjustment && $slideOffsetDirection)
      ? `margin-${$slideOffsetDirection}: ${$slideOffsetAdjustment}px`
      : '')};
  `)}

  video {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

export const OpacityLayer = styled.div`
  position: relative;
  grid-area: 1/1;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: ${({ $color, $opacity }) => `${$color}${rgbaTransparencyToHex($opacity / 100)}`};
`;

export const VideoFrameContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const VideoFrame = styled.iframe`
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

const leftColumnSharedStyles = css`
  justify-content: left;
  text-align: left;
`;

const centerColumnSharedStyles = css`
  justify-content: center;
  text-align: center;
`;

const rightColumnSharedStyles = css`
  justify-content: right;
  text-align: right;
`;

const topRowSharedStyles = css`
  align-items: flex-start;
`;

const centerRowSharedStyles = css`
  align-items: center;
`;

const bottomRowSharedStyles = css`
  align-items: flex-end;
  padding-bottom: 50px;
`;

const contentAlignmentStyles = (
  contentAlignment,
  isMobile,
  $slideOffsetAdjustment,
  $slideOffsetDirection,
  headerStyleContentAdjustment,
) => {
  if (isMobile) {
    switch (contentAlignment) {
      case AlignmentShorthand.TOP_LEFT:
      case AlignmentShorthand.CENTER_LEFT:
      case AlignmentShorthand.BOTTOM_LEFT:
        return `${leftColumnSharedStyles} ${centerRowSharedStyles}`;

      case AlignmentShorthand.TOP_CENTER:
      case AlignmentShorthand.CENTER_CENTER:
      case AlignmentShorthand.BOTTOM_CENTER:
        return `${centerColumnSharedStyles} ${centerRowSharedStyles}`;

      case AlignmentShorthand.TOP_RIGHT:
      case AlignmentShorthand.CENTER_RIGHT:
      case AlignmentShorthand.BOTTOM_RIGHT:
        return `${rightColumnSharedStyles} ${centerRowSharedStyles}`;

      default: return `${centerColumnSharedStyles} ${centerRowSharedStyles}`;
    }
  }

  switch (contentAlignment) {
    // Left Column
    case AlignmentShorthand.TOP_LEFT:
      return `
        ${leftColumnSharedStyles}
        ${topRowSharedStyles}
        padding-top: ${`${headerStyleContentAdjustment + 50}px;`};
        padding-left: ${(($slideOffsetDirection === Alignment.LEFT) ? (75 + $slideOffsetAdjustment) : 75)}px;
      `;
    case AlignmentShorthand.CENTER_LEFT:
      return `
        ${leftColumnSharedStyles}
        ${centerRowSharedStyles}
        padding-left: ${(($slideOffsetDirection === Alignment.LEFT) ? (75 + $slideOffsetAdjustment) : 75)}px;
      `;
    case AlignmentShorthand.BOTTOM_LEFT:
      return `
        ${leftColumnSharedStyles}
        ${bottomRowSharedStyles}
        padding-left: ${(($slideOffsetDirection === Alignment.LEFT) ? (75 + $slideOffsetAdjustment) : 75)}px;
      `;

    // Center Column
    case AlignmentShorthand.TOP_CENTER: return `${centerColumnSharedStyles} ${topRowSharedStyles} padding-top: ${`${headerStyleContentAdjustment + 50}px;`};`;
    case AlignmentShorthand.CENTER_CENTER: return `${centerColumnSharedStyles} ${centerRowSharedStyles}`;
    case AlignmentShorthand.BOTTOM_CENTER: return `${centerColumnSharedStyles} ${bottomRowSharedStyles}`;

    // Right Column
    case AlignmentShorthand.TOP_RIGHT:
      return `
        ${rightColumnSharedStyles}
        ${topRowSharedStyles}
        padding-top: ${`${headerStyleContentAdjustment + 50}px;`};
        padding-right: ${(($slideOffsetDirection === Alignment.RIGHT) ? (75 + $slideOffsetAdjustment) : 75)}px;
    `;
    case AlignmentShorthand.CENTER_RIGHT:
      return `
        ${rightColumnSharedStyles}
        ${centerRowSharedStyles}
        padding-right: ${(($slideOffsetDirection === Alignment.RIGHT) ? (75 + $slideOffsetAdjustment) : 75)}px;
      `;
    case AlignmentShorthand.BOTTOM_RIGHT:
      return `
        ${rightColumnSharedStyles}
        ${bottomRowSharedStyles}
        padding-right: ${(($slideOffsetDirection === Alignment.RIGHT) ? (75 + $slideOffsetAdjustment) : 75)}px;
      `;

    default: return `${centerColumnSharedStyles} ${centerRowSharedStyles}`;
  }
};

export const SlideContentWrapper = styled.div`
  ${({ hasOverlayImage, $isAltCopyDisplay }) => (
    ($isAltCopyDisplay)
      ? 'display: flex;'
      : (!hasOverlayImage)
        ? 'display: grid;'
        : ''
  )};
  grid-area: 1/1;
  position: relative;
  width: 100%;
  height: auto;
  z-index: 100;
  overflow: hidden;
  ${({
    $isAltCopyDisplay,
    headerStyleContentAdjustment,
    contentAlignment,
    $slideOffsetAdjustment,
    $slideOffsetDirection,
    isMobile,
  }) => (($isAltCopyDisplay) ? `
    text-align: left;
    align-items: flex-end;
    width: 600px;
    padding: 0 225px ${headerStyleContentAdjustment}px 50px;
  ` : `
    ${contentAlignmentStyles(
      contentAlignment,
      isMobile,
      $slideOffsetAdjustment,
      $slideOffsetDirection,
      headerStyleContentAdjustment,
    )};
  `)}

  body.translucent & {
    margin-top: 40px;
  }
`;

export const OverlayImage = styled.div``;

export const SlideContent = styled.div`
  ${({
    $isAltCopyDisplay,
    headerStyleContentAdjustment,
    headerStyle,
    contentAlignment,
  }) => (($isAltCopyDisplay) ? '' : `
    padding-bottom: ${(headerStyle === HeaderStyles.TRANSLUCENT
        && (contentAlignment === AlignmentShorthand.CENTER_CENTER
          || contentAlignment === AlignmentShorthand.CENTER_LEFT
          || contentAlignment === AlignmentShorthand.CENTER_RIGHT))
      ? headerStyleContentAdjustment : 0}px;
  `)}

  ${Breakpoints.mobile(css`
    padding-left: 35px;
    padding-right: 35px;
  `)}
`;

export const HeroIconWrapper = styled.div`
  display: block;
  width: fit-content;
  margin-bottom: 10px;

  svg {
    fill: #FFFFFF;
    color: #FFFFFF;
  }

  ${Breakpoints.mobile(css`
    margin: 0 auto 10px auto;
  `)}
`;

const contentBackgroundColor = (colorProfile) => css`${
  (colorProfile) ? `
    text-shadow: none;
    background-color: ${themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};
    color: ${themeUtil(Prop.HEADER, CPNT, colorProfile)};`
    : ''
}`;

const copyStyles = css`
  ${({ $isDisableTextShadow }) => (($isDisableTextShadow) ? '' : 'text-shadow: 2px 2px 10px #000;')};
  color: #fff;
  max-width: 950px;
  margin: 0 auto;

  ${Breakpoints.smaller(css`
    max-width: 500px;
    a h2 span {
      width: 90%;
    }
  `)}

  ${Breakpoints.large(css`
    max-width: 1050px;
  `)}

  ${Breakpoints.larger(css`
    max-width: 1150px;
  `)}
`;

export const CopyHeader = styled.h1`
  ${copyStyles}
  ${({ $colorProfile }) => (contentBackgroundColor($colorProfile))};
  padding-bottom: ${({ $isDisablePadding }) => (($isDisablePadding) ? '0' : '10px')};

  ${() => getFontStyles(FontFamily.PRIMARY_FONT, undefined, 2, undefined, CPNT, 'header')};

  ${Breakpoints.small(css`
    ${() => getFontStyles(FontFamily.PRIMARY_FONT, undefined, 1.75, undefined, CPNT, 'header')};
  `)}

  ${Breakpoints.smaller(css`
    ${() => getFontStyles(FontFamily.PRIMARY_FONT, undefined, 1.5, undefined, CPNT, 'header')};
  `)}

  ${Breakpoints.mobile(css`
    ${() => getFontStyles(FontFamily.PRIMARY_FONT, undefined, undefined, undefined, CPNT, 'headerMobile')};
  `)}
`;

export const CopyHeaderEmbedded = styled.h1`
  ${copyStyles}
  ${({ $colorProfile }) => (contentBackgroundColor($colorProfile))};
  ${({ theme }) => theme.fonts.PRI_HDR_BLK};
  ${() => componentFontSize(ComponentGroups.HERO, 'title-smaller-bp')};
  padding-bottom: ${({ $isDisablePadding }) => (($isDisablePadding) ? '0' : '10px')};
  margin-bottom: 50px;
`;

export const CopyContent = styled.p`
  ${copyStyles}
  ${({ $colorProfile }) => (contentBackgroundColor($colorProfile))};
  line-height: 1.2;

  ${() => getFontStyles(FontFamily.BASE_FONT, undefined, 1.5, undefined, CPNT, 'copy')};

  ${Breakpoints.large(css`
    ${() => getFontStyles(FontFamily.BASE_FONT, undefined, 1.75, undefined, CPNT, 'copy')};
  `)}

  ${Breakpoints.small(css`
    ${() => getFontStyles(FontFamily.BASE_FONT, undefined, 1.5, undefined, CPNT, 'copy')};
  `)}

  ${Breakpoints.mobile(css`
    ${() => getFontStyles(FontFamily.BASE_FONT, undefined, 1.5, undefined, CPNT, 'copy')};
  `)}
`;

export const SlideButton = styled(Link)`
  display: inline-block;
  padding: 16px 20px;
  text-decoration: none;
  width: auto;
  text-align: center;
  color: ${() => themeUtil(Prop.BTN_TXT, CPNT)};
  background-color: ${() => themeUtil(Prop.BTN_BG, CPNT)};
  transition: color 0.25s ease-out, background-color 0.25s ease-out;
  margin-top: 40px;
  ${() => getFontStyles(FontFamily.SECONDARY_FONT, undefined, undefined, undefined, CPNT, 'button')};

  &:hover, &:focus {
    transition: color 0.25s ease-out, background-color 0.25s ease-out;
    color: ${() => themeUtil(Prop.BTN_TXT_HOVER, CPNT)};
    background-color: ${() => themeUtil(Prop.BTN_BG_HOVER, CPNT)};
  }

  &:focus {
    outline: 2px solid ${() => themeUtil(Prop.BTN_BG_HOVER, CPNT)};
    outline-offset: 2px;
  }

  ${Breakpoints.mobile(css`
    ${() => getFontStyles(FontFamily.SECONDARY_FONT, undefined, 0.8, undefined, CPNT, 'button')};
  `)}
`;

export const EmbeddedVideoButton = styled.button`
  width: 50px;
  height: 50px;
`;
