import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DorParkingTypes, ParkingDisplayTypes } from '@powdr/constants';
import { getParking } from '@powdr/stores/actions/parking-action';

import { Availability, Status } from './components';
import { StyledDorParking } from './styles';

export const DorParking = ({
  className,
  content,
  parkingType,
  displayType,
}) => {
  const dispatch = useDispatch();
  const useParkingData = useSelector((state) => state.parking);
  const { data } = useParkingData;
  const parkingValue = data?.free_parking_inventory?.value || null;
  const parkingMax = data?.free_parking_inventory?.max || null;

  useEffect(() => {
    dispatch(getParking());
  }, [dispatch]);

  return (
    <StyledDorParking className={className}>
      {(parkingType === DorParkingTypes.AVAILABILITY) ? (
        <Availability
          content={content?.value || null}
          data={{ parkingValue, parkingMax }}
          displayType={displayType || ParkingDisplayTypes.DATA_CIRCLE}
        />
      ) : (
        <Status
          content={content?.value || null}
          data={{ highwayParking: data?.highway_parking || null }}
          displayType={displayType || ParkingDisplayTypes.TEXT_STATUS}
        />
      )}
    </StyledDorParking>
  );
};

DorParking.propTypes = {
  className: PropTypes.string,
  content: PropTypes.shape({
    value: PropTypes.string,
  }),
  parkingType: PropTypes.string,
  displayType: PropTypes.string,
};

DorParking.defaultProps = {
  className: '',
  content: null,
  parkingType: null,
  displayType: null,
};
