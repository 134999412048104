// Custom hook for throttling value input
import { useState, useEffect, useRef } from 'react';

export const useThrottleValue = (value, delay) => {
  // State to store the throttled value
  const [throttledText, setThrottledText] = useState(value);
  const lastExecuted = useRef(Date.now()); // A ref to track the last execution time
  const throttleTimer = useRef();

  useEffect(() => {
    if (Date.now() - lastExecuted.current >= delay) {
      // If enough time has passed since the last execution, update the throttled value immediately
      lastExecuted.current = Date.now();
      setThrottledText(value);
    } else {
      // Otherwise, create a timer to update the throttled value after the delay
      throttleTimer.current = setTimeout(() => {
        lastExecuted.current = Date.now();
        setThrottledText(value);
      }, delay);
    }

    // Cleanup function: Clear the timer if the component unmounts or the input changes
    return () => clearTimeout(throttleTimer);
  }, [value, delay]);

  return throttledText; // Return the throttled value
};
