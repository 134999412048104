import styled, { css } from 'styled-components';

import {
  ThemeProps as Prop, Breakpoints, FontFamily, ParkingStatus,
  StaticProfiles,
  Components,
  FontSize,
} from '@powdr/constants';
import { componentColor, getFontStyles, themeUtil } from '@powdr/utils';

const CPNT = Components.DOR_PARKING;

export const StyledDorParking = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
`;
