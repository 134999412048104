import axios from 'axios';

import { FetchStatus } from '@powdr/constants';
import { getNodeApiDomain } from '@powdr/utils';

import { ROADS_PENDING, ROADS_SUCCESS, ROADS_ERROR } from '../types';

export const getRoads = () => async (dispatch, getState) => {
  const roadsStatus = getState().roads.status;

  dispatch({
    type: ROADS_PENDING,
    payload: [],
  });

  // If more than one component calls the same feed make sure we only make one request
  if (roadsStatus === FetchStatus.IDLE) {
    try {
      const res = await axios.get(`${getNodeApiDomain()}/dor/drupal/roads`);
      dispatch({
        type: ROADS_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: ROADS_ERROR,
        payload: error,
      });
    }
  }
};
