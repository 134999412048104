import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getTerrains } from '@powdr/stores';

export const useTerrainStatus = (terrainUUID) => {
  if (!terrainUUID) return null;
  const dispatch = useDispatch();
  const useTerrainsData = useSelector((state) => state.terrains);
  const { data } = useTerrainsData;
  const [terrainStatus, setTerrainStatus] = useState(null);

  useEffect(() => {
    dispatch(getTerrains());
  }, [dispatch]);

  useEffect(() => {
    if (data && data?.length > 0) {
      setTerrainStatus(data.find((r) => r.id === terrainUUID));
    }
  }, [data, terrainUUID]);

  return terrainStatus;
};
