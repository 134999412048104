import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';

import { WysiwygStyleWrapper } from '@powdr/components';
import {
  ThemeProps as Prop, Breakpoints, Alignment, Components, ComponentGroups, VerticalPosition,
} from '@powdr/constants';
import { themeUtil, componentFontSize } from '@powdr/utils';

const CPNT = Components.FLOATING_IMAGE_SECTION;

export const StyledFloatingImageSection = styled.div`
  display: grid;
  background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};

  ${Breakpoints.mobile(css`
    display: flex;
    ${({ mobileImagePosition }) => (
    (mobileImagePosition)
      ? `flex-direction: ${(mobileImagePosition === VerticalPosition.TOP)
        ? 'column-reverse'
        : 'column'};`
      : '')};
  `)}
`;

export const ContentSection = styled.div`
  ${Breakpoints.desktopOnly(css`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    grid-area: 1/1;
    position: relative;
    z-index: 1;

    ${({ contentAlignment }) => `
      padding-${contentAlignment}: 100px;
      justify-content: ${contentAlignment};`};
  `)}

  ${Breakpoints.mobile(css`
    padding: 45px;
  `)}
`;

export const ContentWrapper = styled.div`
  ${Breakpoints.desktopOnly(css`
    width: ${({ width }) => `${width}%`};
  `)}
`;

export const Content = styled(WysiwygStyleWrapper)``;

export const ImageWrapper = styled.div`
  ${Breakpoints.desktopOnly(css`
    grid-area: 1/1;
  `)}
`;

export const FloatingImageContainer = styled.div`
  ${Breakpoints.desktopOnly(css`
    width: 100%;
  `)}
`;

export const FloatingImage = styled.div`
  height: ${({ height }) => `${height}px`};
`;

export const Image = styled(GatsbyImage)`
  ${Breakpoints.desktopOnly(css`
    height: ${({ height }) => `${height}px`};
    width: 100%;
  `)}

  ${Breakpoints.mobile(css`
    display: block;
    margin: 0;
  `)}

  // fix for FF and Safari image bug
  &.gatsby-image-wrapper [data-main-image] {
    width: auto !important;
  }
`;
