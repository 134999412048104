import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';

import { DateFormats } from '@powdr/constants/constants';
import { componentFactory, isEmpty, numberToDollar } from '@powdr/utils';

import { DiscountBanner } from '../../discount-banner';

import {
  OrderSummaryWrapper, OSHeader, OSProductDetailBlockWrapper, OSProductDetailBlock,
  ItemInfoWrapper, InfoTicketWrapper, InfoTicket, InfoTicketMainText, InfoTicketSubText,
  ItemQuantityBlock, ItemQuantityBlockNumber, ItemQuantityBlockSubText, ItemInfoBlock,
  ItemInfoBlockTitle, ItemInfoBlockDate, ItemPricingWrapper, ItemPricingBlockPriceDivision,
  ItemPricingBlockPrice, OrderSubtotalWrapper, SubtotalLabel, SubtotalNumber, OrderButtons,
  CheckoutButton, CheckoutButtonInner, CheckoutText, CheckoutIcon, OrderCompletionSection,
  ContinueShoppingButton, PromotionalSection, PromotionalSectionHeader, ScrollSpacer,
} from './styles';

export const OrderSummary = ({
  additionalComponents,
  cart,
  cartCheckoutLink,
  cartCheckoutButtonText,
  cartItems,
  discountMaxPrice,
  discountMinPrice,
  handleCloseOrderSummary,
  subtotal,
}) => {
  if (isEmpty(cart) || isEmpty(cartItems)) {
    return null;
  }
  return (
    <OrderSummaryWrapper>
      <OSHeader>Added to cart:</OSHeader>
      <OSProductDetailBlockWrapper>
        { Object.keys(cart).map((id) => {
          const orderItem = cartItems?.[id];
          if (
            orderItem.id !== id
            || !cart[id]?.item?.id
            || cart[id]?.errors?.length > 0
          ) {
            return null;
          }
          return (
            <OSProductDetailBlock key={id}>
              <ItemInfoWrapper>
                <InfoTicketWrapper>
                  <InfoTicket>
                    <InfoTicketMainText>{orderItem.age}</InfoTicketMainText>
                    <InfoTicketSubText>{orderItem.description}</InfoTicketSubText>
                  </InfoTicket>
                </InfoTicketWrapper>
                <ItemQuantityBlock>
                  <ItemQuantityBlockNumber>{orderItem.count}</ItemQuantityBlockNumber>
                  <ItemQuantityBlockSubText>QTY</ItemQuantityBlockSubText>
                </ItemQuantityBlock>
                <ItemInfoBlock>
                  {/* TODO: no idea if "Lift Ticket" should be hardcoded or not */}
                  <ItemInfoBlockTitle>{`${orderItem.duration} Lift Ticket`}</ItemInfoBlockTitle>
                  <ItemInfoBlockDate>
                    {format(orderItem.date, DateFormats.FULL_DATE_LONG)}
                  </ItemInfoBlockDate>
                </ItemInfoBlock>
              </ItemInfoWrapper>
              <ItemPricingWrapper>
                <ItemPricingBlockPriceDivision>
                  {`$${numberToDollar(orderItem.price)}/each`}
                </ItemPricingBlockPriceDivision>
                <ItemPricingBlockPrice>{`$${numberToDollar(orderItem.price * orderItem.count)}`}</ItemPricingBlockPrice>
              </ItemPricingWrapper>
            </OSProductDetailBlock>
          );
        })}
      </OSProductDetailBlockWrapper>
      <OrderCompletionSection>
        <OrderSubtotalWrapper>
          <SubtotalLabel>Subtotal:</SubtotalLabel>
          <SubtotalNumber>{`$${numberToDollar(subtotal)}`}</SubtotalNumber>
        </OrderSubtotalWrapper>
        <OrderButtons>
          <ContinueShoppingButton onClick={handleCloseOrderSummary}>
            Continue Shopping
          </ContinueShoppingButton>
          <CheckoutButton href={cartCheckoutLink}>
            <CheckoutButtonInner>
              <CheckoutText>{cartCheckoutButtonText}</CheckoutText>
              <CheckoutIcon name="ecomm-secure-checkout" width="20" />
            </CheckoutButtonInner>
          </CheckoutButton>
        </OrderButtons>
        <DiscountBanner maxPrice={discountMaxPrice} minPrice={discountMinPrice} />
        {(additionalComponents?.length > 0) && (
          <PromotionalSection>
            <PromotionalSectionHeader>Have you considered?</PromotionalSectionHeader>
            {additionalComponents.map(
              (component) => componentFactory([component]),
            )}
          </PromotionalSection>
        )}
        <ScrollSpacer />
      </OrderCompletionSection>
    </OrderSummaryWrapper>
  );
};

OrderSummary.propTypes = {
  additionalComponents: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  cart: PropTypes.instanceOf(Object),
  cartCheckoutButtonText: PropTypes.string,
  cartCheckoutLink: PropTypes.string,
  cartItems: PropTypes.instanceOf(Object),
  discountMinPrice: PropTypes.number,
  discountMaxPrice: PropTypes.number,
  handleCloseOrderSummary: PropTypes.func,
  subtotal: PropTypes.number,
};

OrderSummary.defaultProps = {
  additionalComponents: [],
  cart: {},
  cartCheckoutButtonText: '',
  cartCheckoutLink: '',
  cartItems: {},
  discountMinPrice: 0,
  discountMaxPrice: 0,
  handleCloseOrderSummary: () => {},
  subtotal: 0,
};
