import PropTypes from 'prop-types';
import React from 'react';

import { ColorProfiles } from '@powdr/constants';

import { StyledReversibleTable } from './styles';

export const ReversibleTable = ({
  className,
  data,
  colorProfile,
}) => {
  const { isReversedData, tables } = data;

  return (
    <StyledReversibleTable
      className={className}
      isReversed={(isReversedData === '1')}
      colorProfile={colorProfile}
    >
      <table>
        {(isReversedData !== '1') && (
          <thead>
            <tr>
              {tables.value?.[0].filter((i) => i).map((item) => (<th key={item}>{item}</th>))}
            </tr>
          </thead>
        )}
        {((isReversedData === '1')) && (
          <tfoot>
            <tr>
              {tables.value?.[0].filter((i) => i).map((item) => (<th key={item}>{item}</th>))}
            </tr>
          </tfoot>
        )}
        <tbody>
          <tr>
            {tables.value?.[1].filter((i) => i).map((item) => (<td key={item}>{item}</td>))}
          </tr>
        </tbody>
      </table>
    </StyledReversibleTable>
  );
};

ReversibleTable.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape().isRequired,
  colorProfile: PropTypes.string,
};

ReversibleTable.defaultProps = {
  className: '',
  colorProfile: ColorProfiles.BASE,
};
