import PropTypes from 'prop-types';
import React from 'react';

import { HtmlContentLoader, WysiwygStyleWrapper } from '@powdr/components';
import { Alignment } from '@powdr/constants';

import { StyledUiHtml, StyledIcon } from './styles';

export const UiHtml = ({
  html,
  isHideComponent,
  iconName,
  parentColorProfile,
  alignment,
  paddingTop,
  paddingBottom,
}) => (
  (!isHideComponent) && (
  <StyledUiHtml className="ui-html" colorProfile={parentColorProfile} $alignment={alignment} $paddingTop={paddingTop} $paddingBottom={paddingBottom}>
    {iconName && (<StyledIcon name={iconName} width="50" height="50" />)}
    <WysiwygStyleWrapper colorProfile={parentColorProfile}>
      <HtmlContentLoader html={html?.value} allowRerender />
    </WysiwygStyleWrapper>
  </StyledUiHtml>
  )
);

UiHtml.propTypes = {
  html: PropTypes.shape({
    value: PropTypes.string,
  }).isRequired,
  isHideComponent: PropTypes.bool,
  iconName: PropTypes.string,
  parentColorProfile: PropTypes.string,
  alignment: PropTypes.string,
  paddingTop: PropTypes.number,
  paddingBottom: PropTypes.number,
};

UiHtml.defaultProps = {
  isHideComponent: false,
  iconName: '',
  parentColorProfile: null,
  alignment: Alignment.LEFT,
  paddingTop: 0,
  paddingBottom: 0,
};
