import PropTypes from 'prop-types';
import React from 'react';
import { CookiesProvider } from 'react-cookie';

import { AppContextProvider } from '@powdr/context';

// This is a Gatsby browser/SSR API component so do not change the name
export const wrapRootElement = ({ element }) => (
  <AppContextProvider>
    <CookiesProvider>
      {element}
    </CookiesProvider>
  </AppContextProvider>
);

wrapRootElement.propTypes = {
  element: PropTypes.instanceOf(Object).isRequired,
  props: PropTypes.instanceOf(Object).isRequired,
};
