import { stripDrupalFieldFromObjKeys } from '@powdr/utils';

export const trackViewItemList = (trackingItems) => {
  window.dataLayer.push({
    event: 'view_item_list',
    ecommerce: {
      items: trackingItems.map((o, i) => ({
        ...stripDrupalFieldFromObjKeys(o),
        index: i,
      })),
    },
  });
};

export const trackSelectItem = (trackingItem) => {
  window.dataLayer.push({
    event: 'select_item',
    ecommerce: {
      items: [trackingItem].map((o, i) => ({
        ...stripDrupalFieldFromObjKeys(o),
        index: i,
      })),
    },
  });
};
