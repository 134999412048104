import PropTypes from 'prop-types';
import React, {
  useEffect,
} from 'react';

import { useStaticData } from '@powdr/web/src/hooks';

import { StyledDorWebCam } from './styles';

export const DorWebCam = ({ title, url }) => {
  const { property } = useStaticData();

  useEffect(() => { }, []);

  return (
    <StyledDorWebCam className="dor-web-cam" property={property}>
      <img src={url} alt={title} />
    </StyledDorWebCam>
  );
};

DorWebCam.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
DorWebCam.defaultProps = {};
