import { useEffect } from 'react';

export const useMutationObserver = (
  ref,
  callback,
  options = {
    attributes: true,
    characterData: true,
    childList: true,
    subtree: true,
  },
) => {
  useEffect(() => {
    // Create an observer instance linked to the callback function
    if (ref) {
      const observer = new MutationObserver(callback);

      // Start observing the target node for configured mutations
      observer.observe(ref, options);

      return () => {
        observer.disconnect();
      };
    }
    return null;
  }, [callback, options]);
};
