import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

import { fixCmsLinks } from '@powdr/utils';

export const HtmlContentLoader = ({
  html,
  allowRerender,
  ...rest
}) => {
  const divRef = useRef(null);
  const isFirstRender = useRef(true);

  if (!html) return null;

  useEffect(() => {
    if (!divRef.current) return;
    if (!isFirstRender.current) return;
    isFirstRender.current = Boolean(allowRerender);

    const slotHtml = document
      .createRange()
      .createContextualFragment(fixCmsLinks(html));
    divRef.current.innerHTML = '';
    divRef.current.appendChild(slotHtml);
  }, [html, divRef, allowRerender]);

  return React.createElement('div', { ...rest, ref: divRef });
};

HtmlContentLoader.propTypes = {
  html: PropTypes.string.isRequired,
  allowRerender: PropTypes.bool,
};

HtmlContentLoader.defaultProps = {
  allowRerender: false,
};
