import PropTypes from 'prop-types';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import { CalendarBadgeType } from '@powdr/model';
import { getDateInfo } from '@powdr/utils';

import {
  StyledCalendarBadge, DateDayMonthWrapper, DayOfWeekText, DateMonthWrapper, DayOfMonthText, Month,
} from './styles';

export const CalendarBadge = ({
  startDate,
  endDate,
  type,
  colorProfile,
}) => {
  const dateInfo = getDateInfo(startDate, endDate, type);

  return (
    <StyledCalendarBadge className="calendar-badge" colorProfile={colorProfile}>
      <DateDayMonthWrapper className="date-day-month-wrapper" colorProfile={colorProfile}>
        <DayOfWeekText className="day-of-week-text" colorProfile={colorProfile}>{dateInfo.dayOfWeek}</DayOfWeekText>
        <DateMonthWrapper className="date-month-wrapper" colorProfile={colorProfile}>
          <DayOfMonthText
            className="day-of-month-text"
            colorProfile={colorProfile}
            isDateRange={dateInfo.isDateRange}
          >
            {ReactHtmlParser(dateInfo.day)}
          </DayOfMonthText>
          <Month className="month" colorProfile={colorProfile}>{dateInfo.month}</Month>
        </DateMonthWrapper>
      </DateDayMonthWrapper>
    </StyledCalendarBadge>
  );
};

CalendarBadge.propTypes = PropTypes.objectOf(CalendarBadgeType).isRequired;

CalendarBadge.defaultProps = {
  type: null,
};
